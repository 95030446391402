import ChainGroup from '../interfaces/chain-group.enum';

export default class ChainSettingsModel {
    groupBy: ChainGroup = ChainGroup.CLUSTER;
    provider: string = 'booking';
    brand: string = '';
    country: string = '';
    city: string = '';
    region: string = '';
}
