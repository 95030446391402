import { Expose, plainToClass, Transform } from 'class-transformer';
import { ITheme } from './cars-filters.model';

export default class BranchesModel {
    @Expose()
    @Transform((_, plain) => {
        const subordinatesRaw = plain.subordinates;

        if (!subordinatesRaw) {
            return [];
        }

        const subordinates: BranchesModel[] = [];
        subordinatesRaw.forEach((item: BranchesModel) => {
            const data = plainToClass(
                BranchesModel,
                <BranchesModel> item,
                { excludeExtraneousValues: true },
            );
            subordinates.push(data);
        });
        return subordinates;
    })
    childChains?: BranchesModel[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.chainId)
    chainId: string | null = null;

    @Expose()
    @Transform((_, plain) => {
        if (plain.chainBrandName) {
            return plain.chainBrandName;
        }
        return plain.chainName;
    })
    chainName: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.useParentSippCode)
    useParentSippCode: boolean = false;

    @Expose()
    @Transform((_, plain) => {
        if (plain.theme) {
            return plain.theme.logo;
        }
        return null;
    })
    logo!: string;

    @Expose()
    @Transform((_, plain) => {
        if (plain.theme) {
            if (plain.theme.menu_logo) {
                return plain.theme.menu_logo;
            }
            return plain.theme.logo;
        }
        return null;
    })
    menuLogo!: string;

    @Expose()
    @Transform((_, plain) => plain.isCurrent || false)
    isCurrent: boolean = false;

    @Expose()
    @Transform((_, plain) => plain.isBroker || false)
    isBroker: boolean = false;

    @Expose()
    @Transform((_, plain) => {
        if (!plain.theme || Object.keys(plain.theme).length === 0) {
            return null;
        }
        return plain.theme;
    })
    theme: ITheme | null = null;

    @Expose()
    @Transform((_, plain) => plain.isVansFeatureEnabled || false)
    isVansFeatureEnabled: boolean = false;

    @Expose()
    @Transform((_, plain) => plain.enableCustomHolidays || false)
    enableCustomHolidays: boolean = false;
}
