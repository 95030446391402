import { Module, VuexModule } from 'vuex-module-decorators';
import CarsAlertConfigurationModel from '@/modules/cars/models/cars-alert-configuration.model';
import LoadingModel from '@/modules/common/models/loading.model';

export interface CarAlertSettings {
    alertType: String[]
}

@Module
export default class CarsAlertStore extends VuexModule {
    document: CarsAlertConfigurationModel[] | null = null;
    loading: LoadingModel = new LoadingModel();
    saveRequestLoading = new LoadingModel();
    settings: CarAlertSettings = {
        alertType: [],
    };
}
