export enum MileagePolicy {
    UNLIMITED = 0,
    LIMITED = 1,
}

export enum TransmissionType {
    MANUAL = 0,
    AUTOMATIC = 1,
}

export enum RateType {
    PAY_LATER = 0,
    PAY_NOW = 1,
    PARTIAL_PREPAYMENT = 2,
}

export enum Availability {
    AvailableAsNum = 1,
    AvailableAsStr = 'Available',
    PriceIsNotAvailable = 'Price is not available',
}

export default class TransformUtil {
    public static convertCarOfferNumericalValuesToStrings(inputCarOffer: any): any {
        const carOffer = { ...inputCarOffer };
        if (carOffer.availability === Availability.AvailableAsNum) {
            carOffer.availability = Availability.AvailableAsStr;
        }
        if (typeof carOffer.mileage_policy === 'number') {
            carOffer.mileage_policy = carOffer.mileage_policy === MileagePolicy.UNLIMITED ? 'Unlimited' : 'Limited';
        }
        if (typeof carOffer.transmission === 'number') {
            carOffer.transmission = carOffer.transmission === TransmissionType.MANUAL ? 'Manual' : 'Automatic';
        }
        if (typeof carOffer.rate_type === 'number') {
            switch (carOffer.rate_type) {
                case RateType.PAY_NOW:
                    carOffer.rate_type = 'Pay now';
                    break;
                case RateType.PARTIAL_PREPAYMENT:
                    carOffer.rate_type = 'Partial prepayment';
                    break;
                default:
                    carOffer.rate_type = 'Pay later';
            }
        }

        return carOffer;
    }
}
