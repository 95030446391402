import { Inject, injectable } from 'inversify-props';
import { Route } from 'vue-router';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { IGuard } from '@/router/interfaces/guard.interface';

export const HotelOrCarGuardS = Symbol.for('HotelOrCarGuardS');
@injectable(HotelOrCarGuardS as unknown as string)
export default class HotelOrCarGuard implements IGuard {
    @Inject(UserServiceS) private userService!: UserService;

    async canActivate(to: Route, from: Route) {
        return this.userService.isCarUser || this.userService.isHotelUser || false;
    }
}
