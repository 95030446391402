import { Expose, plainToClass, Transform } from 'class-transformer';
import Day from '@/modules/common/types/day.type';
import { IsNumber, IsOptional, Validate } from 'class-validator';
import PriceHistoryTrendDataValidator from './validators/trend-data.validator';

export default class RatesPriceHistoryFilterAllModel {
    @Expose()
    @Transform((_, plain) => plain.day)
    @IsNumber()
    @IsOptional()
    requestDay: Day | null = null;

    @Expose()
    @Transform((_, plain) => {
        const trendData: any = {};

        if (!plain.trend_data) {
            return plain.trend_data;
        }

        Object.keys(plain.trend_data).forEach(date => {
            trendData[date] = {};
            // CORD-1651 TODO: Remove it when will be ready fix on the backend
            const wrongProviders = ['expedia_mobile_app_mobile_app', 'booking_mobile_app_mobile_app', 'booking_basic_mobile_app_mobile_app'];

            Object.keys(plain.trend_data[date]).forEach(provider => {
                if (plain.trend_data[date][provider].statistics && !wrongProviders.includes(provider)) {
                    trendData[date][provider] = { statistics: {}, link: null };
                    trendData[date][provider].statistics = plain.trend_data[date][provider].statistics;
                    trendData[date][provider].link = plain.trend_data[date][provider].link;
                }
            });
        });

        return trendData;
    })
    @Validate(PriceHistoryTrendDataValidator)
    trendData!: {
        [date: string]: {
            [provider: string]: {
                statistics: {
                    lowest: number,
                },
                link: string | null,
            }
        } | null
    };
}
