import { Expose, plainToClass, Transform } from 'class-transformer';
import FleetAvailabilityItemModel from '@/modules/cars/models/fleet-availability-item.model';
/* eslint-disable no-underscore-dangle */

export default class FleetAvailabilityDocumentModel {
    @Expose()
    @Transform((_, plain) => plain._id)
    id!: number;

    @Expose()
    @Transform((_, plain) => {
        const occupancyDates = plain.occupancy_dates;

        if (!occupancyDates) {
            return occupancyDates;
        }

        Object.keys(occupancyDates).forEach(carCategory => {
            Object.keys(occupancyDates[carCategory]).forEach(day => {
                occupancyDates[carCategory][Number(day)] = plainToClass(
                    FleetAvailabilityItemModel,
                    <FleetAvailabilityItemModel> occupancyDates[carCategory][Number(day)],
                    { excludeExtraneousValues: true },
                );
            });
        });
        return occupancyDates;
    })
    occupancyDates!: {
        [carCategory: string]: {
            [day: number]: {
                [carClass: string]: FleetAvailabilityItemModel | null
            }
        } | null
    };

    @Expose()
    @Transform((_, plain) => plain.file_name)
    fileName: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.updated_at && new Date(plain.updated_at))
    updatedDate!: Date;
}
