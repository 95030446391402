import { Inject, injectable } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import Day from '@/modules/common/types/day.type';
import { metricType } from '@/modules/common/types/metric.type';
import COMPARED_TO_SETTINGS from '@/modules/common/constants/compared-to.settings.constant';
import UserSettings from '@/modules/user/store/user-settings.store';
import { UserSettingsS } from '@/modules/user/user-settings.service';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import DocumentFiltersStore from '@/modules/document-filters/store/document-filters.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';

export const HotelHomepageServiceS = Symbol.for('HotelHomepageServiceS');
@injectable(HotelHomepageServiceS as unknown as string)
export default class HotelHomepageService {
    @Inject(DocumentFiltersServiceS) protected documentFiltersService!: DocumentFiltersService;
    @Inject(UserSettingsS) private userSettings!: UserSettings;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;

    private readonly documentFiltersStoreState: DocumentFiltersStore = this.storeFacade.getState('DocumentFiltersStore');

    CIScoresMock: number[] = [
        49, 49, 49, 49, 49,
        49, 49, 49, 49, 49,
        49, 49, 49, 49, 49,
        49, 50, 51, 51, 51,
        51, 51, 51, 51, 52,
        53, 53, 53, 53, 52, 52];
    ChainAveMock: number[] = [
        55, 55, 54, 54, 54,
        53, 53, 53, 53, 53,
        53, 53, 53, 53, 53,
        53, 53, 54, 54, 54,
        54, 55, 55, 55, 55,
        55, 54, 54, 54, 54, 54];
    SomeAveMock: number[] = [
        70, 80, 50, 50, 50,
        51, 51, 61, 51, 51,
        51, 51, 32, 52, 52,
        52, 52, 52, 52, 52,
        51, 21, 51, 51, 52,
        52, 53, 53, 53, 52, 52];
    ratesCompsetsAsessmentsMock: { medium: string[], low: string[], high: string[] } = {
        medium: [],
        low: [],
        high: [],
    };
    demandsMock: number[] = [];

    visibilitiesMock: number[] = [];

    constructor() {
        for (let i = 0; i < this.documentFiltersService.lastDayCurrentMonth; i++) {
            this.demandsMock.push(Math.random() * 100);
        }
    }

    get CIScoreMyHotel() {
        return 77;
    }

    CIScoresLabels(metric: metricType): Date[] {
        const month = new Date().getMonth();
        const year = new Date().getFullYear();
        const days: Day[] = [];

        for (let i = 0; i < this.documentFiltersService.lastDayCurrentMonth; i++) {
            days.push(i + 1 as Day);
        }
        const currentDay = new Date().getDate();

        switch (metric) {
            case '1M': {
                let newDay = currentDay;
                return days.map((day: Day) => {
                    newDay++;
                    return new Date(year, month - 1, newDay);
                });
            }
            case '3M': {
                let result: Date[] = [];
                let newMonth = month - 3;
                for (let i = 0; i < 3; i++) {
                    newMonth++;
                    // eslint-disable-next-line no-loop-func
                    result = result.concat(days.map((day: Day) => new Date(year, newMonth, day)));
                }
                result.push(new Date());
                return result;
            }
            case '6M': {
                let result: Date[] = [];
                let newMonth = month - 6;
                for (let j = 0; j < 6; j++) {
                    newMonth++;
                    // eslint-disable-next-line no-loop-func
                    result = result.concat(days.map((day: Day) => new Date(year, newMonth, day)));
                }
                result.push(new Date());
                return result;
            }
            case 'YTD': {
                let result: Date[] = [];
                let newMonth = month - 9;
                for (let k = 0; k < 9; k++) {
                    newMonth++;
                    // eslint-disable-next-line no-loop-func
                    result = result.concat(days.map((day: Day) => new Date(year, newMonth, day)));
                }
                result.push(new Date());
                return result;
            }
            case '1Y': {
                let result: Date[] = [];
                let newMonth = month - 12;
                for (let l = 0; l < 12; l++) {
                    newMonth++;
                    // eslint-disable-next-line no-loop-func
                    result = result.concat(days.map((day: Day) => new Date(year, newMonth, day)));
                }
                result.push(new Date());
                return result;
            }
            case 'MAX': {
                let result: Date[] = [];
                let newMonth = month - 24;
                for (let p = 0; p < 24; p++) {
                    newMonth++;
                    // eslint-disable-next-line no-loop-func
                    result = result.concat(days.map((day: Day) => new Date(year, newMonth, day)));
                }
                result.push(new Date());
                return result;
            }
            default: {
                return days.map((day: Day) => new Date(year, month, day));
            }
        }
    }

    CIScores(metric: metricType): number[] {
        switch (metric) {
            case '1M': {
                return this.CIScoresMock;
            }
            case '3M': {
                let result = this.CIScoresMock;
                for (let i = 0; i < 3; i++) {
                    result = result.concat(this.CIScoresMock);
                }
                return result;
            }
            case '6M': {
                let result = this.CIScoresMock;
                for (let i = 0; i < 6; i++) {
                    result = result.concat(this.CIScoresMock);
                }
                return result;
            }
            case 'YTD': {
                let result = this.CIScoresMock;
                for (let i = 0; i < 9; i++) {
                    result = result.concat(this.CIScoresMock);
                }
                return result;
            }
            case '1Y': {
                let result = this.CIScoresMock;
                for (let i = 0; i < 12; i++) {
                    result = result.concat(this.CIScoresMock);
                }
                return result;
            }
            case 'MAX': {
                let result = this.CIScoresMock;
                for (let i = 0; i < 24; i++) {
                    result = result.concat(this.CIScoresMock);
                }
                return result;
            }
            default: {
                return this.CIScoresMock;
            }
        }
    }

    ChainAve(metric: metricType): number[] {
        const { comparedTo } = this.userSettings;
        // @ts-ignore
        const comparedToMock = comparedTo === COMPARED_TO_SETTINGS['Chain Average'] ? this.ChainAveMock : this.SomeAveMock;

        switch (metric) {
            case '1M': {
                return comparedToMock;
            }
            case '3M': {
                let result = comparedToMock;
                for (let i = 0; i < 3; i++) {
                    result = result.concat(comparedToMock);
                }
                return result;
            }
            case '6M': {
                let result = comparedToMock;
                for (let i = 0; i < 6; i++) {
                    result = result.concat(comparedToMock);
                }
                return result;
            }
            case 'YTD': {
                let result = comparedToMock;
                for (let i = 0; i < 9; i++) {
                    result = result.concat(comparedToMock);
                }
                return result;
            }
            case '1Y': {
                let result = comparedToMock;
                for (let i = 0; i < 12; i++) {
                    result = result.concat(comparedToMock);
                }
                return result;
            }
            case 'MAX': {
                let result = comparedToMock;
                for (let i = 0; i < 24; i++) {
                    result = result.concat(comparedToMock);
                }
                return result;
            }
            default: {
                return comparedToMock;
            }
        }
    }

    get ratesCompsetsAsessments(): { medium: string[], low: string[], high: string[] } {
        return this.ratesCompsetsAsessmentsMock;
    }

    get demandsData(): number[] {
        return this.demandsMock;
    }

    get demandsLabels(): string[] {
        const { year, month } = this.documentFiltersStoreState.settings;
        const countDays = new Date(year, month, 0).getDate();
        const nextCountDays = new Date(year, month + 1, 0).getDate();

        const labels = [];

        labels.unshift(`${nextCountDays}/${month + 1}`);
        labels.unshift(`25/${month + 1}`);
        labels.unshift(`19/${month + 1}`);
        labels.unshift(`13/${month + 1}`);
        labels.unshift(`7/${month + 1}`);
        labels.unshift(`1/${month + 1}`);

        return labels;
    }

    get visibilityData(): number[] {
        return this.visibilitiesMock;
    }

    get ratesLabels(): Date[] {
        const { year, month } = this.documentFiltersStoreState.settings;

        return this.documentFiltersService.days.map((day: Day) => new Date(year, month, day));
    }

    get visibilitiesSets(): {
        low: number[],
        lowLine: number[],
        poor: number[],
        poorLine: number[],
        medLow: number[],
        medLowLine: number[],
        medHigh: number[],
        high: number[],
    } {
        const { year, month } = this.documentFiltersStoreState.settings;
        const countDays = new Date(year, month + 1, 0).getDate();

        return {
            low: new Array(countDays).fill(0),
            lowLine: new Array(countDays).fill(3.5),
            poor: new Array(countDays).fill(4),
            poorLine: new Array(countDays).fill(7.5),
            medLow: new Array(countDays).fill(8),
            medLowLine: new Array(countDays).fill(11.5),
            medHigh: new Array(countDays).fill(12),
            high: new Array(countDays).fill(16),
        };
    }
}
