import { Inject, injectable } from 'inversify-props';
import ScanDisabledProviders from '@/modules/common/modules/rates/constants/scan-disabled-providers.enum';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import ProvidersStore from '@/modules/providers/store/providers.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import ProvidersApiService, { ProvidersApiServiceS } from '@/modules/providers/providers-api.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';

export const ProvidersServiceS = Symbol.for('ProvidersServiceS');
@injectable(ProvidersServiceS as unknown as string)
export default class ProvidersService implements Stateable {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(ProvidersApiServiceS) private providersApiService!: ProvidersApiService;
    @Inject(HelperServiceS) private helperService!: HelperService;

    readonly storeState: ProvidersStore = this.storeFacade.getState('ProvidersStore');

    async loadFilter(): Promise<boolean> {
        this.storeState.providerFilter = await this.providersApiService.getFilterProviders();
        return true;
    }

    get isLoading() {
        return this.storeState.loadingFilter.isLoading();
    }

    get providerFilter() {
        this.helperService.dynamicLoading(this.storeState.loadingFilter, this.loadFilter.bind(this));
        return this.storeState.providerFilter;
    }

    get isLoadingProviderData() {
        return this.storeState.loading;
    }

    async loadData(): Promise<boolean> {
        this.storeState.providers = await this.providersApiService.getProviders();
        return true;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.providers;
    }

    getDIName(provider: string) {
        if (!this.data) {
            return null;
        }

        const find = this.data.find((element: any) => (element.name === provider));
        if (!find) {
            return null;
        }

        return find.diName;
    }

    getMaxScore(provider: string) {
        if (!this.data) {
            return null;
        }

        const find = this.data.find((element: any) => (element.name === provider));
        if (!find) {
            return null;
        }

        return find.maxScore;
    }

    getProviderLabel(providerName: string) {
        if (!this.data) {
            return providerName;
        }

        const provider = this.data.find(currentProvider => currentProvider.name === providerName);

        if (!provider) {
            return '';
        }

        return provider.label || provider.name || '';
    }

    toItemsList(providerList: string[], ignoreAll = true): { value: string; name: string; }[] {
        return providerList
            .filter(provider => ignoreAll && provider !== 'all')
            .map(provider => ({
                value: provider,
                name: this.getProviderLabel(provider),
            }));
    }

    isDisabledProvider(provider: string | null) {
        const foundProviders = Object.entries(ScanDisabledProviders)
            .find(([key, value]) => {
                if (!provider) {
                    return false;
                }

                return provider.includes(value);
            });

        return !!foundProviders;
    }
}
