import { Expose } from 'class-transformer';

export default class ConfigModel {
    @Expose()
    VUE_APP_API_URL!: string;

    @Expose()
    VUE_APP_SOCKET_URL!: string;

    @Expose()
    VUE_APP_SSO_URL!: string;

    @Expose()
    VUE_APP_GOOGLE_KEY!: string;

    @Expose()
    VUE_APP_BI_URL!: string;

    @Expose()
    VUE_APP_DI_URL!: string;

    @Expose()
    VUE_APP_DI_ADMIN_URL!: string;

    @Expose()
    VUE_APP_EC_URL!: string;

    @Expose()
    VUE_APP_TABLEAU_SERVER!: string;

    @Expose()
    VUE_APP_PARITY_TABLEAU_NAME!: string;

    @Expose()
    VUE_APP_FLEET_DENSITY_TABLEAU_NAME!: string;

    @Expose()
    VUE_APP_ZENDESK_KEY!: string;

    @Expose()
    VUE_APP_MOCK_URL?: string;

    @Expose()
    VUE_APP_MOCK_LIST_URL?: string;

    @Expose()
    VUE_APP_WALKME_URL?: string;

    @Expose()
    VUE_APP_WALKME_USER?: string;

    @Expose()
    VUE_APP_WHATFIX_URL?: string;

    @Expose()
    FORNOVA_APP?: string;

    @Expose()
    VUE_APP_CI_UPGRADE_URL?: string;

    @Expose()
    VUE_APP_DI_UPGRADE_URL?: string;

    @Expose()
    VUE_APP_ADMIN_URL?: string;
}
