import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import { Inject, injectable } from 'inversify-props';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import CarsRateAlertApiService, { CarsRateAlertApiServiceS } from './car.rate-alert-api.service';
import CarsRateAlertStore from './store/cars.rate-alert.store';
import CarsAlertConfigurationModel from './models/cars-rate-alert-configuration.model';
import { ICarAlertConfig } from './modules/settings/interfaces/alert-interfaces';
import { CarRateAlertType } from './modules/settings/enums/alerts.enum';

export const CarsRateAlertServiceS = Symbol.for('CarsRateAlertServiceS');

export enum notificationMessages {
    ADD_SUCCESS = 'The new configuration has been added successfully!',
    UPDATE_SUCCESS = 'The configuration has been updated successfully!',
    FAILED = 'Please fill all the required fields',
    REMOVED_SUCCESS = 'The configuration has been Deleted successfully!',
}
@injectable(CarsRateAlertServiceS as unknown as string)
export default class CarsRateAlertService implements Stateable {
    @Inject(UserServiceS) protected userService!: UserService;
    @Inject(CarsRateAlertApiServiceS) protected carsAlertApiService!: CarsRateAlertApiService;
    @Inject(AuthServiceS) protected authService!: AuthService;
    @Inject(StoreFacadeS) protected storeFacade!: StoreFacade;
    @Inject(HelperServiceS) protected helperService!: HelperService;

    readonly storeState: CarsRateAlertStore = this.storeFacade.getState('CarsRateAlertStore');
    protected alertType = CarRateAlertType.RATE_DIFF;

    constructor() {
        this.storeFacade.watch(
            () => this.userService.storeState.user,
            this.storeState.loading.reset.bind(this.storeState.loading),
        );
    }

    resetLoadings() {
        this.storeState.loading.reset();
    }

    async loadData() {
        if (!this.userService.isCarUser) {
            return false;
        }
        const grouped = await this.carsAlertApiService.getAlertsConfiguration(this.alertType);

        this.storeState.document = grouped as CarsAlertConfigurationModel[];

        return true;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.document;
    }

    get loading() {
        return this.storeState.loading;
    }

    async saveAlertConfiguration(configuration: ICarAlertConfig) {
        return this.carsAlertApiService.saveAlertConfiguration(configuration, this.alertType);
    }

    async updateAlertConfiguration(configuration: ICarAlertConfig, id: string) {
        return this.carsAlertApiService.updateAlertConfiguration(configuration, id);
    }

    async updateAlertConfigurationStatus(configId: string, status: boolean) {
        return this.carsAlertApiService.updateAlertConfigurationStatus(configId, status);
    }

    async deleteAlertConfiguration(id: string) {
        return this.carsAlertApiService.deleteAlertConfiguration(id);
    }
}
