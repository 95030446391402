export const WHERE_OPTIONS = ['in app', 'email'];
export const WHEN_OPTIONS = [
    'score drops',
    'score increase',
    'rate drops',
    'rate increase',
    'visibility drops',
    'visibility increase',
    'review drops',
    'revies increase',
];
export const BY_OPTIONS = ['0.001', '0.05', '0.1'];
export const IN_OPTIONS = ['any ota', 'booking', 'expedia'];

export type POS = 'EN' | 'DE' | 'US';
export const POS = undefined; // need to remove warnings while building https://stackoverflow.com/questions/52258061/export-not-found-on-module

export enum POS_VALUES {
    EN = 'EN',
    DE = 'DE',
    US = 'US',
}

export enum WHERE_SETTINGS {
    IN_APP = 'in app',
    EMAIL = 'email',
}
export enum WHEN_SETTINGS {
    SCORE_DROPS = 'score drops',
    SCORE_INCREASE = 'score increase',
    RATE_DROPS = 'rate drops',
    RATE_INCREASE = 'rate increase',
    VISIBILITY_DROPS = 'visibility drops',
    VISIBILITY_INCREASE = 'visibility increase',
    REVIEW_DROPS = 'review drops',
    REVIEW_INCREASE = 'review increase',
}

export enum IN_SETTINGS {
    ANY_OTA = 'any ota',
    BOOKING = 'booking',
    EXPEDIA = 'expedia',
}

export interface Notifications {
    id?: number;
    where: WHERE_SETTINGS;
    when: WHEN_SETTINGS;
    by: string;
    in: IN_SETTINGS;
}
