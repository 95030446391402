import { Expose, Transform } from 'class-transformer';
import CarsBaseAlertConfigs from './cars-base-alert-configs.model';

export default class CarsRateAlertConfigurationModel extends CarsBaseAlertConfigs {
    @Expose()
    @Transform((_, plain) => plain.ratio)
    ratio!: number;

    @Expose()
    @Transform((_, plain) => plain.alert_type)
    alertType!: string;
}
