import { Expose, plainToClass, Transform } from 'class-transformer';
import Day from '@/modules/common/types/day.type';
import { IsNumber, IsOptional, Validate } from 'class-validator';
import RankingDocumentItemModel from '@/modules/ranking/models/ranking-document-item.model';

interface ITrendDate {
    [date: string]: {
        [provider: string]: {
            rating: number,
            // eslint-disable-next-line camelcase
            total_reviwes: number,
            // eslint-disable-next-line camelcase
            rating_improved: number,
            // eslint-disable-next-line camelcase
            total_difference: number,
            // eslint-disable-next-line camelcase
            new_reviews: number,
        } | RankingDocumentItemModel
    }
}

interface ITrendData {
    [hotelId: number]: ITrendDate
}

export default class RankingHistoryModel {
    @Expose()
    @Transform((_, plain) => {
        const trendData: ITrendData = plain;

        if (!trendData || !Object.keys(trendData).length) {
            return null;
        }

        Object.entries(trendData).forEach(([hotelId, dates]: [string, ITrendDate]) => {
            Object.entries(dates).forEach(([date, providers]) => {
                Object.entries(providers).forEach(([provider, rankingItem]) => {
                    trendData[Number(hotelId)][date][provider] = plainToClass(
                        RankingDocumentItemModel,
                        <RankingDocumentItemModel> rankingItem,
                        { excludeExtraneousValues: true },
                    );
                });
            });
        });

        return trendData;
    })
    trendData!: {
        [hotelId: number]: { [date: string]: { [provider: string]: RankingDocumentItemModel } } | null
    };
}
