import { Expose, plainToClass, Transform } from 'class-transformer';
import CarsDocumentItemModel from '@/modules/cars/models/cars-document-item.model';
import { container } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import { Validate } from 'class-validator';
import CarsCheckinDatesValidator from './validators/checkin-dates.validator';
import TransformUtil, { Availability } from './transform-util';

export default class CarsAnalysisDocumentModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => Number(plain._id))
    id!: number;

    @Expose()
    @Transform((_, plain) => {
        const checkinDates = plain.pickup_dates || {};

        if (!checkinDates) {
            return checkinDates;
        }

        const documentFiltersService = container.get<DocumentFiltersService>(DocumentFiltersServiceS);

        Object.keys(checkinDates || {}).forEach(day => {
            Object.keys(checkinDates[day].phase || {}).forEach(scanPhase => {
                Object.keys(checkinDates[Number(day)].phase[scanPhase] || {}).forEach(company => {
                    Object.keys(checkinDates[Number(day)].phase[scanPhase][company] || {}).forEach(carClass => {
                        const carsDocumentItems: CarsDocumentItemModel[] = [];
                        Object.values(checkinDates[Number(day)].phase[scanPhase][company][carClass] || {}).forEach(carItem => {
                            const stringifiedCarItem = TransformUtil.convertCarOfferNumericalValuesToStrings(carItem);
                            if (stringifiedCarItem.availability === Availability.PriceIsNotAvailable) {
                                return;
                            }
                            carsDocumentItems.push(
                                plainToClass(CarsDocumentItemModel, <CarsDocumentItemModel>stringifiedCarItem, { excludeExtraneousValues: true }),
                            );
                        });

                        checkinDates[Number(day)].phase[scanPhase][company][carClass] = carsDocumentItems;
                    });
                });
            });
        });

        const today = new Date();
        const maxNoAvailabilityDays = 30;

        const pickedMonth = documentFiltersService.storeState.settings.month;
        const pickedYear = documentFiltersService.storeState.settings.year;

        if (pickedYear !== today.getFullYear()
            || today.getMonth() > pickedMonth) {
            return checkinDates;
        }

        let fromDay = 1;
        let tillDay = 0;

        if (today.getMonth() === pickedMonth) {
            fromDay = today.getDate();
            tillDay = fromDay + maxNoAvailabilityDays;
        } else if (today.getMonth() === pickedMonth - 1) {
            const previousMonthMaxDays = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
            fromDay = 1;
            tillDay = (today.getDate() + maxNoAvailabilityDays) % previousMonthMaxDays;
        }

        while (fromDay <= tillDay) {
            if (!checkinDates[fromDay]) {
                checkinDates[fromDay] = null;
            }
            fromDay += 1;
        }
        return checkinDates;
    })

    @Validate(CarsCheckinDatesValidator)
    checkinDates!: {
        [day: number]: {
            [company: string]: {
                [carClass: string]: CarsDocumentItemModel[]
            }
        } | null
    };

    @Expose()
    currency?: string;

    @Expose()
    @Transform((_, plain) => (plain.data_source || '').split(',') || [])
    dataSource!: string[];

    /**
     * Cached property
     */
    documentVendors!: Set<string>;
}
