/* eslint-disable camelcase */
import { Expose, Transform } from 'class-transformer';
import { IsString, IsNumber, IsDate } from 'class-validator';
import { camelCase } from 'lodash';

interface StatisticData {
    comp_set?: number;
    last_year?: number;
    my_hotel?: number;
    absolute_comp_set?: number;
    absolute_last_year?: number;
    performance_comp_set?: number;
    performance_last_year?: number;
    performance_my_hotel?: number;
    performance_calculated_comp_set?: number;
    performance_calculated_last_year?: number;
}
interface StatisticDataCamelCased {
    compSet?: number;
    lastYear?: number;
    myHotel?: number;
    absoluteCompSet?: number;
    absoluteLastYear?: number;
    performanceCompSet?: number;
    performanceLastYear?: number;
    performanceMyHotel?: number;
    performanceCalculatedCompSet?: number;
    performanceCalculatedLastYear?: number;
}

interface DeepAnalysisClusterResponse {
    details: {
        [providerKey: string]: {
            currency: string;
            customer_id: string;
            last: {
                [days: number]: {
                    [statistic: string]: StatisticData;
                };
            };
            next: {
                [days: number]: {
                    [statistic: string]: StatisticData;
                };
            };
        };
    };
}

function objToCC<E, T extends Object = {}>(object: T): E {
    return Object.fromEntries(
        Object
            .entries(object)
            .map(([key, value]) => [camelCase(key), value]),
    ) as E;
}

export default class DeepAnalysisClusterItemModel {
    // eslint-disable-next-line no-underscore-dangle
    @Expose()
    @Transform((_, plain: DeepAnalysisClusterResponse) => {
        if (!plain.details || !Object.keys(plain.details).length) {
            return null;
        }

        const providersData = Object.entries(plain.details);

        const convertData = ([value, data]: [string, { [statistic: string]: StatisticData }]) => {
            const entryToCC = ([statKey, statData]: [string, any]) => [statKey, objToCC<StatisticDataCamelCased>(statData)];
            const camelCasedEnties = Object.entries(data || {}).map(entryToCC);
            const formattedData = Object.fromEntries(camelCasedEnties);

            return [value, formattedData];
        };

        const convertedProvidersData = providersData
            .map(([provider, data]) => {
                const showBy = {
                    book: {} as StatisticDataCamelCased,
                    stay: {} as StatisticDataCamelCased,
                };
                const { currency, customer_id: customerId } = data;
                if (data.last) {
                    const bookEntries = Object.entries(data.last).map(convertData);
                    showBy.book = Object.fromEntries(bookEntries);
                }
                if (data.next) {
                    const stayEntries = Object.entries(data.next).map(convertData);
                    showBy.stay = Object.fromEntries(stayEntries);
                }

                return [provider, {
                    showBy,
                    currency,
                    customerId,
                }];
            });

        const providers = Object.fromEntries(convertedProvidersData);

        return providers;
    })
    providers!: {
        [provider: string]: {
            showBy: {
                [key: string]: {
                    [days: string]: {
                        [statistic: string]: {
                            compSet: number | null;
                            lastYear: number | null;
                            myHotel: number | null;
                            absoluteCompSet: number | null;
                            absoluteLastYear: number | null;
                            performanceMyHotel: number | null;
                            performanceCompSet: number | null;
                            performanceLastYear: number | null;
                            performanceCalculatedCompSet: number | null;
                            performanceCalculatedLastYear: number | null;
                        };
                    }
                } | null;
            };
            currency: string;
            customerId: string;
        };
    };

    @Expose()
    @Transform((_, plain) => plain.fornova_id)
    @IsNumber()
    fornovaId!: number;

    @Expose()
    @Transform((_, plain) => plain.hotel_name)
    @IsString()
    hotelName!: string;

    @Expose()
    @Transform((_, plain) => plain.update_date)
    @IsDate()
    updateDate!: Date;
}
