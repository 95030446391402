import { Expose, Transform } from 'class-transformer';
import { IsNumber } from 'class-validator';

export default class ScoreModel {
    @Expose()
    @IsNumber()
    @Transform((_, plain) => Math.round(Number(plain.score)))
    score!: number;

    @Expose()
    @IsNumber()
    @Transform((_, plain) => 0)
    newScore?: number;
}
