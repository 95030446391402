import { Route } from 'vue-router';
import { container } from 'inversify-props';

import use from '@/router/use';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import { CommonMenuRoutes } from '../common.routes';

export default function PromotionsHotelLevelRoutes(basePath: string, prefix: string) {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/hotel-transition.page.vue'),
        beforeEnter: use([
            container.get<FeaturesGuard>(FeaturesGuardS),
        ]),
        props: (r: Route) => ({
            hotelId: +r.params.hotelId,
        }),
        children: [
            {
                path: '',
                name: prefix,
                component: () => import('@/modules/promotions/pages/index.page.vue'),
                children: [
                    ...CommonMenuRoutes(prefix),
                ],
            },
            {
                path: 'graph',
                name: `${prefix}.graph`,
                component: () => import('@/modules/promotions/pages/index.page.vue'),
                children: [
                    ...CommonMenuRoutes(`${prefix}.graph`),
                ],
            },
            {
                path: 'table',
                name: `${prefix}.table`,
                component: () => import('@/modules/promotions/pages/index.page.vue'),
                children: [
                    {
                        path: 'day-info/:day',
                        name: `${prefix}.table.popup`,
                        meta: { modal: true },
                        component: () => import('@/modules/promotions/pages/promotions-popup.page.vue'),
                        children: [
                            {
                                path: 'history',
                                name: `${prefix}.table.popup.history`,
                                component: () => import('@/modules/promotions/pages/promotions-history.page.vue'),
                                meta: { modal: true },
                            },
                        ],
                    },
                    ...CommonMenuRoutes(`${prefix}.table`),
                ],
            },
            {
                path: 'analysis-table',
                name: `${prefix}.analysis.table`,
                component: () => import('@/modules/promotions/pages/index.page.vue'),
                children: [
                    ...CommonMenuRoutes(`${prefix}.analysis.table`),
                ],
            },
        ],
    };
}
