/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import { Expose, Transform } from 'class-transformer';

export interface PromotionTrendData {
    [date: string]: {
        [provider: string]: {
            [hotelId: string]: {
                [program: string]: {
                    status: boolean;
                    message?: string;

                    /**
                     * Percent value from 0 to 100
                     */
                    percentage?: number;
                } | undefined;
            } | undefined;
        } | undefined;
    } | undefined;
}

export default class PromotionHistoryDocumentModel {
    @Expose()
    @Transform((_, plain) => plain.doc_id)
    public documentId!: number;

    @Expose()
    @Transform((_, plain) => plain.day)
    public day!: number;

    @Expose()
    @Transform((_, plain) => plain.trend_data)
    public trendData!: PromotionTrendData;
}
