import { Expose, plainToClass, Transform } from 'class-transformer';
import {
    IsNumber, IsString, IsBoolean, IsInt,
} from 'class-validator';
import DEFAULT_NUMBER_OF_GUESTS from '@/modules/number-of-guests/constants/default-number-of-guests.constant';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';
import ICheckinDatesHotels from '../interfaces/checkin-dates-hotels.interface';
import RatesDocumentItemModel from './rates-document-item.model';

export default class RatesDocumentIntradayModel {
    @Expose()
    @Transform((_, plain) => (
        Object.entries(plain).reduce((acc, [key, value]) => {
            acc[Number(key)] = {
                rooms: Object
                    .entries((value as { rooms: {} }).rooms || {})
                    .reduce((roomsAcc, [roomTypeId, rooms]) => {
                        const res = {
                            ...roomsAcc,
                            [roomTypeId]: (rooms as {}[])
                                .map(room => plainToClass(RatesDocumentItemModel, <RatesDocumentItemModel>room, { excludeExtraneousValues: true })),
                        };

                        return res;
                    }, {} as { [roomTypeId: string]: RatesDocumentItemModel[] }),
                link: null,
            };

            return acc;
        }, {} as ICheckinDatesHotels)
    ))
    hotels!: ICheckinDatesHotels;
}
