import { Expose, plainToClass, Transform } from 'class-transformer';
import CarsPriceHistoryDocumentItemModel from '@/modules/cars/models/cars-price-history-document-item.model';
import TransformUtil from './transform-util';

export default class CarRatesPriceHistoryModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => Number(plain._id))
    id!: number;

    @Expose()
    @Transform((_, plain) => Number(plain.day))
    day!: number;

    @Expose()
    @Transform((_, plain) => Number(plain.grouped_document_id))
    groupedDocumentId!: number;

    @Expose()
    @Transform((_, plain) => {
        const trendData: any = plain.pickup_dates || {};

        if (!trendData) {
            return null;
        }

        Object.keys(trendData || {}).forEach(date => {
            Object.keys(trendData[date].phase || {}).forEach(scanPhase => {
                Object.keys(trendData[date].phase[scanPhase] || {}).forEach((company: string) => {
                    Object.keys(trendData[date].phase[scanPhase][company] || {}).forEach(carClass => {
                        const carsDocumentItems: CarsPriceHistoryDocumentItemModel[] = [];
                        Object.entries(trendData[date].phase[scanPhase][company][carClass] || {}).forEach(([uselessId, carItem]) => {
                            const stringifiedCarItem = TransformUtil.convertCarOfferNumericalValuesToStrings(carItem);
                            carsDocumentItems.push(
                                plainToClass(
                                    CarsPriceHistoryDocumentItemModel,
                                    <CarsPriceHistoryDocumentItemModel>stringifiedCarItem,
                                    { excludeExtraneousValues: true },
                                ),
                            );
                        });

                        trendData[date].phase[scanPhase][company][carClass] = carsDocumentItems;
                    });
                });
            });
        });
        return trendData;
    })
    checkinDates!: {
        [day: string]: {
            [company: string]: {
                [carClass: string]: CarsPriceHistoryDocumentItemModel[]
            }
        } | null
    };
}
