import { Expose, Transform, plainToClass } from 'class-transformer';
import {
    IsString, IsEnum, IsInt, IsNumber, IsObject,
} from 'class-validator';
import SippItemModel from './sipp-item.model';

export default class SippModel {
    @Expose()
    @Transform((_, plain) => (
        plainToClass(SippItemModel, <SippItemModel[]> plain.documents, { excludeExtraneousValues: true })
    ))
    @IsObject()
    documents!: SippItemModel[];

    @Expose()
    @Transform((_, plain) => plain.total_count)
    @IsString()
    totalCount!: number;

    @Expose()
    @Transform((_, plain) => plain.total_pages)
    @IsString()
    totalPages!: number;
}
