import { injectable, Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import MarketsCompsetMainModel from '@/modules/cluster/models/markets-compset-main.model';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import MarketsDocumentModel from '@/modules/markets/models/markets-document.model';
import Day from '@/modules/common/types/day.type';
import MarketsDocumentItemModel from '@/modules/markets/models/markets-document-item.model';
import CompsetModel from '@/modules/compsets/models/compset.model';
import MarketsApiService, { MarketsApiServiceS } from '@/modules/markets/markets-api.service';

export const MarketsCommonServiceS = Symbol.for('MarketsCommonServiceS');
@injectable(MarketsCommonServiceS as unknown as string)
export default class MarketsCommonService {
    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(MarketsApiServiceS)
    private marketsApiService!: MarketsApiService;

    checkinDate(day: Day, document: MarketsDocumentModel | MarketsCompsetMainModel) {
        const { checkinDates } = document;

        if (checkinDates && checkinDates[day]) {
            return checkinDates[day];
        }

        return null;
    }

    getHotelCheckingDate(day: Day, hotelId: number, document: MarketsDocumentModel | MarketsCompsetMainModel) {
        const checkinDate = this.checkinDate(day, document);

        if (!checkinDate || !checkinDate[hotelId]) {
            return null;
        }

        return checkinDate[hotelId];
    }

    getPosition(day: Day, hotelId: number, document: MarketsDocumentModel | MarketsCompsetMainModel) {
        const hotelCheckingDate = this.getHotelCheckingDate(day, hotelId, document);

        if (!hotelCheckingDate) return null;

        return hotelCheckingDate.position;
    }

    getPage(day: Day, hotelId: number, document: MarketsDocumentModel | MarketsCompsetMainModel) {
        const hotelCheckingDate = this.getHotelCheckingDate(day, hotelId, document);
        if (!hotelCheckingDate) return null;

        return hotelCheckingDate.page;
    }

    getNumberOfHotels(day: Day, hotelId: number, document: MarketsDocumentModel) {
        const checkinDate = this.checkinDate(day, document);

        if (!checkinDate || !checkinDate[hotelId] || !checkinDate[hotelId].numberOfHotels) {
            return null;
        }

        return checkinDate[hotelId].numberOfHotels;
    }

    private calculateThresholdsByDay(day: Day, document: MarketsDocumentModel | MarketsCompsetMainModel, excludeHotelId?: number)
        : [number, number, number] | null {
        const positions: number[] = this.dayPositions(day, document, excludeHotelId) || [];

        return this.calculateThresholds(positions);
    }

    calculateThresholds(positions: number[] | null): [number, number, number] | null {
        if (positions === null) {
            return null;
        }

        const maxPosition = Math.max(...positions);
        const minPosition = Math.min(...positions);

        const midPosition = minPosition + (maxPosition - minPosition) / 2;
        return [minPosition, midPosition, maxPosition];
    }

    dayPositions(day: Day, document: MarketsDocumentModel | MarketsCompsetMainModel, excludeHotelId?: number) {
        const checkinDate = this.checkinDate(day, document);

        if (!checkinDate) {
            return null;
        }

        const positions: number[] = Object.entries(checkinDate).reduce((acc: number[], [hotelId, hotel]) => {
            if (excludeHotelId && Number(hotelId) === excludeHotelId) {
                return acc;
            }
            return acc.concat([hotel.position]);
        }, []);

        return positions;
    }

    getCardAssessment(day: Day, hotelId: number, document: MarketsDocumentModel | MarketsCompsetMainModel): ASSESSMENT_TYPES | null {
        const comparedPosition = this.getPosition(day, hotelId, document);
        const thresholds = this.calculateThresholdsByDay(day, document, hotelId);

        if (!comparedPosition || !thresholds) {
            return null;
        }

        const [highThreshold, midHighThreshold, midLowThreshold] = thresholds;

        if (comparedPosition <= highThreshold) {
            return ASSESSMENT_TYPES.GOOD;
        }
        if (comparedPosition <= midHighThreshold) {
            return ASSESSMENT_TYPES.NORMAL;
        }
        if (comparedPosition <= midLowThreshold) {
            return ASSESSMENT_TYPES.FAIR;
        }
        return ASSESSMENT_TYPES.BAD;
    }

    getTableAssessment(day: Day, hotelId: number, comparedHotelId: number, document: MarketsDocumentModel): ASSESSMENT_TYPES | null {
        const comparedPosition = this.getPosition(day, comparedHotelId, document);
        const checkinDate = this.checkinDate(day, document);

        if (!comparedPosition
            || !checkinDate
            || !checkinDate[hotelId]
            || !checkinDate[hotelId].position
        ) {
            return null;
        }

        if (comparedPosition > checkinDate[hotelId].position) {
            return ASSESSMENT_TYPES.GOOD;
        }
        return ASSESSMENT_TYPES.BAD;
    }

    minMaxPositions(document: MarketsDocumentModel, excludeHotelId?: number): { min: number[], max: number[] } | null {
        const min: number[] = [];
        const max: number[] = [];

        this.documentFiltersService.days.forEach(day => {
            const dayPositions = this.dayPositions(day, document, excludeHotelId);
            if (dayPositions) {
                min.push(Math.min(...dayPositions));
                max.push(Math.max(...dayPositions));
            } else {
                min.push(Math.min(...min));
                max.push(Math.max(...max));
            }
        });
        return { min, max };
    }

    isOutOfRange(document: MarketsDocumentModel | MarketsCompsetMainModel | undefined | null) {
        return !document;
    }

    isNoData(day: Day, document: MarketsDocumentModel | MarketsCompsetMainModel | undefined | null) {
        if (!document) {
            return false;
        }

        return !this.checkinDate(day, document);
    }

    isNA(day: Day, hotelId: number, document: MarketsDocumentModel | MarketsCompsetMainModel | undefined | null) {
        if (!document) {
            return false;
        }

        if (this.isNoData(day, document)) {
            return false;
        }

        const dataByDay = this.checkinDate(day, document) as {[p: number]: MarketsDocumentItemModel};

        const hotelData = dataByDay[hotelId];

        return !hotelData;
    }

    isSoldOut(day: Day, hotelId: number, document: MarketsDocumentModel | undefined | null) {
        if (!document) {
            return false;
        }

        return !this.getPosition(day, hotelId, document);
    }

    isScanAvailable(day?: Day): boolean {
        return day ? !this.documentFiltersService.isPreviousDay(day) : !this.documentFiltersService.isPreviousMonth;
    }

    dayUpdateDate(day: Day, document: MarketsDocumentModel | null) {
        if (!document || !document.updateDates) return null;

        return document.updateDates[day];
    }

    resendScheduledReport(userLevel: 'hotel' | 'cluster', schedulerId: number) {
        return this.marketsApiService
            .resendScheduledReport(userLevel, schedulerId);
    }
}
