import { Expose, Transform } from 'class-transformer';
import {
    IsNotEmpty, Length, NotEquals,
} from 'class-validator';

export default class RoomTypeModel {
    constructor(name: string, id: number = -1) {
        this.id = id;
        this.name = name;
    }

    @Expose()
    @Transform((_, plain) => plain.id)
    @IsNotEmpty()
    id: number;

    @Expose()
    @Transform((_, plain) => plain.name)
    @Length(2, 20)
    @IsNotEmpty()
    @NotEquals('Any')
    name: string;
}
