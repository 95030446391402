import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';

import ClusterScoreStatisticsModel from './models/score-statistics.model';
import ClusterScoreStatisticsItemModel from './models/score-statistics-item.model';
import ChainScoreRankModel from './models/score-rank.model';

export const ClusterScoreApiServiceS = Symbol.for('ClusterScoreApiServiceS');
@injectable(ClusterScoreApiServiceS as unknown as string)
export default class ClusterScoreApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;

    async getScoreStatisticsHistory(): Promise<ClusterScoreStatisticsModel | null> {
        const { data } = await this.apiService.get('users/chain-score-statistics/history');
        if (!data) return null;

        const result = new ClusterScoreStatisticsModel();
        Object.keys(data).forEach(key => {
            result[key] = plainToClass(
                ClusterScoreStatisticsItemModel,
<ClusterScoreStatisticsItemModel> data[key],
{ excludeExtraneousValues: true },
            );
        });
        return result;
    }

    async getScoreRank(): Promise<ChainScoreRankModel[]> {
        const { data } = await this.apiService.get('users/chain-score/rank');
        return plainToClass(ChainScoreRankModel, <ChainScoreRankModel[]> data, { excludeExtraneousValues: true });
    }
}
