import { Expose, plainToClass, Transform } from 'class-transformer';
import { IsString, IsInt, IsNumber } from 'class-validator';
import CompsetModel from '@/modules/compsets/models/compset.model';
import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';

export default class ClusterHotelsModel {
    @Expose()
    @Transform((_, plain) => plain.hotel_name)
    @IsString()
    hotelName!: string;

    @Expose()
    @Transform((_, plain) => plain.fornovaId)
    @IsInt()
    hotelId!: number;

    @Expose()
    @IsNumber()
    @Transform((_, plain) => Math.round(Number(plain.total_score)))
    totalScore!: number;

    @Expose()
    @IsNumber()
    @Transform((_, plain) => Math.round(Number(plain.rate_score)))
    ratesScore!: number;

    @Expose()
    @IsNumber()
    @Transform((_, plain) => plain.diff_score)
    newTotalScore!: number;

    @Expose()
    @Transform((_, plain) => plainToClass(CompsetModel, <CompsetModel[]>plain.compsets, { excludeExtraneousValues: true }))
    compsets!: CompsetModel[];

    @Expose()
    @Transform((_, plain) => plain.hotelsName)
    hotelNames!: { [hotelId: number]: string };

    documentLoaded: boolean = false;
}
