import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import ScoreModel from '@/modules/score/models/score.model';
import ScoreHistoryModel from '@/modules/score/models/score.history.model';

@Module
export default class ScoreStore extends VuexModule {
    document: ScoreModel | null = null;
    usersScoreHistory: ScoreHistoryModel | null = null;
    usersMarketScoreHistory: ScoreHistoryModel | null = null;
    usersChainScoreHistory: ScoreHistoryModel | null = null;
    loading: LoadingModel = new LoadingModel();
}
