import Month from '@/modules/common/types/month.type';
import Year from '@/modules/common/types/year.type';

export default class DocumentFiltersModel {
    compsetId: string | null = null;
    los: number | null = null;
    pos: string | null = null;
    year: Year = new Date().getFullYear();
    month: Month = new Date().getMonth() as Month;
    documentVendors?: Set<string> = new Set();
}
