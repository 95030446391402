import { Inject, injectable } from 'inversify-props';
import moment from 'moment';

import Day from '@/modules/common/types/day.type';
import Stateable from '@/modules/common/interfaces/stateable.interface';

import RatesDocumentItemAllModel from '@/modules/rates/models/rates-document-item-all.model';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import RatesPriceHistoryStore from '@/modules/common/modules/rates-price-history/store/rates-price-history.store';
import RatesPriceHistoryFilterAllModel from '@/modules/common/modules/rates-price-history/models/rates-price-history-filter-all.model';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS }
    from '@/modules/common/modules/rates-price-history/rates-price-history-common.service';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';

export const RatesPriceHistoryAllServiceS = Symbol.for('RatesPriceHistoryAllServiceS');
@injectable(RatesPriceHistoryAllServiceS as unknown as string)
export default class RatesPriceHistoryAllService implements Stateable {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(RatesPriceHistoryCommonServiceS) private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;
    @Inject(UserSettingsS) private userSettingsService!: UserSettingsService;

    readonly storeState: RatesPriceHistoryStore = this.storeFacade.getState('RatesPriceHistoryStore');

    addAverageToHistory(history: { [provider: string]: RatesDocumentItemAllModel }[]) {
        history.map(item => {
            const historyItem = item;
            let numberOfPrices = 0;
            const providers = Object.keys(historyItem).filter(provider => provider !== 'average');
            const price = providers.reduce((totalPrice, provider) => {
                if (!historyItem[provider].statistics || !historyItem[provider].statistics.lowest) {
                    return totalPrice;
                }

                numberOfPrices += 1;
                return totalPrice + historyItem[provider].statistics.lowest;
            }, 0);

            if (numberOfPrices === 0) {
                delete historyItem.average;
                return historyItem;
            }

            historyItem.average = {
                statistics: {
                    lowest: price / numberOfPrices,
                },
                link: '',
            };
            return historyItem;
        });
        return history;
    }

    get lastScanDate() {
        return this.storeState.lastScanDate;
    }

    get providers() {
        const doc = this.storeState.documents.main as RatesPriceHistoryFilterAllModel;

        if (!doc) {
            return [];
        }

        const providers = Object
            .entries(doc.trendData!)
            .map(([, el]) => (el ? Object.keys(el) : []))
            .flat();

        const filteredProviders = [...new Set(providers)]
            .filter(item => item !== 'day_statistics');

        return filteredProviders.length
            ? filteredProviders
            : null;
    }

    getSuitableProviderByDay(dayParam: number) {
        const filteredProviders = {} as {[provider: string] : RatesDocumentItemAllModel};
        const doc = this.storeState.documents.main as RatesPriceHistoryFilterAllModel;
        if (!doc) {
            return filteredProviders;
        }

        if (!this.lastScanDate) {
            return filteredProviders;
        }

        const dateInstance = new Date(this.lastScanDate.getFullYear(), this.lastScanDate.getMonth(), this.lastScanDate.getDate() - dayParam);

        const day = moment(dateInstance).format('DD');
        const month = moment(dateInstance).format('MM');
        const year = moment(dateInstance).format('YYYY');
        // @ts-ignore
        const data = doc.trendData[`${day}-${month}-${year}`];
        if (!data) {
            return filteredProviders;
        }
        return data;
    }

    getProviderGraphColor(index: number) {
        const { chartColors } = this.userSettingsService;
        if (chartColors === null) return '#000000';
        return chartColors[index];
    }

    getPriceHistoryProvidersData(provider: string) {
        const {
            docDay,
            hotelId,
            compsetId,
            documentFilters,
        } = this.ratesPriceHistoryCommonService;
        const priceHistory = docDay
            ? this.priceHistory(
                docDay as Day,
                hotelId,
                compsetId,
                documentFilters,
            ) as {[provider: string]: RatesDocumentItemAllModel}[]
            : null;
        return priceHistory ? priceHistory.map((day: {[provider: string]: RatesDocumentItemAllModel} | null) => {
            if (!day || !Object.keys(day).length) {
                return null;
            }
            const item = day[provider];
            if (!item) {
                return null;
            }
            return day[provider].statistics ? (day[provider].statistics.lowest || null) : null;
        }).reverse() : [];
    }

    priceHistory(
        actualDay: Day | null,
        hotelId: number | null,
        compsetId: string | null = null,
        documentFilters: DocumentFiltersModel | null = null, // TODO: Вопрос по фильтрам
    ): {[provider: string] : RatesDocumentItemAllModel}[] | null {
        this.ratesPriceHistoryCommonService.setData(actualDay, hotelId, compsetId, documentFilters);

        const newDays = this.ratesPriceHistoryCommonService.sortedDaysList.map((_: number, index: number) => this.getSuitableProviderByDay(index)
            || {} as {[provider: string]: RatesDocumentItemAllModel});
        return this.addAverageToHistory(newDays);
    }
}
