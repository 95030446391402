import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';

const EXPEDIA_BOOK_DATE_STATISTICS : STATISTIC_TYPE[] = [
    STATISTIC_TYPE.REVENUE,
    STATISTIC_TYPE.REVENUE_SHARE,
    STATISTIC_TYPE.NET_ADR,
    STATISTIC_TYPE.ROOM_NIGHTS,
    STATISTIC_TYPE.CANCELLATION,
    STATISTIC_TYPE.AVG_LOS,
    STATISTIC_TYPE.PAGE_VIEWS,
    // STATISTIC_TYPE.PAGE_VIEWS_SHARE,
    STATISTIC_TYPE.GROSS_CONVERSION,
    STATISTIC_TYPE.PACKAGE_SHARE,
    STATISTIC_TYPE.AVERAGE_BOOKING_WINDOW,
    STATISTIC_TYPE.CORPORATE_TRAVEL_SHARE,
    STATISTIC_TYPE.INTERNATIONAL_SHARE,
    STATISTIC_TYPE.PROMOTION_SHARE,
];

export default EXPEDIA_BOOK_DATE_STATISTICS;
