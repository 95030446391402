import { Expose, Transform } from 'class-transformer';

export default class KpisDataModel {
    @Expose()
    @Transform((_, plain) => plain.SevereIssues)
    severeIssues!: number;

    @Expose()
    @Transform((_, plain) => plain.AllocationIssues)
    allocationIssues!: number;

    @Expose()
    @Transform((_, plain) => plain.BrandSoldOut)
    soldOut!: number;
}
