import { Inject, injectable } from 'inversify-props';

import Stateable from '@/modules/common/interfaces/stateable.interface';
import Day from '@/modules/common/types/day.type';

import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';

import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import RatesScanModel from '@/modules/common/modules/socket/models/rates-scan.model';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';

import RatesStore from '@/modules/rates/store/rates.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import CustomNotificationService, { CustomNotificationServiceS } from '@/modules/common/modules/custom-notification/custom-notification.service';
import RatesApiService, { RatesApiServiceS, RatesDownloadExcelForm } from '@/modules/rates/rates-api.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import SocketService, { SocketServiceS } from '@/modules/common/modules/socket/socket.service';
import RatesCommonService, { RatesCommonServiceS, AllChannelsCheckinDay } from '@/modules/common/modules/rates/rates-common.service';
import RatesAllService, { RatesAllServiceS } from '@/modules/rates/rates-all.service';
import downloadBlobAsFile from '@/modules/common/filters/download-file';
import RatesDocumentAllModel from './models/rates-document-all.model';
import HotelRooms from '../common/interfaces/hotelRooms.interface';
import UserSettingsService, { UserSettingsS } from '../user/user-settings.service';
import PRICE_SHOWN from './constants/price-shown.constant';
import PRICE from '../common/modules/rates/constants/price.enum';
import COMPSET_TYPE from '../compsets/constants/compset-type.constant';
import CompsetModel from '../compsets/models/compset.model';
import DEFAULT_NUMBER_OF_GUESTS from '../number-of-guests/constants/default-number-of-guests.constant';
import DownloadExcelModel from './models/download-excel.model';

type RatesDocumentUnion = RatesDocumentModel | RatesDocumentAllModel;

export const RatesServiceS = Symbol.for('RatesServiceS');
@injectable(RatesServiceS as unknown as string)
export default class RatesService implements Stateable {
    @Inject(RatesApiServiceS) private ratesApiService!: RatesApiService;
    @Inject(CustomNotificationServiceS) private customNotificationService!: CustomNotificationService;
    @Inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(SocketServiceS) private socketService!: SocketService;
    @Inject(RatesAllServiceS) ratesAllService!: RatesAllService;
    @Inject(UserSettingsS) private userSettingsService!: UserSettingsService;

    readonly storeState: RatesStore = this.storeFacade.getState('RatesStore');

    constructor(storeState: RatesStore | null = null) {
        if (storeState) {
            this.storeState = storeState;
        } else {
            this.storeFacade.watch(() => [
                this.documentFiltersService.storeState.settings.compsetId,
                this.documentFiltersService.storeState.settings.month,
                this.documentFiltersService.storeState.settings.year,
                this.documentFiltersService.storeState.settings.los,
                this.documentFiltersService.storeState.settings.pos,
                this.storeState.settings.provider,
                this.storeState.settings.numberOfGuests,
                this.storeState.settings.roomTypeId,
                this.storeState.settings.mealTypeId,
                this.storeState.settings.priceType,
                this.userService.currentHotelId,
                this.userSettingsService.displayCurrency,
                this.userService.viewAs,
            ], () => {
                this.storeState.isPriceShownSwitchDisabled = true;
                this.storeState.loading.reset();
                this.storeState.intraday.loading.reset();
            });

            this.storeFacade.watch(() => [
                this.storeState.settings.roomTypeId,
                this.storeState.settings.mealTypeId,
                this.storeState.settings.competitors,
            ], () => {
                this.storeState.isPriceShownSwitchDisabled = true;
            });

            this.storeFacade.watch(() => [
                this.storeState.settings.priceShown,
            ], () => {
                this.ratesCommonService
                    .redefineRankForRooms(this.data as RatesDocumentModel, this.storeState.settings);
            });

            this.socketService.onRatesScan(this.onScanUpdate.bind(this));
        }
    }

    async loadData(docSettings?: DocumentFiltersModel): Promise<boolean> {
        const { settings: defaultDocumentSettings } = this.documentFiltersService;
        const { settings: ratesSettings } = this.storeState;
        const { displayCurrency } = this.userSettingsService;
        const { provider } = ratesSettings;

        const documentSettings = false
            || docSettings
            || defaultDocumentSettings;

        const isSettingsValid = false
            || documentSettings.compsetId === null
            || documentSettings.los === null
            || documentSettings.pos === null
            || provider === null;

        if (isSettingsValid) {
            return false;
        }

        this.storeState.document = null;

        const settings = {
            ...documentSettings,
            ...ratesSettings,
        };

        const ratesDocument = await this.ratesApiService
            .getRatesDocument(settings, displayCurrency);

        this.storeState.document = ratesDocument || this.storeState.document;

        if (this.storeState.document) {
            this.ratesCommonService
                .redefineRankForRooms(this.storeState.document as RatesDocumentModel, settings);
        }

        return true;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.document;
    }

    set data(value: RatesDocumentModel | RatesDocumentAllModel | null) {
        this.storeState.document = value;
    }

    set settings(value: RatesSettingsModel) {
        this.storeState.settings = value;
    }

    get settings() {
        return this.storeState.settings;
    }

    get currency() {
        return this.ratesCommonService.currency(this.data);
    }

    get documentId() {
        return this.data && this.data.id;
    }

    get showDiff() {
        return this.storeState.showPriceDiff;
    }

    set showDiff(value: boolean) {
        this.storeState.showPriceDiff = value;
    }

    get finishScanDate() {
        if (!this.data) {
            return null;
        }

        return this.data.finishScanDate || null;
    }

    get scanStatus() {
        if (!this.data) {
            return null;
        }

        return this.data.scanStatus;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get isIntradayLoading() {
        return this.storeState.intraday.loading.isLoading();
    }

    get isAllChannelsMode() {
        return this.settings.provider === 'all';
    }

    getCheckinDay(day: Day) {
        return this.ratesCommonService
            .getCheckinDay(day, this.data as RatesDocumentModel);
    }

    getAllRooms(day: Day): HotelRooms {
        return this.ratesCommonService
            .getAllRooms(day, this.data as RatesDocumentModel);
    }

    getCompetitorsRooms(day: Day, selectedCompetitors?: number[]): HotelRooms {
        const allRooms = this.getAllRooms(day);

        if (this.isAllChannelsMode) {
            return allRooms;
        }

        const hotelId = this.userService.currentHotelId;

        const competitors = selectedCompetitors || this.settings.competitors;

        if (!hotelId || !competitors) return {};

        const entries = competitors
            .map(hid => [hid, allRooms[hid]]);

        return Object.fromEntries(entries);
    }

    getRoom(day: Day, hotelId: number) {
        return this.ratesCommonService
            .getRoom(day, hotelId, this.data as RatesDocumentModel);
    }

    /**
     * **For Cheapest channel only**
     * Returns provider list for specific room
     */
    getRoomProviders(day: Day, hotelId: number) {
        return this.ratesCommonService
            .getRoomProviders(this.data as RatesDocumentModel, day, hotelId);
    }

    getHotelLink(day: Day, hotelId: number | string) {
        const checkinDay = this.getCheckinDay(day);

        if (!checkinDay) return null;

        if (this.isAllChannelsMode) {
            const cd = checkinDay as unknown as AllChannelsCheckinDay;

            if (!cd[hotelId]) return null;

            return cd[hotelId].link;
        }

        if (this.isNA(day, +hotelId)) return null;

        const hotel = checkinDay.hotels[+hotelId];

        return hotel ? hotel.link : null;
    }

    getHotelsFromCheckinDay(day: Day) {
        const checkinDay = this.getCheckinDay(day);

        if (!checkinDay) return null;

        return checkinDay.hotels || null;
    }

    getCompetitionPercent(day: Day, compset?: CompsetModel) {
        if (this.isAllChannelsMode) {
            const averagePrice = this.getAverageRoomsPrice(day);
            const highestPrice = this.getHighestPrice(day);

            return (averagePrice - highestPrice) / highestPrice;
        }

        const hotelPrice = this.getPrice(day);
        const comparePrice = compset
            ? this.getCompsetPriceByCompset(day, compset)
            : this.getCompsetPrice(day);

        if (!hotelPrice || !comparePrice) {
            return null;
        }

        return (hotelPrice - comparePrice) / comparePrice;
    }

    /**
     * Returns price for Hotel Room by its `hotelId` (default: current user hotel)
     * - if `hotelId` is string, it is provider name
     */
    getPrice(day: Day, hotelId?: number | string) {
        const hid = hotelId || this.userService.currentHotelId;

        if (!hid) return null;

        const doc = this.data as RatesDocumentModel;
        const { priceShown } = this.settings;

        const price = this.ratesCommonService
            .getPrice(day, +hid || hid, doc, priceShown);

        return price;
    }

    getUpdateDate(day: Day) {
        return this.ratesCommonService
            .getUpdateDate(day, this.data as RatesDocumentModel);
    }

    getPriceList(day: Day) {
        const rooms = Object.values(this.getAllRooms(day)) as RatesDocumentItemModel[];

        return Object
            .values(rooms)
            .map(room => this.switchPrice(room))
            .filter(price => true
                && price
                && price !== PRICE.NA
                && price !== PRICE.SOLD_OUT) as number[];
    }

    getAverageRoomsPrice(day: Day) {
        const validPrices = this.getPriceList(day);
        return +(validPrices.reduce((a, b) => a + b, 0) / validPrices.length).toFixed(2);
    }

    getLowestPrice(day: Day) {
        const validPrices = this.getPriceList(day);
        return Math.min(...validPrices);
    }

    getHighestPrice(day: Day) {
        const validPrices = this.getPriceList(day);
        return Math.max(...validPrices);
    }

    getCompsetPriceByCompset(day: Day, compset: CompsetModel) {
        if (!compset) return null;

        const competitorRooms = this.getCompetitorsRooms(day, compset.competitors);
        const { priceShown } = this.settings;

        return this.ratesCommonService
            .getCompsetPrice(competitorRooms, compset.type, priceShown);
    }

    getCompsetPrice(day: Day, compsetType?: COMPSET_TYPE, useAllCompetitors?: boolean) {
        const compset = !compsetType
            ? this.compsetsService.currentCompset
            : { type: compsetType, competitors: undefined };

        if (!compset) return null;

        const competitorRooms = this.getCompetitorsRooms(day, useAllCompetitors ? compset.competitors : undefined);
        const { priceShown } = this.settings;

        return this.ratesCommonService
            .getCompsetPrice(competitorRooms, compset.type, priceShown);
    }

    getCardAssessment(day: Day) : ASSESSMENTS_TYPES | null {
        const { currentCompset } = this.compsetsService;
        if (!currentCompset) return null;

        const competitionPercent = this.getCompetitionPercent(day);

        if (competitionPercent === null) return null;

        return this.ratesCommonService
            .getCardAssessment(competitionPercent, currentCompset);
    }

    getTableAssessment(price: number, day: Day) {
        const mainHotelId = this.userService.currentHotelId!;
        const doc = this.data as RatesDocumentModel;
        const { currentCompset: compset } = this.compsetsService;
        const { settings } = this;

        return this.ratesCommonService
            .getTableAssessment(price, day, mainHotelId, compset, doc, settings);
    }

    getDemand(day: Day) {
        const checkinDate = this.getCheckinDay(day);

        if (!checkinDate) {
            return null;
        }
        if (checkinDate.demand) {
            return checkinDate.demand * 100;
        }
        return null;
    }

    getOccupancy(day: Day) {
        const checkinDate = this.getCheckinDay(day);

        if (!checkinDate) {
            return null;
        }

        if (checkinDate.occupancy) {
            return checkinDate.occupancy / 100;
        }

        return null;
    }

    getHotelLosRestriction(day: Day, hotelId?: number) {
        const hid = hotelId || this.userService.currentHotelId;

        return this.ratesCommonService.getHotelLosRestriction(day, hid!, this.data);
    }

    getHotelName(hotelId: number) {
        if (!this.data) return null;

        return this.data.hotelNames[hotelId];
    }

    saveDocument(doc: RatesDocumentUnion | null) {
        this.storeState.document = doc;
    }

    myUpdateStatusDate(day: Day | undefined) {
        if (!day) {
            return null;
        }

        return this.getUpdateDate(day);
    }

    switchPrice(room: RatesDocumentItemModel | null) {
        if (room && room.price && this.settings.provider === 'all') {
            return room.price.lowestPrice;
        }

        return this.ratesCommonService.switchPrice(this.settings, room);
    }

    minMaxPrices(excludeHotelId?: number | null): { minPrices: (number | null)[], maxPrices: (number | null)[] } {
        const { settings, data } = this;
        const { days } = this.documentFiltersService;

        return this.ratesCommonService
            .minMaxPrices(settings, data as RatesDocumentModel, days, excludeHotelId);
    }

    hasRoomMealType(day: Day, hotelId: number) {
        const currentRoom = this.getRoom(day, hotelId);

        if (!currentRoom) {
            return false;
        }

        const roomOnlyId = 0;
        return currentRoom.mealTypeId !== roomOnlyId;
    }

    hasRoomSameOccupancy(day: Day, hotelId: number) {
        const currentRoom = this.getRoom(day, hotelId);

        if (!currentRoom) {
            return false;
        }

        const { numberOfGuests } = this.storeState.settings;

        return currentRoom.occupancy === numberOfGuests;
    }

    hasRoomMultipleCancellation(day: Day, hotelId: number) {
        const currentRoom = this.getRoom(day, hotelId);

        if (!currentRoom) {
            return false;
        }

        return currentRoom.multipleCancellation;
    }

    isBasic(day: Day, hotelId: number) {
        const room = this.getRoom(day, hotelId);

        if (!room) return null;

        return room.isBasic;
    }

    isIntraday(day: Day) {
        if (this.isAllChannelsMode) return null;

        const hotelId = this.userService.currentHotelId;
        if (!hotelId) return null;

        const room = this.getRoom(day, hotelId);
        if (!room) return null;

        return room.intraday;
    }

    isNoData(day: Day) {
        return this.ratesCommonService
            .isNoData(day, this.data as RatesDocumentModel);
    }

    isOutOfRange() {
        return !this.data;
    }

    isNA(day: Day, hotelId: number | string) {
        if (this.isNoData(day)) return false;

        const price = this.getPrice(day, hotelId);

        return false
            || price === PRICE.NA
            || price === null;
    }

    isSoldOut(day: Day, hotelId: number | string) {
        if (this.isNA(day, hotelId)) return false;
        if (this.isNoData(day)) return false;

        const price = this.getPrice(day, hotelId);

        return price === PRICE.SOLD_OUT;
    }

    isRoomPriceValid(room: RatesDocumentItemModel | null) {
        const price = this.switchPrice(room);
        return true
                && price
                && price !== PRICE.NA
                && price !== PRICE.SOLD_OUT;
    }

    isScanAvailable(day?: Day): boolean {
        return this.ratesCommonService.isScanAvailable(this.storeState.settings, day);
    }

    // TODO: implement at all places
    async triggerScanNew(day?: Day) {
        const { settings: docSettings } = this.documentFiltersService;
        const { settings: ratesSettings } = this;

        const isScanStarted = await this.ratesCommonService
            .triggerScan(ratesSettings, docSettings, day);

        if (!isScanStarted) {
            throw new Error('Failed to launch on demand scan!');
        }

        if (this.data && this.data.checkinDates) {
            this.data = {
                ...this.data,
                scanStatus: SCAN_STATUS.IN_PROGRESS,
            };
        } else {
            this.storeState.loading.reset();
        }

        return true;
    }

    private onScanUpdate(data: RatesScanModel) {
        if (this.data && this.data.id === data.ratesDocumentId) {
            this.storeState.loading.reset();
        }
    }

    async checkScanStatus() {
        if (!this.data) {
            return null;
        }

        const { scanId, id } = this.data as RatesDocumentModel;
        if (!scanId || !id) {
            return null;
        }

        const { status } = await this.ratesApiService.checkScanStatus(id, scanId);
        return status;
    }

    // Intraday methods

    async loadIntradayData(day: Day) {
        const { settings: docSettings } = this.documentFiltersService;
        const { settings: ratesSettings } = this;
        const { displayCurrency: currency } = this.userSettingsService;
        const { currentHotelId: fornovaId } = this.userService;
        const unitedSettings = {
            ...docSettings,
            ...ratesSettings,
        };

        const isSettingsNotValid = false
            || unitedSettings.compsetId === null
            || unitedSettings.los === null
            || unitedSettings.pos === null
            || unitedSettings.provider === null
            || fornovaId === null;

        if (isSettingsNotValid) {
            return false;
        }

        this.storeState.intraday.document = await this.ratesApiService
            .getIntradayPrice(day, fornovaId as number, unitedSettings, currency);
        this.storeState.intraday.day = day;

        return true;
    }

    getIntradayData(day: Day) {
        if (day !== this.storeState.intraday.day && !this.isIntradayLoading) {
            this.storeState.intraday.loading.reset();
            this.storeState.intraday.document = null;
        }

        this.helperService.dynamicLoading(this.storeState.intraday.loading, () => this.loadIntradayData(day));
        return this.storeState.intraday.document;
    }

    getMedianPrice(day: Day) {
        return this.getCompsetPrice(day, COMPSET_TYPE.MEDIAN);
    }

    intradayHotelRooms(day: Day) {
        if (!this.settings) {
            return null;
        }

        const intradayData = this.getIntradayData(day);

        if (!intradayData || !intradayData.hotels || !Object.keys(intradayData.hotels).length) {
            return null;
        }

        let hotelRooms: { [hotelId: number]: RatesDocumentItemModel } | null = null;

        hotelRooms = this.ratesCommonService.getAllRoomsByHotels(intradayData.hotels);

        return hotelRooms;
    }

    intradayByHotelId(day: Day, hotelId: number, priceShown: PRICE_SHOWN) {
        const hotelRooms = this.intradayHotelRooms(day);
        if (!hotelRooms) {
            return null;
        }

        const room = hotelRooms[hotelId];

        return this.ratesCommonService
            .switchPrice({ ...this.settings, priceShown }, room);
    }

    intradayMedian(day: Day) {
        const hotelRooms = this.intradayHotelRooms(day);
        const { priceShown } = this.settings;

        if (!hotelRooms) {
            return null;
        }

        return this.ratesCommonService.getMedianPrice(hotelRooms, priceShown);
    }

    async getExcel(params: RatesDownloadExcelForm, toEmail = false, onDemand = false) {
        const { currentHotelId: fornovaId } = this.userService;

        if (!fornovaId) {
            return false;
        }

        const excelData = await this.ratesApiService
            .getExcelDocument({ ...params, fornovaId }, toEmail, onDemand);

        if (excelData) {
            if (!toEmail && !onDemand) {
                await this.customNotificationService
                    .handleExcel(excelData);
            }
        }

        return true;
    }

    async downloadExcelDirectly(
        _compsetId: string,
        dateStart: string,
        dateEnd: string,
        query: {
            pos: string, los: string, priceType: string, providers: string,
            numberOfGuests: string, roomTypeId: string, mealTypeId: string,
        },
    ): Promise<void> {
        const monthRange: string[] = [dateStart, dateEnd];
        const numOfGuests = Number(query.numberOfGuests) || DEFAULT_NUMBER_OF_GUESTS;

        const convertStringToArray = (str: string, type: string): any[] => {
            if (!str) {
                return [];
            }
            if (type === 'string') {
                return str.split(',').map(String);
            }
            if (type === 'int') {
                return str.split(',').map(Number);
            }
            return [];
        };

        const data = {
            compsetId: _compsetId,
            pos: convertStringToArray(query.pos, 'string')[0],
            los: convertStringToArray(query.los, 'int')[0],
            priceType: convertStringToArray(query.priceType, 'string'),
            provider: convertStringToArray(query.providers, 'string')[0],
            numberOfGuests: numOfGuests,
            roomTypeId: convertStringToArray(query.roomTypeId, 'int'),
            mealTypeId: convertStringToArray(query.mealTypeId, 'int'),
            monthrange: monthRange,
            competitors: [],
            priceShown: PRICE_SHOWN.TOTAL,
            displayCurrency: 'USD',
            columns: {
                market_demand: true,
                occupancy: true,
                rank: true,
                diff_delta: true,
                diff_precent: true,
                median: true,
                mealType: true,
                roomType: true,
                roomName: true,
            },
        };

        await this.getExcel(data);
    }
}
