import CompsetMainModel from '@/modules/cluster/models/compset-main.model';
import MarketsDocumentItemModel from '@/modules/markets/models/markets-document-item.model';
import MarketsCheckinDatesValidator from '@/modules/markets/models/validators/checkin-dates.validator';
import { Expose, plainToClass, Transform } from 'class-transformer';
import { IsOptional, Validate } from 'class-validator';

export default class MarketsCompsetMainModel extends CompsetMainModel {
    @Expose()
    @Transform((_, plain) => {
        if (!plain.checkin_dates) {
            return {};
        }

        const checkinDates = JSON.parse(JSON.stringify(plain.checkin_dates));

        Object.keys(checkinDates).forEach(day => {
            Object.keys(checkinDates[Number(day)]).forEach(hotelId => {
                if (hotelId === 'update_date') {
                    delete checkinDates[Number(day)][hotelId];
                    return;
                }

                checkinDates[Number(day)][hotelId] = plainToClass(
                    MarketsDocumentItemModel,
                    <MarketsDocumentItemModel> checkinDates[Number(day)][hotelId],
                    { excludeExtraneousValues: true },
                );
            });
        });

        return checkinDates;
    })
    @IsOptional()
    @Validate(MarketsCheckinDatesValidator)
    checkinDates!: {
        [day: number]: {
            [hotelId: number]: MarketsDocumentItemModel,
        };
    };

    @Expose()
    @Transform((_, plain) => {
        if (!plain.checkin_dates) {
            return {};
        }

        const checkinDates = JSON.parse(JSON.stringify(plain.checkin_dates));

        if (!checkinDates) {
            return checkinDates;
        }

        Object.keys(checkinDates).forEach(day => {
            checkinDates[Number(day)] = checkinDates[Number(day)].update_date || null;
        });

        return checkinDates;
    })
    @Validate(MarketsCheckinDatesValidator)
    updateDates!: {
        [day: number]: string;
    };
}
