import LoadingModel from '@/modules/common/models/loading.model';
import { Module, VuexModule } from 'vuex-module-decorators';
import CarsCompSetModel from '../models/cars-comp-set.model';

@Module
export default class CarsCompSetStore extends VuexModule {
    document: CarsCompSetModel[] = [];
    loading: LoadingModel = new LoadingModel();
    saveRequestLoading = new LoadingModel();
    settings = {
        vendorsList: {},
    };
}
