import { Module, VuexModule } from 'vuex-module-decorators';
import CarsFiltersModel from '@/modules/cars/models/cars-filters.model';
import LoadingModel from '@/modules/common/models/loading.model';
import BranchesModel from '@/modules/cars/models/branches.model';

/**
 * todo - rename to CarsGlobalStore, it holds global data for all cars components.
 */
@Module
export default class CarsFiltersStore extends VuexModule {
    filtersReady: boolean = false;
    settings: CarsFiltersModel = new CarsFiltersModel();
    loading: LoadingModel = new LoadingModel();
    // Next properties are actually global for cars, todo: move into separate global cars store. Or leave here is this store is renamed to global
    branchesLoading: LoadingModel = new LoadingModel();
    branches: BranchesModel[] = [];
    chain?: BranchesModel | null;
}
