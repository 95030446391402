// eslint-disable-next-line import/named
import { CarClassMapping } from '@/modules/cars-category-manager/models/cars-category-model.model';

// eslint-disable-next-line import/prefer-default-export
export const updateCarsCategoryBodyAdapter = (data: CarClassMapping) => {
    const result = {} as Record<string, Record<string, string[]>>;
    Object
        .entries(data)
        .forEach(([providerName, normalizedClassesMap]) => {
            const providerMappings = { } as Record<string, string[]>;
            Object
                .entries(normalizedClassesMap)
                .forEach(([normalized, classList]) => {
                    if (classList && classList.length) {
                        providerMappings[normalized] = classList;
                    }
                });
            if (Object.keys(providerMappings).length) {
                result[providerName] = providerMappings;
            }
        });
    return result;
};
