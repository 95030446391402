import { Expose, Transform } from 'class-transformer';

export default class KpisMapDataModel {
    @Expose()
    @Transform((_, plain) => plain.location_code)
    locationCode!: string;

    @Expose()
    @Transform((_, plain) => (plain.total_cases ? plain.total_cases : 0))
    totalCases!: number;

    @Expose()
    @Transform((_, plain) => (plain.B ? Number(plain.B * 100).toFixed(2) : 0))
    B!: number;

    @Expose()
    @Transform((_, plain) => (plain.L ? Number(plain.L * 100).toFixed(2) : 0))
    L!: number;

    @Expose()
    @Transform((_, plain) => (plain.M ? Number(plain.M * 100).toFixed(2) : 0))
    M!: number;

    @Expose()
    @Transform((_, plain) => (plain.A ? Number(plain.A * 100).toFixed(2) : 0))
    A!: number;
}
