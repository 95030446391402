








import { Vue, Component, Watch } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { Notification } from 'element-ui';
import NotificationsContainer
    from '@/modules/common/modules/custom-notification/components/notifications-container.vue';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import NotifyService, { NotifyServiceS } from '@/modules/common/services/notify.service';
import ExceptionHandler, { ExceptionHandlerS } from '@/modules/common/modules/exception-handler/exception-handler.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CustomNotificationService, { CustomNotificationServiceS } from '@/modules/common/modules/custom-notification/custom-notification.service';

@Component({
    components: {
        NotificationsContainer,
    },
})
export default class App extends Vue {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(NotifyServiceS) private notifyService!: NotifyService;
    @Inject(ExceptionHandlerS) private exceptionHandler!: ExceptionHandler;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CustomNotificationServiceS) private customNotificationService!: CustomNotificationService;

    /**
     * Here we will connect services with vue features
     */
    created() {
        this.apiService.setRequestInterceptor(config => {
            this.$Progress.start();
            return config;
        });
        this.apiService.setResponseInterceptor(response => {
            this.$Progress.finish();
            return response;
        });

        this.notifyService.init({
            errorNotification: (message?: string) => {
                Notification({
                    title: message || 'ERROR',
                    message: '',
                    type: 'error',
                    showClose: false,
                    customClass: 'custom-notification error',
                });
            },
            infoNotification: (message?: string) => {
                Notification({
                    title: message || 'INFO',
                    message: '',
                    type: 'info',
                    showClose: false,
                    customClass: 'custom-notification info',
                });
            },
            successNotification: (message?: string) => {
                Notification({
                    title: message || 'SUCCESS',
                    message: '',
                    type: 'success',
                    showClose: false,
                    customClass: 'custom-notification success',
                });
            },
            warningNotification: (message?: string, params: {} = {}) => {
                Notification({
                    title: message || 'WARNING',
                    message: '',
                    type: 'warning',
                    showClose: false,
                    customClass: 'custom-notification warning',
                    ...params,
                });
            },
        });
    }

    @Watch('$route.path', { deep: true })
    handleRouteChange() {
        if (this.userService.isCarUser) {
            return;
        }
        this.$gtag.event('page_view', {
            page_path: this.$route.path,
            chainId: this.userService.chainId,
            userId: this.userService.id,
        });
    }

    errorCaptured(error: Error) {
        this.exceptionHandler.catch(error);
    }
}
