import { Inject, injectable } from 'inversify-props';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import DEFAULT_NUMBER_OF_GUESTS from '@/modules/number-of-guests/constants/default-number-of-guests.constant';
import NumberOfGuestsStore from '@/modules/number-of-guests/store/number-of-guests.store';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import NumberOfGuestsApiService, { NumberOfGuestsApiServiceS } from '@/modules/number-of-guests/number-of-guests-api.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import HelperService, { HelperServiceS } from '../common/services/helper.service';

export const NumberOfGuestsServiceS = Symbol.for('NumberOfGuestsServiceS');
@injectable(NumberOfGuestsServiceS as unknown as string)
export default class NumberOfGuestsService implements Stateable {
    @Inject(NumberOfGuestsApiServiceS) private numberOfGuestsApiService!: NumberOfGuestsApiService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(RatesServiceS) private ratesService!: RatesService;

    readonly storeState: NumberOfGuestsStore = this.storeFacade.getState('NumberOfGuestsStore');

    constructor() {
        this.storeFacade.watch(
            () => this.userService.storeState.user,
            this.storeState.loading.reset.bind(this.storeState.loading),
        );
    }

    get numberOfGuests() {
        // this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        // const numberOfGuests = new Set([DEFAULT_NUMBER_OF_GUESTS, ...this.storeState.numberOfGuests]);
        // Temporary fix, required by product
        // const numberOfGuests = new Set([DEFAULT_NUMBER_OF_GUESTS]);
        // return Array.from(numberOfGuests).sort();

        // Temporary solution, backend fix is needed
        if (!this.ratesService.data) {
            return [DEFAULT_NUMBER_OF_GUESTS];
        }

        return this.ratesService.data.numberOfGuestsOptions;
    }

    async loadData() {
        if (!this.userService.isHotelUser) {
            return false;
        }

        const { currentHotelId } = this.userService;
        if (currentHotelId === null) {
            return false;
        }

        const numberOfGuests = await this.numberOfGuestsApiService.getNumbersOfGuests(currentHotelId);

        this.storeState.numberOfGuests = numberOfGuests || [];
        return true;
    }
}
