import { Expose, plainToClass, Transform } from 'class-transformer';
import FleetAvailabilityItemModel from '@/modules/cars/models/fleet-availability-item.model';

export default class FleetAvailabilityTrendsDocumentModel {
    @Expose()
    @Transform((_, plain) => {
        const occupancyDates = plain.occupancy_dates;

        if (!occupancyDates) {
            return occupancyDates;
        }
        Object.keys(occupancyDates).forEach(date => {
            Object.keys(occupancyDates[date]).forEach(carCategory => {
                occupancyDates[date][carCategory] = plainToClass(
                    FleetAvailabilityItemModel,
                    <any> occupancyDates[date][carCategory],
                    { excludeExtraneousValues: true },
                );
            });
        });
        return occupancyDates;
    })
    occupancyDates!: {
        [date: string]: {
            [carCategory: string]: FleetAvailabilityItemModel | null
        }
    };

    @Expose()
    day: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.date && new Date(plain.date))
    date!: Date;

    @Expose()
    @Transform((_, plain) => plain.fleet_occupancy_id)
    fleetOccupancyId!: number;
}
