import { Expose, Transform } from 'class-transformer';
import deepMapKeys from 'deep-map-keys';
import { IsInt, IsOptional } from 'class-validator';

export default class MarketsDocumentItemModel {
    @Expose()
    @IsInt()
    position!: number;

    @Expose()
    @IsOptional()
    @IsInt()
    page?: number;

    @Expose()
    @IsOptional()
    @IsInt()
    @Transform((_, plain) => {
        if (!plain.number_of_hotels) {
            return undefined;
        }
        return Number(plain.number_of_hotels);
    })
    numberOfHotels?: number;

    @Expose()
    @IsOptional()
    @IsInt()
    link?: string;

    @Expose()
    @Transform((_, plain) => plain.promoted)
    promoted?: boolean;
}
