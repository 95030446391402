import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { event } from 'vue-gtag';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import MarketsDocumentModel from '@/modules/markets/models/markets-document.model';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import DownloadExcelModel from '@/modules/rates/models/download-excel.model';
import MarketSettingsModel from './models/market-settings.model';
import MarketsTriggerScanModel from './models/market-trigger-scan.model';
import Item from '../common/interfaces/item.interface';
import UserService, { UserServiceS } from '../user/user.service';

const marketsDocumentCache: { [k: string]: any } = {};

export interface DownloadExcelForm {
    choosedProviders: Item[];
    providers: (string | number)[];
    provider: string | number | null;
    los: number | null;
    pos: string | null;
    month: number;
    year: number;
    compsetId: string | null;
    date: Date | undefined;
}

export const MarketsApiServiceS = Symbol.for('MarketsApiServiceS');
@injectable(MarketsApiServiceS as unknown as string)
export default class MarketsApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(UserServiceS) private userService!: UserService;

    async getMarketsDocument(
        documentSettings: DocumentFiltersModel,
        marketSettings: MarketSettingsModel,
        force?: boolean,
    ): Promise<MarketsDocumentModel | null> {
        const { provider, los, pos } = marketSettings;
        const { year, month, compsetId } = documentSettings;
        const requestRoute = `/market/${compsetId}/${year}/${month + 1}/${provider}/${los}/${pos}`;

        let promise: Promise<any> | null = null;

        if (!force && marketsDocumentCache[requestRoute]) {
            if (marketsDocumentCache[requestRoute] instanceof Promise) {
                promise = marketsDocumentCache[requestRoute];
            } else {
                return marketsDocumentCache[requestRoute];
            }
        }

        if (!promise) {
            promise = this.apiService.get(requestRoute);
        }

        marketsDocumentCache[requestRoute] = promise;

        const response = await promise;

        if (!response.data) {
            return null;
        }

        marketsDocumentCache[requestRoute] = plainToClass(
            MarketsDocumentModel,
<MarketsDocumentModel> response.data,
{ excludeExtraneousValues: true },
        );

        return marketsDocumentCache[requestRoute];
    }

    async getExcelDocument(settings: DownloadExcelForm, toEmail?: boolean) {
        const {
            provider, los, pos, compsetId, year, month,
        } = settings;

        const params = [compsetId, year, month + 1, provider, los, pos].join('/');

        event('download_excel', {
            chainId: this.userService.chainId,
            userId: this.userService.id,
        });

        const { data } = await this.apiService.get(`market/excel/${params}`, {
            send_to_email: toEmail,
        });

        if (!data) {
            return null;
        }

        return plainToClass(DownloadExcelModel, <DownloadExcelModel>data, { excludeExtraneousValues: true });
    }

    async triggerScan(compsetId: string, los: number, pos: string, provider: string, startDate: Date, endDate?: Date) {
        const { data } = await this.apiService.post('/scan/mv-trigger/by-compset-id', {
            ondemand: 'true',
            compSetIds: [compsetId],
            pos: [pos],
            los: [los],
            providers: [provider],
            start_date: `${startDate.getFullYear()}-${(`0${startDate.getMonth() + 1}`).slice(-2)}-${(`0${startDate.getDate()}`).slice(-2)}`,
            end_date: endDate && `${endDate.getFullYear()}-${(`0${endDate.getMonth() + 1}`).slice(-2)}-${(`0${endDate.getDate()}`).slice(-2)}`,
        });

        if (!data) return null;

        return plainToClass(MarketsTriggerScanModel, <MarketsTriggerScanModel> data, { excludeExtraneousValues: true });
        /*
        {
            "compSetIds":["5e98706358686c4a4cd34680"],
            "ondemand":"true",
            "pos":["US"],
            "los":[1,3],
            "providers":["booking"],
            "start_date":"2020-05-03T08:40:18+0000",
            "end_date":"2020-05-03T08:40:18+0000"
        }
         */
    }

    async resendScheduledReport(level: 'hotel' | 'cluster', schedulerId: number) {
        const endpoint = {
            hotel: `/market/excel/by-scheduler-config-id/${schedulerId}`,
            cluster: `/market/cluster-excel/by-scheduler-config-id/${schedulerId}`,
        };

        const { data } = await this.apiService.post(endpoint[level], {});

        if (!data) return null;

        return data;
    }
}
