import { Inject, injectable } from 'inversify-props';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { IGuard } from '@/router/interfaces/guard.interface';

export const ClusterGuardS = Symbol.for('ClusterGuardS');
@injectable(ClusterGuardS as unknown as string)
export default class ClusterGuard implements IGuard {
    @Inject(UserServiceS) private userService!: UserService;

    async canActivate() {
        return this.userService.isClusterUser || false;
    }
}
