import VueRouter from 'vue-router';
import initRoutes from '@/router/routes';
import { container } from 'inversify-props';

const convertRouteName = (routeName: string) => {
    if (!routeName) return routeName;

    if (routeName.startsWith('home')) {
        const [_, userLevel = ''] = routeName.split('-');

        return ['HOME', userLevel.toUpperCase()].filter(e => !!e).join('_');
    }

    const [section] = routeName.split('.');
    let [, userLevel, kind = '', subKind = ''] = routeName.split('.');

    if (kind === 'hotel') {
        userLevel = kind;
        kind = subKind;
        subKind = '';
    }

    return [section, userLevel, kind]
        .filter(e => !!e)
        .map(e => e.toUpperCase())
        .join('_');
};

export const routerData = {
    router: null as unknown as VueRouter,
};

export default function initRouter() {
    const router = new VueRouter({
        mode: 'history',
        routes: initRoutes(),
        scrollBehavior(to, from, savedPosition) {
            if (to.meta?.modal && !from.meta?.modal) {
                return savedPosition;
            }
            if (!to.meta?.modal && from.meta?.modal) {
                return savedPosition;
            }
            return { x: 0, y: 0 };
        },
    });

    routerData.router = router;

    return router;
}
