import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import SHOW_BY from '@/modules/deep-analysis/constants/show-by-filter.constant';
import { Inject, injectable } from 'inversify-props';
import { Route } from 'vue-router';
import { IMiddleware } from '@/router/interfaces/middleware.interface';
import DeepCompsetAnalysisService, { DeepCompsetAnalysisServiceS } from '@/modules/deep-analysis/deep-analysis.service';

export const DeepAnalysisFiltersMiddlewareS = Symbol.for('DeepAnalysisFiltersMiddlewareS');
@injectable(DeepAnalysisFiltersMiddlewareS as unknown as string)
export default class DeepAnalysisFiltersMiddleware implements IMiddleware {
    @Inject(DeepCompsetAnalysisServiceS) private deepCompsetAnalysisService!: DeepCompsetAnalysisService;

    async canActivate(to: Route, from: Route) {
        if (to.query) {
            const { provider, showBy, compareTo } = to.query;
            if (provider && showBy && compareTo) {
                this.deepCompsetAnalysisService.showBy = showBy as SHOW_BY;
                this.deepCompsetAnalysisService.provider = provider as string;
                this.deepCompsetAnalysisService.compareTo = compareTo as COMPARE_TO;
            }
        }
        return true;
    }
}
