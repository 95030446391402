/**
 *
 * @param options.lorsToFilter input list of lort to be filtered
 * @param options.allowedLORs object describes lors restrictions
 * @param options.pos (optional) POS by which the list should be filtered
 * @returns The filterd list of lors
 */
const filterLorsByAllowance = <T extends string | number>(
    options: { lorsToFilter: T[], allowedLORs: Record<string, number[]>, pos?: string},
): T[] => {
    const { lorsToFilter, allowedLORs, pos } = options;

    const isNoRestrictions = !Object.keys(allowedLORs);

    const whitelistByAnyPOS = allowedLORs.all;
    const whitelistByPOS = (pos && allowedLORs[pos]) || whitelistByAnyPOS;

    if (isNoRestrictions || !whitelistByPOS || !whitelistByPOS.length) {
        return lorsToFilter;
    }

    return lorsToFilter.filter(lor => whitelistByPOS.includes(+lor));
};

export default filterLorsByAllowance;
