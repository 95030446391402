import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import THRESHOLD from '@/modules/compsets/constants/treshhold.constant';
import Percent from '@/modules/common/types/percent.type';
import { Inject, injectable } from 'inversify-props';
import CompsetsApiService, { CompsetsApiServiceS } from '@/modules/compsets/compsets-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import CompsetModel from '@/modules/compsets/models/compset.model';
import Vue from 'vue';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import CompsetsStore from './store/compsets.store';
import DocumentFiltersStore from '../document-filters/store/document-filters.store';
import { CompsetCreationBody } from './interfaces/compset-creation-body.interface';

export const CompsetsServiceS = Symbol.for('CompsetsServiceS');
@injectable(CompsetsServiceS as unknown as string)
export default class CompsetsService {
    @Inject(CompsetsApiServiceS) private compsetsApiService!: CompsetsApiService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(ClusterCompsetsServiceS) private clusterCompsetsService!: ClusterCompsetsService;

    readonly storeState: CompsetsStore = this.storeFacade.getState('CompsetsStore');
    private readonly documentFiltersStoreState: DocumentFiltersStore = this.storeFacade.getState('DocumentFiltersStore');

    constructor() {
        this.storeFacade.watch(
            () => this.userService.storeState.user,
            this.storeState.loading.reset.bind(this.storeState.loading),
        );
    }

    get compsets() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.compsets;
    }

    get localCompsets() {
        return this.storeState.localCompsets;
    }

    get onboarding() {
        return this.storeState.onboarding;
    }

    get currentCompset() {
        return this.getCompset(this.documentFiltersStoreState.settings.compsetId);
    }

    get currentMarketId() {
        const compset = this.currentCompset;

        if (compset === null) {
            return null;
        }

        return compset.marketId;
    }

    get competitors(): number[] | null {
        return this.currentCompset ? this.currentCompset.competitors : null;
    }

    get poses() {
        const allPoses = this.compsets ? [...new Set(this.compsets.map(compset => compset.pos).flat())] : [];
        const mainPoses = this.compsets ? [...new Set(this.compsets.map(compset => compset.mainPos))] : [];
        return [...new Set([...mainPoses, ...allPoses])];
    }

    async loadData() {
        if (!this.userService.storeState.currentUserHotelInited) {
            return false;
        }

        const { currentHotelId } = this.userService;
        if (currentHotelId === null) {
            return false;
        }

        const compsets = await this.compsetsApiService.getCompsets(currentHotelId);

        this.storeState.compsets = compsets;
        this.resetUpdatedCompsets();

        return true;
    }

    createCompset(compsetData?: CompsetCreationBody) {
        // eslint-disable-next-line no-param-reassign
        compsetData = compsetData || this.storeState.onboarding.data;

        return this.compsetsApiService
            .createCompset(compsetData);
    }

    getLocalCompset(compsetId: string) {
        if (!this.storeState.localCompsets) {
            return null;
        }

        const compsetIndex = this.storeState.localCompsets.findIndex(currentCompset => currentCompset.id === compsetId);

        if (compsetIndex === -1 || !this.storeState.localCompsets[compsetIndex]) {
            return null;
        }

        return this.storeState.localCompsets[compsetIndex] || null;
    }

    getCompset(compsetId: string | null) {
        const neededCompset = this.compsets && (this.compsets.find(compset => compset.id === compsetId) || null);
        const { isClusterUser, isChainUser } = this.userService;

        if (compsetId !== null && !neededCompset && (isChainUser || isClusterUser)) {
            return this.clusterCompsetsService.getCompsetById(compsetId);
        }
        return neededCompset;
    }

    getCompsetByType(compsetType: COMPSET_TYPE) {
        if (!this.compsets) {
            return null;
        }
        return this.compsets.find(compset => compset.type === compsetType) || null;
    }

    setUpdatedCompsetName(compsetId: string, updatedName: string) {
        const compset = this.getLocalCompset(compsetId);
        if (compset) {
            compset.name = updatedName;
        }
    }

    setUpdatedCompsetType(compsetId: string, updatedType: COMPSET_TYPE) {
        const compset = this.getLocalCompset(compsetId);
        if (compset) {
            compset.type = updatedType;
        }
    }

    validateUpdatedCompsets() {
        const { localCompsets, settingsActiveCompsetId } = this.storeState;

        if (!localCompsets || !settingsActiveCompsetId) {
            return [];
        }

        const activeCompset = localCompsets.find(compset => compset.id === settingsActiveCompsetId);

        const validateErrors = [] as Error[];

        if (!activeCompset) {
            validateErrors.push(new Error('Please select compset.'));
        } else {
            if (!activeCompset.name) {
                validateErrors.push(new Error('Please enter compset name.'));
            }
            if (!activeCompset.competitors.length) {
                validateErrors.push(new Error('Please select one hotel to comparison.'));
            }
            if (activeCompset.competitors.length > 10) {
                validateErrors.push(new Error('Please select less then 10 hotels per one compset.'));
            }
        }

        const totalUniqCompetitors = Array.from(new Set(localCompsets.reduce((compsetitors: number[], compset) => (
            compsetitors.concat(compset.competitors)
        ), [])));

        if (totalUniqCompetitors.length > 15) {
            validateErrors.push(new Error('Please select less then 15 unique hotels for all compsets.'));
        }

        return validateErrors;
    }

    async updateCompset() {
        const errors = this.validateUpdatedCompsets();

        const { localCompsets, settingsActiveCompsetId } = this.storeState;
        if (!localCompsets || !localCompsets.length || !settingsActiveCompsetId) {
            return errors;
        }

        const activeCompset = localCompsets.find(compset => compset.id === settingsActiveCompsetId);
        if (!activeCompset) {
            return errors;
        }

        if (!errors.length && activeCompset) {
            const updatedCompsets = await this.compsetsApiService.updateCompsets([activeCompset]);
            if (updatedCompsets) {
                this.storeState.loading.reset();
                this.resetUpdatedCompsets();
            }
        }

        return errors;
    }

    async updateCompsetLos(los: number[]) {
        const { currentCompset } = this;
        if (!currentCompset) {
            return;
        }

        const updatedCompset = await this.compsetsApiService.updateCompsetLos({ ...currentCompset, los });
        const compsets = this.storeState.compsets as CompsetModel[];
        if (updatedCompset) {
            const index = compsets.findIndex(item => item.id === updatedCompset[0].id);
            Vue.set(compsets, index, updatedCompset[0]);
            this.resetUpdatedCompsets();
        }
    }

    resetUpdatedCompsets() {
        const { compsets } = this.storeState;

        if (!compsets) {
            this.storeState.localCompsets = null;
        } else {
            this.storeState.localCompsets = JSON.parse(JSON.stringify(compsets));
        }
    }

    maxThreshold(compsetId?: string) : number | null {
        const compset = !compsetId ? this.currentCompset : this.getCompset(compsetId);
        return compset ? compset.maxThreshold : null;
    }

    minThreshold(compsetId?: string) : number | null {
        const compset = !compsetId ? this.currentCompset : this.getCompset(compsetId);
        return compset ? compset.minThreshold : null;
    }

    thresholdRange(compsetId?: string): { from: Percent[], to: Percent[] } {
        const range: { from: Percent[], to: Percent[] } = { from: [], to: [] };

        const nextValue = (value: number) => Number((value + THRESHOLD.STEP).toFixed(2));

        for (let value = THRESHOLD.MIN_RANGE; value <= THRESHOLD.MAX_RANGE; value = nextValue(value)) {
            range.from.unshift(value);
            range.to.unshift(value);
        }

        const minCompsetThreshold = this.minThreshold(compsetId);
        if (minCompsetThreshold !== null) {
            range.from.unshift(minCompsetThreshold);
        }

        const maxCompsetThreshold = this.maxThreshold(compsetId);
        if (maxCompsetThreshold !== null) {
            range.to.unshift(maxCompsetThreshold);
        }

        range.from = Array.from(new Set(range.from)).sort((a, b) => b - a);
        range.to = Array.from(new Set(range.to)).sort((a, b) => b - a);

        return range;
    }

    async updateThresholds(minThreshold: Percent, maxThreshold: Percent) {
        if (!this.currentCompset) {
            return;
        }

        await this.compsetsApiService.updateThresholds(this.currentCompset.id, minThreshold, maxThreshold);

        const { currentCompset } = this;
        currentCompset.minThreshold = minThreshold;
        currentCompset.maxThreshold = maxThreshold;
    }
}
