import { container } from 'inversify-props';
import HotelGuard, { HotelGuardS } from '@/router/guards/hotel.guard';
import use from '../use';

export default function CompsetSettings(prefix: string) {
    return {
        path: 'compset-settings',
        name: `${prefix}.compset`,
        component: () => import('@/modules/compsets/pages/compset-settings.modal.page.vue'),
        beforeEnter: use([container.get<HotelGuard>(HotelGuardS)]),
        children: [{
            path: 'confirm',
            name: `${prefix}.compset.confirm`,
            component: () => import('@/modules/compsets/components/confirm-close.popup.vue'),
        }, {
            path: 'distance-warning',
            name: `${prefix}.compset.warning`,
            component: () => import('@/modules/compsets/components/warning-distance.popup.vue'),
        }],
    };
}
