import arraysAreIdentical from '@/modules/common/filters/arrays-are-identical';
import { Inject, injectable } from 'inversify-props';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import Day from '@/modules/common/types/day.type';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import CompsetModel from '@/modules/compsets/models/compset.model';
import MarketsCompsetMainModel from '@/modules/cluster/models/markets-compset-main.model';
import ClusterApiService, { ClusterApiServiceS } from '@/modules/cluster/cluster-api.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import MarketsCommonService, { MarketsCommonServiceS } from '@/modules/common/modules/markets/markets-common.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import ClusterStore from './store/cluster.store';
import ProvidersService, { ProvidersServiceS } from '../providers/providers.service';
import CustomNotificationService, { CustomNotificationServiceS } from '../common/modules/custom-notification/custom-notification.service';

export const ClusterMarketsServiceS = Symbol.for('ClusterMarketsServiceS');
@injectable(ClusterMarketsServiceS as unknown as string)
export default class ClusterMarketsService implements Stateable {
    @Inject(ClusterApiServiceS)
    private clusterApiService!: ClusterApiService;

    @Inject(StoreFacadeS)
    public storeFacade!: StoreFacade;

    @Inject(HelperServiceS)
    private helperService!: HelperService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(ProvidersServiceS)
    private providerService!: ProvidersService;

    @Inject(ClusterServiceS)
    private clusterService!: ClusterService;

    @Inject(MarketsCommonServiceS)
    private marketsCommonService!: MarketsCommonService;

    @Inject(CustomNotificationServiceS)
    private customNotificationService!: CustomNotificationService;

    readonly storeState: ClusterStore = this.storeFacade.getState('ClusterStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.clusterService.storeState.provider,
            this.documentFiltersService.storeState.settings.month,
            this.documentFiltersService.storeState.settings.year,
            this.storeState.marketsSorting,
        ], this.resetLoading.bind(this));

        this.storeFacade.watch(
            () => this.providerService.storeState.providerFilter,
            (n: string[], o: string[]) => {
                if (!arraysAreIdentical(n, o)) {
                    this.resetLoading();
                }
            },
        );
    }

    get isLoading() {
        return this.storeState.marketsLoading.isLoading();
    }

    get clusterMarketsHotels() {
        this.helperService.dynamicLoading(this.storeState.marketsLoading, this.clusterService.loadData.bind(this.clusterService, 'markets'));
        return this.storeState.clusterHotels;
    }

    toggleScoreSort() {
        this.storeState.skip = 0;
        if (this.storeState.marketsSorting === 1) {
            this.storeState.marketsSorting = -1;
        } else {
            this.storeState.marketsSorting = 1;
        }
    }

    get providers() {
        const providers = new Set<string>();

        if (!this.clusterMarketsHotels || !this.storeState.marketsLoading.finishDate) {
            return Array.from(providers) as string[];
        }

        this.clusterMarketsHotels.forEach(hotel => {
            hotel.compsets.forEach(compset => {
                compset.marketProviders.forEach(provider => providers.add(provider));
            });
        });

        return Array.from(providers) as string[];
    }

    resetLoading() {
        this.storeState.marketsLoading.reset();
    }

    isNA(day: Day, compsetId: string, hotelId: number) {
        const marketsDocument = this.clusterService.getMainCompsetData(hotelId) as MarketsCompsetMainModel;

        if (!marketsDocument) {
            return false;
        }

        return this.marketsCommonService.isNA(day, hotelId, marketsDocument);
    }

    getAssessments(day: Day, hotelId: number, compset: CompsetModel) {
        if (!this.clusterService.currentProvider) {
            return null;
        }

        const document = this.clusterService.getMainCompsetData(hotelId) as MarketsCompsetMainModel;

        if (!document) {
            return null;
        }

        const marketAssessments = this.marketsCommonService.getCardAssessment(day, hotelId, document);

        if (marketAssessments === null) {
            const isNoData = this.marketsCommonService.isNoData(day, document);
            const isOutOfRange = this.marketsCommonService.isOutOfRange(document);

            if (isNoData || isOutOfRange) {
                return ASSESSMENT_TYPES.NO_DATA;
            }

            return ASSESSMENT_TYPES.SOLD_OUT;
        }
        return marketAssessments;
    }

    dayUpdateDate(day: Day, compsetId: string, hotelId: number) {
        const marketsDocument = this.clusterService.getMainCompsetData(hotelId) as MarketsCompsetMainModel;

        if (!marketsDocument) {
            return false;
        }

        return marketsDocument.updateDates[day];
    }

    getPosition(day: Day, hotelId: number, document: MarketsCompsetMainModel) {
        return this.marketsCommonService.getPosition(day, hotelId, document);
    }

    getPage(day: Day, hotelId: number, document: MarketsCompsetMainModel) {
        return this.marketsCommonService.getPage(day, hotelId, document);
    }

    async downloadExcel() {
        // TODO add notifications
        const { provider } = this.storeState;

        if (provider === null) {
            return;
        }

        const { month, year, days } = this.documentFiltersService;

        // Make ISO string without timezone differences
        const tzoffset = new Date().getTimezoneOffset() * 60000;
        const startDate = new Date(new Date(year, month, 1).getTime() - tzoffset).toISOString().slice(0, -1);
        const endDate = new Date(new Date(year, month, days.length).getTime() - tzoffset).toISOString().slice(0, -1);

        const excelData = await this.clusterApiService.getMarketsExcelDocument(provider, startDate, endDate);

        if (excelData) {
            await this.customNotificationService
                .handleExcel(excelData);
        }
    }
}
