import { Inject, injectable } from 'inversify-props';
import CarsCompsetsAlertStore from './store/cars.compsets-alert.store';
import CarsBaseAlertConfigs from './models/cars-base-alert-configs.model';
import CarsCompsetsAlertApiService, { CarsCompsetsAlertApiServiceS } from './car.compsets-alert-api.service';
import Stateable from '../common/interfaces/stateable.interface';
import UserService, { UserServiceS } from '../user/user.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import { ICarAlertConfig } from './modules/settings/interfaces/alert-interfaces';

export const CarsCompsetsAlertServiceS = Symbol.for('CarsCompsetsAlertServiceS');

@injectable(CarsCompsetsAlertServiceS as unknown as string)
export default class CarsCompsetsAlertService implements Stateable {
    @Inject(CarsCompsetsAlertApiServiceS) protected carsCompsetsAlertApiService!: CarsCompsetsAlertApiService;
    @Inject(UserServiceS) protected userService!: UserService;
    @Inject(CarsCompsetsAlertApiServiceS) protected carsAlertApiService!: CarsCompsetsAlertApiService;
    @Inject(StoreFacadeS) protected storeFacade!: StoreFacade;
    @Inject(HelperServiceS) protected helperService!: HelperService;

    readonly storeState: CarsCompsetsAlertStore = this.storeFacade.getState('CarsCompsetsAlertStore');

    resetLoadings() {
        this.storeState.loading.reset();
    }

    async loadData() {
        if (!this.userService.isCarUser) {
            return false;
        }
        const grouped = await this.carsAlertApiService.getAlertsConfiguration();

        this.storeState.document = grouped as CarsBaseAlertConfigs[];

        return true;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.document;
    }

    get loading() {
        return this.storeState.loading;
    }

    async saveAlertConfiguration(configuration: ICarAlertConfig) {
        return this.carsAlertApiService.saveAlertConfiguration(configuration);
    }

    async updateAlertConfiguration(configuration: ICarAlertConfig, id: string) {
        return this.carsAlertApiService.updateAlertConfiguration(configuration, id);
    }

    async updateAlertConfigurationStatus(configId: string, status: boolean) {
        return this.carsAlertApiService.updateAlertConfigurationStatus(configId, status);
    }

    async deleteAlertConfiguration(id: string) {
        return this.carsAlertApiService.deleteAlertConfiguration(id);
    }
}
