import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import ProvidersModel from '@/modules/providers/models/providers.model';

@Module
export default class ProvidersStore extends VuexModule {
    loading: LoadingModel = new LoadingModel();
    providers: ProvidersModel[] | null = null;
    loadingFilter: LoadingModel = new LoadingModel();
    providerFilter: string[] = [];
}
