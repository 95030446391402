import { injectable } from 'inversify-props';
import RankingDocument from '@/modules/ranking/models/ranking-document.model';

export const RankingCommonServiceS = Symbol.for('RankingCommonServiceS');
@injectable(RankingCommonServiceS as unknown as string)
export default class RankingCommonService {
    providers(document: RankingDocument | null) {
        if (!document) {
            return null;
        }

        return Object.keys(document.providers);
    }

    hotelIds(document: RankingDocument | null, provider: string) {
        const rankingHotels = this.hotels(document, provider);
        if (!rankingHotels) {
            return null;
        }

        return Object.keys(rankingHotels).map(key => Number(key));
    }

    hotels(document: RankingDocument | null, provider: string) {
        if (!document || !document.providers[provider]) {
            return null;
        }

        return document.providers[provider];
    }

    currentHotelRank(document: RankingDocument | null, provider: string, hotelId: number | null) {
        if (hotelId === null) {
            return null;
        }

        const hotels = this.hotels(document, provider);

        if (!hotels || !hotels[hotelId]) {
            return null;
        }

        return hotels[hotelId];
    }

    competitiveHotels(document: RankingDocument | null, provider: string, hotelId: number | null) {
        if (hotelId === null) {
            return null;
        }

        const hotels = this.hotels(document, provider);

        if (!hotels) {
            return null;
        }

        const rankingHotels = { ...hotels };

        if (rankingHotels[hotelId]) {
            delete rankingHotels[hotelId];
        }

        if (!Object.keys(rankingHotels).length) {
            return null;
        }

        return rankingHotels;
    }

    minMaxCompetitiveRating(document: RankingDocument | null, provider: string, hotelId: number | null): [number, number] | null {
        if (hotelId === null) {
            return null;
        }

        const competitiveHotels = this.competitiveHotels(document, provider, hotelId);

        if (!competitiveHotels) {
            return null;
        }

        const competitiveRatings = Object.entries(competitiveHotels).map(([, hotel]) => hotel.rating);
        return [Math.min(...competitiveRatings), Math.max(...competitiveRatings)];
    }

    minMaxCompetitiveReview(document: RankingDocument | null, provider: string, hotelId: number | null): [number, number] | null {
        if (hotelId === null) {
            return null;
        }

        const competitiveHotels = this.competitiveHotels(document, provider, hotelId);

        if (!competitiveHotels) {
            return null;
        }

        const competitiveReviews = Object.entries(competitiveHotels).map(([, hotel]) => hotel.reviews);
        return [Math.min(...competitiveReviews), Math.max(...competitiveReviews)];
    }
}
