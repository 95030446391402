import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import CarAlertModel from '@/modules/cars/alerts/models/car.alert.model';

export const CarsAlertsApiServiceS = Symbol.for('CarsAlertsApiServiceS');
@injectable(CarsAlertsApiServiceS as unknown as string)
export default class CarsAlertsApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    private url = 'cr-insight';

    async getCarAlerts(): Promise<CarAlertModel[] | null> {
        const res = await this.apiService.get(`${this.url}/get-alert-message-by-user`);

        if (!res || !res.data || !res.data) {
            return null;
        }

        const alertsDocument = plainToClass(CarAlertModel, <CarAlertModel[]> res.data, { excludeExtraneousValues: true });

        return alertsDocument;
    }

    async markAlertAsRead(id: string, status: number = 1) {
        const { data } = await this.apiService.post(`${this.url}/mark-as-read`, { ids: [String(id)], status });

        return data;
    }

    async markAlertsAsRead(ids: string[]) {
        const { data } = await this.apiService.post(`${this.url}/mark-as-read`, { ids: ids.map(String) });

        return data;
    }

    async markAllUserAlertsAsRea() {
        const { data } = await this.apiService.post(`${this.url}/mark-all-as-read`);

        return data;
    }

    async deleteAlertById(id: string) {
        const { data } = await this.apiService.post(`${this.url}/hide-alert/${id}`);
        return data;
    }

    async deleteAlertByIds(ids: string[]) {
        const { data } = await this.apiService.post(`${this.url}/hide-alerts`, { ids });
        return data;
    }

    async deleteAllAlerts() {
        const { data } = await this.apiService.post(`${this.url}/hide-all-alerts`);
        return data;
    }
}
