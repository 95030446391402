import { Expose, Transform } from 'class-transformer';

export default class MarketsTriggerScanModel {
    @Expose()
    status!: String;

    @Expose()
    @Transform((_, plain) => plain.documentIds[0])
    documentId!: number;
}
