enum ChainGroup {
    COUNTRY = 'country',
    CLUSTER = 'cluster',
    REGION = 'region',
}

export const groupNames = {
    cluster: 'Cluster',
    country: 'Country',
    region: 'Region',
} as { [groupValue: string]: string };

export default ChainGroup;
