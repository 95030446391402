import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import CarAlertModel from '@/modules/cars/alerts/models/car.alert.model';
import LoadingModel from '@/modules/common/models/loading.model';

@Module
export default class CarsNotificationsStore extends VuexModule {
    alerts: CarAlertModel[] = [];
    loading: LoadingModel = new LoadingModel();
}
