/* eslint-disable camelcase */
import { Inject, injectable } from 'inversify-props';
import { union } from 'lodash';
import { Params } from '@/modules/common/services/api.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import CompsetModel from '@/modules/compsets/models/compset.model';
import ClusterHotelsMarketsModel from '@/modules/cluster/models/cluster-markets.model';
import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import CompsetMainModel from '@/modules/cluster/models/compset-main.model';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import ClusterApiService, { ClusterApiServiceS } from '@/modules/cluster/cluster-api.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import HotelsApiService, { HotelsApiServiceS } from '@/modules/hotels/hotels-api.service';
import HotelModel from '@/modules/hotels/models/hotel.model';
import RankingChainItemModel from '@/modules/cluster/models/ranking-cluster-item.model';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import ClusterStore from './store/cluster.store';
import ProvidersService, { ProvidersServiceS } from '../providers/providers.service';
import COMPSET_TYPE from '../compsets/constants/compset-type.constant';
import UserService, { UserServiceS } from '../user/user.service';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '../room-types/room-types.service';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import ChainGroup from '../chain/interfaces/chain-group.enum';
import ChainService, { ChainServiceS } from '../chain/chain.service';

export const ClusterServiceS = Symbol.for('ClusterServiceS');
@injectable(ClusterServiceS as unknown as string)
export default class ClusterService implements Stateable {
    @Inject(ChainServiceS) private chainService!: ChainService;
    @Inject(ClusterApiServiceS) private clusterApiService!: ClusterApiService;
    @Inject(ClusterCompsetsServiceS) private clusterCompsetsService!: ClusterCompsetsService;
    @Inject(StoreFacadeS) public storeFacade!: StoreFacade;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(HotelsApiServiceS) private hotelsApiService!: HotelsApiService;
    @Inject(ProvidersServiceS) private providerService!: ProvidersService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(MealTypesServiceS) private mealTypeService!: MealTypesService;
    @Inject(RoomTypesServiceS) private roomTypeService!: RoomTypesService;
    @Inject(HelperServiceS) private helperService!: HelperService;

    readonly storeState: ClusterStore = this.storeFacade.getState('ClusterStore');

    get currentProvider(): string {
        if (!this.storeState.provider) {
            const { providerFilter } = this.providerService;
            const isHaveBooking = providerFilter.includes('booking');

            if (isHaveBooking) {
                this.saveCurrentProvider('booking');
            } else {
                this.saveCurrentProvider(providerFilter[0]);
            }
        }

        return this.storeState.provider!;
    }

    get activePage() {
        return this.storeState.activePage;
    }

    get hotels() {
        return this.storeState.clusterHotels;
    }

    set hotels(value) {
        this.storeState.clusterHotels = value;
    }

    get ratesLoading() {
        return this.storeState.ratesLoading;
    }

    get isLoadingMore() {
        return this.storeState.isLoadingMore;
    }

    get isChainPairSpecified() {
        return !!this.chainService.chainPair;
    }

    async loadClusterPosList() {
        const res = await this.clusterApiService.getClusterPos();
        this.storeState.pos.list = res;
        return true;
    }

    resetChainItems() {
        this.storeState.rankingChainItems = {} as {
            [hotelId: number]: {
                [compsetId: string]: RankingChainItemModel
            }
        };
    }

    getActualParamsForDocument(mode: 'markets' | 'rates' | 'ranking') {
        const params = {
            year: this.documentFiltersService.year,
            month: this.documentFiltersService.month + 1,
            provider_name: this.currentProvider,
            skip: this.storeState.skip,
            limit: this.storeState.limit,
        } as Params;

        switch (mode) {
            case 'rates':
                this.assignParamsForRates(params);
                break;
            case 'markets':
                params['sort[score.mv]'] = this.storeState.marketsSorting;
                break;
            case 'ranking':
                break;
            default:
                return null;
        }

        return params;
    }

    /**
     * **THIS METHOD MUTATES INPUT ARGUMENT**
     * Injects rates related parameters into specified `params` object
     */
    private assignParamsForRates(params: any) {
        const { ratesSorting, settings, hotelNameSorting } = this.storeState;
        const { los } = this.documentFiltersService.settings;
        const { priceType: price, roomTypeId } = settings;
        const { mealTypeId, numberOfGuests: number_of_guest } = settings;

        const meal_type = this.mealTypeService.getMealType(mealTypeId);
        const room_type = this.roomTypeService.getRoomType(roomTypeId);

        const filters = {
            price,
            room_type: room_type && room_type.name,
            meal_type: meal_type && meal_type.name,
        };

        if (this.chainService.chainPair) {
            this.assignChainParamsInto(params);
        }

        // NOTE: Makes query params in `format[key]` format
        const filterParams = Object.fromEntries(
            Object
                .entries(filters)
                .filter(([, v]) => !!v)
                .map(([k, v]) => [`filters[${k}]`, v]),
        );

        Object.assign(params, {
            los,
            number_of_guest,
            ...filterParams,
        });

        if (ratesSorting) {
            Object.assign(params, {
                'sort[score.rate]': ratesSorting,
            });
        }

        if (hotelNameSorting) {
            Object.assign(params, {
                'sort[hotel_name]': hotelNameSorting,
            });
        }
    }

    /**
     * **THIS METHOD MUTATES INPUT ARGUMENT**
     * Injects chain parameters into specified `params` object
     */
    private assignChainParamsInto(params: any) {
        const { chainPair } = this.chainService;
        const { city, country } = this.chainService.settings;
        const { region, brand } = this.chainService.settings;

        const chainParams = {
            region: chainPair!.group === ChainGroup.REGION
                ? chainPair!.value
                : region,
            country: chainPair!.group === ChainGroup.COUNTRY
                ? chainPair!.value
                : country,
            brand,
            city,
            grouped_by: chainPair!.group,
            grouped_by_value: chainPair!.value,
        };

        Object
            .keys(chainParams)
            .forEach(key => {
                const typedKey = key as keyof typeof chainParams;
                if (!chainParams[typedKey]) {
                    delete chainParams[typedKey];
                }
            });

        Object.assign(params, chainParams);
    }

    async loadData(mode: 'markets' | 'rates' | 'ranking') {
        const { chainPair } = this.chainService;
        this.resetPagination();

        if (!this.providerService.providerFilter.length) {
            return false;
        }
        const params = this.getActualParamsForDocument(mode);
        if (!params) return false;

        let data = null;

        switch (mode) {
            case 'rates':
                data = await this.clusterApiService.getRatesClusterData(params, chainPair);
                if (data) {
                    this.updateRatesMap(data.data);
                }
                break;
            case 'markets':
                data = await this.clusterApiService.getMarketsClusterData(params, chainPair);
                break;
            case 'ranking':
                data = await this.clusterApiService.getGuestReviewsData(params);
                break;
            default: break;
        }

        if (!data) return false;
        this.storeState.totalCount = data.total;
        this.storeState.clusterHotels = data.data;
        return true;
    }

    async loadMoreData(mode: 'markets' | 'rates' | 'ranking') {
        const { chainPair } = this.chainService;

        if (!this.providerService.providerFilter.length) {
            return;
        }

        if (!this.storeState.clusterHotels || this.storeState.isLoadingMore) return;
        if (this.storeState.skip >= this.storeState.totalCount!) return;
        this.storeState.skip = ++this.storeState.skip;
        this.storeState.isLoadingMore = true;

        const params = this.getActualParamsForDocument(mode);
        if (!params) return;
        let data = null;

        switch (mode) {
            case 'rates':
                data = await this.clusterApiService.getRatesClusterData(params, chainPair);

                if (data) {
                    this.updateRatesMap(data.data);
                }
                break;
            case 'markets':
                data = await this.clusterApiService.getMarketsClusterData(params, chainPair);
                break;
            case 'ranking':
                data = await this.clusterApiService.getGuestReviewsData(params);
                break;
            default: break;
        }

        this.storeState.isLoadingMore = false;
        if (!data) return;
        const moreDocuments = union(this.storeState.clusterHotels, data.data);
        this.storeState.clusterHotels = moreDocuments;
    }

    resetPagination() {
        this.storeState.totalCount = null;
        this.storeState.skip = 0;
    }

    async getClusterHotels(hotelId: number) {
        const competitorsIds = this.getCompetitors(hotelId);
        this.storeState.hotels = competitorsIds;
        if (competitorsIds) {
            const data = await this.hotelsApiService.getHotelsById(competitorsIds);
            this.storeState.hotels = data ? data.map((item: HotelModel) => item.name) : [];
        }
    }

    getCompsetName(compsetId: string, hotelId: number) {
        if (!this.hotels) {
            return null;
        }
        const cluster = this.hotels.find(el => el.hotelId === hotelId);
        if (!cluster) {
            return null;
        }
        const compset = cluster.compsets.find(el => el.id === compsetId);
        if (!compset) {
            return null;
        }
        return { name: compset.name, type: compset.type };
    }

    getMainCompsetData(hotelId: number) {
        if (!this.hotels) {
            return null;
        }
        const hotel = this.hotels.find(el => el.hotelId === hotelId);
        if (hotel && hotel.compsetMain) {
            return hotel.compsetMain;
        }
        return null;
    }

    getMainCompset(hotelData: ClusterHotelsRatesModel | ClusterHotelsMarketsModel): CompsetModel {
        const { id: mainId } = hotelData.compsetMain as CompsetMainModel || { id: '' };

        if (mainId) {
            return hotelData.compsets.find(c => c.id === mainId)!;
        }

        return hotelData.compsets.find(c => c.type === COMPSET_TYPE.MEDIAN)
            || hotelData.compsets[0];
    }

    getCompetitors(hotelId: number) {
        const mainCompsetData = this.getMainCompsetData(hotelId);
        return mainCompsetData ? this.clusterCompsetsService.getCompetitors(mainCompsetData.id) : null;
    }

    saveCurrentProvider(provider: string | null) {
        this.storeState.provider = provider;
        this.storeState.settings.provider = provider;
    }

    switchCurrentHotel(hotelId: number) {
        this.storeState.currentHotelId = hotelId;
    }

    getHotelBy({ compsetId, hotelId }: { compsetId?: string, hotelId?: number }) {
        if ((!compsetId && !hotelId) || !this.hotels) {
            return null;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const hotelChain of this.hotels) {
            if (compsetId) {
                const needCompset = hotelChain.compsets.find(item => item.id === compsetId);

                if (needCompset) {
                    return hotelChain;
                }
            } else if (hotelChain.hotelId === hotelId) {
                return hotelChain;
            }
        }

        return null;
    }

    getHotelIdByCompset(compsetId: string) {
        const { isClusterUser, currentHotelId } = this.userService;

        if (!isClusterUser) {
            return currentHotelId;
        }

        const hotelChain = this.getHotelBy({ compsetId });
        return hotelChain && hotelChain.hotelId;
    }

    updateRatesMap(data: ClusterHotelsRatesModel[]) {
        data.forEach(hotel => {
            const newHotelMap = {
                ...this.storeState.ratesHotelMap,
                [hotel.hotelId]: hotel,
            };

            this.storeState.ratesHotelMap = newHotelMap;
            this.storeState.hotelNamesMap[hotel.hotelId] = hotel.hotelName;

            Object.assign(this.storeState.hotelNamesMap, hotel.hotelNames);
        });
    }

    get poses(): string[] {
        this.helperService.dynamicLoading(this.storeState.pos.loading, this.loadClusterPosList.bind(this));
        return this.storeState.pos.list;
    }
}
