import { Inject, injectable } from 'inversify-props';
import { Route } from 'vue-router';
import { IMiddleware } from '@/router/interfaces/middleware.interface';
import MarketsFiltersService, { MarketsFiltersServiceS } from '@/modules/markets/markets-filters.service';

export const MarketsFiltersMiddlewareS = Symbol.for('MarketsFiltersMiddlewareS');
@injectable(MarketsFiltersMiddlewareS as unknown as string)
export default class MarketsFiltersMiddleware implements IMiddleware {
    @Inject(MarketsFiltersServiceS) private marketsFiltersService!: MarketsFiltersService;

    async canActivate(to: Route, from: Route) {
        if (to.query && to.query.provider) {
            await this.marketsFiltersService.saveProvider(to.query.provider as string);
        }
        return true;
    }
}
