import { Inject, injectable } from 'inversify-props';
import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';

export const WhatfixServiceS = Symbol.for('WhatfixServiceS');
@injectable(WhatfixServiceS as unknown as string)
export default class WhatfixService {
    @Inject(ConfigServiceS) private configService!: ConfigService;

    private isInited: boolean = false;

    init() {
        if (this.isInited === true) return;

        const { whatfixUrl } = this.configService;

        const scriptElement = this.generateScript(whatfixUrl);
        if (scriptElement === null) {
            // eslint-disable-next-line no-console
            console.error('whatfixUrl or whatfixUser is missing');
            return;
        }

        this.insertScript(scriptElement);
        this.isInited = true;
    }

    generateScript(whatfixUrl: string | null) {
        if (whatfixUrl === null) {
            return null;
        }

        const whatfixScript = document.createElement('script');
        whatfixScript.async = true;
        whatfixScript.type = 'text/javascript';
        whatfixScript.src = whatfixUrl;

        return whatfixScript;
    }

    insertScript(element: HTMLScriptElement) {
        const [firstScriptElement] = document.getElementsByTagName('script');

        if (!firstScriptElement || !firstScriptElement.parentNode) {
            // eslint-disable-next-line no-console
            console.error('Failed to insert Whatfix script');
            return;
        }

        firstScriptElement.parentNode.insertBefore(element, firstScriptElement);
    }
}
