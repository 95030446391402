/* eslint-disable no-param-reassign */
import { injectable, Inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';

import ApiService, { ApiServiceS } from '../common/services/api.service';
import DocumentFiltersModel from '../document-filters/models/document-filters.model';
import PromotionsDocumentModel from './models/promotions-document.model';
import PromotionHistoryDocumentModel from './models/promotion-history-document.model';

export const PromotionsApiServiceS = Symbol.for('PromotionsApiServiceS') as unknown as string;

@injectable(PromotionsApiServiceS)
export default class PromotionsApiService {
    @Inject(ApiServiceS)
    private apiService!: ApiService;

    public async getPromotionData(settings: DocumentFiltersModel, comparison?: { key: string, value: string }) {
        const params = [
            settings.compsetId,
            settings.month + 1,
            settings.year,
        ].join('/');

        const query: { [key: string]: string | number | null } = {
            pos: settings.pos,
            los: settings.los,
        };

        if (comparison) {
            query[comparison.key] = comparison.value;
        }

        const { data } = await this.apiService.get(`promotion-detections/${params}`, query);

        return plainToClass(PromotionsDocumentModel, data, { excludeExtraneousValues: true });
    }

    public async getTrendData(documentId: number, day: number) {
        const { data } = await this.apiService
            .get(`promotion-detections/trend/${documentId}/${day}`);

        return plainToClass(PromotionHistoryDocumentModel, data, { excludeExtraneousValues: true });
    }

    public async triggerScan(params: Promotions.ScanParams) {
        const { data } = await this.apiService
            .post('scan/promotion-detection/by-compset-id', params);

        return data as Promotions.ScanResponse;
    }

    public async setOnDemandFeature(state: boolean) {
        await this.apiService
            .post('users/enable-features', {
                enableFeatures: {
                    on_demand_promotion_detection: state,
                },
            });
    }
}
