import { Expose, Transform } from 'class-transformer';

export enum IncreaseType {
    Percentage = 'percentage',
    Fixed = 'fixed',
}

export default class CarsExtraExpensesModel {
    @Expose()
    pos!: string;

    @Expose()
    @Transform((_, plain) => Number(plain.fornova_location_id))
    fornovaLocationId!: number;

    @Expose()
    @Transform((_, plain) => plain.provider_name)
    providerName!: string;

    @Expose()
    description!: string;

    @Expose()
    source!: string;

    @Expose()
    @Transform((_, plain) => plain.increase_type)
    increaseType!: IncreaseType;

    @Expose()
    value!: number;
}
