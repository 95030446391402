import { Expose, Transform, plainToClass } from 'class-transformer';
import { Validate } from 'class-validator';
import CompsetMainModel from '@/modules/cluster/models/compset-main.model';
import RankingProviderValidator from './validators/ranking-provider.validator';
import RankingDocumentItem from './ranking-document-item.model';

export default class RankingDocument extends CompsetMainModel {
    @Expose()
    @Transform((_, plain) => {
        const providers = plain;
        if (!providers) {
            return providers;
        }

        Object.keys(providers).forEach(provider => {
            Object.entries(providers[provider]).forEach(([hotelId, rankingDocumentItem]) => {
                providers[provider][hotelId] = plainToClass(RankingDocumentItem, <RankingDocumentItem> rankingDocumentItem, { excludeExtraneousValues: true });
            });
        });

        return providers;
    })
    @Validate(RankingProviderValidator)
    providers!: {
        [provider: string] : {
            [hotelId: number]: RankingDocumentItem
        }
    };
}
