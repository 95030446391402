import { Expose, Transform } from 'class-transformer';
import { IsNumber, IsOptional, IsString } from 'class-validator';

export default class HotelModel {
    @Expose()
    @Transform((_, plain) => Number(plain.fornovaId))
    @IsNumber()
    id!: number;

    @Expose()
    @Transform((_, plain) => plain.hotel_name)
    @IsString()
    name!: string;

    @Expose()
    @Transform((_, plain) => {
        if (plain.geo_location) {
            return {
                lat: plain.geo_location.latitude,
                lng: plain.geo_location.longitude,
            };
        }
        return null;
    })
    @IsOptional()
    geoLocation?: {
        lat: number,
        lng: number,
    };
}
