import { Inject, injectable } from 'inversify-props';
import { classToPlain, plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import NotifyService, { NotifyServiceS } from '@/modules/common/services/notify.service';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import { updateCarsCategoryBodyAdapter } from '@/modules/cars-category-manager/helpers';
import CarsCategoryModel from './models/cars-category-model.model';

export const CarsCategoryManagerApiServiceS = Symbol.for('CarsCategoryManagerApiServiceS');
@injectable(CarsCategoryManagerApiServiceS as unknown as string)
export default class CarsCategoryManagerApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(NotifyServiceS) private notifyService!: NotifyService;
    @Inject(ValidatorServiceS) private validatorService!: ValidatorService;

    async getCarsCategory(): Promise<CarsCategoryModel | null> {
        const response = await this.apiService.get('/car/car-class-mapping');

        if (!response) {
            return null;
        }

        const carCategoryDocument = plainToClass(CarsCategoryModel, { categories: response.data }, { excludeExtraneousValues: true });
        return carCategoryDocument;
    }

    async updateCarsCategory(carCategories: CarsCategoryModel): Promise<boolean> {
        const response = await this.apiService.post(
            '/car/car-class-mapping',
            updateCarsCategoryBodyAdapter(carCategories.categories),
        );

        return !!response;
    }

    async deleteCategory(carCategories: CarsCategoryModel): Promise<boolean> {
        const response = await this.apiService.delete('/car/car-class-mapping', { data: carCategories.categories });

        if (!response) {
            return false;
        }

        return response.data.status === 'deleted';
    }
}
