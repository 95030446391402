import { Inject, injectable } from 'inversify-props';
import UserModel from '@/modules/user/models/user.model';
import UserApiService, { UserApiServiceS } from '@/modules/user/user-api.service';
import HeadService, { HeadServiceS } from '@/modules/common/services/head.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import WalkmeService, { WalkmeServiceS } from '@/modules/walkme/walkme.service';
import _ from 'lodash';
import UserStore from './store/user.store';
import USER_LEVELS from './constants/user-levels.constant';
import USER_ROLES from './constants/user-roles.constant';
import UserSettings from './store/user-settings.store';
import WhatfixService, { WhatfixServiceS } from '../whatfix/whatfix.service';

export const UserServiceS = Symbol.for('UserServiceS');
@injectable(UserServiceS as unknown as string)
export default class UserService {
    @Inject(UserApiServiceS)
    private userApiService!: UserApiService;

    @Inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @Inject(HeadServiceS)
    private headService!: HeadService;

    @Inject(WalkmeServiceS)
    private walkmeService!: WalkmeService;

    @Inject(WhatfixServiceS)
    private whatfixService!: WhatfixService;

    readonly storeState: UserStore = this.storeFacade.getState('UserStore');
    readonly settingsStoreState: UserSettings = this.storeFacade.getState('UserSettings');

    async initUser(token: string | null) {
        this.storeState.loading.start();

        if (token === null) {
            return;
        }

        this.storeState.user = await this.userApiService.getUser(token);

        if (this.user === null) {
            return;
        }

        this.headService.setFavicon(this.storeState.user.level);
        this.headService.setTitle(this.storeState.user.level);
        this.storeState.loading.finish();

        if (this.isWalkmeEnabled === true) {
            this.walkmeService.init();
        }

        this.whatfixService.init();
    }

    get user(): UserModel | null {
        return this.storeState.user;
    }

    get isNewUser() {
        return this.user
            && this.user.level === USER_LEVELS.ONBOARDING;
    }

    get currentHotelId(): number | null {
        if (!this.user || !this.user.currentHotelId) {
            return null;
        }
        return this.user.currentHotelId;
    }

    set currentHotelId(value: number | null) {
        if (!this.user || !value) {
            return;
        }
        this.user.currentHotelId = value;
    }

    get enabledFeatures() {
        const isDebug = window.location.search.includes('debug=true')
            && process.env.NODE_ENV === 'development';

        if (isDebug) {
            // NOTE: can be used for testing
            return {
                home: true,
                rate: true,
                market: true,
                guest_review: true,
                events: true,
                deep_compset: true,
                lite_di: true,
                promotion_detection: true,
            } as { [k: string]: boolean };
        }

        if (!this.user || !this.user.enabledFeatures) {
            return null;
        }

        return this.user.enabledFeatures;
    }

    get viewAs(): USER_LEVELS | null {
        if (!this.user) {
            return null;
        }

        return this.user.viewAs;
    }

    get isWalkmeEnabled() {
        if (this.user === null) {
            return false;
        }

        return this.user.isWalkmeEnabled;
    }

    get isLoadingAndInitialized() {
        return this.storeState.loading.isLoading() && this.storeState.loading.isInitialized;
    }

    setViewAs(level: USER_LEVELS, id?: number) {
        if (!this.storeState.user) {
            return;
        }

        switch (level) {
            case USER_LEVELS.HOTEL:
                if (!id) {
                    throw new Error('No hotelId!');
                }

                this.storeState.user = {
                    ...this.storeState.user,
                    viewAs: USER_LEVELS.HOTEL,
                    currentHotelId: id,
                };
                break;

            case USER_LEVELS.CHAIN:
                this.storeState.user = {
                    ...this.storeState.user,
                    viewAs: USER_LEVELS.CHAIN,
                };
                break;
            case USER_LEVELS.CLUSTER:
                this.storeState.user = {
                    ...this.storeState.user,
                    viewAs: USER_LEVELS.CLUSTER,
                };
                break;

            default:
                throw new Error('Wrong user level!');
        }
    }

    get currentCompany(): string | null {
        if (!this.user) {
            return null;
        }
        return this.user.chainName;
    }

    set currentCompany(value: string | null) {
        if (this.user && value) {
            this.user.chainName = value;
        }
    }

    get chainName(): string | null {
        if (!this.user) {
            return null;
        }
        return this.user.chainName;
    }

    get chainId(): string | null {
        if (!this.user) {
            return null;
        }
        return this.user.chainId;
    }

    get id(): string | null {
        if (!this.user) {
            return null;
        }
        return this.user.id;
    }

    get chainNumber(): string | null {
        if (!this.user) {
            return null;
        }
        return this.user.chainNumber || null;
    }

    get levelName() {
        if (!this.user) {
            return null;
        }
        return this.user.levelName;
    }

    get isHotelUser() {
        if (this.isCarUser) {
            return false;
        }

        return this.user
            && [
                USER_LEVELS.HOTEL,
                USER_LEVELS.CLUSTER,
                USER_LEVELS.CHAIN,
                USER_LEVELS.ONBOARDING,
            ].includes(this.user.level);
    }

    get isClusterUser() {
        return this.user && this.user.level === USER_LEVELS.CLUSTER;
    }

    get isChainUser() {
        return this.user && this.user.level === USER_LEVELS.CHAIN;
    }

    get isChainOrClusterUser() {
        return this.user && (this.user.level === USER_LEVELS.CHAIN || this.user.level === USER_LEVELS.CLUSTER);
    }

    get isCarUser() {
        return this.user && this.user.level === USER_LEVELS.CAR;
    }

    get isDemoUser() {
        return /(dev)|(demo)|(localhost)/.test(window.location.host);
    }

    get isViewAsHotel() {
        return this.user && this.user.viewAs === USER_LEVELS.HOTEL;
    }

    get isViewAsChain() {
        return this.user && (this.user.viewAs === USER_LEVELS.CHAIN);
    }

    get isViewAsCluster() {
        return this.user && (this.user.viewAs === USER_LEVELS.CLUSTER);
    }

    get isAdmin() {
        return (this.user && this.user.role === USER_ROLES.ADMIN) || false;
    }

    get isSuperadmin() {
        return !!this.user && this.user.isSuperadmin;
    }

    get urlChainClusterParam() {
        if (this.isClusterUser) {
            return 'cluster';
        }

        return 'chain';
    }

    get userLevel(): 'chain' | 'cluster' | 'hotel' {
        if (this.isChainUser) {
            return 'chain';
        }

        if (this.isClusterUser) {
            return 'cluster';
        }

        return 'hotel';
    }

    get hasDI() {
        return !!this.user && this.user.applications.DI;
    }

    get isDemo() {
        return this.user ? !!this.user.demoId : false;
    }

    get isReadonly() {
        return _.get(this.user, 'isReadonly', false);
    }

    get carCategoryManagement() {
        return _.get(this.user, 'carCategoryManagement', false);
    }

    get generalSettings() {
        return _.get(this.user, 'generalSettings', false);
    }

    get sippMapManagement() {
        return _.get(this.user, 'sippMapManagement', false);
    }

    get userManagement() {
        return _.get(this.user, 'userManagement', false);
    }

    set currentUserHotelInited(value: boolean) {
        this.storeState.currentUserHotelInited = value;
    }

    async switchCurrentHotel(hotelId: number) {
        if (!this.user || (!this.isChainUser && !this.isClusterUser)) {
            this.storeState.currentUserHotelInited = true;
            return;
        }

        await this.userApiService.updateCurrentHotel(hotelId);

        this.storeState.currentUserHotelInited = true;

        this.storeState.user = {
            ...this.user,
            // hotelIds: [hotelId],
            currentHotelId: hotelId,
        };
    }

    // Only in case to avoid circle dependency when can't import UserSettingsService
    get chartColors() {
        return this.settingsStoreState.chartColors.list;
    }
}
