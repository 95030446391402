import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import HotelCatalogItemModel from './models/hotel-catalog-item.model';
import HotelCatalogItemUrlModel from './models/hotel-catalog-item-url.model';

export const HotelCatalogApiServiceS = Symbol.for('HotelCatalogApiServiceS');
@injectable(HotelCatalogApiServiceS as unknown as string)
export default class HotelCatalogApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;

    async getHotelCatalog(query: string, limit?: number): Promise<HotelCatalogItemModel[]> {
        const defaultLimit = 10;

        const { data } = await this.apiService.post('hc/v3/hotels/search/hotel_names_for_autocomplete', {
            starting_string: query,
            max_hotel_count: limit || defaultLimit,
        });

        const hotelCatalogDocument = plainToClass(HotelCatalogItemModel, <HotelCatalogItemModel[]> data, { excludeExtraneousValues: true });
        // TODO validate

        return hotelCatalogDocument;
    }

    async getHotelCatalogByUrl(query: string, limit?: number): Promise<HotelCatalogItemUrlModel[]> {
        const defaultLimit = 1;

        const { data } = await this.apiService.post('hc/v2/injectdata', {
            rest_type: 'post',
            request_id: '5258531353158291623',
            security_token: '0987654321',
            request_name: 'injectdata',
            request_params: {
                count_of_similar_hotels: defaultLimit,
                items: [
                    {
                        providersData: [
                            {
                                hotelUrl: query,
                            },
                        ],
                    },
                ],
            },
        });

        if (!data || !data.data || !data.data.items) {
            return [] as HotelCatalogItemUrlModel[];
        }

        const hotelCatalogDocument = plainToClass(HotelCatalogItemUrlModel, <HotelCatalogItemUrlModel[]> data.data.items, { excludeExtraneousValues: true });

        // TODO validate

        return hotelCatalogDocument;
    }
}
