import { UpdateHotelsRoomTypeResponseDTO } from '@/modules/rooms-type-manager/dto/hotels-room-type.dto';
import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import HotelsRoomTypeModel from '@/modules/rooms-type-manager/models/hotels-room-type.model';
import UserService, { UserServiceS } from '../user/user.service';

export const RoomsTypeManagerApiServiceS = Symbol.for('RoomsTypeManagerApiServiceS');
@injectable(RoomsTypeManagerApiServiceS as unknown as string)
export default class RoomsTypeManagerApiService {
    @Inject(ApiServiceS)
    private apiService!: ApiService;

    @Inject(UserServiceS)
    private userService!: UserService;

    async getHotelsRoomType(): Promise<HotelsRoomTypeModel | null> {
        const response = await this.apiService.get('/users/competitors-rooms-types-mapping');

        if (!response) {
            return null;
        }

        const roomTypeDocument = plainToClass(HotelsRoomTypeModel, { providers: response.data }, { excludeExtraneousValues: true });

        // const error = await this.validatorService.validateResponse(roomTypeDocument);
        // if (error) {
        //     throw new ResponseValidationException(error);
        // }

        return roomTypeDocument;
    }

    async updateHotelsRoomType(hotelsRoomTypes: HotelsRoomTypeModel): Promise<HotelsRoomTypeModel | null> {
        const { data }: { data: UpdateHotelsRoomTypeResponseDTO } = await this.apiService.put(
            '/users/competitors-rooms-types-mapping',
            { hotels_room_types: hotelsRoomTypes.providers },
        );

        if (!data) {
            return null;
        }

        return plainToClass(HotelsRoomTypeModel, { providers: data.hotels_room_types }, { excludeExtraneousValues: true });
    }

    async getIgnoredRoomList(hotelId: number) {
        const { data } = await this.apiService
            .get(`/users/ignored_rooms_names/${hotelId}`);

        if (!data) {
            return null;
        }

        return data.ignored_rooms_names;
    }

    async updateIgnoreList(hotelId: number, newList: string[]) {
        const { data } = await this.apiService.put('/users/ignored_rooms_names/', {
            ignored_rooms_names: newList,
            fornova_id: hotelId,
        });

        if (!data) return [];

        return data as string[];
    }
}
