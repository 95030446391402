import { Inject, injectable } from 'inversify-props';

import { i18n } from '@/languages';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';

import RatesStore from '@/modules/rates/store/rates.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';

import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '../room-types/room-types.service';
import RatesFiltersService, { RatesFiltersServiceS } from './rates-filters.service';
import DEFAULT_LOS from '../document-filters/constants/default-los.constant';
import DocumentFiltersModel from '../document-filters/models/document-filters.model';

type Item = { value: string | number, name: string };

export const RatesAnalysisFiltersServiceS = Symbol.for('RatesAnalysisFiltersServiceS');
@injectable(RatesAnalysisFiltersServiceS as unknown as string)
export default class RatesAnalysisFiltersService implements Stateable {
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @Inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @Inject(RatesFiltersServiceS) private ratesFilterService!: RatesFiltersService;

    readonly storeState: RatesStore = this.storeFacade.getState('RatesStore');

    comparisonDays: Item[] = [
        { value: 1, name: 'Yesterday' },
        { value: 3, name: '3 Days' },
        { value: 7, name: '7 Days' },
        { value: 14, name: '14 Days' },
    ];

    comparisonFieldsDictionary = new Map([
        ['diffDays', 'Past Period'],
        ['provider', 'Source'],
        ['mealTypeId', 'Meal Type'],
        ['roomTypeId', 'Room Type'],
        ['los', 'LOS'],
        ['pos', 'POS'],
        ['numberOfGuests', 'Number of Guests'],
        ['priceType', 'Price'],
    ]);

    constructor() {
        this.storeFacade.watch(() => [
            this.documentFiltersService.storeState.settings.compsetId,
        ], this.resetComparisonFilters.bind(this));

        this.storeFacade.watch(() => [
            this.documentFiltersService.storeState.settings.pos,
            this.documentFiltersService.storeState.settings.los,

            this.ratesFilterService.storeState.settings.provider,
            this.ratesFilterService.storeState.settings.priceType,
            this.ratesFilterService.storeState.settings.roomTypeId,
            this.ratesFilterService.storeState.settings.mealTypeId,
            this.ratesFilterService.storeState.settings.numberOfGuests,
        ], this.reorderValueList.bind(this));

        this.storeFacade.watch(() => [
            this.storeState.analysis.settings.comparisonFilter.key,
        ], this.selectComparisonKey.bind(this));
    }

    onComparisonValuesChange(callback: (values: { value: (string | number), name: string }[])=> Promise<any>) {
        return this.storeFacade.watch(() => [
            this.storeState.analysis.settings.comparisonFilter.values,

        ], (n, o) => callback(this.storeState.analysis.settings.comparisonFilter.values));
    }

    get mainFilterLabel() {
        const { settings } = this.ratesFilterService;
        const { comparisonKey } = this;

        if (comparisonKey === 'diffDays') {
            return 'Today';
        }

        const value = settings[comparisonKey as keyof typeof settings] as string | number;

        return this.getComparisonValueLabel(value);
    }

    get providerItems(): Item[] {
        const { currentCompset } = this.compsetsService;

        if (!currentCompset) {
            return [];
        }

        const exceptSelectedProvider = ({ value }: { value: string; }) => value !== this.ratesFilterService.currentProvider;

        return this.providersService
            .toItemsList(currentCompset.rateProviders)
            .filter(exceptSelectedProvider);
    }

    get filterComparisonName() {
        return this.comparisonFieldsDictionary.get(this.comparisonKey);
    }

    get comparisonKey() {
        return this.storeState.analysis.settings.comparisonFilter.key;
    }

    set comparisonKey(value: string) {
        this.storeState.analysis.settings.comparisonFilter.key = value;
        this.comparisonValues = [];
    }

    get comparisonValues() {
        return this.storeState.analysis.settings.comparisonFilter.values;
    }

    set comparisonValues(values: { value: string | number, name: string }[]) {
        this.storeState.analysis.settings.comparisonFilter.values = values;
    }

    get comparisonDayItems(): Item[] {
        return this.comparisonDays;
    }

    get filterList(): Item[] {
        return Array
            .from(this.comparisonFieldsDictionary)
            .filter(([key]) => this.filterItems[key]().length)
            .map(([value, name]) => ({ value, name }));
    }

    get filterItems(): {[k: string]: (wholeList?: boolean) => Item[]} {
        const { settings: ratesSettings } = this.ratesFilterService.storeState;
        const { settings: docSettings } = this.documentFiltersService.storeState;
        const { currentCompset } = this.compsetsService;

        const providers = Array.from(
            new Set(currentCompset ? currentCompset.rateProviders : []),
        );

        const itemLists: { [k: string]: () => Item[] } = {
            diffDays: () => this.comparisonDayItems,
            provider: (wholeList = false) => (wholeList
                ? this.providersService.toItemsList(providers)
                : this.providerItems),

            mealTypeId: (wholeList = false) => this.mealTypesService.mealTypes
                .filter(m => wholeList || (m.id !== -1 && m.id !== ratesSettings.mealTypeId))
                .map(m => ({ value: m.id, name: m.displayName })),

            roomTypeId: (wholeList = false) => this.roomTypesService.rooms
                .filter(r => wholeList || (r.id !== -1 && r.id !== ratesSettings.roomTypeId))
                .map(r => ({ value: r.id, name: r.name })),

            los: (wholeList = false) => {
                const { losItems } = this.documentFiltersService;
                const defaultLosItems = DEFAULT_LOS.map(value => ({
                    value,
                    name: `LOS${value}`,
                })) as Item[];

                const { isScanDisabledProvider } = this.ratesFilterService;
                const targetList = isScanDisabledProvider
                    ? losItems
                    : defaultLosItems;

                if (wholeList) {
                    return targetList;
                }

                return targetList
                    .filter(l => l.value !== docSettings.los);
            },

            pos: (wholeList = false) => this.documentFiltersService.posRatesItems
                .filter(item => wholeList || item.value !== docSettings.pos),

            numberOfGuests: (wholeList = false) => Array.from({ length: 10 })
                .map((_, i) => ({ value: i + 1, name: String(i + 1) }))
                .filter(nog => wholeList || nog.value !== ratesSettings.numberOfGuests),

            priceType: (wholeList = false) => Object
                .values(PRICE_TYPE)
                .filter(v => wholeList || v !== ratesSettings.priceType)
                .map(value => ({ value, name: value })),
        };

        return itemLists;
    }

    get mainCompareTitle() {
        const { comparisonKey } = this as {
            comparisonKey: keyof (DocumentFiltersModel & { diffDays: string })
        };

        switch (comparisonKey) {
            case 'diffDays':
                return 'Today';
            case 'los':
                return `LOS${this.documentFiltersService.settings.los}`;
            case 'pos':
                return this.documentFiltersService.settings.pos;
            default:
                return this.getMainComparisonFilterLabel();
        }
    }

    get currentFilterItems(): Item[] {
        return this.filterItems[this.comparisonKey]();
    }

    getMainComparisonFilterLabel() {
        const { comparisonKey } = this;
        const { settings } = this.documentFiltersService.ratesStoreState;

        switch (comparisonKey) {
            case 'provider':
                return settings.provider
                    ? this.providersService.getProviderLabel(settings.provider)
                    : '-';

            case 'mealTypeId': {
                const mealType = this.mealTypesService
                    .getMealType(settings.mealTypeId);

                return mealType
                    ? mealType.displayName
                    : 'Any';
            }

            case 'roomTypeId': {
                const roomType = this.roomTypesService
                    .getRoomType(settings.roomTypeId);

                return roomType
                    ? roomType.name
                    : 'Any';
            }

            case 'numberOfGuests':
                return settings.numberOfGuests;

            case 'priceType':
                return i18n.$t(`filterSettings.price.${settings.priceType}`) as string;

            default: return '-';
        }
    }

    getComparisonValueLabel(needle: string | number) {
        const { comparisonKey } = this;

        const valueTypes: { [k: string]: () => any } = {
            diffDays: () => this.getItemLabel(needle),
            provider: () => this.providersService.getProviderLabel(needle.toString()),
            mealTypeId: () => {
                if (needle === -1) {
                    return 'Any';
                }

                return this.mealTypesService
                    .getMealType(Number(needle))!.displayName || 'Unknown';
            },
            roomTypeId: () => {
                if (needle === -1) {
                    return 'Any';
                }

                return this.roomTypesService
                    .getRoomType(Number(needle))!.name || 'Unknown';
            },
            los: () => this.getItemLabel(needle),
            pos: () => String(needle),
            numberOfGuests: () => String(needle),
            priceType: () => {
                const dict: { [k: string]: string } = {
                    best_flex: 'Best flex',
                    lowest: 'Lowest',
                    lowest_flex: 'Lowest Flex',
                    non_refundable: 'Non Refundable',
                };

                return dict[String(needle)];
            },
        };

        return valueTypes[comparisonKey]();
    }

    private selectComparisonKey() {
        const comparisonValues: { [k: string]: () => any } = {
            diffDays: () => this.comparisonDays[0].value,
            provider: () => {
                const providerItem = this.providerItems.shift();
                return providerItem ? providerItem.value : '';
            },
            mealTypeId: () => this.filterItems.mealTypeId().shift()!.value,
            roomTypeId: () => this.filterItems.roomTypeId().shift()!.value,
            los: () => this.filterItems.los().shift()!.value,
            pos: () => {
                const { pos: globalPos } = this.documentFiltersService.settings;
                const availablePoses = this.compsetsService.poses
                    .filter(pos => pos !== globalPos);

                return availablePoses[0] || 'US';
            },
            numberOfGuests: () => this.filterItems.numberOfGuests().shift()!.value,
            priceType: () => this.filterItems.priceType().shift()!.value,
        };
        const value = comparisonValues[this.comparisonKey]();
        const name = this.getComparisonValueLabel(value);
        this.comparisonValues = [{ name, value }];
    }

    private getItemLabel(needle: number | string) {
        const item = this.currentFilterItems.find(x => x.value === needle);
        return item ? item.name : null;
    }

    resetComparisonFilters() {
        this.comparisonKey = 'diffDays';
        this.comparisonValues = [{ value: 1, name: 'Yesterday' }];
        this.storeState.analysis.loading.reset();
    }

    reorderValueList(newValues: (string | number)[], oldValues: (string | number)[]) {
        const keys = ['pos', 'los', 'provider', 'priceType', 'roomTypeId', 'mealTypeId', 'numberOfGuests'];
        const changedField = keys.find((_, i) => newValues[i] !== oldValues[i]);
        this.storeState.analysis.loading.reset();

        if (!changedField) return;

        const isComparisonFieldChanged = this.comparisonKey === changedField;
        if (!isComparisonFieldChanged) return;

        const unionGlobalSettings: { [k: string]: any } = {
            ...this.documentFiltersService.storeState.settings,
            ...this.ratesFilterService.storeState.settings,
        };

        const globalValue = unionGlobalSettings[changedField];

        const isHaveGlobalPos = !!this.comparisonValues.find(item => item.value === globalValue);

        if (!isHaveGlobalPos) return;

        this.comparisonValues = this.comparisonValues
            .filter(item => item.value !== globalValue);

        if (!this.comparisonValues.length) {
            this.selectComparisonKey();
        }
    }
}
