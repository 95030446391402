import { Expose } from 'class-transformer';
import { Validate } from 'class-validator';
import HotelsRoomTypeValidator from '../validators/hotels-room-type.validator';

export default class HotelsRoomTypeModel {
    @Expose()
    @Validate(HotelsRoomTypeValidator)
    providers!: {
        [provider: string]: {
            [fornovaId: number]: {
                [roomName: string]: number
            }
        }
    };
}
