import { Expose, Transform } from 'class-transformer';

export default class RatesScanModel {
    @Expose()
    @Transform((_, plain) => plain.fornova_Id)
    hotelId!: number;

    @Expose()
    @Transform((_, plain) => plain.message)
    message!: string;

    @Expose()
    @Transform((_, plain) => Number(plain.document_id_string))
    ratesDocumentId!: number;
}
