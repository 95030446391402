import { Expose, Transform } from 'class-transformer';

export default class ExcelProgressSocketModel {
    @Expose()
    @Transform((_, plain) => (plain.percent >= 100 ? 100 : plain.percent))
    progress: number = 0;

    @Expose()
    @Transform((_, plain) => plain.report_id)
    reportId: number = 0;
}
