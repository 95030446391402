import { injectable } from 'inversify-props';
import BOOKING_BOOK_DATE_STATISTICS from '@/modules/deep-analysis/constants/booking-book-date-statistics.constant';
import BOOKING_STAY_DATE_STATISTICS from '@/modules/deep-analysis/constants/booking-stay-date-statistics.constant';
import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import EXPEDIA_BOOK_DATE_STATISTICS from '@/modules/deep-analysis/constants/expedia-book-date-statistics.constant';
import EXPEDIA_STAY_DATE_STATISTICS from '@/modules/deep-analysis/constants/expedia-stay-date-statistics.constant';
import SHOW_BY from '@/modules/deep-analysis/constants/show-by-filter.constant';
import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';
import VALUE_TYPE from '@/modules/deep-analysis/constants/value-type.constant';

export const DeepCompsetAnalysisCommonServiceS = Symbol.for('DeepCompsetAnalysisCommonServiceS');
@injectable(DeepCompsetAnalysisCommonServiceS as unknown as string)
export default class DeepCompsetAnalysisCommonService {
    getValueType(statistic: STATISTIC_TYPE) {
        switch (statistic) {
            case STATISTIC_TYPE.REVENUE:
                return VALUE_TYPE.PRICE;
            case STATISTIC_TYPE.REVENUE_SHARE:
                return VALUE_TYPE.PERCENT;
            case STATISTIC_TYPE.CANCELLATION:
                return VALUE_TYPE.PERCENT;
            case STATISTIC_TYPE.PAGE_VIEWS_SHARE:
                return VALUE_TYPE.PERCENT;
            case STATISTIC_TYPE.PACKAGE_SHARE:
                return VALUE_TYPE.PERCENT;
            case STATISTIC_TYPE.GROSS_CONVERSION:
                return VALUE_TYPE.PERCENT;
            case STATISTIC_TYPE.CORPORATE_TRAVEL_SHARE:
                return VALUE_TYPE.PERCENT;
            case STATISTIC_TYPE.INTERNATIONAL_SHARE:
                return VALUE_TYPE.PERCENT;
            case STATISTIC_TYPE.PROMOTION_SHARE:
                return VALUE_TYPE.PERCENT;
            default:
                return VALUE_TYPE.NUMBER;
        }
    }

    getFormattedValue(val: number | string | null, statistic: STATISTIC_TYPE, isPerformance?: boolean) {
        if (val && typeof val === 'number') {
            const valueType = this.getValueType(statistic);
            const convertedValue = val && valueType === VALUE_TYPE.PERCENT ? val * 100 : val;
            if (statistic === STATISTIC_TYPE.REVENUE && !isPerformance) {
                return Number(convertedValue.toFixed(0));
            }
            return Number(convertedValue.toFixed(2));
        }
        if (val === 0) {
            return val;
        }
        return null;
    }

    getAbsoluteValue(val: number | string | null) {
        if (val && typeof val === 'number') {
            const convertedValue = val * 100;
            return Number(convertedValue.toFixed(2));
        }
        if (val === 0) {
            return val;
        }
        return null;
    }

    getsStatistics(provider: string, showBy: SHOW_BY) {
        const isStay = showBy.startsWith('stay');

        switch (provider) {
            case 'booking':
                return isStay ? BOOKING_STAY_DATE_STATISTICS : BOOKING_BOOK_DATE_STATISTICS;
            case 'expedia':
            case 'all':
                return isStay ? EXPEDIA_STAY_DATE_STATISTICS : EXPEDIA_BOOK_DATE_STATISTICS;
            default:
                return [];
        }
    }

    getCalculatedFields(provider: string, showBy: SHOW_BY, compareTo: COMPARE_TO) {
        if (compareTo === COMPARE_TO.LAST_YEAR) {
            return [];
        }
        const isBook = showBy.startsWith('book');
        switch (provider) {
            case 'booking':
                if (isBook) {
                    return [STATISTIC_TYPE.INTERNATIONAL_SHARE];
                }
                return [STATISTIC_TYPE.NET_ADR];
            case 'expedia':
            case 'all':
                return [STATISTIC_TYPE.REVENUE, STATISTIC_TYPE.ROOM_NIGHTS];
            default:
                return [];
        }
    }
}
