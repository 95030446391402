import { injectable, Inject } from 'inversify-props';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import PromotionsStore, { PromotionGraphType } from './promotions.store';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import PromotionsApiService, { PromotionsApiServiceS } from './promotions-api.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '../document-filters/document-filters.service';
import UserService, { UserServiceS } from '../user/user.service';
import { programLogos } from './consts/logos';
import { COMPARE_KEY } from './consts/compare-filter-values';
import SCAN_STATUS from '../rates/constants/scan-status.constant';
import CompsetsService, { CompsetsServiceS } from '../compsets/compsets.service';
import getScanRange from '../common/utils/get-scan-range.util';
import Day from '../common/types/day.type';
import SocketService, { SocketServiceS } from '../common/modules/socket/socket.service';
import PromotionsScanModel from './models/promotions-scan.model';

type ProgramLabel = string;
export interface ProgramDictionary {
    [provider: string]: {
        [program: string]: ProgramLabel;
    }
}

export const PromotionsServiceS = Symbol.for('PromotionsServiceS') as unknown as string;

@injectable(PromotionsServiceS)
export default class PromotionsService {
    @Inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @Inject(HelperServiceS)
    private helperService!: HelperService;

    @Inject(PromotionsApiServiceS)
    private promotionsApiService!: PromotionsApiService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(SocketServiceS)
    private socketService!: SocketService;

    private storeState: PromotionsStore = this.storeFacade.getState('PromotionsStore');
    private programDictionary: ProgramDictionary = {
        booking: {
            percentage_off: '% Off',
            preferred: 'Preferred',
            preferred_plus: 'Preferred Plus',
            booster: 'Booster',
            guest_favorite: 'Guests Favorite',
            special_promotion: 'Special Promotions',
        },
        expedia: {
            member_price: 'Member Price Available',
            percentage_off: '% Off',
            travel_ads: 'Travel Ads Direct',
            vip_access: 'VIP Access',
            all_inclusive: 'All Inclusive',
            scarcity_message: 'Scarcity Message',
        },
    };

    private storeChangeListeners: (() => void)[] = [];

    constructor() {
        this.storeFacade.watch(() => [
            this.documentFiltersService.settings.year,
            this.documentFiltersService.settings.month,
            this.documentFiltersService.settings.compsetId,
            this.documentFiltersService.settings.pos,
            this.documentFiltersService.settings.los,
        ], () => {
            this.storeState.loading.reset();
            this.dispatchChangeEvent();
        });

        this.storeFacade.watch(() => [
            this.documentFiltersService.settings.year,
            this.documentFiltersService.settings.month,
            this.documentFiltersService.settings.compsetId,
            this.storeState.comparisonFilter,
        ], () => {
            this.storeState.comparedLoading.reset();
        });

        this.socketService.onPromotionsScan(this.onScanUpdate.bind(this));
    }

    private onScanUpdate(data: PromotionsScanModel) {
        if (this.data && this.data.id === data.promotionsDocumentId) {
            this.storeState.loading.reset();
        }
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.data;
    }

    get comparedData() {
        this.helperService.dynamicLoading(this.storeState.comparedLoading, this.loadData.bind(this, this.storeState.comparisonFilter));
        return this.storeState.comparedData;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get loading() {
        return this.storeState.loading;
    }

    get isComparedLoading() {
        return this.storeState.comparedLoading.isLoading();
    }

    get competitors() {
        return this.compsetsService.competitors || [];
    }

    get provider() {
        return this.storeState.provider;
    }

    set provider(value: string | null) {
        this.storeState.provider = value;
    }

    get programView() {
        return this.storeState.programView;
    }

    set programView(value: string) {
        this.storeState.programView = value;
    }

    get graphType() {
        return this.storeState.graphType;
    }

    set graphType(value: PromotionGraphType) {
        this.storeState.graphType = value;
    }

    get comparisonFilter() {
        return this.storeState.comparisonFilter;
    }

    set comparisonFilter(value: { key: COMPARE_KEY, value: string }) {
        this.storeState.comparisonFilter = value;
    }

    get scanStatus() {
        if (!this.data) return SCAN_STATUS.FINISHED;

        return this.data.scanStatus;
    }

    set scanStatus(value: SCAN_STATUS) {
        if (!this.data) return;

        this.data.scanStatus = value;
    }

    get isScanFeatureEnabled() {
        return this.userService.enabledFeatures!.on_demand_promotion_detection;
    }

    private dispatchChangeEvent() {
        this.storeChangeListeners.forEach(handler => handler());
    }

    public onFiltersChanged(handler: () => void) {
        this.storeChangeListeners.push(handler);

        return () => {
            this.storeChangeListeners = this.storeChangeListeners.filter(h => handler !== h);
        };
    }

    private async loadData(comparisonFilter?: {
        key: COMPARE_KEY;
        value: string;
    }) {
        const { settings: docSettings } = this.documentFiltersService;

        if (!docSettings.compsetId) return false;

        const doc = await this.promotionsApiService.getPromotionData(docSettings, comparisonFilter);

        if (comparisonFilter) {
            this.storeState.comparedData = doc;
        } else {
            this.storeState.data = doc;
        }

        return true;
    }

    public getPrograms(day: number, provider: string, hotelId: number) {
        if (!this.data || !this.data.providerData) return null;

        const hotels = this.data.providerData[provider];

        if (!hotels) return null;
        if (!hotels[hotelId]) return null;

        return hotels[hotelId][day] || null;
    }

    public getProgram(day: number, provider: string, hotelId: number, program: string) {
        const programs = this.getPrograms(day, provider, hotelId);

        if (!programs) return null;

        return programs[program];
    }

    public isNoPromotions(provider: string, program: string, hotelId: number) {
        if (!this.data) return true;

        return !this.documentFiltersService.days
            .some(day => {
                const { [provider]: hotels } = this.data!.providerData;

                if (!hotels[hotelId]) return false;
                if (!hotels[hotelId][day]) return false;

                return !!hotels[hotelId][day][program];
            });
    }

    public getActivePrograms(day: number, provider: string, hotelId: number) {
        const { data } = this;

        if (!data) return [];

        return data.promotions[provider]
            .filter(program => {
                const programData = this.getProgram(day, provider, hotelId, program);

                return programData ? programData.status : false;
            });
    }

    public getProgramActiveDays(provider: string, program: string, hotelId: number) {
        return this.documentFiltersService.days
            .filter(day => {
                const programs = this.getPrograms(day, provider, hotelId);

                if (!programs) return false;

                return programs[program]
                    ? programs[program].status
                    : false;
            })
            .length;
    }

    public getProgramLabel(provider: string, program: string): ProgramLabel {
        return this.programDictionary[provider]
            ? this.programDictionary[provider][program] || program
            : program;
    }

    public getProgramAveragePercent(provider: string, program: string, hotelId: number) {
        let activeDays = 0;

        const percent = this.documentFiltersService.days
            .reduce((sum, day) => {
                const programData = this.getProgram(day, provider, hotelId, program);

                if (!programData || !programData.status) return sum;

                activeDays++;

                return sum + programData.percentage;
            }, 0) / activeDays;

        return percent;
    }

    public getCompetitorsAveragePercentDay(day: number, provider: string, program: string, mainHotel: number) {
        if (!this.data) return null;

        let activeCompetitorsCount = 0;

        const competitors = this.data.hotels
            .filter(hotelId => +hotelId !== +mainHotel);

        const competitorsSum = competitors.reduce((sum, hotelId) => {
            const programData = this.getProgram(day, provider, hotelId, program);

            if (!programData || !programData.status) return sum;
            activeCompetitorsCount++;
            return sum + programData.percentage;
        }, 0);

        if (!activeCompetitorsCount) return 0;

        return competitorsSum / activeCompetitorsCount;
    }

    public isProgramNew(provider: string, program: string, hotelId: number) {
        if (!this.data || !this.data.newPromotions) return false;

        const programList = this.data.newPromotions[provider];
        if (!programList) return false;

        const programData = programList[program];
        if (!programData) return false;

        return programData[hotelId];
    }

    public getProgramLogo(provider: string, program: string) {
        if (!programLogos[provider]) return '';
        return programLogos[provider][program];
    }

    public getDealsPrograms(day: number, provider: string, hotelId: number) {
        const programs = this.getPrograms(day, provider, hotelId);

        if (!programs) return [];

        return Object
            .values(programs)
            .filter(programData => programData.status && programData.percentage);
    }

    public async triggerScan(day?: number, allProviders: boolean = false) {
        const { data } = this;
        const { currentCompset } = this.compsetsService;
        const { pos, los } = this.documentFiltersService.settings;
        const { provider } = this.storeState;

        const isProviderValid = allProviders ? true : !!provider;

        if (!currentCompset || !los || !pos || !isProviderValid || !data) return;

        const [startDate, endDate] = getScanRange(this.documentFiltersService.settings, day as Day);
        const toIso = (d: Date) => [
            d.getFullYear(),
            String(d.getMonth() + 1).padStart(2, '0'),
            String(d.getDate()).padStart(2, '0'),
        ].join('-');

        await this.promotionsApiService.triggerScan({
            ondemand: true,
            compSetIds: [currentCompset.id],
            los: [los],
            pos: [pos],
            providers: allProviders
                ? Object.keys(data.providerData)
                : [provider!],
            start_date: toIso(startDate),
            end_date: endDate && toIso(endDate),
        });

        data.scanStatus = SCAN_STATUS.IN_PROGRESS;
    }

    public async toggleScanFeature() {
        await this.promotionsApiService
            .setOnDemandFeature(!this.isScanFeatureEnabled);
    }

    public getPromotionLink(day: number, provider: string, hotelId: number) {
        const d = this.getPrograms(day, provider, hotelId);

        if (!d) return null;

        const programData = Object.values(d).find(p => !!p.deep_link);

        if (!programData) return null;

        return programData.deep_link;
    }
}
