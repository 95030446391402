import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import AlertModel from '@/modules/alerts/models/alert.model';
import LoadingModel from '@/modules/common/models/loading.model';

@Module
export default class AlertsStore extends VuexModule {
    alerts: AlertModel[] = [];
    loading: LoadingModel = new LoadingModel();
}
