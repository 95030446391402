import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import CarAlertModel from '@/modules/cars/alerts/models/car.alert.model';
import LoadingModel from '@/modules/common/models/loading.model';
import { COUNTRIES_ANY } from '@/modules/cars/constants/car-filter-types.constant';

@Module
export default class NotificationsStore extends VuexModule {
    notifications: CarAlertModel[] = [];
    loading: LoadingModel = new LoadingModel();
    selectedDate: number = 1;
    alertType: Record<string, any>[] = [];
    selectedDatesRange: Date[] = [];
    trend: number = 1;
    country: string = COUNTRIES_ANY;
    location: Record<string, any>[] = [];
    dataSource: string = 'Brand';
    competitors: Record<string, any>[] = [];
    POS: Record<string, any>[] = [];
    LOK: Record<string, any>[] = [];
    carCategory: Record<string, any>[] = [];
    fuelType: Record<string, any>[] = [];
    filterReady: boolean = false;
    dateFilterReady: boolean = false;
    otherFilterReady: boolean = false;
    seen: number = 2;
    showDeleteAllNotifications: boolean = false;
    page: number = 1;
    isUserHaveBrand: boolean = false;
}
