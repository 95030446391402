import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import DEFAULT_NUMBER_OF_GUESTS from '@/modules/number-of-guests/constants/default-number-of-guests.constant';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';

export enum DILiteFilterDevice {
    ALL = '',
    MOBILE = 'mobile',
    DESKTOP = 'desktop',
}

export default class AllChannelsSettingsModel {
    provider: string = 'all';
    device: DILiteFilterDevice = DILiteFilterDevice.ALL;

    // NOTE: Temporary solution, to show by default
    //       some previously selected value
    priceType: PRICE_TYPE = localStorage.getItem('priceType') as PRICE_TYPE || PRICE_TYPE.LOWEST;
    roomTypeId: number = ANY_ROOM_TYPE.id;
    mealTypeId: number = ANY_MEAL_TYPE.id;
    numberOfGuests: number = DEFAULT_NUMBER_OF_GUESTS;
    priceShown: PRICE_SHOWN = PRICE_SHOWN.SHOWN;
    competitors: number[] | null = null;
}
