import { Expose, Transform } from 'class-transformer';

export enum RateAlertTrends {
    ANY = 1,
    HIGHER,
    LOWER,
}

export default class CarsBaseAlertConfigs {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain._id)
    id!: string;

    @Expose()
    @Transform((_, plain) => plain.chain_id)
    chainId!: string;

    @Expose()
    @Transform((_, plain) => plain.fornova_user_id)
    userId!: string;

    @Expose()
    @Transform((_, plain) => plain.config_ids)
    configIds!: number[];

    @Expose()
    @Transform((_, plain) => plain.location_ids)
    locationIds!: string[];

    @Expose()
    @Transform((_, plain) => plain.pos)
    pos!: string[];

    @Expose()
    @Transform((_, plain) => plain.car_categories)
    carCategories!: string[];

    @Expose()
    @Transform((_, plain) => plain.fuel_type)
    fuelType!: string[];

    @Expose()
    @Transform((_, plain) => plain.data_source)
    dataSource!: string[];

    @Expose()
    @Transform((_, plain) => plain.pickup_dates)
    pickupDates!: Record<string, any>;

    @Expose()
    @Transform((_, plain) => plain.lors)
    lors!: number[];

    @Expose()
    @Transform((_, plain) => plain.is_active)
    isActive!: boolean;

    @Expose()
    @Transform((_, plain) => plain.competitors)
    competitors!: string[];

    @Expose()
    @Transform((_, plain) => (plain.trend ? plain.trend : RateAlertTrends.ANY))
    trend!: RateAlertTrends;
}
