import { Module, VuexModule } from 'vuex-module-decorators';
import GRAPH_TYPE from '@/modules/markets/constants/graph-types.constant';
import MarketsDocumentModel from '@/modules/markets/models/markets-document.model';
import LoadingModel from '@/modules/common/models/loading.model';
import MarketSettingsModel from '../models/market-settings.model';

@Module
export default class MarketsStore extends VuexModule {
    providersMarketsDocuments: { [provider: string]: MarketsDocumentModel| null } = {};
    marketGraphType: { [provider: string]: GRAPH_TYPE } = {};
    loading: LoadingModel = new LoadingModel();
    settings: MarketSettingsModel = new MarketSettingsModel();
}
