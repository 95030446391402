import { Expose, Transform } from 'class-transformer';

export default class ParityBrokerDataModel {
    @Expose()
    @Transform((_, plain) => plain.provider_name)
    providerName!: string;

    @Expose()
    @Transform((_, plain) => plain.car_description)
    carDescription!: string;

    @Expose()
    availability!: string;

    @Expose()
    @Transform((_, plain) => plain.shown_price)
    shownPrice!: number;

    @Expose()
    @Transform((_, plain) => plain.total_price)
    totalPrice!: number;

    @Expose()
    @Transform((_, plain) => plain.site_currency)
    siteCurrency!: string;

    @Expose()
    @Transform((_, plain) => plain.price_for_comparison)
    priceForComparison!: number;

    @Expose()
    @Transform((_, plain) => plain.screenshot_url)
    screenshot!: string;

    @Expose()
    @Transform((_, plain) => plain.rectangle_uniqueness)
    rectangleUniqueness!: string;

    @Expose()
    @Transform((_, plain) => {
        if (!plain.pattern_rect) {
            return null;
        }

        const patternRect: string = plain.pattern_rect;

        if (/-/.test(patternRect)) {
            return JSON.parse(patternRect.replace('-', ','));
        }

        return JSON.parse(patternRect);
    })
    patternRect!: [number, number, number, number] | null;
}
