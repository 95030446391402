import { Module, VuexModule } from 'vuex-module-decorators';
import IChainDictionary from './interfaces/chain-dictionary.interface';
import LoadingModel from '../common/models/loading.model';
import ChainGroup from './interfaces/chain-group.enum';
import ChainSettingsModel from './models/chain-settings.model';

@Module
export default class ChainStore extends VuexModule {
    dictionary: IChainDictionary | null = null;
    settings = new ChainSettingsModel();
    chainPair: {
        group: ChainGroup,
        value: string,
    } | null = null;

    dictionaryLoading = new LoadingModel();
}
