import BmlFilterType from '../types/bml-filter.type';

export default class ParitySettingsModel {
    provider: string | null = null;
    pickUpCityCodes: string[] | null = null;
    lor: number[] | null = null;
    pos: string[] | null = null;
    carClasses: string[] | null = null;
    doors: string[] | null = null;
    transmission: string[] | null = null;
    mileage: string[] | null = null;
    paymentTerm: string[] | null = null;
    country: string | null = null;
    bml: BmlFilterType[] | null = null;
    pickUpDate: Date | null = null;
    priceDif: [number | null, number | null][] | null = null;
    dataSources: string[] | null = null;
}
