import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import UserModel from '@/modules/user/models/user.model';
import LoadingModel from '@/modules/common/models/loading.model';

@Module
export default class UserStore extends VuexModule {
    // Sets true if it is hotel level user, or after hotel switch request for chain\cluster
    currentUserHotelInited: boolean = false;
    user: UserModel | null = null;
    loading: LoadingModel = new LoadingModel();
}
