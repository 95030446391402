import { Inject, injectable } from 'inversify-props';
import CarsAlertsApiService, { CarsAlertsApiServiceS } from '@/modules/cars/alerts/cars-alerts-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import CarsNotificationsStore from '@/modules/cars/alerts/store/cars-notifications-store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';

export const CarsAlertsServiceS = Symbol.for('CarsAlertsServiceS');
@injectable(CarsAlertsServiceS as unknown as string)
export default class CarsAlertsService implements Stateable {
    @Inject(CarsAlertsApiServiceS) private alertsApiService!: CarsAlertsApiService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;

    readonly storeState: CarsNotificationsStore = this.storeFacade.getState('CarsNotificationsStore');

    constructor() {
        this.storeFacade.watch(
            () => this.userService.storeState.user,
            this.storeState.loading.reset.bind(this.storeState.loading),
        );
    }

    get alerts() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.alerts;
    }

    async loadData() {
        const alerts = this.userService.isCarUser ? await this.alertsApiService.getCarAlerts() : null;
        this.storeState.alerts = (alerts || []).sort((a, b) => b.date.getTime() - a.date.getTime());
        return true;
    }

    async markAlertAsRead(id: string) {
        const alert = this.alerts.find(a => a.id === id);
        if (alert) {
            await this.alertsApiService.markAlertAsRead(id);
            alert.read = true;
        }
    }

    async markAllAlertsAsRead() {
        const alertIds = this.alerts.map(a => a.id);
        await this.alertsApiService.markAlertsAsRead(alertIds);

        this.storeState.loading.reset();
    }

    async markAllUserAlertsAsRead() {
        await this.alertsApiService.markAllUserAlertsAsRea();

        this.storeState.loading.reset();
    }

    isLoading() {
        return this.storeState.loading.isLoading();
    }

    async deleteAlertById(id: string) {
        const results = await this.alertsApiService.deleteAlertById(id);
        if (results === 'ok') {
            this.storeState.alerts = this.storeState.alerts.filter(alert => alert.id !== id);
            return true;
        }
        return false;
    }

    async deleteAlertByIds(ids: string[]) {
        const results = await this.alertsApiService.deleteAlertByIds(ids);
        if (results === 'ok') {
            this.storeState.alerts = this.storeState.alerts.filter(alert => !ids.includes(alert.id));
            return true;
        }
        return false;
    }

    async deleteAllAlerts() {
        const results = await this.alertsApiService.deleteAllAlerts();
        if (results === 'ok') {
            this.storeState.alerts = [];
            return true;
        }
        return false;
    }
}
