import { Module, VuexModule } from 'vuex-module-decorators';
import ClusterHotelsMarketsModel from '@/modules/cluster/models/cluster-markets.model';
import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';
import LoadingModel from '@/modules/common/models/loading.model';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import RankingChainItemModel from '@/modules/cluster/models/ranking-cluster-item.model';
import ClusterRankingModel from '@/modules/cluster/models/cluster-ranking.model';

@Module
export default class ClusterStore extends VuexModule {
    // common
    clusterHotels: (ClusterHotelsRatesModel | ClusterHotelsMarketsModel | ClusterRankingModel)[] | null = null;
    provider: string | null = null;
    currentHotelId: number | null = null;

    isLoadingMore: boolean = false;
    totalCount: number | null = null;
    limit: number = 10;
    skip: number = 0;
    hotelNameSorting: (1 | -1 | 0) = 1;
    ratesSorting: (1 | -1 | 0) = 0;
    marketsSorting: (1 | -1) = 1;

    // rates data
    activePage: 'list' | 'table' = 'list';
    settings: RatesSettingsModel = new RatesSettingsModel();
    ratesClusterCurrentDocument: RatesCompsetMainModel | null = null;
    ratesClusterCurrentSettings: RatesSettingsModel | null = null;
    ratesLoading: LoadingModel = new LoadingModel();

    marketsLoading: LoadingModel = new LoadingModel();
    rankingLoading: LoadingModel = new LoadingModel();

    hotels: any = [];
    rankingChainItems: { [hotelId: number]: { [compsetId: string]: RankingChainItemModel }; } = {};

    ratesHotelMap: {
        [hotelId: number]: ClusterHotelsRatesModel | undefined,
    } = {};

    hotelNamesMap: {
        [hotelId: number]: string,
    } = {};

    // all cluster POS, serarate request
    pos: {
        list: string[],
        loading: LoadingModel,
    } = {
        list: [],
        loading: new LoadingModel(),
    };
}
