import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import AuthModel from '@/modules/auth/models/auth.model';

@Module
export default class AuthStore extends VuexModule {
    auth: AuthModel = new AuthModel();
    token: string | null = null;
}
