import { Inject, injectable } from 'inversify-props';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import RatesStore from '@/modules/rates/store/rates.store';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';
import DEFAULT_NUMBER_OF_GUESTS from '@/modules/number-of-guests/constants/default-number-of-guests.constant';
import ScanDisabledProviders from '@/modules/common/modules/rates/constants/scan-disabled-providers.enum';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import ClusterService, { ClusterServiceS } from '../cluster/cluster.service';
import UserService, { UserServiceS } from '../user/user.service';
import PRICE_TYPE from '../document-filters/constants/price-type.constant';
import RatesSettingsModel from './models/rates-settings.model';
import DocumentFiltersModel from '../document-filters/models/document-filters.model';
import PRICE_SHOWN from './constants/price-shown.constant';

export const RatesFiltersServiceS = Symbol.for('RatesFiltersServiceS');
@injectable(RatesFiltersServiceS as unknown as string)
export default class RatesFiltersService implements Stateable {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @Inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;

    readonly storeState: RatesStore = this.storeFacade.getState('RatesStore');

    constructor() {
        this.storeFacade.watch(() => this.documentFiltersService.storeState.settings.compsetId, this.resetCompsetProvider.bind(this));
        this.storeFacade.watch(() => this.compsetsService.competitors, () => {
            if (this.compsetsService.competitors) {
                this.setDefaultCompetitors();
            }
        });
    }

    get settings() {
        return this.storeState.settings;
    }

    get priceType() {
        return this.storeState.settings.priceType;
    }

    set priceType(value: PRICE_TYPE) {
        this.storeState.settings.priceType = value;
        // Temporary solution, to show by default some previously selected value
        localStorage.setItem('priceType', value);
    }

    set providerFromHighLevel(value: string | null) {
        this.storeState.providerFomHighLevel = value;
    }

    get providerFromHighLevel() {
        return this.storeState.providerFomHighLevel;
    }

    get currentProvider() {
        if (this.storeState.settings.provider === null) {
            this.resetProvider();
        }

        return this.storeState.settings.provider;
    }

    get competitors() {
        return this.storeState.settings.competitors;
    }

    set competitors(value: number[] | null) {
        this.storeState.settings.competitors = value;
    }

    get currentNumberOfGuests() {
        return this.storeState.settings.numberOfGuests;
    }

    get isProviderAll() {
        return this.currentProvider === ScanDisabledProviders.ALL;
    }

    resetRatesFilters() {
        this.resetCompsetProvider();
        this.resetMealType();
        this.resetRoomType();
        this.resetPriceType();
        this.resetNumberOfGuests();
        this.setDefaultCompetitors();
    }

    resetProvider() {
        if (!this.compsetsService || this.compsetsService.currentCompset === null) {
            return;
        }

        const { rateProviders } = this.compsetsService.currentCompset;

        let currentProvider = this.storeState.settings.provider;
        if (this.userService.isChainUser || this.userService.isClusterUser) {
            currentProvider = this.clusterService.storeState.settings.provider;
        }

        if (currentProvider && rateProviders.includes(currentProvider)) {
            this.saveProvider(currentProvider);
        } else {
            // eslint-disable-next-line prefer-destructuring
            this.saveProvider(rateProviders[0]);
        }
    }

    async resetCompsetProvider() {
        if (!this.compsetsService || this.compsetsService.currentCompset === null) {
            return;
        }

        const { rateProviders } = this.compsetsService.currentCompset;
        this.setDefaultCompetitors();

        if (this.providerFromHighLevel) {
            this.saveProvider(this.providerFromHighLevel);
            return;
        }

        if (this.storeState.settings.provider === null
            || !rateProviders.includes(this.storeState.settings.provider)) {
            this.saveProvider(
                (rateProviders && rateProviders.length)
                    ? rateProviders[0]
                    : this.storeState.settings.provider,
            );
        }
    }

    resetMealType() {
        const { mealTypes } = this.mealTypesService;

        const mealTypeId = mealTypes && mealTypes.length ? mealTypes[0].id : null;
        if (mealTypeId !== null) {
            this.storeState.settings.mealTypeId = mealTypeId;
        } else {
            this.storeState.settings.mealTypeId = ANY_MEAL_TYPE.id;
        }
    }

    resetRoomType() {
        const { rooms } = this.roomTypesService;

        const roomTypeId = rooms && rooms.length ? rooms[0].id : null;
        if (roomTypeId !== null) {
            this.storeState.settings.roomTypeId = roomTypeId;
        } else {
            this.storeState.settings.roomTypeId = ANY_ROOM_TYPE.id;
        }
    }

    setDefaultCompetitors() {
        const { competitors } = this.compsetsService;

        if (!competitors) {
            return null;
        }

        this.competitors = competitors;
        return true;
    }

    setClusterCompetitors(hotelId: number) {
        this.competitors = this.clusterService.getCompetitors(hotelId);
    }

    setPriceType(newState: PRICE_TYPE) {
        this.storeState.settings.priceType = newState;
    }

    setPriceShown(newState: PRICE_SHOWN) {
        this.storeState.settings.priceShown = newState;
    }

    resetPriceType() {
        // Temporary solution, to show by default some previously selected value
        const priceType = localStorage.getItem('priceType') as PRICE_TYPE || PRICE_TYPE.LOWEST;
        this.priceType = priceType;
    }

    resetNumberOfGuests() {
        this.storeState.settings.numberOfGuests = DEFAULT_NUMBER_OF_GUESTS;
    }

    async saveProvider(provider: string | null): Promise<void> {
        const lowestPriceOnlyProviders = ['google', 'all'];

        if (provider && lowestPriceOnlyProviders.includes(provider)) {
            this.priceType = PRICE_TYPE.LOWEST;
        }

        this.storeState.settings.provider = provider;
        this.providerFromHighLevel = null;
        this.updateFieldsState();
    }

    async saveNumberOfGuests(numberOfGuests: number) {
        this.storeState.settings.numberOfGuests = numberOfGuests;
    }

    togglePrices(priceType: PRICE_SHOWN) {
        this.storeState.settings.priceShown = priceType;
    }

    private updateFieldsState() {
        const { provider } = this.storeState.settings;
        const { compsetId } = this.documentFiltersService.storeState.settings;
        const shortFilters = ['google', 'all'];
        if (!provider) return;

        const currentCompset = this.compsetsService.getCompset(compsetId)!;

        if (!currentCompset) {
            return;
        }

        const providerPos = currentCompset.providersPos
            && currentCompset.providersPos.find(item => item.name === provider);

        if (shortFilters.includes(provider)) {
            this.resetRatesFilters();
            this.storeState.disabledFilters = ['numberOfGuests', 'priceType', 'mealTypeId', 'roomTypeId'];

            if (provider === 'all') {
                this.storeState.disabledFilters = this.storeState.disabledFilters.concat(['los']);
            }

            this.documentFiltersService.saveLos(1);
            if (providerPos && providerPos.pos && providerPos.pos.length && !providerPos.pos.includes(currentCompset.mainPos)) {
                this.documentFiltersService.savePos(providerPos.pos[0]);
            } else {
                this.documentFiltersService.savePos(currentCompset.mainPos);
            }
        } else if (this.isScanDisabledProvider && provider !== ScanDisabledProviders.ALL) {
            this.resetRatesFilters();
            this.storeState.disabledFilters = ['numberOfGuests'];
            this.documentFiltersService.savePos(currentCompset.mainPos);
            this.documentFiltersService.saveLos(1);
        } else {
            if (providerPos && providerPos.pos && providerPos.pos.length
                && !providerPos.pos.includes(this.documentFiltersService.storeState.settings.pos as string)) {
                this.documentFiltersService.savePos(providerPos.pos[0]);
            }

            this.storeState.disabledFilters = [];
        }
    }

    get isScanDisabledProvider() {
        const { provider } = this.storeState.settings;
        return this.providersService.isDisabledProvider(provider);
    }

    isFilterDisabled(filter: keyof (RatesSettingsModel & DocumentFiltersModel)): boolean {
        return this.storeState.disabledFilters.includes(filter);
    }
}
