import ClusterHotelsMarketsModel from '@/modules/cluster/models/cluster-markets.model';
import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { event } from 'vue-gtag';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import ClusterRankingModel from '@/modules/cluster/models/cluster-ranking.model';
import ChainGroup from '../chain/interfaces/chain-group.enum';
import DownloadExcelModel from '../rates/models/download-excel.model';
import UserService, { UserServiceS } from '../user/user.service';

interface Params {
    [key: string]: number | string;
}

type ChainPair = { group: ChainGroup, value: string } | null;

export const ClusterApiServiceS = Symbol.for('ClusterApiServiceS');
@injectable(ClusterApiServiceS as unknown as string)
export default class ClusterApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(UserServiceS) private userService!: UserService;

    async getRatesClusterData(params: Params, chainPair: ChainPair = null): Promise<{ data: ClusterHotelsRatesModel[], total: number } | null> {
        if (chainPair) {
            const newParams = {
                ...params,
                grouped_by: chainPair.group,
                grouped_by_value: chainPair.value,
            };

            const { data } = await this.apiService.get('rate/cluster-chain-user', newParams);

            const clusterHotels = plainToClass(
                ClusterHotelsRatesModel,
                <ClusterHotelsRatesModel[]> data.data,
                { excludeExtraneousValues: true },
            );

            return {
                data: clusterHotels,
                total: data.total_count,
            };
        }

        const { data } = await this.apiService.get('rate/cluster', params);

        const clusterHotels = plainToClass(
            ClusterHotelsRatesModel,
            <ClusterHotelsRatesModel[]> data.data,
            { excludeExtraneousValues: true },
        );

        const totalCount = data.total_count;

        return { data: clusterHotels, total: totalCount };
    }

    async getMarketsClusterData(params: Params, chainPair: ChainPair = null): Promise<{ data: ClusterHotelsMarketsModel[], total: number } | null> {
        if (chainPair) {
            const newParams = {
                ...params,
                grouped_by: chainPair.group,
                grouped_by_value: chainPair.value,
                // skip: undefined,
                // limit: undefined,
                'sort[score.mv]': undefined,
            };

            const { data } = await this.apiService.get('market/chain-hotels', newParams);

            const clusterHotels = plainToClass(
                ClusterHotelsMarketsModel,
                <ClusterHotelsMarketsModel[]> data,
                { excludeExtraneousValues: true },
            );

            return {
                data: clusterHotels,
                total: data.length,
            };
        }

        const { data } = await this.apiService.get('market/cluster', params);

        const clusterHotels = plainToClass(
            ClusterHotelsMarketsModel,
<ClusterHotelsMarketsModel[]> data.data,
{ excludeExtraneousValues: true },
        );

        const totalCount = data.total_count;

        return { data: clusterHotels, total: totalCount };
    }

    async getGuestReviewsData(params: Params): Promise<{ data: ClusterRankingModel[], total: number } | null> {
        const paramsData = { ...params, isVisibilityPage: false };
        const { data } = await this.apiService.get('market/cluster', paramsData);

        const clusterHotels = plainToClass(
            ClusterRankingModel,
<ClusterRankingModel[]> data.data,
{ excludeExtraneousValues: true },
        );

        const totalCount = data.total_count;

        return { data: clusterHotels, total: totalCount };
    }

    async getMarketsExcelDocument(provider: string, startDate: string, endDate: string) {
        event('download_excel', {
            userId: this.userService.id,
            chainId: this.userService.chainId,
        });

        const { data } = await this.apiService.get(
            `market/cluster-excel/${provider}/${startDate}/${endDate}`,
            {},
        );
        if (!data) {
            return null;
        }

        return plainToClass(DownloadExcelModel, <DownloadExcelModel> data, { excludeExtraneousValues: true });
    }

    async getGuestReviewsExcelDocument() {
        event('download_excel', {
            userId: this.userService.id,
            chainId: this.userService.chainId,
        });

        const { data } = await this.apiService.post(
            'market/excel-cluster-guest-reviews',
            {},
        );

        if (!data) {
            return null;
        }

        return plainToClass(DownloadExcelModel, <DownloadExcelModel> data, { excludeExtraneousValues: true });
    }

    async getClusterPos() {
        const { data } = await this.apiService.get('/users/available-pos');
        if (!data) {
            return [] as string[];
        }

        return data as string[];
    }

    async getClusterExcelDocument(provider: string, period:string[], columns: { name: string, value: boolean }[], sendToEmail?: boolean) {
        const query = (columns as {
            name: string;
            value: boolean;
        }[]).reduce((acc, column) => {
            let param = !acc.length ? '?' : '&';
            param += `columnsOptions[${column.name}]=${column.value}`;
            return acc + param;
        }, '');

        let url = `rate/cluster-excel/${provider}/${period[0]}/${period[1]}${query}`;
        if (sendToEmail) {
            url += '&send_to_email=true';
        }

        event('download_excel', {
            userId: this.userService.id,
            chainId: this.userService.chainId,
        });

        const res = await this.apiService.get(url, {}).catch(() => null);

        if (!res || !res.data) {
            return null;
        }

        return plainToClass(DownloadExcelModel, <DownloadExcelModel>res.data, { excludeExtraneousValues: true });
    }
}
