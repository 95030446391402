import { Inject, injectable } from 'inversify-props';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import MarketApiService, { MarketApiServiceS } from './market-api.service';
import MarketStore from './store/market.store';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';

export const MarketServiceS = Symbol.for('MarketServiceS');
@injectable(MarketServiceS as unknown as string)
export default class MarketService implements Stateable {
    @Inject(MarketApiServiceS) private marketApiService!: MarketApiService;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;

    readonly storeState: MarketStore = this.storeFacade.getState('MarketStore');

    async loadData(marketId: number) {
        const name = await this.marketApiService.getMarketById(marketId);
        this.storeState.marketNames[marketId] = name;
        return true;
    }

    marketNameById(marketId: number) {
        const name = this.storeState.marketNames[marketId];

        if (name === undefined && !this.storeState.loading.isLoading()) {
            this.storeState.loading.reset();
        }

        this.helperService.dynamicLoading(this.storeState.loading, async () => {
            const loaded = await this.loadData(marketId);
            return loaded;
        });

        return name;
    }
}
