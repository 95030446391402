import { Route } from 'vue-router';
import { CommonMenuRoutes } from './common.routes';

export default function SettingsPageRoutes() {
    return {
        path: '/settings/:hotelId?',
        component: () => import('@/modules/common/pages/hotel-transition.page.vue'),
        props: (r: Route) => ({
            hotelId: Number(r.params.hotelId),
        }),

        children: [
            {
                name: 'settings',
                component: () => import('@/modules/settings/pages/index.page.vue'),
                path: '',
                children: [
                    ...CommonMenuRoutes('settings'),
                ],
            },
        ],
    };
}
