import { Expose, Transform } from 'class-transformer';
import { IsNumber } from 'class-validator';

export default class FleetAvailabilityItemModel {
    @Expose()
    @Transform((_, plain) => Number(plain.available_cars))
    @IsNumber()
    availableCars!: number;

    @Expose()
    @Transform((_, plain) => Math.round(Number(plain.fleet_size)))
    @IsNumber()
    fleetSize!: number;
}
