import { Expose, Transform } from 'class-transformer';

export interface IPickUpDates {
    [dayNumber: string]: {
        [vendor: string]: {
            [carCategory: string]: {
                [transmission: string]: {
                    [mileagePolicy: string]: {
                        [rateType: string]: number;
                    },
                },
            },
        };
    };
}

export default class FleetDocumentModel {
    @Expose()
    @Transform((_, plain) => {
        const providers: { [provider: string]: { pickupDates: IPickUpDates } } = { };
        Object.keys(plain).forEach(provider => {
            providers[provider] = { pickupDates: { } };
            providers[provider].pickupDates = plain[provider].pickup_dates;
        });
        return providers;
    })
    providers!: {
        [provider: string]: {
            pickupDates: IPickUpDates,
        },
    };
}
