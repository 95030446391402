import Vue from 'vue';
import { Inject, injectable } from 'inversify-props';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import NotificationStore from '@/modules/common/modules/custom-notification/store/notification.store';
import NotificationItemModel from '@/modules/common/modules/custom-notification/models/notification-item.model';
import SocketService, { SocketServiceS } from '@/modules/common/modules/socket/socket.service';
import CustomNotificationApiService, { CustomNotificationApiServiceS }
    from '@/modules/common/modules/custom-notification/custom-notification-api.service';
import NotificationSocketModel from '@/modules/common/modules/custom-notification/models/notification-socket.model';
import DownloadExcelModel from '@/modules/rates/models/download-excel.model';
import ExcelProgressSocketModel from '@/modules/common/modules/custom-notification/models/excel-progress-socket.model';
import UserService, { UserServiceS } from '@/modules/user/user.service';

export const CustomNotificationServiceS = Symbol.for('CustomNotificationServiceS');

export interface INotificationOtions {
    isReady: boolean;
    isFailed: boolean;
    fileName?: string;
}

@injectable(CustomNotificationServiceS as unknown as string)
export default class CustomNotificationService {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(SocketServiceS) private socketService!: SocketService;
    @Inject(CustomNotificationApiServiceS) private customNotificationApiService!: CustomNotificationApiService;
    @Inject(UserServiceS) private userService!: UserService;

    readonly storeState: NotificationStore = this.storeFacade.getState('NotificationStore');

    constructor() {
        this.socketService.onExcelReady(this.findNotification.bind(this));
        this.socketService.onUpdateProgressExcel(this.updateProgress.bind(this));
        this.socketService.onExcelFailed(this.createExcelGenerateFailedNotification.bind(this));
    }

    createExcelNotification(data: NotificationSocketModel | DownloadExcelModel, isReady: boolean = false, isFailed = false) {
        const options = new NotificationItemModel();
        this.pushExcel({ ...options, ...data, isReady, isFailed });
    }

    findNotification(data: NotificationSocketModel, options: INotificationOtions = { isReady: true, isFailed: false }) {
        const index = this.storeState.items.findIndex(item => +item.reportId === +data.reportId);

        if (index === -1) {
            this.createExcelNotification(data, options.isReady, options.isFailed);
            return;
        }
        const foundItem = this.storeState.items[index];
        if (options.fileName) {
            foundItem.fileName = options.fileName;
        }
        Vue.set(this.storeState.items, index, { ...foundItem, isReady: options.isReady, progress: 100, isFailed: options.isFailed });
    }

    updateProgress(data: ExcelProgressSocketModel) {
        const index = this.storeState.items.findIndex(item => item.reportId === Number(data.reportId));
        if (index === -1) {
            return;
        }
        const foundItem = this.storeState.items[index];
        Vue.set(this.storeState.items, index, { ...foundItem, progress: data.progress });
    }

    pushExcel(optionsParams: NotificationItemModel) {
        if (Vue.prototype.$isServer) {
            return;
        }
        const options = optionsParams;
        const id = `notification-custom_${this.storeState.notificationSeed++}`;
        const position = options.position || 'top-middle';
        const verticalOffset = options.verticalOffset || 16;
        const defaultParams = new NotificationItemModel();

        const instance = {
            ...defaultParams,
            id,
            position,
            verticalOffset,
            clientHeight: verticalOffset,
            reportId: options.reportId,
            fileName: options.fileName,
            isReady: options.isReady,
            type: options.type,
            progress: options.isReady || options.isFailed ? 100 : 0,
            isFailed: options.isFailed,
        };
        this.notifications.push(instance);
    }

    get notifications() {
        return this.storeState.items;
    }

    async handleExcel(excelData: DownloadExcelModel) {
        if (!excelData.isReady) {
            this.createExcelNotification(excelData);
            return;
        }
        await this.getExcelFile(excelData.reportId, excelData.fileName);
    }

    async getExcelFile(reportId: number, name: string) {
        const { isCarUser } = this.userService;
        const blobData = await this.customNotificationApiService.getExcelDocument(reportId, isCarUser);

        if (blobData === null) {
            return false;
        }

        // It is needed to open download file window in browser
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blobData);
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        return true;
    }

    close(id: string, func: Function | null = null) {
        const { length } = this.notifications;
        const index = this.notifications.findIndex((item: { id: string; }) => item.id === id);
        if (index === -1) return;

        const element = this.notifications[index];
        this.notifications.splice(index, 1);

        if (length <= 1) {
            return;
        }

        if (func) {
            func();
        }
        const { position } = element;
        const removedHeight = element.clientHeight;
        for (let i = index; i < length - 1; i++) {
            if (this.notifications[i].position === position) {
                this.notifications[i].verticalOffset = this.notifications[i].verticalOffset - removedHeight - 16;
            }
        }
    }

    createExcelGenerateFailedNotification(data: NotificationSocketModel) {
        const { isFailed } = data;
        if (isFailed) {
            const options: INotificationOtions = {
                isReady: false,
                isFailed: true,
                fileName: data.fileName,
            };
            this.findNotification(data, options);
        }
    }
}
