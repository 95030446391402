import { Expose, Transform } from 'class-transformer';
import {
    IsNumber, IsBoolean,
} from 'class-validator';

export default class DownloadExcelModel {
    @Expose()
    // eslint-disable-next-line
    @Transform((_, plain) => plain.file_name)
    @IsNumber()
    fileName!: string;

    @Expose()
    // eslint-disable-next-line
    @Transform((_, plain) => Number(plain.report_id))
    @IsNumber()
    reportId!: number;

    @Expose()
    // eslint-disable-next-line
    @Transform((_, plain) => plain.is_exists)
    @IsBoolean()
    isReady: boolean = false;
}
