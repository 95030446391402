import { Expose, Transform } from 'class-transformer';
import { IsNumber, IsOptional, IsString } from 'class-validator';

export default class HotelCatalogItemModel {
    @Expose()
    @IsNumber()
    @Transform((_, plain) => plain.fornovaId)
    id!: number;

    @Expose()
    @IsString()
    @Transform((_, plain) => plain.name)
    hotelName!: string;

    @Expose()
    @Transform((_, plain) => {
        if (plain.geoLocation) {
            return {
                lat: plain.geoLocation.latitude,
                lng: plain.geoLocation.longitude,
            };
        }
        return null;
    })
    @IsOptional()
    geoLocation?: {
        lat: number,
        lng: number,
    };
}
