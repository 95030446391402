import { Expose, Transform } from 'class-transformer';
import deepMapKeys from 'deep-map-keys';
import {
    Validate, IsNumber, IsInt, Min, Max, IsString,
} from 'class-validator';

export default class RankingDocumentItem {
    @Expose()
    @IsString()
    @Transform((_, plain) => plain.hotelName || plain.hotel_name)
    hotelName!: string;

    @Expose()
    @IsNumber()
    rating!: number;

    @Expose()
    @Transform((_, plain) => plain.total_reviwes)
    @IsInt()
    reviews!: number;

    @Expose()
    @Transform((_, plain) => plain.rating_improved)
    @IsInt()
    @Min(-1)
    @Max(1)
    ratingImproved!: -1 | 0 | 1;

    @Expose()
    @Transform((_, plain) => plain.total_difference)
    @IsNumber()
    totalDifference?: number;

    @Expose()
    @Transform((_, plain) => plain.link)
    @IsNumber()
    link!: string;
}
