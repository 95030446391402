import { Expose, Transform } from 'class-transformer';

export default class BrokerDataModel {
    @Expose()
    @Transform((_, plain) => plain.name)
    brokerName!: string;

    @Expose()
    @Transform((_, plain) => Math.round(plain.distribution_health * 100))
    health!: number;

    @Expose()
    @Transform((_, plain) => {
        const values = Object.values(plain.bml);
        if (values.length === 0) {
            return {
                B: 0,
                M: 0,
                L: 0,
                A: 0,
                ND: 100,
            };
        }
        // @ts-ignore
        const total = values.reduce((accumulator: number, currentValue: number) => accumulator + currentValue);
        const newData: any = {};
        Object.entries(plain.bml).forEach(value => {
            // @ts-ignore
            newData[value[0]] = Number((value[1] / total) * 100).toFixed(2);
        });
        return newData;
    })
    bml!: object;

    @Expose()
    @Transform((_, plain) => (
        Object.entries(plain.bml).reduce((acc: number, item: any) => {
            if (item && item[1]) {
                const value = item[1] as number;
                return acc + value;
            }
            return acc;
        }, 0)
    ))
    total!: number;

    @Expose()
    @Transform((_, plain) => plain.severe_issues)
    severeIssues!: number;

    @Expose()
    @Transform((_, plain) => plain.allocation)
    allocation!: string;

    @Expose()
    @Transform((_, plain) => plain.latest_bml_scan_date)
    latestBmlScanDate!: Date;
}
