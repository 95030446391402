import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import use from '@/router/use';
import { container } from 'inversify-props';
import { RouteConfig } from 'vue-router';
import RankingClusterLevelRoutes from '../cluster/ranking.routes';

export default function RankingChainLevelRoutes(): RouteConfig {
    return {
        path: 'ranking',
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS)]),
        children: [
            // NOTE: Make chain ranking
            // {
            //     path: '',
            //     name: 'chain.ranking',
            //     component: () => import('@/modules/cluster/pages/ranking-cluster.page.vue'),
            //     children: [
            //         ...CommonMenuRoutes('chain.ranking'),
            //     ],
            // },

            ...RankingClusterLevelRoutes('', 'chain.ranking').children!,
        ],
    };
}
