import { Expose, plainToClass, Transform } from 'class-transformer';

import SippLogChangeItemModel from '@/modules/cars/modules/sipp/models/sipp-log-change-item.model';

export default class SippLogItemModel {
    @Expose()
    @Transform((_, plain) => (
        plainToClass(SippLogChangeItemModel, <SippLogChangeItemModel[]> plain.changes, { excludeExtraneousValues: true })
    ))
    changes!: SippLogChangeItemModel[];

    @Expose()
    @Transform((_, plain) => plain.version)
    version!: number;

    @Expose()
    @Transform((_, plain) => plain.update_timestamp && new Date(plain.update_timestamp))
    updateDate!: Date;

    @Expose()
    @Transform((_, plain) => plain.updated_by)
    email!: string;
}
