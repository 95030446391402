enum FEATURES {
    HOME ='home',
    RATE ='rate',
    MARKET='market',
    GUEST_REVIEW = 'guest_review',
    EVENTS = 'events',
    DEEP_COMPSET = 'deep_compset',
    LITE_DI = 'lite_di',
    PROMOTION_DETECTION = 'promotion_detection',
}

export default FEATURES;
