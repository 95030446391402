import CAR_PRICE_TYPE from './car-price-type.constant';

const CAR_ASSESSMENTS_ITEMS = {
    [CAR_PRICE_TYPE.LOWEST]: {
        from: [0, -0.01, -0.05, -0.1, -0.15, -0.2, -0.3, -0.5],
        to: [0.5, 0.3, 0.2, 0.15, 0.10, 0.05, 0.01, 0],
    },
    [CAR_PRICE_TYPE.MEDIAN]: {
        from: [0.14, 0.10, 0.05, 0.01, 0, -0.01, -0.05, -0.10, -0.14],
        to: [0.20, 0.10, 0.05, 0.01, 0, -0.01, -0.05, -0.10, -0.20],
    },
    [CAR_PRICE_TYPE.HIGHEST]: {
        from: [0, -0.01, -0.05, -0.1, -0.15, -0.2, -0.3, -0.5],
        to: [0.5, 0.3, 0.2, 0.15, 0.10, 0.05, 0.01, 0],
    },
};

export default CAR_ASSESSMENTS_ITEMS;
