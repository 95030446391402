/* eslint-disable camelcase */
import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { AxiosRequestConfig } from 'axios';
import { event } from 'vue-gtag';

import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import ScanDisabledProviders from '@/modules/common/modules/rates/constants/scan-disabled-providers.enum';
import DownloadExcelModel from '@/modules/rates/models/download-excel.model';
import ExcelQueryParams from './dto/excel-query-params.dto';
import RatesTriggerScanModel from './models/rates-trigger-scan.model';
import RatesScanStatusModel from './models/rates-scan-status.model';
import Day from '../common/types/day.type';
import RatesDocumentIntradayModel from './models/rates-document-intraday.model';
import ClusterHotelsRatesModel from '../cluster/models/cluster-rates.model';
import ChainSettingsModel from '../chain/models/chain-settings.model';
import { IClusterRatesList } from '../chain/interfaces/cluster-list.interface';
import Item from '../common/interfaces/item.interface';
import UserService, { UserServiceS } from '../user/user.service';

export interface RequestFilters {
    statistics: boolean;
    requested_currency?: string;
    [k: string]: any;
}

type UnitedSettings = RatesSettingsModel & DocumentFiltersModel & { [k: string]: any };

export enum RatesRequestMode {
    DILITE,
    ANALYSIS,
    NONE,
}

export interface RatesDownloadExcelForm {
    compsetId: string;
    competitors: number[];
    provider: string;
    pos: string;
    los: number;
    numberOfGuests: number;
    priceType: Item[];
    roomTypeId: Item[];
    mealTypeId: Item[];
    monthrange: string[];
    priceShown: string;
    displayCurrency: string;
    fornovaId?: number;
    compareTo?: string;
    compareValue?: any;

    columns: {
        market_demand: boolean;
        occupancy: boolean;
        rank: boolean;
        diff_delta: boolean;
        diff_precent: boolean;
        median: boolean;
        mealType: boolean;
        roomType: boolean;
        roomName: boolean;
    }
}

export interface RatesDownloadExcelPayload {
    providers: (string | number)[];
    los: (string | number)[];
    pos: (string | number)[];
    meal_type: (string | number)[];
    room_type: (string | null)[];
    price: (string | number)[];
    occupancy: number;
    price_type: string;
    extra_columns: {
        mealType: boolean;
        roomType: boolean;
        roomName: boolean;
    };
    columnsOptions: {
        [key: string]: boolean;
    };
    comparison_to?: {
        key: string;
        value: any;
    };
    requested_currency?: string;
    send_to_email?: boolean;
}

export const RatesApiServiceS = Symbol.for('RatesApiServiceS');
@injectable(RatesApiServiceS as unknown as string)
export default class RatesApiService {
    @Inject(ApiServiceS)
    private apiService!: ApiService;

    @Inject(RoomTypesServiceS)
    private roomTypesService!: RoomTypesService;

    @Inject(MealTypesServiceS)
    private mealTypesService!: MealTypesService;

    @Inject(UserServiceS)
    private userService!: UserService;

    async getRatesDocument(
        settings: UnitedSettings,
        currency?: string | null,
        additionalQuery: { [k: string]: any } = {},
        mode: RatesRequestMode = RatesRequestMode.NONE,
    ): Promise<RatesDocumentModel | RatesDocumentAllModel | null> {
        // eslint-disable-next-line camelcase
        const query: RequestFilters = {
            statistics: true,
            'filters[price]': settings.priceType,
            ...additionalQuery,
        };

        if (settings.roomTypeId !== -1) {
            query['filters[room_type]'] = this.roomTypesService
                .getRoomType(settings.roomTypeId)!.name;
        }

        if (settings.mealTypeId !== -1) {
            query['filters[meal_type]'] = this.mealTypesService
                .getMealType(settings.mealTypeId)!.name;
        }

        if (currency) {
            query.requested_currency = currency;
        }

        if (mode === RatesRequestMode.DILITE) {
            if (settings.device) {
                query['filters[device_name]'] = settings.device;
            }
        }

        if (mode === RatesRequestMode.ANALYSIS) {
            delete query.statistics;
        }

        const params = [
            settings.compsetId,
            settings.year,
            settings.month + 1,
            settings.provider,
            settings.los,
            settings.pos,
            settings.numberOfGuests,
        ].join('/');

        const { data } = await this.apiService.get(
            `/rate/${params}`,
            query,
        );

        if (!data) {
            return null;
        }

        if (settings.provider === ScanDisabledProviders.ALL) {
            const ratesDocument = plainToClass(RatesDocumentAllModel, <RatesDocumentAllModel>data, { excludeExtraneousValues: true });
            return ratesDocument;
        }

        const ratesDocument = plainToClass(RatesDocumentModel, <RatesDocumentModel>data, { excludeExtraneousValues: true });

        return ratesDocument;
    }

    async getRatesAnalysisDocument(
        settings: UnitedSettings,
        key: string,
        value: number | string,
        currency?: string | null,
    ) {
        const query = key === 'diffDays'
            ? { diff_days: value }
            : {};

        if (key !== 'diffDays') {
            // eslint-disable-next-line no-param-reassign
            settings[key] = value;
        }

        return this.getRatesDocument(settings, currency, query, RatesRequestMode.ANALYSIS);
    }

    async getExcelDocument(form: RatesDownloadExcelForm, toEmail = false, onDemand = false) {
        const {
            roomTypeId,
            numberOfGuests,
            displayCurrency,
            columns,
            compareTo,
            compareValue,
            monthrange,
            priceShown,
            mealTypeId,
            priceType,
            compsetId,
            provider,
            los,
            pos,
            fornovaId,
        } = form;

        const parceCompareToKey = (key: string): string => {
            const keys = {
                diffDays: 'past period',
                provider: 'source',
                roomTypeId: 'roomType',
                mealTypeId: 'mealType',
                numberOfGuests: 'number of guest',
                priceType: 'price',
            } as { [k: string]: any };

            return keys[key] || key;
        };

        const {
            mealType: mealTypeColumn,
            roomType: roomTypeColumn,
            roomName: roomNameColumn,
            market_demand,
            occupancy,
            rank,
            diff_delta,
            diff_precent,
            median,
        } = columns || {} as RatesDownloadExcelForm['columns'];

        const mealTypeName = mealTypeId
            .map(val => val.value)
            .filter(meal => !!meal);

        const roomName = roomTypeId
            .map(val => val.name)
            .filter(room => !!room);

        const url = onDemand
            ? `scan/trigger-to-send-report/by-compset-id/${compsetId}/${monthrange![0]}/${monthrange![1]}`
            : `rate/excel/${compsetId}/${monthrange![0]}/${monthrange![1]}/${fornovaId}`;

        const body: RatesDownloadExcelPayload = {
            providers: [provider],
            los: [los],
            pos: [pos],
            meal_type: mealTypeName,
            room_type: roomName,
            price: priceType.map(p => p.value),
            occupancy: numberOfGuests,
            price_type: `${priceShown.toLocaleLowerCase()}_price`,
            extra_columns: {
                mealType: mealTypeColumn,
                roomType: roomTypeColumn,
                roomName: roomNameColumn,
            },
            columnsOptions: {
                market_demand,
                occupancy,
                rank,
                diff_delta,
                diff_precent,
                median,
            },
        };

        if (toEmail) {
            body.send_to_email = true;
        }

        if (displayCurrency) {
            body.requested_currency = displayCurrency;
        }

        if (compareTo) {
            // eslint-disable-next-line
            body.comparison_to = {
                key: parceCompareToKey(compareTo),
                value: compareValue,
            };

            if (compareTo === 'diffDays') {
                body.comparison_to.value = -compareValue;
            }

            if (compareTo === 'roomTypeId') {
                const roomType = this.roomTypesService.getRoomType(compareValue as number);
                body.comparison_to.value = roomType ? roomType.name : '';
            }

            if (compareTo === 'mealTypeId') {
                const mealType = this.mealTypesService.getMealType(compareValue as number);
                body.comparison_to.value = mealType ? mealType.name : '';
            }

            delete body.extra_columns;
            delete body.columnsOptions;
        }

        const isCompareMode = !!body.comparison_to;

        const excelReqUrl = isCompareMode
            ? 'rate/excel-compare_mode'
            : url;

        event('download_excel', {
            chainId: this.userService.chainId,
            userId: this.userService.id,
        });

        const res = await this.apiService
            .post(excelReqUrl, body);

        if (!res || !res.data) {
            return null;
        }

        return plainToClass(DownloadExcelModel, <DownloadExcelModel> res.data, { excludeExtraneousValues: true });
    }

    async getAnalysisExcelDocument(settings: ExcelQueryParams, compareTo: { key: string, value: string | number }) {
        const {
            compsetId,
            providers,
            mealTypeId,
            priceType,
            roomTypeId,
            numberOfGuests,
            los,
            pos,
            monthrange,
            priceShown,
            displayCurrency,
        } = settings;

        const url = 'rate/excel-compare_mode';

        const body: {
            compset_id: string
            providers: (string | number)[]
            los: (string | number)[]
            pos: (string | number)[]
            meal_type: (string | undefined)[]
            room_type: (string | null)[]
            price: (string | number)[]
            occupancy: number
            price_type: string
            start_date: string
            end_date: string
            comparison_to: {
                key: string
                value: string | number
            }
            requested_currency?: string
        } = {
            compset_id: compsetId || '',
            providers,
            los,
            pos,
            meal_type: mealTypeId as string[],
            room_type: roomTypeId as string[],
            price: priceType,
            occupancy: numberOfGuests,
            price_type: `${priceShown.toLocaleLowerCase()}_price`,
            comparison_to: compareTo,
            start_date: monthrange![0],
            end_date: monthrange![1],
        };

        if (displayCurrency) {
            body.requested_currency = displayCurrency;
        }

        event('download_excel', {
            chainId: this.userService.chainId,
            userId: this.userService.id,
        });

        const res = await this.apiService.post(
            url,
            body,
            { responseType: 'blob' },
        ).catch(() => null);

        if (!res || !res.data) {
            return null;
        }

        return res.data as Blob;
    }

    async getClusterList(settings: ChainSettingsModel & DocumentFiltersModel) {
        const query = {
            grouped_by: settings.groupBy,
            provider_name: settings.provider,
            year: settings.year,
            month: settings.month,
        };

        const { data } = await this.apiService.get('rate/chain', query);

        return data as IClusterRatesList;
    }

    async getChainHotels(settings: RatesSettingsModel & DocumentFiltersModel) {
        const query = {
            grouped_by: settings.groupBy,
            grouped_by_value: settings.groupValue,
            month: settings.month,
            year: settings.year,
            provider_name: settings.provider || 'booking',
        };

        const { data } = await this.apiService.get('/rate/chain-hotels', query);

        return plainToClass(
            ClusterHotelsRatesModel,
            <ClusterHotelsRatesModel[]> data,
            { excludeExtraneousValues: true },
        );
    }

    async triggerScan(documentSettings: DocumentFiltersModel, ratesSettings: RatesSettingsModel, startDate: Date, endDate?: Date) {
        const { compsetId, los, pos } = documentSettings;
        const { provider, numberOfGuests } = ratesSettings;

        const { data } = await this.apiService.post('/scan/trigger/by-compset-id', {
            ondemand: 'true',
            compSetIds: [compsetId],
            pos: [pos],
            los: [los],
            providers: [provider],
            number_of_guests: numberOfGuests, // TODO discuss: do we need it?
            start_date: `${startDate.getFullYear()}-${(`0${startDate.getMonth() + 1}`).slice(-2)}-${(`0${startDate.getDate()}`).slice(-2)}`,
            end_date: endDate && `${endDate.getFullYear()}-${(`0${endDate.getMonth() + 1}`).slice(-2)}-${(`0${endDate.getDate()}`).slice(-2)}`,
        });

        return plainToClass(RatesTriggerScanModel, <RatesTriggerScanModel>data, { excludeExtraneousValues: true });
        /*
        {
            "compSetIds":["5e98706358686c4a4cd34680"],
            "ondemand":"true",
            "pos":["US"],
            "los":[1,3],
            "providers":["booking"],
            "number_of_guests":2,
            "start_date":"2020-05-03T08:40:18+0000",
            "end_date":"2020-05-03T08:40:18+0000"
        }
         */
    }

    async logScanFail(documentSettings: DocumentFiltersModel, ratesSettings: RatesSettingsModel, startDate: Date, endDate?: Date) {
        const { compsetId, los, pos } = documentSettings;
        const { provider, numberOfGuests } = ratesSettings;

        await this.apiService.post('/scan/log-fail', {
            ondemand: 'true',
            compSetIds: [compsetId],
            pos: [pos],
            los: [los],
            providers: [provider],
            number_of_guests: numberOfGuests, // TODO discuss: do we need it?
            start_date: `${startDate.getFullYear()}-${(`0${startDate.getMonth() + 1}`).slice(-2)}-${(`0${startDate.getDate()}`).slice(-2)}`,
            end_date: endDate && `${endDate.getFullYear()}-${(`0${endDate.getMonth() + 1}`).slice(-2)}-${(`0${endDate.getDate()}`).slice(-2)}`,
        });

        return true;
    }

    async checkScanStatus(docId: number, scanId: string) {
        const { data } = await this.apiService.get(`/rate/scan/status/${docId}/${scanId}`);
        return plainToClass(RatesScanStatusModel, <RatesScanStatusModel>data, { excludeExtraneousValues: true });
    }

    async getIntradayPrice(
        day: Day,
        fornovaId: number,
        settings: DocumentFiltersModel & RatesSettingsModel,
        currency?: string | null,
    ) {
        const { compsetId, year, month } = settings;
        const { provider, numberOfGuests } = settings;
        const { pos, los } = settings;

        const params = [
            compsetId,
            day,
            year,
            month + 1,
            provider,
            los,
            pos,
            numberOfGuests,
            fornovaId,
        ].join('/');

        // eslint-disable-next-line camelcase
        const query: RequestFilters = {
            statistics: true,
            'filters[price]': settings.priceType,
        };

        if (settings.roomTypeId !== -1) {
            query['filters[room_type]'] = this.roomTypesService
                .getRoomType(settings.roomTypeId)!.name;
        }

        if (settings.mealTypeId !== -1) {
            query['filters[meal_type]'] = this.mealTypesService
                .getMealType(settings.mealTypeId)!.displayName;
        }

        if (currency) {
            query.requested_currency = currency;
        }

        const { data } = await this.apiService
            .get(`/rate/previous-price/${params}`, query);

        if (!data) {
            return null;
        }

        return plainToClass(
            RatesDocumentIntradayModel,
            <RatesDocumentIntradayModel>data,
            { excludeExtraneousValues: true },
        );
    }

    async resendScheduledReport(level: 'hotel' | 'cluster', schedulerId: number) {
        const endpoint = {
            hotel: `/rate/excel/by-scheduler-config-id/${schedulerId}`,
            cluster: `/rate/cluster-excel/by-scheduler-config-id/${schedulerId}`,
        };

        const { data } = await this.apiService.post(endpoint[level], {});

        if (!data) return null;

        return data;
    }

    async resendHTMLReport(hotelId: number, scanDate: string) {
        const { user } = this.userService;

        if (!user) return null;

        const { email } = user;
        const { data } = await this.apiService
            .get(`/rate/html-email-report/${hotelId}/${email}?scan_date=${scanDate}`);

        if (!data) return null;

        return data;
    }
}
