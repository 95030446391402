import LoadingModel from '@/modules/common/models/loading.model';
import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import SHOW_BY from '@/modules/deep-analysis/constants/show-by-filter.constant';
import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';
import VIEW_TYPE from '@/modules/deep-analysis/constants/view-type-filter.constant';
import DeepAnalysisClusterItemModel from '@/modules/deep-analysis/models/deep-analysis-cluster-item.model';
import { Module, VuexModule } from 'vuex-module-decorators';
import TREND_TYPE from '../constants/trend-type.constant';

@Module
export default class DeepAnalysisClusterStore extends VuexModule {
    items: DeepAnalysisClusterItemModel[] = [];
    loading: LoadingModel = new LoadingModel();

    totalCount: number | null = null;
    limit: number = 10;
    skip: number = 0;

    sortingKey: STATISTIC_TYPE | null = null;
    sortingType: 1 | -1 = 1;

    showBy: SHOW_BY = 'stay.7';
    provider : string = 'all';
    compareTo : COMPARE_TO= COMPARE_TO.COMPSET_AVG;
    view: VIEW_TYPE = VIEW_TYPE.ABSOLUTE;
    filterByStatistic: [STATISTIC_TYPE | null, TREND_TYPE | null] = [null, null];
}
