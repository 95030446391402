/* eslint-disable camelcase */
import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import moment from 'moment';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import ScheduledItemModel from './models/scheduled-item.model';
import { DATA_TYPE, TIME_BUFFER } from './constants';
import {
    IForm, IRecipient, ISchedulerConfig,
} from './interfaces';

export const ReportsApiServiceS = Symbol.for('ReportsApiServiceS');
@injectable(ReportsApiServiceS as unknown as string)
export default class ReportsApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;

    private mapDataType(dataType: DATA_TYPE) {
        switch (dataType) {
            case DATA_TYPE.RATES_CLUSTER:
            case DATA_TYPE.RATES: return 'rate';
            case DATA_TYPE.MARKETS_CLUSTER:
            case DATA_TYPE.MARKETS: return 'market';
            case DATA_TYPE.RANKING_CLUSTER:
            case DATA_TYPE.RANKING: return 'guestReviews';
            case DATA_TYPE.DI_LITE: return 'DILight';
            case DATA_TYPE.RATES_COMPARE: return 'ratesCompareMode';
            default: throw new Error('Wrong data type!');
        }
    }

    // Is needed for BE
    private mapServiceType(dataType: DATA_TYPE) {
        switch (dataType) {
            case DATA_TYPE.DI_LITE:
            case DATA_TYPE.RATES_COMPARE:
            case DATA_TYPE.RATES_CLUSTER:
            case DATA_TYPE.RATES: return 'rate';
            case DATA_TYPE.MARKETS:
            case DATA_TYPE.MARKETS_CLUSTER:
            case DATA_TYPE.RANKING_CLUSTER:
            case DATA_TYPE.RANKING: return 'market';
            default: throw new Error('Wrong data type!');
        }
    }

    mapSettings(form: IForm) {
        const camelToUnderscore = (key: string) => key.replace(/([A-Z])/g, '_$1').toLowerCase();

        const filters: { [key: string]: (number | string) | (number | string)[] | { [key: string]: boolean } } = Object.fromEntries(
            Object.entries(form.filters)
                .filter(([key, value]) => key !== 'compset' && value)
                .map(([key, value]) => [camelToUnderscore(key), value] as [string, (string | number) | (string | number)[]]),
        );

        const columnsOptions = Object.entries(form.customColumns as { [key: string]: boolean }).reduce((acc, [key, value]) => ({
            ...acc,
            [key]: value,
        }), {} as {
            [key: string]: boolean;
        });

        const configObject: { [key: string]: string | number } = {};
        Object.entries(form.frequency as { [key: string]: string | number | undefined })
            .filter(([key, value]) => value !== undefined)
            .forEach((([key, value]) => {
                configObject[camelToUnderscore(key)] = value as string | number;
            }));
        const date = moment({ hour: Number(configObject.hour) }).subtract(TIME_BUFFER, 'minutes');
        configObject.hour = date.hour();
        configObject.minute = date.minute();

        const body: { [key: string]: any } = {
            compset_id: form.filters.compset,
            type: this.mapDataType(form.properties.dataType as DATA_TYPE),
            service_type: this.mapServiceType(form.properties.dataType as DATA_TYPE),
            scheduler_config: configObject,
            subscribed_recipients_emails: Object.entries(form.recipients).map(r => (r[1] as IRecipient).value),
        };

        if (form.properties.dateRange) {
            body.number_of_days = form.properties.dateRange;
        }

        if (Object.keys(filters).length) {
            body.filters = filters;

            if (Object.keys(columnsOptions).length) {
                body.filters.columnsOptions = columnsOptions;
            }
        }

        return body;
    }

    getUrl(dataType: DATA_TYPE) {
        const isCluster = (() => dataType === DATA_TYPE.RATES_CLUSTER
            || dataType === DATA_TYPE.MARKETS_CLUSTER
            || dataType === DATA_TYPE.RANKING_CLUSTER
            || dataType === DATA_TYPE.CLUSTER_ALL
        )();

        return isCluster ? '/scheduler/cluster-excel-config' : '/scheduler/excel-config';
    }

    async getExcelReports(dataType: DATA_TYPE) {
        let url = this.getUrl(dataType);
        if (dataType !== DATA_TYPE.CLUSTER_ALL && dataType !== DATA_TYPE.HOTEL_ALL) {
            const typeQuery = this.mapDataType(dataType);
            url += `?type=${typeQuery}`;
        }
        const { data } = await this.apiService.get(url);

        const reports = plainToClass(ScheduledItemModel, <ScheduledItemModel[]>data, { excludeExtraneousValues: true });
        return reports
            .sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime())
            .sort((a, b) => (a.type || '').localeCompare(b.type || ''));
    }

    async putExcelReport(id: string, form: IForm) {
        const body = this.mapSettings(form);
        delete body.compset_id;
        delete body.service_type;
        delete body.type;

        const res = await this.apiService.put(`${this.getUrl(form.properties.dataType as DATA_TYPE)}/${id}`, body);
        return res;
    }

    async postExcelReport(form: IForm) {
        const body = this.mapSettings(form);

        const res = await this.apiService.post(this.getUrl(form.properties.dataType as DATA_TYPE), body);
        return res;
    }

    async deleteExcelReport(reportId: string) {
        const res = await this.apiService.delete(`/scheduler/excel-config/${reportId}`);
        return res;
    }
}
