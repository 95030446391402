import { plainToClass } from 'class-transformer';
import { Inject, injectable } from 'inversify-props';
import ApiService, { ApiServiceS } from '../common/services/api.service';
import BranchesModel from './models/branches.model';
import CarsExcelReportModel from './models/cars-excel-report.model';

export const CarsExcelReportApiServiceS = Symbol.for('CarsExcelReportApiServiceS');

@injectable(CarsExcelReportApiServiceS as unknown as string)
export default class CarsExcelReportApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;

    private readonly baseUrl = '/car/excel-report';
    async generateExcelReport(chain: BranchesModel | null | undefined, report: Record<string, any>, isVans: boolean) {
        const body = {
            details: {
                ...report,
            },
        };

        if (chain && chain.chainId) {
            body.details.required.chain_id = chain.chainId;
        }

        if (!body.details.optional) {
            body.details.optional = {};
        }

        body.details.optional.is_vans = isVans;

        const { data } = await this.apiService.post(`${this.baseUrl}/generate-excel-report`, body);

        if (!data) {
            return null;
        }

        return plainToClass(CarsExcelReportModel, <CarsExcelReportModel>data, { excludeExtraneousValues: true });
    }

    async downloadExcelReport(reportId: string) {
        const result = await this.apiService.get(
            `${this.baseUrl}/download-excel-report/${reportId}`,
            {},
            { responseType: 'blob' },
        ).catch(() => null);

        if (!result || !result.data) {
            return null;
        }

        return result.data as Blob;
    }
}
