import { Inject, injectable } from 'inversify-props';
import * as _ from 'lodash';
import RankingApiService, { RankingApiServiceS, RankingDownloadExcelForm } from '@/modules/ranking/ranking-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RankingStore from '@/modules/ranking/store/ranking.store';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import RankingCommonService, { RankingCommonServiceS } from '../common/modules/ranking/ranking-common.service';
import CustomNotificationService, { CustomNotificationServiceS } from '../common/modules/custom-notification/custom-notification.service';

export const RankingServiceS = Symbol.for('RankingServiceS');
@injectable(RankingServiceS as unknown as string)
export default class RankingService {
    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(RankingApiServiceS)
    private rankingApiService!: RankingApiService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @Inject(HelperServiceS)
    private helperService!: HelperService;

    @Inject(ProvidersServiceS)
    private providersService!: ProvidersService;

    @Inject(RankingCommonServiceS)
    private rankingCommonService!: RankingCommonService;

    @Inject(CustomNotificationServiceS)
    private customNotificationService!: CustomNotificationService;

    readonly storeState: RankingStore = this.storeFacade.getState('RankingStore');

    constructor(storeState: RankingStore | null = null) {
        if (storeState) {
            this.storeState = storeState;
        } else {
            this.storeFacade.watch(() => [
                this.documentFiltersService.storeState.settings.compsetId,
                this.providersService.storeState.providers,
                this.userService.currentHotelId,
                this.userService.viewAs,
                // @ts-ignore
            ], ((newValue, oldValue) => {
                const [newCompsetId, newProviders, newCurrentHotelId, newViewAs] = newValue;
                const [oldCompsetId, oldProviders, oldCurrentHotelId, oldViewAs] = oldValue;

                if (newCompsetId === oldCompsetId
                    && newCurrentHotelId === oldCurrentHotelId
                    && newViewAs === oldViewAs
                    && _.isEqual(newProviders, oldProviders)) {
                    return {};
                }

                return this.storeState.loading.reset.call(this.storeState.loading);
            }));
        }
    }

    async loadData(settings?: DocumentFiltersModel): Promise<boolean> {
        if (!settings) {
            // eslint-disable-next-line no-param-reassign, prefer-destructuring
            settings = this.documentFiltersService.storeState.settings;

            if (!settings.compsetId) {
                this.documentFiltersService.resetCompset();
            }
        }

        if (settings.compsetId === null || this.providersService.data === null) {
            return false;
        }

        this.storeState.document = null;

        const rankingDocument = await this.rankingApiService.getRanking(settings);
        if (rankingDocument) {
            this.storeState.document = rankingDocument;
        }

        return true;
    }

    private get currentHotelId() {
        return this.userService.currentHotelId;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.document;
    }

    get providers() {
        return this.rankingCommonService.providers(this.data);
    }

    hotelIds(provider: string) {
        return this.rankingCommonService.hotelIds(this.data, provider);
    }

    hotels(provider: string) {
        return this.rankingCommonService.hotels(this.data, provider);
    }

    currentHotelRank(provider: string) {
        return this.rankingCommonService.currentHotelRank(this.data, provider, this.currentHotelId);
    }

    competitiveHotels(provider: string) {
        return this.rankingCommonService.competitiveHotels(this.data, provider, this.currentHotelId);
    }

    minMaxCompetitiveRating(provider: string): [number, number] | null {
        return this.rankingCommonService.minMaxCompetitiveRating(this.data, provider, this.currentHotelId);
    }

    minMaxCompetitiveReview(provider: string): [number, number] | null {
        return this.rankingCommonService.minMaxCompetitiveReview(this.data, provider, this.currentHotelId);
    }

    async downloadExcel(form: RankingDownloadExcelForm) {
        const excelData = await this.rankingApiService.getExcelDocument(form);

        if (excelData) {
            this.customNotificationService
                .handleExcel(excelData);
        }
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }
}
