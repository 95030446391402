import { Inject, injectable } from 'inversify-props';
import { Route } from 'vue-router';
import { IGuard } from '@/router/interfaces/guard.interface';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import USER_LEVELS from '@/modules/user/constants/user-levels.constant';
import UserNavigationService, { UserNavigationServiceS } from '@/modules/user/user-navigation.service';

export const FeaturesGuardS = Symbol.for('FeaturesGuardS');
@injectable(FeaturesGuardS as unknown as string)
export default class FeaturesGuard implements IGuard {
    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(UserNavigationServiceS)
    private userNavigationService!: UserNavigationService;

    private setViewAs(viewBranch: USER_LEVELS, hotelId: number) {
        const { user } = this.userService;
        if (user) {
            this.userService.currentHotelId = hotelId;
            this.userService.storeState.user = {
                ...user,
                viewAs: viewBranch as USER_LEVELS,
            };
        }
    }

    async canActivate(to: Route) {
        const oldViewAs = this.userService.viewAs;
        const oldHotelId = this.userService.currentHotelId;
        const commonRoutes = ['settings', 'events-manager'];
        const viewBranch = to.fullPath.split('/')[1];
        const isCommonBranch = commonRoutes.includes(viewBranch);

        if (!isCommonBranch) {
            this.setViewAs(viewBranch as USER_LEVELS, +to.params.hotelId);
        }

        const isFeatureAllowed = this.userNavigationService.checkIsRouteAllowed(to.fullPath);

        this.setViewAs(oldViewAs!, oldHotelId!);

        return isFeatureAllowed;
    }
}
