import { Module, VuexModule } from 'vuex-module-decorators';
import HotelModel from '@/modules/hotels/models/hotel.model';
import LoadingModel from '@/modules/common/models/loading.model';

@Module
export default class HotelsStore extends VuexModule {
    hotels: HotelModel[] = [];
    myHotels: HotelModel[] = [];
    missedHotels: HotelModel[] = [];

    loading: LoadingModel = new LoadingModel();
}
