import use from '@/router/use';
import { container } from 'inversify-props';
import Sipp from '@/router/march-demo/sipp';
import { Route } from 'vue-router';
import ClusterOrChainGuard, { ClusterOrChainGuardS } from '../guards/cluster-or-chaine.guard';
import CompsetSettings from '../march-demo/compset-settings';

export function LockedPage(prefix: string) {
    return {
        path: 'locked',
        name: `${prefix}.locked`,
        component: () => import('@/modules/common/pages/locked.page.vue'),
        meta: { modal: true },
    };
}

export function HotelListRoute(prefix: string) {
    return {
        name: `${prefix}.hotel-list`,
        path: 'hotel-list',
        beforeEnter: use([container.get<ClusterOrChainGuard>(ClusterOrChainGuardS)]),
        component: () => import('@/modules/hotels/pages/hotel-list.modal.page.vue'),
        meta: { modal: true },
    };
}

export function RoomMappingRoute(prefix: string) {
    const rootPrefix = `${prefix}.rooms-mapping`;

    return {
        path: 'rooms-mapping',
        name: rootPrefix,
        component: () => import('@/modules/rooms-type-manager/pages/room-mapping.modal.page.vue'),
        meta: { modal: true },
        children: [
            {
                path: 'new-room-type',
                name: `${rootPrefix}.new-room-type`,
                component: () => import('@/modules/room-types/pages/add-edit-room-type.modal.page.vue'),
                meta: { modal: true },
            },
            {
                path: 'edit-room-type/:id',
                name: `${rootPrefix}.edit-room-type`,
                props: (route: Route) => ({ roomTypeId: Number(route.params.id) }),
                component: () => import('@/modules/room-types/pages/add-edit-room-type.modal.page.vue'),
                meta: { modal: true },
            },
            {
                path: 'delete-room-type/:id',
                name: `${rootPrefix}.delete-room-type`,
                props: (route: Route) => ({ roomTypeId: Number(route.params.id) }),
                component: () => import('@/modules/room-types/pages/delete-room.modal.page.vue'),
                meta: { modal: true },
            },
            {
                path: 'confirm-close',
                name: `${rootPrefix}.confirm-close`,
                component: () => import('@/modules/rooms-type-manager/components/confirm-close-type-manager.popup.vue'),
                meta: { modal: true },
            },
        ],
    };
}

export function CommonMenuRoutes(prefix: string) {
    return [
        CompsetSettings(prefix),
        HotelListRoute(prefix),
        RoomMappingRoute(prefix),
        Sipp(prefix),
        LockedPage(prefix),
    ];
}
