import { Expose, Transform } from 'class-transformer';

export default class SippFiltersModel {
    carName!: string | null;
    standardCode!: string | null;
    customCode!: string | null;

    @Expose()
    @Transform((_, plain) => plain.country_code)
    pos!: string[] | null;

    @Expose()
    @Transform((_, plain) => plain.car_category)
    carClasses!: string[] | null;

    @Expose()
    @Transform((_, plain) => plain.transmission)
    transmission!: string[] | null;

    @Expose()
    @Transform((_, plain) => plain.provider_name)
    providers!: string[] | null;

    @Expose()
    @Transform((_, plain) => plain.vehicle_types)
    vehicleTypes!: string[] | null;

    @Expose()
    @Transform((_, plain) => plain.fuel_type)
    fuelTypes!: string[] | null;

    @Expose()
    @Transform((_, plain) => plain.normalized_car_category)
    normalizedCarCategory!: string[] | null;

    @Expose()
    @Transform((_, plain) => plain.brand_name)
    brandName!: string[] | null;

    @Expose()
    @Transform((_, plain) => plain.country_name)
    countryName!: string[] | null;

    @Expose()
    @Transform((_, plain) => plain.country_code)
    countryCode!: string[] | null;

    @Expose()
    @Transform((_, plain) => plain.normalized_car_category_per_pos)
    normalizedCarCategoryPerPos!: { [pos: string]: string[] } | null;

    carBrands!: string[] | null | undefined;
}
