enum ScanDisabledProviders {
    ALL = 'all',
    BRAND_COM = 'brand.com',
    CTRIP = 'ctrip',
    CUG = 'cug',
    MOBILE_APP = 'mobile_app',
    CHEAPEST = 'cheapest',
}

export default ScanDisabledProviders;
