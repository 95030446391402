import _ from 'lodash';
import CarFilterModel from '@/modules/cars/models/cars-filters.model';
import { COUNTRIES_ANY } from '@/modules/cars/constants/car-filter-types.constant';

interface IFiltersRestrictions {
    /** Countries whitelist. Empty list means no restrictions. * */
    countries: string[];
    /** Locations whitelist. Empty list means no restrictions. * */
    locations: string[];
}

const DEFAULT_RESTRICTIONS: IFiltersRestrictions = { countries: [], locations: [] };

/**
 *
 * @param rawFilters - the filter should be restricted
 * @param restrictions - the restriction rules
 * @returns The subset of `rawFilters`, or unchanged `rawFilters` if no restrictions provided.
 */
const restrictCarsFilters = (rawFilters: CarFilterModel, restrictions: IFiltersRestrictions = DEFAULT_RESTRICTIONS): CarFilterModel => {
    const filters = _.cloneDeep(rawFilters);

    if (filters.clusters && filters.countries && restrictions.countries.length) {
        filters.clusters = _.pick(filters.clusters, restrictions.countries);
        filters.clusters[COUNTRIES_ANY] = {};
        filters.countries = _.pick(filters.countries, restrictions.countries);

        if (restrictions && restrictions.locations.length) {
            filters.locations = filters.locations!.filter(item => restrictions.locations.includes(item.locationId));
        } else {
            const allowedLocations = _.flatten(Object.values(filters.countries));
            filters.locations = filters.locations!.filter(loc => allowedLocations.includes(loc.locationId));
        }
        const locationIds = filters.locations!.map(locId => locId.locationId);

        Object.keys(filters.clusters || {}).forEach((country: string) => {
            if (filters.clusters && country in filters.clusters) {
                Object.keys(filters.clusters[country] || {}).forEach((cluster: string) => {
                    if (filters.clusters && filters.clusters[country] && cluster in filters.clusters[country]) {
                        filters.clusters[country][cluster] = filters.clusters[country][cluster].filter(locId => locationIds.includes(locId));
                        if (filters.clusters[country][cluster].length === 0) {
                            delete filters.clusters[country][cluster];
                        }
                    }
                });
            }
        });
        const allowedCountryNames = Object.keys(filters.countries);
        filters.countryCodes = filters.countryCodes!.filter(({ name }) => allowedCountryNames.includes(name));
    }

    return filters;
};

export default restrictCarsFilters;
