import { Expose, Transform } from 'class-transformer';
import { IsString, IsInt } from 'class-validator';

export default class MealTypeModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain._id)
    @IsInt()
    id!: number;

    @Expose()
    @Transform((_, plain) => plain.display_name)
    @IsString()
    displayName!: string;

    @Expose()
    @Transform((_, plain) => plain.name)
    @IsString()
    name!: string;
}
