import { Expose } from 'class-transformer';

export default class CarClassChangeModel {
    @Expose()
    category!: string;

    @Expose()
    dataSource!: string;

    @Expose()
    rawCategory!: string;
}
