import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import CarsAlertConfigurationModel from '@/modules/cars/models/cars-alert-configuration.model';
import SIppCodeAlertConfigurationModel from './models/sippcode-alert-configuration.model';

export const CarsAlertApiServiceS = Symbol.for('CarsAlertApiServiceS');
@injectable(CarsAlertApiServiceS as unknown as string)
export default class CarsAlertApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    private url = 'cr-insight';

    async getAlertsConfiguration() : Promise<CarsAlertConfigurationModel | null | any> {
        const { data } = await this.apiService.get(`${this.url}/get-alert-config-by-user`);

        if (!data) {
            return null;
        }

        const carsDocument = plainToClass(CarsAlertConfigurationModel, data, { excludeExtraneousValues: true });
        return carsDocument;
    }

    async saveSippCodeAlertConfiguration(configuration: SIppCodeAlertConfigurationModel) {
        return this.apiService.post(`${this.url}/sippmap-alert-config`, configuration);
    }

    async saveCarCategoryAlertConfiguration(configuration: SIppCodeAlertConfigurationModel) {
        return this.apiService.post(`${this.url}/save-car-category-alert-config`, configuration);
    }
}
