import { Expose, Transform } from 'class-transformer';
import { IsNumber, IsOptional, IsString } from 'class-validator';

export default class HotelCatalogItemModel {
    @Expose()
    @IsNumber()
    @Transform((_, plain) => plain.fornovaId)
    id!: number;

    @Expose()
    @IsString()
    @Transform((_, plain) => plain.hotel_name)
    hotelName!: string;

    @Expose()
    @Transform((_, plain) => {
        if (plain.geo_location) {
            return {
                lat: plain.geo_location.latitude,
                lng: plain.geo_location.longitude,
            };
        }
        return null;
    })
    @IsOptional()
    geoLocation?: {
        lat: number,
        lng: number,
    };
}
