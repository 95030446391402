import { injectable } from 'inversify-props';
import CarsRateAlertService from './car.rate-alert.service';
import { CarRateAlertType } from './modules/settings/enums/alerts.enum';
import CarsCompetitorsAlertStore from './store/car.competitors-alert.store';

export const CarsCompetitorsAlertServiceS = Symbol.for('CarsCompetitorsAlertServiceS');

@injectable(CarsCompetitorsAlertServiceS as unknown as string)
export default class CarsCompetitorsAlertService extends CarsRateAlertService {
    readonly storeState: any = this.storeFacade.getState('CarsCompetitorsAlertStore');
    protected alertType = CarRateAlertType.COMPETITORS_DIFF;
}
