import { container } from 'inversify-props';
import { Route } from 'vue-router';
import HotelGuard, { HotelGuardS } from '../guards/hotel.guard';
import use from '../use';
import { CommonMenuRoutes } from './common.routes';

export const DownloadExcelPopup = (prefix: string) => ({
    name: `${prefix}.download-excel`,
    path: 'download-excel',
    meta: { modal: true },
    component: () => import('@/modules/rates/pages/download-excel.page.vue'),
});

export function RatesPriceHistory(prefix: string) {
    return {
        name: `${prefix}.price-history-popup`,
        path: 'price-history/:historyDay',
        props: (route: Route) => ({ historyDay: route.params.historyDay }),
        component: () => import('@/modules/common/modules/rates-price-history/pages/rates-price-history.modal.vue'),
        meta: { modal: true },
    };
}

export function RatesDemandMap(prefix: string) {
    return {
        name: `${prefix}.demand-map-popup`,
        path: 'demand-map/:day',
        props: (route: Route) => ({ day: route.params.day }),
        component: () => import('@/modules/rates/march-demo/rates-demand-map.modal.vue'),
        meta: { modal: true },
        beforeEnter: use([container.get<HotelGuard>(HotelGuardS)]),
    };
}

export function DayRates(prefix: string) {
    return {
        name: `${prefix}.day-rate`,
        path: 'day-rate/:day',
        props: (route: Route) => ({ day: route.params.day, hotelId: Number(route.params.hotelId), compsetId: route.params.compsetId }),
        component: () => import('@/modules/rates/pages/day-rate.modal.page.vue'),
        meta: { modal: true },
        children: [
            RatesPriceHistory(`${prefix}.day-rate`),
        ],
    };
}

export function ScheduledAnalysisReports(prefix: string) {
    return {
        name: `${prefix}.scheduled-reports`,
        path: 'scheduled-reports',
        component: () => import('@/modules/rates/pages/analysis/analysis-report.modal.page.vue'),
        meta: { modal: true },
    };
}

export function RatesAnalysisRoutes(prefix: string) {
    const rootPrefix = `${prefix}.analysis`;
    const rootPrefixTable = `${prefix}.analysis.table`;
    return [
        {
            name: rootPrefix, // NOTE: Default calendar mode
            path: 'analysis',
            component: () => import('@/modules/rates/pages/analysis/rates-analysis-calendar.page.vue'),
            children: [
                DayRates(rootPrefix),
                RatesDemandMap(rootPrefix),
                RatesPriceHistory(rootPrefix),
                DownloadExcelPopup(rootPrefix),
                ScheduledAnalysisReports(rootPrefix),
                ...CommonMenuRoutes(rootPrefix),
            ],
        },
        {
            name: rootPrefixTable,
            path: 'analysis/table',
            component: () => import('@/modules/rates/pages/analysis/rates-analysis-table.page.vue'),
            children: [
                DayRates(rootPrefixTable),
                RatesDemandMap(rootPrefixTable),
                RatesPriceHistory(rootPrefixTable),
                DownloadExcelPopup(rootPrefixTable),
                ScheduledAnalysisReports(rootPrefixTable),
                ...CommonMenuRoutes(rootPrefixTable),
            ],
        },
    ];
}
