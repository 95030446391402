import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import { StoreS, StoreType } from '@/store/types';
import { Inject, injectable } from 'inversify-props';
import FleetHistoryStore from '@/modules/cars/modules/cars-price-history/store/fleet-history.store';
import GRAPH_COLORS from '@/modules/common/constants/default-graph-colors.constant';

export const FleetHistoryServiceS = Symbol.for('FleetHistoryServiceS');
@injectable(FleetHistoryServiceS as unknown as string)
export default class FleetHistoryService implements Stateable {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(StoreS) private store!: StoreType;

    readonly storeState: FleetHistoryStore = this.storeFacade.getState('FleetHistoryStore');

    getBrokersGraphColor(brokerId: number) {
        return GRAPH_COLORS[brokerId];
    }
}
