/* eslint-disable global-require */
export const programLogos = {
    booking: {
        percentage_off: require('@/modules/common/assets/promotions/percentage_off.svg'),
        preferred: require('@/modules/common/assets/promotions/preferred.svg'),
        preferred_plus: require('@/modules/common/assets/promotions/preferred_plus.svg'),
        booster: require('@/modules/common/assets/promotions/booster.svg'),
        guest_favorite: require('@/modules/common/assets/promotions/guest_favorite.svg'),
        special_promotion: require('@/modules/common/assets/promotions/special_promotion.svg'),
    },
    expedia: {
        member_price: require('@/modules/common/assets/promotions/member_price.svg'),
        percentage_off: require('@/modules/common/assets/promotions/percentage_off.svg'),
        travel_ads: require('@/modules/common/assets/promotions/travel_ads.svg'),
        vip_access: require('@/modules/common/assets/promotions/vip_access.svg'),
        all_inclusive: require('@/modules/common/assets/promotions/all_inclusive.svg'),
        scarcity_message: require('@/modules/common/assets/promotions/scarcity_message.svg'),
    },
} as {
    [provider: string]: {
        [program: string]: string,
    }
};

export default {};
