import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import use from '@/router/use';
import { container } from 'inversify-props';
import { RouteConfig } from 'vue-router';
import { CommonMenuRoutes } from '../common.routes';
import DeepCompsetHotelLevelRoutes from '../hotel/deep-analysis.routes';

export default function DeepAnalysisClusterLevelRoutes(basePath: string, prefix: string): RouteConfig {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS)]),
        children: [
            {
                path: '',
                name: prefix,
                component: () => import('@/modules/deep-analysis/pages/deep-analysis-cluster.page.vue'),
                children: [
                    ...CommonMenuRoutes(prefix),
                ],
            },
            DeepCompsetHotelLevelRoutes(':hotelId', `${prefix}.hotel`),
        ],
    };
}
