import { Transform } from 'class-transformer';

export default class EventsLocationModel {
    @Transform((_, plain) => plain.coordinates[0])
    latitude!: number;
    @Transform((_, plain) => plain.coordinates[1])
    longitude!: number;
    @Transform((_, plain) => plain.distance_from_hotel)
    distanceFromHotel!: string;
}
