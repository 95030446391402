import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { validate, ValidationError } from 'class-validator';
import Vue from 'vue';
import { NOT_SPECIFIED } from '@/modules/cars-category-manager/constants';
import CarsCategoryManagerApiService, { CarsCategoryManagerApiServiceS } from './cars-category-manager-api.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import CarsCategoryStore from './store/cars-category.store';
import CarClassChangeModel from './models/car-class-change.model';
import NewCategoryModel from './models/new-category.model';

export const CarsCategoryManagerServiceS = Symbol.for('CarsCategoryManagerServiceS');
@injectable(CarsCategoryManagerServiceS as unknown as string)
export default class CarsCategoryManagerService {
    @Inject(CarsCategoryManagerApiServiceS) private carsCategoryManagerApiService!: CarsCategoryManagerApiService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;

    readonly storeState: CarsCategoryStore = this.storeFacade.getState('CarsCategoryStore');

    constructor() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
    }

    async loadData() {
        const carsCategory = await this.carsCategoryManagerApiService.getCarsCategory();
        this.storeState.carsCategory = carsCategory;
        return true;
    }

    get dataSources() {
        const { localCarsCategory, carsCategory } = this.storeState;
        const documentData = localCarsCategory || carsCategory;

        if (!documentData) {
            return null;
        }

        let dataSources: string[] = [];

        Object.keys(documentData.categories).forEach((key, value) => {
            dataSources = [...dataSources, ...Object.keys(documentData.categories[key])];
        });

        dataSources = [...new Set(dataSources)]
            .sort((a, b) => (a === NOT_SPECIFIED ? -1 : 0));
        return dataSources;
    }

    getRawCategories(categoryName: string) {
        const { localCarsCategory, carsCategory } = this.storeState;
        const documentData = localCarsCategory || carsCategory;

        if (!documentData || !documentData.categories[categoryName]) {
            return null;
        }
        return documentData.categories[categoryName];
    }

    get categories() {
        const { localCarsCategory, carsCategory } = this.storeState;
        const documentData = localCarsCategory || carsCategory;
        if (!documentData || !documentData.categories) {
            return [];
        }
        return Object.keys(documentData.categories);
    }

    resetLocalChanges() {
        this.storeState.localCarsCategory = null;
    }

    addLocalChanges(category: string, className: string, categoryFrom: string, categoryTo: string) {
        const { localCarsCategory, carsCategory } = this.storeState;
        const documentData = localCarsCategory || carsCategory;

        if (documentData && documentData.categories) {
            const newDocumentData = JSON.parse(JSON.stringify(documentData));
            const providerMapping = newDocumentData.categories[category];

            if (!providerMapping[categoryTo]) {
                providerMapping[categoryTo] = [];
            }

            providerMapping[categoryTo].push(className);
            providerMapping[categoryFrom] = newDocumentData.categories[category][categoryFrom]
                .filter((item: string) => item !== className);

            if (!providerMapping[categoryFrom].length) {
                delete providerMapping[categoryFrom];
            }

            const dataSourceName = category;

            const newChange = plainToClass(CarClassChangeModel, {
                category: categoryTo,
                dataSource: dataSourceName,
                rawCategory: className,
            });

            this.storeState.carClassChanges.push(newChange);

            this.storeState.localCarsCategory = newDocumentData;
        }
    }

    get isChange(): boolean {
        return Boolean(this.storeState.localCarsCategory);
    }

    async save() {
        const changes = this.storeState.carClassChanges;
        if (changes && this.storeState.localCarsCategory) {
            const updated = await this.carsCategoryManagerApiService.updateCarsCategory(this.storeState.localCarsCategory);

            if (updated) {
                this.storeState.carsCategory = this.storeState.localCarsCategory;
                this.resetLocalChanges();
            }
        }
    }

    async addCategory(newCategory: NewCategoryModel): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = await validate(newCategory);
        const { localCarsCategory, carsCategory } = this.storeState;
        const documentData = localCarsCategory || carsCategory;

        if (this.isExistsCategory(newCategory.category)) {
            validationErrors = this.updateValidationErrors(validationErrors, 'Category name already exists');
            return validationErrors;
        }
        if (validationErrors.length > 0) {
            return validationErrors;
        }
        if (this.dataSources) {
            if (documentData && documentData.categories) {
                const newDocumentData = {
                    categories: {
                        ...documentData.categories,
                    },
                };
                Object.keys(newDocumentData.categories).forEach((item: string) => {
                    Vue.set(newDocumentData.categories[item], newCategory.category, []);
                });
                this.storeState.localCarsCategory = newDocumentData;
            }
        }

        this.storeState.newCategories.push(newCategory);

        return validationErrors;
    }

    async renameCategory(newCategory: NewCategoryModel, oldCategory: string): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = await validate(newCategory);
        const { localCarsCategory, carsCategory } = this.storeState;
        const documentData = localCarsCategory || carsCategory;

        if (this.isExistsCategory(newCategory.category)) {
            validationErrors = this.updateValidationErrors(validationErrors, 'Category name already exists');
            return validationErrors;
        }
        if (validationErrors.length > 0) {
            return validationErrors;
        }
        if (this.dataSources) {
            if (documentData && documentData.categories) {
                const newDocumentData = {
                    categories: {
                        ...documentData.categories,
                    },
                };
                Object.keys(newDocumentData.categories).forEach((item: string) => {
                    Vue.set(newDocumentData.categories[item], newCategory.category, newDocumentData.categories[item][oldCategory]);
                    delete newDocumentData.categories[item][oldCategory];
                });

                this.storeState.localCarsCategory = newDocumentData;
            }
        }

        return validationErrors;
    }

    async deleteCategory(category: NewCategoryModel) {
        const { localCarsCategory, carsCategory } = this.storeState;
        const documentData = localCarsCategory || carsCategory;

        if (this.dataSources) {
            let categories = {};
            const deleteDocument = {
                categories: {} as any,
            };
            if (documentData && documentData.categories) {
                const newDocumentData = {
                    categories: {
                        ...(JSON.parse(JSON.stringify(documentData.categories))),
                    },
                };

                Object.keys(newDocumentData.categories).forEach(item => {
                    categories = {
                        ...categories,
                        [item]: newDocumentData.categories[item][category.category],
                    };
                    deleteDocument.categories = {
                        ...deleteDocument.categories,
                        [item]: {
                            [category.category]: newDocumentData.categories[item][category.category] || [],
                        },
                    };
                    delete newDocumentData.categories[item][category.category];
                });
                this.storeState.localCarsCategory = newDocumentData;
            }
            this.carsCategoryManagerApiService.deleteCategory(deleteDocument);
            this.resetLocalChanges();
        }
    }

    isExistsCategory(category: string): boolean {
        return this.dataSources ? this.dataSources.map(item => item.toLowerCase()).indexOf(category.toLowerCase()) !== -1 : false;
    }

    // TODo: check solution for validation
    updateValidationErrors(validationErrors: ValidationError[], message: string) :ValidationError[] {
        const error = new ValidationError();
        error.constraints = {
            message,
        };
        return [...validationErrors, ...[error]];
    }
}
