/* eslint-disable no-param-reassign */
import { Expose, Transform } from 'class-transformer';
import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';
import { camelCase, isDate } from 'lodash';
import { isDateString } from 'class-validator';

export default class DeepAnalysisItemModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Expose()
    @Transform((_, plain) => {
        if (!plain.details || !Object.keys(plain.details).length) {
            return null;
        }

        const transformValue = (val: string | number | undefined, statistic: STATISTIC_TYPE) => {
            if (val === undefined || val === 'n') return null;
            if (isDateString(val)) { return new Date(val); }
            if (statistic === STATISTIC_TYPE.REVENUE || statistic === STATISTIC_TYPE.NET_ADR) {
                if (val < 0) {
                    return null;
                }
            }

            return val;
        };

        const transformStatistics = ([days, statData]: [string, any]) => {
            const keys = ['comp_set', 'last_year', 'my_hotel', 'absolute_comp_set', 'absolute_last_year', 'update_date'];
            const statistics = Object.keys(statData || {}) as STATISTIC_TYPE[];

            const toStatisticEntry = (key: string) => {
                const valuesEntries = statistics
                    .map(statistic => [statistic, statData[statistic] ? transformValue(statData[statistic][key], statistic) : null]);
                if (key === 'comp_set') {
                    key = 'compset';
                }

                return [
                    camelCase(key),
                    Object.fromEntries(valuesEntries),
                ];
            };

            const statisticEntries = keys.map(toStatisticEntry);

            return [days, Object.fromEntries(statisticEntries)];
        };

        const transformProviderData = ([provider, data]: [string, any]) => {
            const { currency, customer_id: customerId } = data;
            const showBy = {
                book: null,
                stay: null,
            };

            if (data.last) {
                const bookEntries = Object
                    .entries(data.last)
                    .map(transformStatistics);
                showBy.book = Object.fromEntries(bookEntries);
            }

            if (data.next) {
                const stayEntries = Object
                    .entries(data.next)
                    .map(transformStatistics);
                showBy.stay = Object.fromEntries(stayEntries);
            }

            return [provider, {
                showBy,
                currency,
                customerId,
                updateDate: new Date(plain.update_date),
            }];
        };

        const providersEntries = Object
            .entries(plain.details)
            .map(transformProviderData);
        const providers = Object.fromEntries(providersEntries);

        return providers;
    })
    providers!: {
        [provider: string]: {
            showBy: {
                [showBy: string]: {
                    [days: string]: {
                        absoluteLastYear: {
                            [statistic: string]: number;
                        };
                        absoluteCompSet: {
                            [statistic: string]: number;
                        };
                        compset: {
                            [statistic: string]: number;
                        };
                        lastYear: {
                            [statistic: string]: number;
                        };
                        myHotel: {
                            [statistic: string]: number;
                        };
                        updateDate: {
                            [statistic: string]: Date;
                        };
                    } | null;
                } | null;
            },
            currency: string,
            currentId: string,
            updateDate: Date,
        } | null;
    };

    @Expose()
    @Transform((_, plain) => (plain.details && plain.details.currency ? plain.details.currency : null))
    currency!: string | null;

    @Expose()
    @Transform((_, plain) => plain.update_date)
    updateDate!: Date;
}
