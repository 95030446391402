import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';

import ChainScoreRankModel from '../models/score-rank.model';
import ChainScoreStatisticsModel from '../models/score-statistics.model';

@Module
export default class ClusterScoreStore extends VuexModule {
    scoreRank: ChainScoreRankModel[] = [];
    scoreStatisticsHistory: ChainScoreStatisticsModel | null = null;
    loading: LoadingModel = new LoadingModel();
}
