enum EVENT_TYPE_SETTINGS {
    CONCERTS ='concerts',
    CONFERENCES ='conferences',
    COMMUNITY ='community',
    EXPOS ='expos',
    FESTIVAL ='festival',
    SPORTS ='sports',
    POLITICS ='politics',
    PERFORMING_ARTS ='performing-arts',
    OTHER ='other',
}
export default EVENT_TYPE_SETTINGS;
