import { Route } from 'vue-router';

export default {};

export function CarMappingPopup(prefix: string) {
    return {
        name: `${prefix}.cars-mapping`,
        path: 'cars-mapping',
        component: () => import('@/modules/cars-category-manager/pages/car-mapping.modal.page.vue'),
        meta: { modal: true },
        children: [
            {
                path: 'new-car-category',
                name: `${prefix}.cars-mapping.new-edit-car-category`,
                component: () => import('@/modules/cars-category-manager/pages/add-edit-car-category.modal.page.vue'),
                props: (route: Route) => ({ carCategoryName: route.params.carCategoryName }),
                meta: { modal: true },
            },
        ],
    };
}
