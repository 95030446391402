import { injectable, Inject } from 'inversify-props';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import ChainStore from './chain.store';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import ChainApiService, { ChainApiServiceS } from './chain-api.service';
import IChainDictionary from './interfaces/chain-dictionary.interface';
import ChainGroup from './interfaces/chain-group.enum';

export const ChainServiceS = Symbol.for('ChainServiceS');

let watchDisabled = false;

@injectable(ChainServiceS as unknown as string)
export default class ChainService {
    @Inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @Inject(HelperServiceS)
    private helperService!: HelperService;

    @Inject(ChainApiServiceS)
    private chainApiService!: ChainApiService;

    private readonly storeState: ChainStore = this.storeFacade.getState('ChainStore');

    constructor() {
        this.storeFacade.watch(
            () => [
                this.settings.country,
                this.settings.region,
            ],
            (newValues, oldValues) => {
                if (watchDisabled) return;

                const isCountryChanged = oldValues[0] !== newValues[0];
                const isRegionChanged = oldValues[1] !== newValues[1];

                watchDisabled = true;

                if (isRegionChanged) {
                    this.settings.country = '';
                }

                if (isCountryChanged) {
                    this.settings.city = '';
                }

                this.resetDictionaryLoading();
            },
        );
    }

    get chainPair() {
        return this.storeState.chainPair;
    }

    get dictionary() {
        this.helperService.dynamicLoading(
            this.storeState.dictionaryLoading,
            this.loadDictionary.bind(this),
        );

        return this.storeState.dictionary;
    }

    get isDictionaryLoading() {
        return false
            || !this.dictionary
            || this.storeState.dictionaryLoading.isLoading();
    }

    get settings() {
        return this.storeState.settings;
    }

    public getItemSet(key: keyof IChainDictionary) {
        if (this.dictionary) {
            const list = Array.from(new Set(this.dictionary[key]))
                .filter(e => !!e) // NOTE: Might be null value in the array
                .map(value => ({
                    value,
                    name: value,
                }));

            list.splice(0, 0, {
                value: '',
                name: 'All',
            });

            return list;
        }

        return [];
    }

    public setChainPair(group: ChainGroup, value: string) {
        this.storeState.chainPair = {
            group,
            value,
        };

        watchDisabled = true;

        this.settings.region = '';
        this.settings.country = '';

        if (group !== ChainGroup.CLUSTER) {
            this.settings.city = '';
            this.settings[group] = value; // NOTE: Region or Country
        }
        this.resetDictionaryLoading();
    }

    public resetChainPair() {
        this.storeState.chainPair = null;
    }

    private async resetDictionaryLoading() {
        this.storeState.dictionaryLoading.reset();
    }

    private async loadDictionary() {
        const { settings } = this.storeState;

        const query = {
            region: settings.region,
            country: settings.country,
        };

        // TODO: Assign query when the BE will support it.
        this.storeState.dictionary = await this.chainApiService
            .getFilterDictionary(query);

        watchDisabled = false;

        return true;
    }
}
