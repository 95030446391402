import { Inject, injectable } from 'inversify-props';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import { StoreS, StoreType } from '@/store/types';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ReportsApiService, { ReportsApiServiceS } from './reports-api.service';
import ReportsStore from './store/reports.store';
import {
    IRecipient,
    IFilterItem,
    IProperties,
    ICustomColumn,
    IForm,
} from './interfaces';
import { DATA_TYPE } from './constants';

export const ReportsServiceS = Symbol.for('ReportsServiceS');
@injectable(ReportsServiceS as unknown as string)
export default class ReportsService implements Stateable {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(StoreS) private store!: StoreType;
    @Inject(ReportsApiServiceS) private reportsApiService!: ReportsApiService;
    @Inject(HelperServiceS) private helperService!: HelperService;

    readonly storeState: ReportsStore = this.storeFacade.getState('ReportsStore');

    get loading() {
        return this.storeState.loading;
    }

    async loadData(dataType: DATA_TYPE): Promise<boolean> {
        const items = await this.reportsApiService.getExcelReports(dataType);
        if (items) {
            this.storeState.items = items;
        }

        return true;
    }

    getReports(dataType: DATA_TYPE) {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this, dataType));
        return this.storeState.items;
    }

    resetReports() {
        this.storeState.items = null;
        this.storeState.loading.reset();
    }

    async updateReport(reportId: string, form: IForm): Promise<boolean> {
        this.loading.start();
        let updatedReport = null;

        try {
            updatedReport = await this.reportsApiService.putExcelReport(reportId, form);
        } catch (e) {
            throw e;
        } finally {
            this.loading.finish();
            if (updatedReport) {
                this.resetReports();
            }
        }

        return true;
    }

    async addReport(form: IForm): Promise<boolean> {
        this.loading.start();
        let newReport = null;

        try {
            newReport = await this.reportsApiService.postExcelReport(form);
        } catch (e) {
            throw e;
        } finally {
            this.loading.finish();
            if (newReport) {
                this.resetReports();
            }
        }

        return true;
    }

    async deleteReport(reportId: string) {
        this.loading.start();

        try {
            await this.reportsApiService.deleteExcelReport(reportId);
        } catch (e) {
            throw e;
        } finally {
            this.loading.finish();
            this.storeState.loading.reset();
        }
    }
}
