import Item from '@/modules/common/interfaces/item.interface';
import LoadingModel from '@/modules/common/models/loading.model';
import CarsDocumentModel from './cars-document.model';

interface ICarsAnalysisSettings {
    closeNoDataNotification: boolean,
    comparisonFilter: {
        key: string,
        values: { value: (string | number), name: string },
    },
}

export const comparisonDays: Item[] = [
    { value: 0, name: 'Today' },
    { value: 1, name: 'Yesterday' },
    { value: 3, name: '3 Days' },
    { value: 7, name: '7 Days' },
    { value: 14, name: '14 Days' },
];

export default class CarsAnalysisData {
    loading = new LoadingModel();
    documents: CarsDocumentModel | null = null;
    settings: ICarsAnalysisSettings = {
        closeNoDataNotification: false,
        comparisonFilter: {
            key: 'diffDays',
            values: comparisonDays[1],
        },
    };
    filtersReady: boolean = false;
    filtersWatched: boolean = false;
}
