export enum DATA_TYPE {
    RATES = 'Rates',
    MARKETS = 'Visibility',
    RANKING = 'Guest Reviews',
    DI_LITE = 'DI Lite',
    RATES_COMPARE = 'Analysis',
    RATES_CLUSTER = 'Rates Cluster',
    MARKETS_CLUSTER = 'Markets Cluster',
    RANKING_CLUSTER = 'Guest Reviews Cluster',
    HOTEL_ALL = 'Hotel All',
    CLUSTER_ALL = 'Cluster All',
}

export enum SCHEDULER_CONFIG {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    YEARLY = 'yearly',
}

export enum DAY_CONFIG {
    FIRST = 'first',
    SECOND = 'second',
    THIRD = 'third',
    LAST = 'last',
}

export enum DAY_TYPE {
    DYNAMIC = 'dynamic',
    SPECIFIC = 'specific',
}

export enum SECTIONS {
    PROPERTIES = 'properties',
    CUSTOM_COLUMNS = 'customColumns',
    FILTERS = 'filters',
    FREQUENCY = 'frequency',
    RECIPIENTS = 'recipients',
}

export const TIME_BUFFER = 15;
