import { injectable, Inject } from 'inversify-props';
import USER_LEVELS from '@/modules/user/constants/user-levels.constant';

export const HeadServiceS = Symbol.for('HeadServiceS');
@injectable(HeadServiceS as unknown as string)
export default class HeadService {
    setFavicon(userLevel: USER_LEVELS) {
        const icons = document.head.querySelectorAll('[rel=icon]') as NodeListOf<HTMLAnchorElement>;

        icons.forEach(icon => {
            if (userLevel === USER_LEVELS.CAR) {
                // eslint-disable-next-line no-param-reassign
                icon.href = icon.href.replace('favicon', 'favicon-cr');
            } else {
                // eslint-disable-next-line no-param-reassign
                icon.href = icon.href.replace('favicon-cr', 'favicon');
            }
        });
    }

    setTitle(userLevel: USER_LEVELS) {
        if (userLevel === USER_LEVELS.CAR) {
            document.title = 'FornovaCR';
        } else {
            document.title = 'FornovaCI';
        }
    }
}
