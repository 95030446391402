import { Expose, Transform } from 'class-transformer';
import deepMapKeys from 'deep-map-keys';
import { IsString, IsOptional, IsNumber } from 'class-validator';

export default class ProvidersModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain._id)
    @IsString()
    @IsOptional()
    id!: string | null;

    @Expose()
    @Transform((_, plain) => plain.provider_name)
    @IsString()
    @IsOptional()
    name!: string | null;

    @Expose()
    @Transform((_, plain) => plain.di_name)
    @IsNumber()
    @IsOptional()
    diName!: string | null; // TODO: Should be added on the BE

    @Expose()
    @Transform((_, plain) => plain.display_name)
    @IsString()
    @IsOptional()
    label?: string | null;

    @Expose()
    @Transform((_, plain) => plain.total_score)
    @IsNumber()
    @IsOptional()
    maxScore!: number | null;
}
