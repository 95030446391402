import { Expose, plainToClass, Transform } from 'class-transformer';
import EventsLocationModel from '@/modules/events/models/events-location.model';
import { IsOptional } from 'class-validator';

interface Location {
    coordinates: [number, number],
    // eslint-disable-next-line camelcase
    distance_from_hotel :number,
    city: string
}

export default class EventsDTO {
    @Expose({ groups: ['update'] })
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.id)
    id!: string;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.marketId)
    marketId!: number;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.name)
    name!: string;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.type)
    type!: string;
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.startDate)
    starts!: string;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.endDate)
    ends!: string;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => ({
        coordinates: [32.065070, 34.762880],
        distance_from_hotel: 300,
        city: 'Tel Aviv',
    }))
    location!: Location;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.numberOfGuests)
    // eslint-disable-next-line camelcase
    no_of_guests!: number;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.description)
    description!: string;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.url)
    url!: URL;

    @Expose()
    @IsOptional()
    @Transform((_, plain) => plain.privacy)
    privacy?: string;
}
