import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';
import LoadingModel from '@/modules/common/models/loading.model';

@Module
export default class MealTypesStore extends VuexModule {
    mealTypes: MealTypeModel[] = [];
    loading: LoadingModel = new LoadingModel();
}
