import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';

const BOOKING_STAY_DATE_STATISTICS : STATISTIC_TYPE[] = [
    STATISTIC_TYPE.REVENUE,
    STATISTIC_TYPE.ROOM_NIGHTS,
    STATISTIC_TYPE.NET_ADR,
    STATISTIC_TYPE.PAGE_VIEWS,
    STATISTIC_TYPE.CONVERSION,
];

export default BOOKING_STAY_DATE_STATISTICS;
