import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import LoadingModel from '@/modules/common/models/loading.model';

@Module
export default class RoomTypesStore extends VuexModule {
    roomTypes: RoomTypeModel[] = [];
    loading: LoadingModel = new LoadingModel();
}
