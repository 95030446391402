import { Inject, injectable } from 'inversify-props';
import { $enum } from 'ts-enum-util';
import StorageService, { StorageServiceS } from '@/modules/common/services/storage.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import EVENT_STATUS_SETTINGS from '@/modules/events/constants/event-statuses.constant';
import EVENT_TYPE_SETTINGS from '@/modules/events/constants/event-types-settings.constant';
import EventsSettingsModel from '@/modules/events/models/events-setting.model';
import EventsModel from '@/modules/events/models/events.model';
import EventsStore from '@/modules/events/store/events.store';
import EventsApiService, { EventsApiServiceS } from './events-api.service';

const SETTINGS_KEY = 'event-manager-settings-v2';

interface IEventsFilterSettings {
    settings: EventsSettingsModel,
    userId: string
}

export const EventsFilterServiceS = Symbol.for('EventsFilterServiceS');
@injectable(EventsFilterServiceS as unknown as string)
export default class EventsFilterService {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StorageServiceS) private storageService!: StorageService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(EventsApiServiceS) private eventsApiService!: EventsApiService;
    readonly storeState: EventsStore = this.storeFacade.getState('EventsStore');

    constructor() {
        this.loadFilter();

        // Recover settings from storage
        const settingsData = this.storageService.getItem(SETTINGS_KEY) as IEventsFilterSettings;
        if (settingsData) {
            if (this.userService.user && settingsData.userId === this.userService.user.id) {
                this.storeState.settings = settingsData.settings;
                this.storeState.filtersReady = true;
            } else {
                this.storageService.removeItem(SETTINGS_KEY);
            }
        }
        if (!this.storeState.filtersReady) {
            this.setDefaultSettings();
            this.storeState.filtersReady = true;
        }
    }

    async loadFilter() {
        this.storeState.countries = await this.eventsApiService.getAllCountries();
    }

    async resetFilters(): Promise<void> {
        // this.settings = DEFAULT_EVENT_FILTERS_SETTINGS;
    }

    get settings(): EventsSettingsModel {
        return this.storeState.settings;
    }

    get countries() {
        return this.storeState.countries;
    }

    set settings(settings: EventsSettingsModel) {
        this.storeState.settings = settings;
        if (this.userService.user) {
            this.storageService.setItem(SETTINGS_KEY, { settings, userId: this.userService.user.id });
        }
    }

    updateCountries(holidayEvents: EventsModel[]) {
        this.storeState.settings.countriesList = holidayEvents
            .filter(e => !!e.country && !!e.countryCode)
            .sort((e1: EventsModel, e2: EventsModel) => (e1.country! > e2.country! ? 1 : -1))
            .reduce((acc: any, e: EventsModel) => {
                acc[e.countryCode!.toUpperCase()] = e.country;
                return acc;
            }, {}) as { [countryCode: string]: string };

        if (this.defaultCountryCodes) {
            const countries = holidayEvents
                .filter(e => !!e.country && !!e.countryCode)
                .filter(e => {
                    const { countryCode } = e!;
                    return !!this.defaultCountryCodes.find(code => code.toLowerCase() === countryCode!.toLowerCase());
                })
                .map((event: EventsModel) => event.countryCode!.toUpperCase()) as string[];
            this.storeState.settings.countries = Array.from(new Set(countries));
        } else {
            this.storeState.settings.countries = Object.keys(this.storeState.settings.countriesList);
        }
    }

    getCountries(holidayEvents: EventsModel[]) {
        const countriesList = holidayEvents
            .filter(e => !!e.country && !!e.countryCode)
            .sort((e1: EventsModel, e2: EventsModel) => (e1.country! > e2.country! ? 1 : -1))
            .reduce((acc: any, e: EventsModel) => {
                acc[e.countryCode!.toUpperCase()] = e.country;
                return acc;
            }, {}) as { [countryCode: string]: string };
        let countries = [];
        if (this.defaultCountryCodes) {
            const cs = holidayEvents
                .filter(e => !!e.country && !!e.countryCode)
                .filter(e => {
                    const { countryCode } = e!;
                    return !!this.defaultCountryCodes.find(code => code.toLowerCase() === countryCode!.toLowerCase());
                })
                .map((event: EventsModel) => event.countryCode!.toUpperCase()) as string[];
            countries = Array.from(new Set(cs));
        } else {
            countries = Object.keys(countriesList);
        }
        return {
            countriesList,
            countries,
        };
    }

    setDefaultSettings() {
        this.storeState.settings.status = EVENT_STATUS_SETTINGS.ALL;
        this.storeState.settings.types = $enum(EVENT_TYPE_SETTINGS).map(item => item);
        this.storeState.settings.countries = this.defaultCountryCodes;
    }

    get defaultCountryCodes() {
        let countryCodes: string[];

        const { isChainOrClusterUser, isCarUser, viewAs } = this.userService;
        const isClusterUser = isChainOrClusterUser && viewAs !== 'hotel';

        if (isCarUser) {
            countryCodes = this.carsSharedService.filters.pos || [];
        } else if (isClusterUser) {
            countryCodes = this.clusterService.poses;
        } else {
            countryCodes = this.compsetsService.poses;
        }

        return countryCodes;
    }
}
