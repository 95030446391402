import { Expose, Transform } from 'class-transformer';

export default class ChainScoreRankModel {
    @Expose()
    @Transform((_, plain) => (plain.hotel && plain.hotel.fornovaId ? plain.hotel.fornovaId : null))
    fornovaId!: number | null;

    @Expose()
    position!: number | null;

    @Expose()
    @Transform((_, plain) => (plain.hotel && plain.hotel.hotel_name ? plain.hotel.hotel_name : null))
    hotelName!: string | null;

    @Expose()
    @Transform((_, plain) => (plain.hotel && plain.hotel.score && plain.hotel.score.rate ? Math.round(Number(plain.hotel.score.rate)) : null))
    ratesScore!: number | null;

    @Expose()
    @Transform((_, plain) => (plain.hotel && plain.hotel.score && plain.hotel.score.mv ? Math.round(Number(plain.hotel.score.mv)) : null))
    marketScore!: number | null;

    @Expose()
    @Transform((_, plain) => (
        plain.hotel && plain.hotel.score && plain.hotel.score.customer_reviews ? Math.round(Number(plain.hotel.score.customer_reviews)) : null))
    customerReviews!: number | null;

    @Expose()
    @Transform((_, plain) => (plain.hotel && plain.hotel.score && plain.hotel.score.total ? Math.round(Number(plain.hotel.score.total)) : null))
    total!: number | null;

    @Expose()
    @Transform((_, plain) => (plain.score_month_diff ? Math.round(Number(plain.score_month_diff)) : null))
    totalDifference!: number | null;
}
