enum STATISTIC_TYPE {
    REVENUE='revenue',
    REVENUE_SHARE='revenue_share',
    NET_ADR='net_adr',
    ROOM_NIGHTS='room_nights',
    CANCELLATION='cancellation',
    AVG_LOS='average_los',
    PAGE_VIEWS='page_views',
    PAGE_VIEWS_SHARE='page_views_share',
    GROSS_CONVERSION='gross_conversion',
    PACKAGE_SHARE='package_share',
    AVERAGE_BOOKING_WINDOW = 'average_booking_window',
    CORPORATE_TRAVEL_SHARE = 'corporate_travel_share',
    INTERNATIONAL_SHARE = 'internationall_share',
    PROMOTION_SHARE = 'promotion_share',
    CONVERSION = 'conversion',
}
export default STATISTIC_TYPE;
