import { Expose, Transform } from 'class-transformer';
import HOTEL_NOTIFICATIONS from '../constants/hotel-level-notifications';

export default class UserHotelAlertsModel {
    @Expose()
    @Transform((_, plain) => {
        const hotels = {} as UserAPI.IUserHotelAlerts;
        Object.entries(plain.hotels).forEach(([hotelId, hotelAlerts]: [string, any]) => {
            hotels[parseInt(hotelId, 10)] = {
                [HOTEL_NOTIFICATIONS.COMPETITOR_RATE_CHANGE]: hotelAlerts.CompetitorRateChange,
            };
        });

        return Object.keys(hotels).length ? hotels : null;
    })
    hotels!: UserAPI.IUserHotelAlerts | null;
}
