import { Expose, Transform } from 'class-transformer';

export default class ClusterScoreStatisticsItemModel {
    @Expose()
    @Transform((_, plain) => (plain.score ? Math.round(Number(plain.score)) : null))
    score!: number | null;

    @Expose()
    @Transform((_, plain) => (plain.min_score ? Math.round(Number(plain.min_score)) : null))
    minScore!: number | null;

    @Expose()
    @Transform((_, plain) => (plain.max_score ? Math.round(Number(plain.max_score)) : null))
    maxScore!: number | null;
}
