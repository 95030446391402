import {
    ValidatorConstraint,
    ValidatorConstraintInterface,
    ValidationArguments,
    validate,
} from 'class-validator';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import { Inject } from 'inversify-props';
import RankingDocumentItemModel from '../ranking-document-item.model';

interface IProviders {
    [provider: string] : {
        [hotelId: number]: RankingDocumentItemModel
    }
}

@ValidatorConstraint({ name: 'RankingProviders', async: true })
export default class RankingProviderValidator implements ValidatorConstraintInterface {
    @Inject(ValidatorServiceS) private validatorService!: ValidatorService;

    private errorMessage = 'Ranking providers not valid';

    private async validateDocumentItem(documentItems: RankingDocumentItemModel[]) {
        // let documentItemErrors: string[] = [];

        const documentItemErrors = await Promise.all(documentItems.map(item => (
            this.validatorService.validateResponse(item)
        )));

        return Array.from(new Set(documentItemErrors.filter(errorItem => errorItem) as string[])).join('; ');
    }

    async validate(providers: IProviders, args: ValidationArguments) {
        let isValid = true;

        let rankingDocumentItems: RankingDocumentItemModel[] = [];

        if (!providers) {
            return isValid;
        }

        Object.keys(providers).forEach(provider => {
            rankingDocumentItems = Object.entries(providers[provider]).map(([hotelId, hotel]) => {
                if (Number.isNaN(Number(hotelId))) {
                    this.errorMessage += ', hotelId is not a number';
                    isValid = false;
                }

                return hotel;
            });
        });

        if (!rankingDocumentItems.length) {
            return isValid;
        }

        const errors = await this.validateDocumentItem(rankingDocumentItems);

        if (!errors) {
            return isValid;
        }

        isValid = false;
        this.errorMessage += `, ${errors}`;

        return isValid;
    }

    defaultMessage(args: ValidationArguments) {
        return this.errorMessage;
    }
}
