import { AxiosResponse } from 'axios';
import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import NotifyService, { NotifyServiceS } from '@/modules/common/services/notify.service';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import ErrorException from '@/modules/common/modules/exception-handler/exceptions/error.exception';

export const RoomTypesApiServiceS = Symbol.for('RoomTypesApiServiceS');
@injectable(RoomTypesApiServiceS as unknown as string)
export default class RoomTypesApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(NotifyServiceS) private notifyService!: NotifyService;

    async getRoomTypes(): Promise<RoomTypeModel[] | null> {
        const res = await this.apiService.get('users/rooms-types');
        return this.transformRoomTypes(res);
    }

    async addRoomType(roomName: string): Promise<RoomTypeModel | null> {
        const res = await this.apiService.post('/users/rooms-types', { room_name: roomName });
        if (!res || !res.data || !Object.entries(res.data).length) {
            throw new ErrorException('No data at post:users/rooms-types');
        }

        const [[id, name]] = Object.entries(res.data);

        return plainToClass(RoomTypeModel, <RoomTypeModel> { id: Number(id), name }, { excludeExtraneousValues: true });
    }

    async editRoomType(roomType: RoomTypeModel): Promise<RoomTypeModel[] | null> {
        const res = await this.apiService.put(`/users/rooms-types/${roomType.id}`, { room_name: roomType.name });
        return this.transformRoomTypes(res);
    }

    async deleteRoomType(roomTypeId: number): Promise<RoomTypeModel[] | null> {
        const res = await this.apiService.delete(`/users/rooms-types/${roomTypeId}`);

        if (!res || !res.data) {
            throw new ErrorException('No data at get:users/rooms-types');
        }

        return this.transformRoomTypes(res);
    }

    private transformRoomTypes(res: AxiosResponse) {
        const { data } = res;

        const roomsId = Object.keys(data);
        const transformedData: RoomTypeModel[] = roomsId.map((roomId: string) => ({
            id: Number(roomId),
            name: data[roomId],
        }));

        return plainToClass(RoomTypeModel, <RoomTypeModel[]> transformedData, { excludeExtraneousValues: true });
    }
}
