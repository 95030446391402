import {
    ValidatorConstraint,
    ValidatorConstraintInterface,
    ValidationArguments,
    validate,
} from 'class-validator';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import { Inject } from 'inversify-props';

interface IProviders {
    [provider: string]: {
        [fornovaId: number]: {
            [roomName: string]: number
        }
    }
}

@ValidatorConstraint({ name: 'HotelsRoomType', async: true })
export default class HotelsRoomTypeValidator implements ValidatorConstraintInterface {
    @Inject(ValidatorServiceS) private validatorService!: ValidatorService;

    private errorMessage = 'Hotel room types not valid';

    async validate(providers: IProviders, args: ValidationArguments) {
        let isValid = true;

        if (!providers) {
            return isValid;
        }

        Object.keys(providers).forEach(provider => {
            Object.keys(providers[provider]).forEach(fornovaId => {
                if (Number.isNaN(Number(fornovaId))) {
                    this.errorMessage += ', fornovaId is not a number';
                    isValid = false;
                }
                Object.entries(providers[provider][Number(fornovaId)]).forEach(([_, roomName]) => {
                    if (Number.isNaN(Number(roomName))) {
                        this.errorMessage += ', roomName is not a number';
                        isValid = false;
                    }
                });
            });
        });

        return isValid;
    }

    defaultMessage(args: ValidationArguments) {
        return this.errorMessage;
    }
}
