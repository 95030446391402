import { Expose, Transform } from 'class-transformer';
import {
    IsNumber, IsString, IsBoolean, IsInt,
} from 'class-validator';
import moment from 'moment';
import DEFAULT_NUMBER_OF_GUESTS from '@/modules/number-of-guests/constants/default-number-of-guests.constant';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';
import { ISchedulerConfig } from '../interfaces';
import { TIME_BUFFER } from '../constants';

type ServiceType = 'rate' | 'market';

export default class ScheduledItemModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain._id.toString())
    @IsString()
    id!: string;

    @Expose()
    @Transform((_, plain) => plain.compset_id)
    @IsString()
    compsetId!: string;

    @Expose()
    @Transform((_, plain) => plain.number_of_days)
    @IsInt()
    numberOfDays!: number;

    @Expose()
    @Transform((_, plain) => (new Date(plain.created_date)))
    @IsInt()
    createdDate!: Date;

    @Expose()
    @Transform((_, plain) => (plain.last_export ? plain.last_export : null))
    @IsInt()
    lastExport!: Date | null;

    @Expose()
    @Transform((_, plain) => (plain.next_export ? plain.next_export : null))
    @IsInt()
    nextExport!: Date | null;

    @Expose()
    @Transform((_, plain) => plain.service_type)
    @IsBoolean()
    serviceType!: ServiceType;

    @Expose()
    @Transform((_, plain) => plain.type)
    @IsBoolean()
    type!: string;

    @Expose()
    @Transform((_, plain) => {
        const {
            // eslint-disable-next-line camelcase
            repeat_every, type, hour, minute, day_of_week, month_period, day_of_month, month,
        } = plain.scheduler_config;
        const date = moment({ hour: Number(hour), minute: Number(minute) }).subtract(-TIME_BUFFER, 'minutes');
        const originalHour = date.hour();
        const originalMinute = date.minute();

        return {
            repeatEvery: repeat_every,
            dayOfWeek: day_of_week,
            monthPeriod: month_period,
            dayOfMonth: day_of_month,
            type,
            hour: originalHour,
            minute: originalMinute,
            month,
        };
    })
    schedulerConfig!: ISchedulerConfig;

    @Expose()
    filters?: { [key: string]: string | number | (string | number)[] | { key: string, value: string | number } };

    @Expose()
    @Transform((_, plain) => plain.subscribed_recipients_emails)
    recipients!: string[];

    @Expose()
    @Transform((_, plain) => (plain.filters ? plain.filters.columnsOptions : {}))
    columnsOptions!: { [key: string]: boolean };
}
