import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import CarsRateAlertConfigurationModel from '../models/cars-rate-alert-configuration.model';
import { CarRateAlertSettings } from './cars.rate-alert.store';

@Module
export default class CarsCompetitorsAlertStore extends VuexModule {
    document: CarsRateAlertConfigurationModel[] | null = null;
    loading: LoadingModel = new LoadingModel();
    saveRequestLoading = new LoadingModel();
    settings: CarRateAlertSettings = {
        alertType: [],
    };
}
