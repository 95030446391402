import { Inject, injectable } from 'inversify-props';
import { Route } from 'vue-router';
import { IMiddleware } from '@/router/interfaces/middleware.interface';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import TokenSessionStorageService, { TokenSessionStorageServiceS } from '@/modules/auth/token-session-storage.service';

export const LevelMiddlewareS = Symbol.for('LevelMiddlewareS');
@injectable(LevelMiddlewareS as unknown as string)
export default class LevelMiddleware implements IMiddleware {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(AuthServiceS) private authService!: AuthService;
    @Inject(TokenSessionStorageServiceS) private tokenSessionStorageService!: TokenSessionStorageService;

    async canActivate() {
        await this.authService.isLogin();
        const isCr = /cr/.test(window.location.origin);
        const isCi = /ci/.test(window.location.origin);

        const token = this.authService.oldToken;

        if (
            window.location.origin !== window.location.origin.replace('ci', 'cr')
            || window.location.origin !== window.location.origin.replace('cr', 'ci')
        ) {
            if (this.userService.isCarUser && isCi) {
                this.tokenSessionStorageService.removeToken();
                window.location.href = `${window.location.origin.replace('ci', 'cr')}/auth?token=${token}`;
            }

            if (this.userService.isHotelUser && isCr) {
                this.tokenSessionStorageService.removeToken();
                window.location.href = `${window.location.origin.replace('cr', 'ci')}/auth?token=${token}`;
            }
        }

        return true;
    }
}
