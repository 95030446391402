import { Inject, injectable } from 'inversify-props';
import CustomNotificationService, { CustomNotificationServiceS } from '../common/modules/custom-notification/custom-notification.service';
import CarsExcelReportApiService, { CarsExcelReportApiServiceS } from './cars-excel-report-api.service';
import CarsSharedService, { CarsSharedServiceS } from './cars-shared.service';

export const CarsExcelReportServiceS = Symbol.for('CarsExcelReportServiceS');
@injectable(CarsExcelReportServiceS as unknown as string)
export default class CarsExcelReportService {
    @Inject(CarsExcelReportApiServiceS) protected carExcelReportApiService!: CarsExcelReportApiService;
    @Inject(CarsSharedServiceS) protected carsSharedService!: CarsSharedService;
    @Inject(CustomNotificationServiceS) private customNotificationService!: CustomNotificationService;

    public async generateExcelReport(report: Record<string, any>): Promise<boolean> {
        const chain = this.carsSharedService.currentChain;
        const { isVans } = this.carsSharedService.carsStoreState.settings;
        const result = await this.carExcelReportApiService.generateExcelReport(chain, report, isVans);
        if (result && !report?.optional?.users_email) {
            await this.customNotificationService.handleExcel(result);
        }
        return true;
    }
}
