import { Inject, injectable } from 'inversify-props';
import { AxiosRequestConfig } from 'axios';
import { plainToClass } from 'class-transformer';
import ConfigModel from '@/modules/config/models/config.model';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';

export const ConfigServiceS = Symbol.for('ConfigServiceS');
@injectable(ConfigServiceS as unknown as string)
export default class ConfigService {
    @Inject(ApiServiceS) private apiService!: ApiService;

    private config: ConfigModel | null = null;

    async init() {
        const response = await this.apiService.get('/app.config.json');

        if (response && response.data) {
            this.config = plainToClass(ConfigModel, <ConfigModel> response.data, { excludeExtraneousValues: true });
            this.apiService.setRequestInterceptor(this.setBaseUrl.bind(this));
        }
    }

    setBaseUrl(req: AxiosRequestConfig) {
        return {
            ...req,
            baseURL: this.apiUrl || '',
        };
    }

    get apiUrl(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_API_URL;
    }

    get fornovaApp(): string {
        if (!this.config) {
            return '';
        }
        return this.config.FORNOVA_APP || '';
    }

    get socketUrl(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_SOCKET_URL;
    }

    get ssoUrl(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_SSO_URL;
    }

    get googleKey(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_GOOGLE_KEY;
    }

    get diUrl(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_DI_URL;
    }

    get diAdminUrl(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_DI_ADMIN_URL;
    }

    get biUrl(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_BI_URL;
    }

    get ecUrl(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_EC_URL;
    }

    get tableauServer(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_TABLEAU_SERVER;
    }

    get parityTableauName(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_PARITY_TABLEAU_NAME;
    }

    get fleetDensityTableauName(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_FLEET_DENSITY_TABLEAU_NAME;
    }

    get appVersion(): string | undefined {
        return process.env.VUE_APP_VERSION;
    }

    get zendeskKey() {
        return this.config ? this.config.VUE_APP_ZENDESK_KEY : null;
    }
    get zendeskWidgetUrl() {
        return 'https://static.zdassets.com/ekr/snippet.js';
    }

    get walkmeUrl() {
        return this.config ? this.config.VUE_APP_WALKME_URL || null : null;
    }

    get walkmeUser() {
        return this.config ? this.config.VUE_APP_WALKME_USER || null : null;
    }

    get whatfixUrl() {
        return this.config ? this.config.VUE_APP_WHATFIX_URL || null : null;
    }

    get ciUpgradeUrl() {
        return this.config ? this.config.VUE_APP_CI_UPGRADE_URL || null : null;
    }

    get diUpgradeUrl() {
        return this.config ? this.config.VUE_APP_DI_UPGRADE_URL || null : null;
    }

    get adminUrl() {
        return this.config ? this.config.VUE_APP_ADMIN_URL || null : null;
    }
}
