import arraysAreIdentical from '@/modules/common/filters/arrays-are-identical';
import { Inject, injectable } from 'inversify-props';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import RankingDocument from '@/modules/ranking/models/ranking-document.model';
import RankingCommonService, { RankingCommonServiceS } from '@/modules/common/modules/ranking/ranking-common.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import ClusterStore from './store/cluster.store';
import ProvidersService, { ProvidersServiceS } from '../providers/providers.service';
import ClusterApiService, { ClusterApiServiceS } from './cluster-api.service';
import CustomNotificationService, { CustomNotificationServiceS } from '../common/modules/custom-notification/custom-notification.service';

export const ClusterRankingServiceS = Symbol.for('ClusterRankingServiceS');
@injectable(ClusterRankingServiceS as unknown as string)
export default class ClusterRankingService implements Stateable {
    @Inject(StoreFacadeS)
    public storeFacade!: StoreFacade;

    @Inject(HelperServiceS)
    private helperService!: HelperService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(ProvidersServiceS)
    private providerService!: ProvidersService;

    @Inject(ClusterServiceS)
    private clusterService!: ClusterService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(RankingCommonServiceS)
    private rankingCommonService!: RankingCommonService;

    @Inject(ClusterApiServiceS)
    private clusterApiService!: ClusterApiService;

    @Inject(CustomNotificationServiceS)
    private customNotificationService!: CustomNotificationService;

    readonly storeState: ClusterStore = this.storeFacade.getState('ClusterStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.clusterService.storeState.provider,
            this.documentFiltersService.storeState.settings.month,
            this.documentFiltersService.storeState.settings.year,
        ], this.resetLoading.bind(this));

        this.storeFacade.watch(
            () => this.providerService.storeState.providerFilter,
            (n: string[], o: string[]) => {
                if (!arraysAreIdentical(n, o)) {
                    this.resetLoading();
                }
            },
        );
    }

    get isLoading() {
        return this.storeState.rankingLoading.isLoading();
    }

    get clusterRankingsData() {
        this.helperService.dynamicLoading(this.storeState.rankingLoading, this.clusterService.loadData.bind(this.clusterService, 'ranking'));
        return this.storeState.clusterHotels;
    }

    minMaxCompetitiveRating(document: any, provider: string, compsetId: string): [number, number] | null {
        const currentHotelId = this.getCurrentHotelId(compsetId);
        return this.rankingCommonService.minMaxCompetitiveRating(document, provider, currentHotelId);
    }

    minMaxCompetitiveReview(document: any, provider: string, compsetId: string): [number, number] | null {
        const currentHotelId = this.getCurrentHotelId(compsetId);
        return this.rankingCommonService.minMaxCompetitiveReview(document, provider, currentHotelId);
    }

    private getCurrentHotelId(compsetId: string) {
        const hotel = this.getHotelBy({ compsetId });
        return hotel && hotel.hotelId;
    }

    hotelCompetitors(document: any, provider: string) {
        if (!document) {
            return null;
        }

        const providerHotels = document.providers[provider];

        if (!providerHotels) {
            return null;
        }

        return providerHotels;
    }

    get providers() {
        const providers = new Set<string>();

        if (!this.clusterRankingsData || !this.storeState.rankingLoading.finishDate) {
            return Array.from(providers) as string[];
        }
        this.clusterRankingsData.forEach(hotel => {
            if (hotel.compsetMain) {
                const compsetMain = hotel.compsetMain as RankingDocument;
                Object.keys(compsetMain.providers).forEach(provider => providers.add(provider));
            }
        });

        return Array.from(providers).sort((a, b) => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
        }) as string[];
    }

    getHotelBy({ compsetId, hotelId }: { compsetId?: string, hotelId?: number}) {
        if ((!compsetId && !hotelId) || !this.clusterRankingsData) {
            return null;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const rankingData of this.clusterRankingsData) {
            if (compsetId) {
                const needCompset = rankingData.compsets.find(item => item.id === compsetId);

                if (needCompset) {
                    return rankingData;
                }
            } else if (rankingData.hotelId === hotelId) {
                return rankingData;
            }
        }

        return null;
    }

    resetLoading() {
        this.storeState.rankingLoading.reset();
    }

    hotels(provider: string) {
        if (!this.userService.currentHotelId) {
            return null;
        }
        const hotel = this.getHotelBy({ hotelId: this.userService.currentHotelId });
        if (!hotel || !hotel.compsetMain) {
            return null;
        }
        return this.rankingCommonService.hotels(hotel.compsetMain as RankingDocument, provider);
    }

    async downloadExcel() {
        // TODO add notifications
        const excelData = await this.clusterApiService.getGuestReviewsExcelDocument();

        if (excelData) {
            await this.customNotificationService
                .handleExcel(excelData);
        }
    }
}
