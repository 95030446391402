import { injectable } from 'inversify-props';

interface CacheData {
    [module: number]: {
        [method: string]: {
            [hash: string]: {
                data: any,
                expires: Date,
            };
        }
    }
}

enum CARS_METHODS {
    getCars = 'getCars',
    getFleetAvailability = 'getFleetAvailability',
}

enum COMPSETS_METHODS {
    getCompsetsReq = 'getCompsetsReq',
}

type ModuleMethods = CARS_METHODS | COMPSETS_METHODS;

export enum MODULES {
    CARS,
    COMPSETS,
}

export const MODULE_METHODS = {
    [MODULES.CARS]: CARS_METHODS,
    [MODULES.COMPSETS]: COMPSETS_METHODS,
};

export const CacheServiceS = Symbol.for('CacheServiceS');
@injectable(CacheServiceS as unknown as string)
export default class CacheService {
    private data: CacheData = {};

    /* Default expiration time of cached data (in minutes) */
    private expirationTime: number = 30;

    private getBank(moduleName: MODULES, methodName: ModuleMethods) {
        if (!this.data[moduleName]) {
            this.data[moduleName] = {
                [methodName]: {},
            };
            return this.data[moduleName][methodName];
        }

        if (!this.data[moduleName][methodName]) {
            this.data[moduleName][methodName] = {};
        }

        return this.data[moduleName][methodName];
    }

    public save(moduleName: MODULES, methodName: ModuleMethods, deps: any[], data: any, cacheTime = this.expirationTime) {
        if (!data) return;

        if (Array.isArray(data) && !data.length) {
            return;
        }

        const isPromise = data instanceof Promise;

        if (!isPromise) {
            if (typeof data === 'object' && !Object.keys(data).length) {
                return;
            }
        }

        const hash = this.generateHash(deps);
        const expires = new Date();
        expires.setMinutes(expires.getMinutes() + cacheTime);

        this.getBank(moduleName, methodName)[hash] = {
            data,
            expires,
        };
    }

    public get<T>(moduleName: MODULES, methodName: ModuleMethods, deps: any[]): T | null {
        const hash = this.generateHash(deps);
        const cachedData = this.getBank(moduleName, methodName)[hash];

        if (cachedData) {
            if (cachedData.expires < new Date()) {
                this.erase(moduleName, methodName, deps);
                return null;
            }
            return cachedData.data;
        }

        return null;
    }

    /* Removes module or method or specific value from cache */
    public erase(moduleName: MODULES, methodName?: ModuleMethods, deps?: any[]) {
        if (!methodName) {
            delete this.data[moduleName];
            return;
        }

        if (!deps) {
            delete this.data[moduleName][methodName];
            return;
        }

        const hash = this.generateHash(deps);
        delete this.getBank(moduleName, methodName)[hash];
    }

    /* Decorates function, returned functions returns cached data */
    public memorize<T extends(...args: any[]) => any>
    (moduleName: MODULES, methodName: ModuleMethods, method: T, cacheTime: number = this.expirationTime) {
        return (...deps: Parameters<T>) => {
            const data = this.get(moduleName, methodName, deps);

            if (data) {
                return data;
            }

            const result = method(...deps);

            this.save(moduleName, methodName, deps, result, cacheTime);

            return result;
        };
    }

    private generateHash(deps: any[]) {
        return JSON.stringify(deps.sort());
    }
}
