import { Inject, injectable } from 'inversify-props';
import { Route } from 'vue-router';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { IGuard } from '@/router/interfaces/guard.interface';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import { APP_ROUTES } from '@/modules/cars/constants/app_routes.constant';

export const CarGuardS = Symbol.for('CarGuardS');
@injectable(CarGuardS as unknown as string)
export default class CarGuard implements IGuard {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    async canActivate(to: Route, from: Route): Promise<true | false | string> {
        if (to.path && this.carsSharedService.isChildChain
            && [APP_ROUTES.notifications.index, APP_ROUTES.carUserSettings.index].includes(to.path.slice(1, to.path.length))
        ) {
            return '/cars-rates';
        }
        return this.userService.isCarUser || false;
    }
}
