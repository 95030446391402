import { container } from 'inversify-props';
import { Route, RouteConfig } from 'vue-router';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import use from '@/router/use';
import { CommonMenuRoutes } from '../common.routes';

export default function DeepAnalysisHotelLevelRoutes(basePath: string, prefix: string): RouteConfig {
    return {
        path: basePath,
        component: () => import('@/modules/deep-analysis/pages/deep-analysis-hotel-transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS)]),
        props: (route: Route) => ({ hotelId: Number(route.params.hotelId) }),
        children: [
            {
                name: prefix,
                path: '',
                component: () => import('@/modules/deep-analysis/pages/deep-analysis.page.vue'),
                children: [
                    {
                        path: ':id',
                        name: `${prefix}.modal`,
                        component: () => import('@/modules/deep-analysis/components/hotel/deep-analysis.modal.vue'),
                        props: (route: Route) => ({ id: route.params.id }),
                        meta: { modal: true },
                    },
                    ...CommonMenuRoutes(`${prefix}.modal`),
                ],
            },
        ],
    };
}
