import { Inject, injectable } from 'inversify-props';
import { Route } from 'vue-router';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { IGuard } from '@/router/interfaces/guard.interface';

export const HotelGuardS = Symbol.for('HotelGuardS');
@injectable(HotelGuardS as unknown as string)
export default class HotelGuard implements IGuard {
    @Inject(UserServiceS) private userService!: UserService;

    async canActivate(to: Route) {
        if (!this.userService.currentHotelId) return false;

        const isViewAsRoute = true
            && this.userService.userLevel !== 'hotel'
            && !!to.params.hotelId;

        return isViewAsRoute
            || this.userService.userLevel === 'hotel'
            || this.userService.viewAs === 'hotel';
    }
}
