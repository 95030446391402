import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import CarFilterModel from '@/modules/cars/models/cars-filters.model';
import BranchesModel from '@/modules/cars/models/branches.model';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';

export const CarsFilterApiServiceS = Symbol.for('CarsFilterApiServiceS');
@injectable(CarsFilterApiServiceS as unknown as string)
export default class CarsFilterApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(ValidatorServiceS) private validatorService!: ValidatorService;

    async getCarFilters(chain: BranchesModel | null | undefined): Promise<CarFilterModel | null> {
        const chainId = chain ? chain.chainId : '';
        const childParam = `?child_chain_id=${chainId}`;
        const { data } = await this.apiService.get(`car/supported/car-filters${childParam}`);

        const carFiltersDocument = plainToClass(CarFilterModel, <CarFilterModel>data, { excludeExtraneousValues: true });

        // const error = await this.validatorService.validateResponse(carFiltersDocument);
        // if (error) {
        //     throw new ResponseValidationException(error);
        // }

        return carFiltersDocument;
    }

    async chainBranches(): Promise<BranchesModel | null> {
        const { data } = await this.apiService.get('car/get-branches');

        const chainBranches = plainToClass(BranchesModel, <BranchesModel>data, { excludeExtraneousValues: true });
        // const error = await this.validatorService.validateResponse(carFiltersDocument);
        // if (error) {
        //     throw new ResponseValidationException(error);
        // }

        return chainBranches;
    }
}
