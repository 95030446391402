import { Inject, injectable } from 'inversify-props';
import RoomsTypeManagerApiService, { RoomsTypeManagerApiServiceS } from '@/modules/rooms-type-manager/rooms-type-manager-api.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import HotelsRoomTypeStore from '@/modules/rooms-type-manager/store/hotels-room-type.store';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import { plainToClass } from 'class-transformer';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import CompsetsService, { CompsetsServiceS } from '../compsets/compsets.service';
import HotelsRoomTypeModel from './models/hotels-room-type.model';

export const RoomsTypeManagerServiceS = Symbol.for('RoomsTypeManagerServiceS');
@injectable(RoomsTypeManagerServiceS as unknown as string)
export default class RoomsTypeManagerService {
    @Inject(RoomsTypeManagerApiServiceS)
    private hotelsRoomTypeApiService!: RoomsTypeManagerApiService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @Inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(HelperServiceS)
    private helperService!: HelperService;

    readonly storeState: HotelsRoomTypeStore = this.storeFacade.getState('HotelsRoomTypeStore');

    constructor() {
        this.storeFacade.watch(
            () => this.compsetsService.storeState.compsets,
            this.storeState.loading.reset.bind(this.storeState.loading),
        );
    }

    get source() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.source;
    }

    get providers(): string[] | null {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        const { hotelsRoomType } = this.storeState;

        if (hotelsRoomType) {
            return Object.keys(hotelsRoomType.providers);
        }

        return null;
    }

    get isIgnoreListUpdating() {
        return this.storeState.isIgnoreListUpdating;
    }

    async loadData() {
        const hotelsRoomType = await this.hotelsRoomTypeApiService.getHotelsRoomType();
        this.storeState.hotelsRoomType = hotelsRoomType;

        if (this.providers) {
            const [defaultProvider] = this.providers;
            this.saveSource(defaultProvider);
        }

        return true;
    }

    saveSource(source: string | null) {
        this.storeState.source = source;
    }

    addLocalChanges(hotelId: number, roomName: string, newRoomType: number) {
        const { localHotelsRoomType, hotelsRoomType } = this.storeState;
        const documentData = localHotelsRoomType || hotelsRoomType;
        const hotelRooms:{ [roomName: string]: number } = { ...this.roomsByHotel(hotelId) };

        if (documentData && hotelRooms && hotelRooms[roomName] && this.source) {
            hotelRooms[roomName] = newRoomType;
            const newDocumentLocalChanges = {
                providers: {
                    ...documentData.providers,
                    [this.source]: {
                        ...documentData.providers[this.source],
                        [hotelId]: {
                            ...hotelRooms,
                        },
                    },
                },
            };
            this.storeState.localHotelsRoomType = newDocumentLocalChanges;
        }
    }

    async save() {
        const { localHotelsRoomType } = this.storeState;
        if (localHotelsRoomType) {
            const providers = this.providers || [];
            Promise.all(providers.map(async provider => {
                const hotelsRoomTypesProvider = {
                    providers: {
                        [provider]: {
                            ...localHotelsRoomType.providers[provider],
                        },
                    },
                };
                const newHotelsRoomTypeModel = plainToClass(HotelsRoomTypeModel, hotelsRoomTypesProvider, { excludeExtraneousValues: true });
                const updatedHotelsRoomType = await this.hotelsRoomTypeApiService.updateHotelsRoomType(newHotelsRoomTypeModel);
                if (updatedHotelsRoomType) {
                    this.storeState.hotelsRoomType = updatedHotelsRoomType;
                    this.resetLocalChanges();
                }
            }));
        }
    }

    resetLocalChanges() {
        this.storeState.localHotelsRoomType = null;
    }

    get hotels() {
        const { hotelsRoomType } = this.storeState;

        if (!hotelsRoomType || !this.source || !hotelsRoomType.providers[this.source]) {
            return null;
        }

        const hotels = Object.keys(hotelsRoomType.providers[this.source]).map((hotel: string) => ({
            name: this.hotelsService.getHotelName(Number(hotel)),
            id: Number(hotel),
        }));

        return hotels;
    }

    roomsByHotel(hotel: number): { [roomName: string]: number } | null {
        const { localHotelsRoomType, hotelsRoomType } = this.storeState;
        const documentData = localHotelsRoomType || hotelsRoomType;

        if (!documentData || !this.source || !documentData.providers[this.source]) {
            return null;
        }
        return documentData.providers[this.source][hotel];
    }

    isMappedRomType(roomTypeId: number) {
        let hotelsRooms : {
            [roomName: string]: number
        } = {};

        if (this.hotels) {
            this.hotels.forEach(hotel => {
                const hotelRooms = this.roomsByHotel(hotel.id);

                hotelsRooms = { ...hotelsRooms, ...hotelRooms };
            });
        }

        const allRoomTypes: number[] = Object.keys(hotelsRooms).map(key => hotelsRooms[key]);

        return allRoomTypes.some(x => Number(x) === roomTypeId);
    }

    updateIgnoredList(hotelId: number, list: string[]) {
        this.storeState.ignoredRoomNames = {
            ...this.storeState.ignoredRoomNames,
            [hotelId]: list || [],
        };
    }

    async getIgnoredRoomList(hotelId: number, cached = false) {
        if (cached) {
            return this.storeState.ignoredRoomNames[hotelId] || [];
        }

        const list = await this.hotelsRoomTypeApiService
            .getIgnoredRoomList(hotelId);

        this.updateIgnoredList(hotelId, list);

        return this.storeState.ignoredRoomNames[hotelId] || [];
    }

    async toggleIgnoreRoom(hotelId: number, name: string) {
        this.storeState.isIgnoreListUpdating = true;
        try {
            const oldList = await this.getIgnoredRoomList(hotelId, true);
            let updatedList = [...oldList];

            if (updatedList.includes(name)) {
                updatedList = updatedList.filter(n => name !== n);
            } else {
                updatedList.push(name);
            }

            const newList = await this.hotelsRoomTypeApiService
                .updateIgnoreList(hotelId, updatedList);

            this.updateIgnoredList(hotelId, newList);
        } finally {
            this.storeState.isIgnoreListUpdating = false;
        }
    }
}
