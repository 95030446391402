enum SCORE_THRESHOLDS {
    EXCELLENT = 100,
    GOOD = 90,
    FAIR = 80,
    POOR = 70,
    CRITICAL = 60,
    MIN = 0,
}

export default SCORE_THRESHOLDS;
