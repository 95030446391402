import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';

const STATISTIC_NAMES = {
    [STATISTIC_TYPE.REVENUE]: 'Revenue',
    [STATISTIC_TYPE.REVENUE_SHARE]: 'Revenue Share',
    [STATISTIC_TYPE.NET_ADR]: 'Net ADR',
    [STATISTIC_TYPE.ROOM_NIGHTS]: 'Net Room Nights',
    [STATISTIC_TYPE.CANCELLATION]: 'Cancellations',
    [STATISTIC_TYPE.AVG_LOS]: 'Avg. LOS',
    [STATISTIC_TYPE.PAGE_VIEWS]: 'Page views',
    [STATISTIC_TYPE.PAGE_VIEWS_SHARE]: 'Page Views Share',
    [STATISTIC_TYPE.GROSS_CONVERSION]: 'Gross Conversion',
    [STATISTIC_TYPE.PACKAGE_SHARE]: 'Package Share',
    [STATISTIC_TYPE.AVERAGE_BOOKING_WINDOW]: 'Average Booking Window',
    [STATISTIC_TYPE.CORPORATE_TRAVEL_SHARE]: 'Corporate Travel Share',
    [STATISTIC_TYPE.INTERNATIONAL_SHARE]: 'International Share',
    [STATISTIC_TYPE.PROMOTION_SHARE]: 'Promotion Share',
    [STATISTIC_TYPE.CONVERSION]: 'Conversion (bookings)',
};

export default STATISTIC_NAMES;
