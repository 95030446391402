import { Module, VuexModule } from 'vuex-module-decorators';
import EventsItemModel from '@/modules/events/models/events-item.model';
import EventsSettingsModel from '@/modules/events/models/events-setting.model';
import LoadingModel from '@/modules/common/models/loading.model';

@Module
export default class EventsStore extends VuexModule {
    loading: LoadingModel = new LoadingModel();
    settings: EventsSettingsModel = new EventsSettingsModel();
    filtersReady: boolean = false;
    useFreshCountry: boolean = true;
    cacheEvents: { [p: string]: EventsItemModel } = {};
    cacheLoaded: { [p: string]: number } = {};
    countries: {[code: string]: string} = {};
}
