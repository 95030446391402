/* eslint-disable camelcase */
import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import ResponseValidationException from '@/modules/common/modules/exception-handler/exceptions/response-validation.exception';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import BrokerDataModel from '@/modules/cars/modules/parity/models/broker-data.model';
import KpisDataModel from '@/modules/cars/modules/parity/models/kpis-data.model';
import KpisMapDataModel from '@/modules/cars/modules/parity/models/kpis-map-data.model';
import { BROKER, BRAND } from '@/modules/cars/constants/data-source-mode.constant';
import ParityDocumentItemModel from './models/parity-document-item.model';
import ParitySettingsModel from './models/parity-settings.model';
import { ITrend, ISpread } from './interfaces/graph.interface';
import { ISort } from './interfaces/grid.interface';
import BmlFilterType from './types/bml-filter.type';

interface IMongoQuery {
    $in?: ({} | string | number)[] | null
    $lt?: number | null
    $gt?: number | null
    $gte?: number | null
    $lte?: number | null
}

interface IFilter {
    customer_id?: string | null
    'latest_bml.broker_data.provider_name'?: string | null
    'latest_bml.brand_data.provider_name'?: string | null
    'latest_bml.bml_classification'?: IMongoQuery | null
    transmission?: IMongoQuery | null
    mileage_policy?: IMongoQuery | null
    rate_type?: IMongoQuery | null
    lor?: IMongoQuery | null
    pos?: IMongoQuery | null
    fn_pick_up_location_code?: IMongoQuery | null
    customer_category_id?: IMongoQuery | null
    pick_up_date?: string | null
    'latest_bml.bml_price_diff_ratio'?: IMongoQuery | null
    $or?: {
        'latest_bml.bml_classification'?: IMongoQuery | null
        'latest_bml.bml_price_diff_ratio'?: IMongoQuery | null
    }[] | null
    $and?:any[] | null
    chain_mode?:string | null
    broker_name?:string | null
}

export const ParityApiServiceS = Symbol.for('ParityApiServiceS');
@injectable(ParityApiServiceS as unknown as string)
export default class ParityApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(ValidatorServiceS) private validatorService!: ValidatorService;

    async getBmlCount(filterSettings: ParitySettingsModel, brokerName: string, chainMode: string): Promise<number | null> {
        const url = '/bml/get-bmls-count';

        const allBml: (BmlFilterType | 'AB' | 'AU' | 'AM')[] | null = filterSettings.bml ? [...filterSettings.bml] : null;

        if (allBml && filterSettings.bml && filterSettings.bml.find(bml => bml === 'A')) {
            allBml.push('AB', 'AU', 'AM');
        }

        const body: { filter: IFilter } = {
            filter: {
                // To get only available
                'latest_bml.bml_classification': { $in: allBml },

                transmission: { $in: filterSettings.transmission },
                mileage_policy: { $in: filterSettings.mileage },
                rate_type: { $in: filterSettings.paymentTerm },
                lor: { $in: filterSettings.lor },
                pos: { $in: filterSettings.pos },
                fn_pick_up_location_code: { $in: filterSettings.pickUpCityCodes },
                customer_category_id: { $in: filterSettings.carClasses },
            },
        };

        if (filterSettings.priceDif && allBml?.length) {
            const notAvailablePriceDif = JSON.stringify([null, null]);
            const notAvailableBml = ['A', 'AM', 'AU', 'AB'];

            if (filterSettings.priceDif.length === 1 && JSON.stringify(filterSettings.priceDif[0]) === notAvailablePriceDif) {
                body.filter['latest_bml.bml_classification'] = { $in: notAvailableBml };
            } else if (filterSettings.priceDif.length === 1) {
                let gt = '$gt';
                let lt = '$lt';

                if (filterSettings.priceDif[0][0] && filterSettings.priceDif[0][0] < 0) gt = '$gte';
                if (filterSettings.priceDif[0][1] && filterSettings.priceDif[0][1] > 0) lt = '$lte';

                const query: { $gt?: number, $lte?: number } = {
                    [gt]: filterSettings.priceDif[0][0] || undefined,
                    [lt]: filterSettings.priceDif[0][1] || undefined,
                };

                body.filter['latest_bml.bml_price_diff_ratio'] = query;
                body.filter['latest_bml.bml_classification'] = { $in: allBml.filter(bml => !notAvailableBml.find(naBml => naBml === bml)) };
            } else {
                const query: {
                    'latest_bml.bml_classification'?: IMongoQuery | null
                    'latest_bml.bml_price_diff_ratio'?: IMongoQuery | null
                }[] = filterSettings.priceDif.map(range => ({
                    'latest_bml.bml_price_diff_ratio': {
                        $gt: range[0] || undefined,
                        $lte: range[1] || undefined,
                    },
                }));

                if (filterSettings.priceDif.find(dif => JSON.stringify(dif) === notAvailablePriceDif)) {
                    query.push({ 'latest_bml.bml_classification': { $in: notAvailableBml } as IMongoQuery });
                } else {
                    body.filter['latest_bml.bml_classification'] = { $in: allBml.filter(bml => !notAvailableBml.find(naBml => naBml === bml)) };
                }

                body.filter.$or = query;
            }
        }

        if (chainMode === BROKER && filterSettings.provider) {
            const [, brandName] = filterSettings.provider.split(',');
            body.filter['latest_bml.brand_data.provider_name'] = brandName;
        } else {
            body.filter['latest_bml.broker_data.provider_name'] = filterSettings.provider;
        }

        if (filterSettings.pickUpDate) {
            body.filter.pick_up_date = new Date(filterSettings.pickUpDate).toISOString();
        }

        const { data } = await this.apiService.post(url, body);

        if (!data || data.status === 'no data') {
            return null;
        }

        // TODO add validation

        return data;
    }

    async getBmlGrid(
        settings: { page: number, pageLimit: number },
        filterSettings: ParitySettingsModel,
        sorting: ISort,
        brokerName: string,
        chainMode: string,
    ): Promise<ParityDocumentItemModel[] | null> {
        const { page, pageLimit } = settings;
        const url = '/bml/get-bmls-grid';

        const allBml: (BmlFilterType | 'AB' | 'AU' | 'AM')[] | null = filterSettings.bml ? [...filterSettings.bml] : null;

        if (allBml && filterSettings.bml && filterSettings.bml.find(bml => bml === 'A')) {
            allBml.push('AB', 'AU', 'AM');
        }

        const body: { filter: IFilter, projection: {}, sort: {}, page: number, page_limit: number } = {
            filter: {
                'latest_bml.bml_classification': { $in: allBml },

                transmission: { $in: filterSettings.transmission },
                mileage_policy: { $in: filterSettings.mileage },
                rate_type: { $in: filterSettings.paymentTerm },
                lor: { $in: filterSettings.lor },
                pos: { $in: filterSettings.pos },
                fn_pick_up_location_code: { $in: filterSettings.pickUpCityCodes },
                customer_category_id: { $in: filterSettings.carClasses },
            },
            projection: {
                fn_pick_up_location_code: 1,
                pick_up_date: 1,
                customer_category_id: 1,
                customer_id: 1,
                fn_car_id: 1,
                latest_bml: 1,
                lor: 1,
                pos: 1,
                mileage_policy: 1,
                transmission: 1,
                rate_type: 1,
                creation_timestamp: 1,
                brand_data_from: 1,
                broker_data_from: 1,
            },
            sort: {},
            page,
            page_limit: pageLimit,
        };

        if (chainMode === BROKER && filterSettings.provider) {
            const [, brandName] = filterSettings.provider.split(',');
            body.filter['latest_bml.brand_data.provider_name'] = brandName;
        } else {
            body.filter['latest_bml.broker_data.provider_name'] = filterSettings.provider;
        }

        if (filterSettings.priceDif && allBml?.length) {
            const notAvailablePriceDif = JSON.stringify([null, null]);
            const notAvailableBml = ['A', 'AM', 'AU', 'AB'];

            if (filterSettings.priceDif.length === 1 && JSON.stringify(filterSettings.priceDif[0]) === notAvailablePriceDif) {
                body.filter['latest_bml.bml_classification'] = { $in: notAvailableBml };
            } else if (filterSettings.priceDif.length === 1) {
                let gt = '$gt';
                let lt = '$lt';

                if (filterSettings.priceDif[0][0] && filterSettings.priceDif[0][0] < 0) gt = '$gte';
                if (filterSettings.priceDif[0][1] && filterSettings.priceDif[0][1] > 0) lt = '$lte';

                const query: { $gt?: number, $lte?: number } = {
                    [gt]: filterSettings.priceDif[0][0] || undefined,
                    [lt]: filterSettings.priceDif[0][1] || undefined,
                };

                body.filter['latest_bml.bml_price_diff_ratio'] = query;
                body.filter['latest_bml.bml_classification'] = { $in: allBml.filter(bml => !notAvailableBml.find(naBml => naBml === bml)) };
            } else {
                const query: {
                    'latest_bml.bml_classification'?: IMongoQuery | null
                    'latest_bml.bml_price_diff_ratio'?: IMongoQuery | null
                }[] = filterSettings.priceDif.map(range => ({
                    'latest_bml.bml_price_diff_ratio': {
                        $gt: range[0] || undefined,
                        $lte: range[1] || undefined,
                    },
                }));

                if (filterSettings.priceDif.find(dif => JSON.stringify(dif) === notAvailablePriceDif)) {
                    query.push({ 'latest_bml.bml_classification': { $in: notAvailableBml } as IMongoQuery });
                } else {
                    body.filter['latest_bml.bml_classification'] = { $in: allBml.filter(bml => !notAvailableBml.find(naBml => naBml === bml)) };
                }

                body.filter.$or = query;
            }
        }

        if (filterSettings.pickUpDate) {
            body.filter.pick_up_date = new Date(filterSettings.pickUpDate).toISOString();
        }

        if (sorting.sortBy && sorting.sortOrder) {
            switch (sorting.sortBy) {
                case 'pickUpCity':
                    body.sort = { pick_up_location_name: sorting.sortOrder };
                    break;
                case 'priceDif':
                    body.sort = { 'latest_bml.bml_price_diff_ratio': sorting.sortOrder };
                    break;
                default:
                    body.sort = {};
            }
        }

        const { data } = await this.apiService.post(url, body);

        if (!data || data.status === 'no data') {
            return null;
        }

        const parityDocuments = plainToClass(ParityDocumentItemModel, <ParityDocumentItemModel[]> data.documents, { excludeExtraneousValues: true });

        // TODO add validation

        return parityDocuments;
    }

    async getBrokers(
        filterSettings: ParitySettingsModel,
        brokerName: string,
        chainMode: string,
        brokersCompetitors: string[],
    ): Promise<BrokerDataModel[] | null> {
        const url = '/bml/get-brokers-insights-table';

        const body: any = {
            filter: {
                fn_pick_up_location_code: { $in: filterSettings.pickUpCityCodes },
                lor: { $in: filterSettings.lor },
            },
        };

        if (chainMode !== BROKER) {
            body.filter['latest_bml.broker_data.provider_name'] = { $in: brokersCompetitors };
        }

        const { data } = await this.apiService.post(url, body);

        const brokers = plainToClass(BrokerDataModel, <BrokerDataModel[]> data, { excludeExtraneousValues: true });

        return brokers;
    }

    async getKpisMap(
        filterSettings: ParitySettingsModel,
        brokerName: string,
        chainMode: string,
        brokersCompetitors: string[],
    ): Promise<KpisMapDataModel[] | null> {
        const url = '/bml/get-locations-insights-map';

        const body: any = {
            filter: {
                lor: { $in: filterSettings.lor },
                fn_pick_up_location_code: { $in: filterSettings.pickUpCityCodes },
            },
        };

        if (chainMode !== BROKER) {
            body.filter['latest_bml.broker_data.provider_name'] = { $in: brokersCompetitors };
        }

        const { data } = await this.apiService.post(url, body);

        const map = plainToClass(KpisMapDataModel, <KpisMapDataModel[]> data, { excludeExtraneousValues: true });

        return map;
    }

    async getKpis(
        filterSettings: ParitySettingsModel,
        brokerName: string,
        chainMode: string,
        brokersCompetitors: string[],
    ): Promise<KpisDataModel[] | null> {
        const url = '/bml/get-top-kpis';

        const body: any = {
            filter: {
                lor: { $in: filterSettings.lor },
                fn_pick_up_location_code: { $in: filterSettings.pickUpCityCodes },
            },
        };

        if (chainMode !== BROKER) {
            body.filter['latest_bml.broker_data.provider_name'] = { $in: brokersCompetitors };
        }

        const { data } = await this.apiService.post(url, body);

        const kpis = plainToClass(KpisDataModel, <KpisDataModel[]> data);

        return kpis;
    }

    async getBrokersPerformance(
        filterSettings: ParitySettingsModel,
        brokerName: string,
        chainMode: string,
        brokersCompetitors: string[],
    ): Promise<{ [index:string] : { [index:string] : number } } | null> {
        const url = '/bml/get-brokers-performance';

        const body: any = {
            filter: {
                lor: { $in: filterSettings.lor },
                fn_pick_up_location_code: { $in: filterSettings.pickUpCityCodes },
            },
        };

        if (chainMode !== BROKER) {
            body.filter['latest_bml.broker_data.provider_name'] = { $in: brokersCompetitors };
        }

        const { data } = await this.apiService.post(url, body);

        return data;
    }

    async getLatestSpread(filterSettings: ParitySettingsModel, brokerName: string, chainMode: string): Promise<ISpread | null> {
        const url = '/bml/get-latest-spread';

        const allBml: (BmlFilterType | 'AB' | 'AU' | 'AM')[] | null = filterSettings.bml ? [...filterSettings.bml] : null;

        if (allBml && filterSettings.bml && filterSettings.bml.find(bml => bml === 'A')) {
            allBml.push('AB', 'AU', 'AM');
        }

        const body: { filter: IFilter } = {
            filter: {
                // To get only available
                'latest_bml.bml_classification': { $in: allBml },

                transmission: { $in: filterSettings.transmission },
                mileage_policy: { $in: filterSettings.mileage },
                rate_type: { $in: filterSettings.paymentTerm },
                lor: { $in: filterSettings.lor },
                pos: { $in: filterSettings.pos },
                fn_pick_up_location_code: { $in: filterSettings.pickUpCityCodes },
                customer_category_id: { $in: filterSettings.carClasses },
            },
        };

        if (filterSettings.pickUpDate) {
            body.filter.pick_up_date = new Date(filterSettings.pickUpDate).toISOString();
        }

        if (chainMode === BROKER && filterSettings.provider) {
            const [, brandName] = filterSettings.provider.split(',');
            body.filter['latest_bml.brand_data.provider_name'] = brandName;
        } else {
            body.filter['latest_bml.broker_data.provider_name'] = filterSettings.provider;
        }

        const { data } = await this.apiService.post(url, body);

        if (!data || !Object.keys(data).length) {
            return null;
        }

        // TODO add validation

        return data;
    }

    async getLatestTrending(filterSettings: ParitySettingsModel, brokerName: string, chainMode: string): Promise<ITrend | null> {
        const url = '/bml/get-latest-trending';

        const allBml: (BmlFilterType | 'AB' | 'AU' | 'AM')[] | null = filterSettings.bml ? [...filterSettings.bml] : null;

        if (allBml && filterSettings.bml && filterSettings.bml.find(bml => bml === 'A')) {
            allBml.push('AB', 'AU', 'AM');
        }

        const body: { filter: IFilter } = {
            filter: {
                // To get only available
                'latest_bml.bml_classification': { $in: allBml },

                transmission: { $in: filterSettings.transmission },
                mileage_policy: { $in: filterSettings.mileage },
                rate_type: { $in: filterSettings.paymentTerm },
                lor: { $in: filterSettings.lor },
                pos: { $in: filterSettings.pos },
                fn_pick_up_location_code: { $in: filterSettings.pickUpCityCodes },
                customer_category_id: { $in: filterSettings.carClasses },
            },
        };

        if (chainMode === BROKER && filterSettings.provider) {
            const [, brandName] = filterSettings.provider.split(',');
            body.filter['latest_bml.brand_data.provider_name'] = brandName;
        } else {
            body.filter['latest_bml.broker_data.provider_name'] = filterSettings.provider;
        }

        const { data } = await this.apiService.post(url, body);

        if (!data || !Object.keys(data).length) {
            return null;
        }

        // TODO add validation

        return data;
    }
}
