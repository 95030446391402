import { Route } from 'vue-router';
import use from '@/router/use';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import { container } from 'inversify-props';
import { CommonMenuRoutes } from '../common.routes';
import { RatesPriceHistory } from '../rates.routes';
import RatesHotelLevelRoutes from '../hotel/rates.routes';

function DayRatesCluster(prefix: string) {
    return {
        name: `${prefix}.day-rate`,
        path: 'day-rate/:day/:hotelId/:compsetId',
        props: (route: Route) => ({ day: route.params.day, hotelId: Number(route.params.hotelId), compsetId: route.params.compsetId }),
        component: () => import('@/modules/cluster/components/rates/rates-cluster-popup.vue'),
        meta: { modal: true },
        children: [
            RatesPriceHistory(`${prefix}.day-rate`),
        ],
    };
}

function ClusterScheduledReports(prefix: string) {
    return {
        name: `${prefix}.cluster-scheduled-reports-popup`,
        path: 'scheduled-reports',
        component: () => import('@/modules/cluster/pages/rates-cluster-report.modal.page.vue'),
        meta: { modal: true },
    };
}

function ClusterDownloadExcel(prefix: string) {
    return {
        name: `${prefix}.download-excel-popup`,
        path: 'download-excel',
        component: () => import('@/modules/cluster/pages/rates-cluster-excel.page.vue'),
        meta: { modal: true },
    };
}

export default function RatesClusterLevelRoutes(basePath: string, prefix: string) {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS)]),
        children: [
            {
                name: prefix,
                path: '',
                component: () => import('@/modules/cluster/pages/rates-cluster-list.page.vue'),
                children: [
                    ClusterDownloadExcel(prefix),
                    ClusterScheduledReports(prefix),
                    DayRatesCluster(prefix),
                    ...CommonMenuRoutes(prefix),
                ],
            },
            {
                name: `${prefix}.table`,
                path: 'table',
                component: () => import('@/modules/cluster/pages/rates-cluster-table.page.vue'),
                children: [
                    ClusterDownloadExcel(`${prefix}.table`),
                    ClusterScheduledReports(`${prefix}.table`),
                    DayRatesCluster(`${prefix}.table`),
                    ...CommonMenuRoutes(`${prefix}.table`),
                ],
            },
            RatesHotelLevelRoutes(':hotelId', `${prefix}.hotel`),
        ],
    };
}
