import { Inject, injectable } from 'inversify-props';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import SippApiService, { SippApiServiceS } from '@/modules/cars/modules/sipp/sipp-api.service';
import SippPageSettingsModel from '@/modules/cars/modules/sipp/models/sipp-page-settings.model';
import SippDiffModel from '@/modules/cars/modules/sipp/models/sipp-diff.model';
import CarsFilterApiService, { CarsFilterApiServiceS } from '@/modules/cars/cars-filter-api.service';
import CarsFilterService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import {
    TRANSMISSION_ANY, CAR_CLASS_ANY, POS_ANY, PROVIDER_ANY, PROVIDER_NONE, CAR_CLASS_BLANK, VEHICLE_TYPE_ANY, FUEL_TYPE_ANY, COUNTRIES_ANY,
} from '@/modules/cars/constants/car-filter-types.constant';
import SippLogChangeItemModel from '@/modules/cars/modules/sipp/models/sipp-log-change-item.model';
import SippItemModel from '@/modules/cars/modules/sipp/models/sipp-item.model';
import _ from 'lodash';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';
import SippStore from './store/sipp.store';
import StoreFacade, { StoreFacadeS } from '../../../common/services/store-facade';

export const SippServiceS = Symbol.for('SippServiceS');

@injectable(SippServiceS as unknown as string)
export default class SippService {
    @Inject(SippApiServiceS) private sippApiService!: SippApiService;
    @Inject(CarsFilterApiServiceS) private carsFilterApiService!: CarsFilterApiService;
    @Inject(CarsFiltersServiceS) private carsFilterService!: CarsFilterService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(SippSettingsServiceS) private sippSettingsService!: SippSettingsService;

    readonly storeState: SippStore = this.storeFacade.getState('SippStore');

    constructor() {
        this.storeFacade.watch(() => this.storeState.loadingFilters, this.loadFilters.bind(this));
        this.storeFacade.watch(
            () => [
                this.storeState.settings.customCode,
                this.storeState.settings.standardCode,
                this.storeState.settings.categoryName,
                this.storeState.settings.carName,
                this.storeState.settings.provider,
                this.storeState.settings.pos,
                this.storeState.settings.transmission,
                this.storeState.settings.carClasses,
                this.storeState.settings.vehicleType,
                this.storeState.settings.countryName,
                this.storeState.settings.fuelType,
                this.storeState.settings.carBrand,
                this.carsFilterService.storeState.settings.isVansSippCodePopup,
            ],
            this.resetLoading.bind(this),
        );
        this.storeFacade.watch(() => this.storeState.diffDocuments, this.resetDiffDocs.bind(this));
    }

    get currentPos() {
        return this.storeState.settings.pos;
    }

    get items() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadSippData.bind(this));
        return this.storeState.documents;
    }

    get newCategories() {
        return this.storeState.newCategories;
    }

    set newCategories(value) {
        const categories = [...this.storeState.newCategories, ...value];
        this.storeState.newCategories = [...new Set(categories)];
    }

    get diffDocuments() {
        return this.storeState.diffDocuments;
    }

    set diffDocuments(value) {
        this.storeState.diffDocuments = value;
    }

    async updateSippCode() {
        const { diffDocuments } = this.storeState;

        if (!this.storeState.documents || !diffDocuments) {
            return;
        }

        const result = await this.sippApiService.updateSippCode(this.storeState.diffDocuments);

        if (result && result?.status === 'update done') {
            this.storeState.diffDocuments.forEach(updatedDoc => {
                // eslint-disable-next-line no-underscore-dangle
                const docIndex = this.storeState.documents!.findIndex(currentDoc => currentDoc.id === updatedDoc._id);

                if (this.storeState.documents![docIndex]) {
                    // eslint-disable-next-line camelcase
                    this.storeState.documents![docIndex].customerSippCode = updatedDoc.new_customer_sipp_code || '';
                    this.storeState.documents![docIndex].categoryName = updatedDoc.new_customer_normalized_category || '';
                    this.storeState.documents![docIndex].vehicleType = updatedDoc.new_vehicle_type || '';
                    this.storeState.documents![docIndex].fuelType = updatedDoc.new_fuel_type || '';
                }
            });
            this.storeState.newCategories = [];
            this.storeState.diffDocuments = [];
            this.carsFilterService.storeState.loading.reset();
            this.storeState.loading.reset();
            await this.loadSippLogsData();
        }
    }

    async revertSippCode() {
        const { version } = this.storeState.historyLogs;
        const { documents } = this.storeState;

        if (!documents) {
            return;
        }
        if (!version) {
            return;
        }

        await this.sippApiService.revertSippCode(version);

        if (this.logsChanges) {
            this.logsChanges.forEach(item => {
                if (documents) {
                    const index = documents.findIndex(doc => item.id === doc.id);
                    if (index !== -1) {
                        documents[index].customerSippCode = item.currentCode;
                        documents[index].categoryName = item.newCategoryName;
                        documents[index].vehicleType = item.currentVehicleType;
                    }
                }
            });
        }
        if (this.documentsPreview) {
            this.documentsPreview.forEach(item => {
                if (documents) {
                    const index = documents.findIndex(doc => item.id === doc.id);
                    if (index !== -1) {
                        documents[index].customerSippCode = item.customerSippCode;
                        documents[index].categoryName = item.categoryName;
                        documents[index].vehicleType = item.vehicleType;
                        documents[index].fuelType = item.fuelType;
                    }
                }
            });
        }
        this.documentsPreview = null;
        this.logVersion = null;
        this.logsChanges = null;
        await this.loadSippLogsData();
        this.storeState.loading.reset();
    }

    changedSippCode(diffSipp: SippDiffModel) {
        // eslint-disable-next-line no-underscore-dangle
        const index = this.storeState.diffDocuments.findIndex(item => item._id === diffSipp._id);
        const isValueExist = () => {
            const isEqualSipp = diffSipp.current_customer_sipp_code === diffSipp.new_customer_sipp_code;
            const isEqualCategory = diffSipp.new_customer_normalized_category === diffSipp.current_customer_normalized_category;
            const isEqualVehicle = diffSipp.new_vehicle_type === diffSipp.current_vehicle_type;
            const isEqualFuelType = diffSipp.new_fuel_type === diffSipp.current_fuel_type;
            return isEqualSipp && isEqualCategory && isEqualVehicle && isEqualFuelType;
        };
        if (index === -1 && !isValueExist()) {
            this.storeState.diffDocuments.push(diffSipp);
        } else if (index !== -1 && isValueExist()) {
            // eslint-disable-next-line no-underscore-dangle
            this.storeState.diffDocuments = this.storeState.diffDocuments.filter(item => item._id !== diffSipp._id);
        } else {
            this.storeState.diffDocuments[index] = diffSipp;
        }
    }

    async loadSippData(): Promise<boolean> {
        const filterSettings = this.storeState.settings;
        const sippCodesFilters = this.filters;
        if (!filterSettings.pos || !filterSettings.transmission || !filterSettings.carClasses || !sippCodesFilters.providers || !sippCodesFilters.pos || !sippCodesFilters.countryCode) {
            return false;
        }

        filterSettings.providerName = sippCodesFilters.providers.filter(item => !['Brand', PROVIDER_ANY, PROVIDER_NONE].includes(item));

        filterSettings.posCodes = sippCodesFilters.pos;

        const allowedCountryNames = sippCodesFilters.countryName || [];
        const body = { ...filterSettings };

        if (allowedCountryNames.length && filterSettings.countryName === COUNTRIES_ANY) {
            body.countryName = allowedCountryNames;
        }

        const pageSettings: SippPageSettingsModel = {
            page: 1,
            pageLimit: 100,
        };
        body.isVans = this.carsFilterService.storeState.settings.isVansSippCodePopup;
        const { documents, totalPages, totalCount } = await this.sippApiService.getSippData(pageSettings, body);
        this.storeState.documents = documents;
        this.storeState.totalPages = totalPages;
        this.storeState.totalCount = totalCount;

        return true;
    }

    async loadSIPPChanges(): Promise<boolean> {
        if (!this.storeState.historyLogs.version) {
            return true;
        }
        const { documents } = await this.sippApiService.getSIPPChanges(this.storeState.historyLogs.version);
        this.storeState.previewDocuments = documents;
        return true;
    }

    get loading() {
        return this.storeState.loading;
    }

    // For lazy loading
    async loadMoreData() {
        if (!this.storeState.documents) {
            return;
        }

        if (this.storeState.historyLogs.showChangesOnly) {
            return;
        }

        const { page, totalPages } = this.storeState;

        if (totalPages === 0) {
            return;
        }

        if (totalPages === page) {
            return;
        }

        const filterSettings = this.storeState.settings;

        this.storeState.page += 1;
        const pageSettings: SippPageSettingsModel = {
            page: this.storeState.page,
            pageLimit: 100,
        };
        const { documents } = await this.sippApiService.getSippData(pageSettings, filterSettings);

        if (documents) {
            this.storeState.documents = this.storeState.documents.concat(documents);
        }
    }

    get sippLogs() {
        this.helperService.dynamicLoading(this.storeState.historyLogs.loading, this.loadSippLogsData.bind(this));
        return this.storeState.historyLogs.logs;
    }

    async loadSippLogsData(): Promise<boolean> {
        this.storeState.historyLogs.logs = await this.sippApiService.sippHistoryLog();
        return true;
    }

    get logsChanges() {
        return this.storeState.historyLogs.changes;
    }

    get documentsPreview() {
        return this.storeState.previewDocuments;
    }

    set documentsPreview(value: SippItemModel[] | null) {
        this.storeState.previewDocuments = value;
    }

    set logsChanges(value: SippLogChangeItemModel[] | null) {
        this.storeState.historyLogs.changes = value;
    }

    get logVersion() {
        return this.storeState.historyLogs.version;
    }

    set logVersion(value: number | null) {
        this.storeState.historyLogs.version = value;
    }

    get showChangesOnly() {
        return this.storeState.historyLogs.showChangesOnly;
    }

    set showChangesOnly(value: boolean) {
        this.storeState.historyLogs.showChangesOnly = value;
    }

    async loadFilters() {
        const filtersSipp = await this.sippApiService.getSippFilters();

        if (!filtersSipp) {
            return false;
        }

        if (filtersSipp.pos) this.storeState.filters.pos = [...filtersSipp.pos];

        if (filtersSipp.transmission) {
            this.storeState.filters.transmission = [TRANSMISSION_ANY, ...filtersSipp.transmission];
        }

        if (filtersSipp.carClasses) {
            this.storeState.filters.carClasses = [CAR_CLASS_ANY, CAR_CLASS_BLANK, ...filtersSipp.carClasses, ...this.newCategories];
        }

        if (filtersSipp.providers) {
            this.storeState.filters.providers = [PROVIDER_ANY, PROVIDER_NONE, ...filtersSipp.providers];
        }

        if (filtersSipp.vehicleTypes) {
            this.storeState.filters.vehicleTypes = [VEHICLE_TYPE_ANY, ...filtersSipp.vehicleTypes];
        }

        if (filtersSipp.fuelTypes) {
            this.storeState.filters.fuelTypes = [FUEL_TYPE_ANY, ...filtersSipp.fuelTypes];
        }

        if (filtersSipp.brandName) {
            this.storeState.filters.carBrands = [...filtersSipp.brandName];
        }

        if (filtersSipp.countryName) {
            this.storeState.filters.countryName = [...filtersSipp.countryName];
        }

        if (filtersSipp.countryCode) {
            this.storeState.filters.countryCode = [...filtersSipp.countryCode];
        }

        if (filtersSipp.normalizedCarCategory) {
            this.storeState.filters.normalizedCarCategory = filtersSipp.normalizedCarCategory;
        }

        if (filtersSipp.normalizedCarCategoryPerPos) {
            this.storeState.filters.normalizedCarCategoryPerPos = filtersSipp.normalizedCarCategoryPerPos;
        }

        return true;
    }

    get filters() {
        this.helperService.dynamicLoading(this.storeState.loadingFilters, this.loadFilters.bind(this));
        return this.storeState.filters;
    }

    get totalCountOfSippRecords() {
        return this.storeState.totalCount;
    }

    async initSettings() {
        await this.initSippCarClasses();
        await this.initSippTransmissionFilter();
        await this.initPos();
    }

    async initPos() {
        this.storeState.settings.pos = this.storeState.settings.pos || POS_ANY;
    }

    async initSippTransmissionFilter() {
        this.storeState.settings.transmission = this.storeState.settings.transmission || TRANSMISSION_ANY;
    }

    async initSippCarClasses() {
        this.storeState.settings.carClasses = this.storeState.settings.carClasses || CAR_CLASS_ANY;
    }

    resetSippLoading() {
        this.storeState.documents = null;
        this.storeState.page = 1;
        this.storeState.totalPages = 1;
        this.storeState.totalCount = null;
        this.storeState.loading.reset();
    }

    resetDiffDocs() {
        if (this.storeState.diffDocuments && this.storeState.diffDocuments.length && this.storeState.isPopupClose) {
            this.storeState.diffDocuments = [];
        }
    }

    async download() {
        const link = document.createElement('a');
        const isVan = this.carsFilterService.storeState.settings.isVansSippCodePopup;
        const blobData = await this.sippApiService.downloadData(isVan);
        link.style.display = 'none';
        document.body.appendChild(link);

        if (blobData === null) {
            return;
        }
        // It is needed to open download file window in browser
        link.href = window.URL.createObjectURL(blobData);
        link.setAttribute('download', 'sipp-codes.csv');
        link.click();
    }

    resetLoading() {
        this.resetSippLoading();
    }

    get historyLoading() {
        return this.storeState.historyLogs.loading;
    }

    get isHistoryLoading() {
        return this.storeState.historyLogs.loading.isLoading();
    }
}
