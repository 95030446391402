import { Inject, injectable } from 'inversify-props';

import Stateable from '@/modules/common/interfaces/stateable.interface';
import Percent from '@/modules/common/types/percent.type';
import Day from '@/modules/common/types/day.type';

import RatesStore from '@/modules/rates/store/rates.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import RatesDocumentAllModel from './models/rates-document-all.model';

export const RatesAllServiceS = Symbol.for('RatesAllServiceS');
@injectable(RatesAllServiceS as unknown as string)
export default class RatesAllService implements Stateable {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;

    readonly storeState: RatesStore = this.storeFacade.getState('RatesStore');

    constructor(storeState: RatesStore | null = null) {
        if (storeState) {
            this.storeState = storeState;
        }
    }

    getCardAssessment(day: Day, percentFromFilterAll: number | null) : ASSESSMENTS_TYPES | null {
        const compset = this.compsetsService.currentCompset;
        if (!compset || !percentFromFilterAll) {
            return null;
        }

        if (this.getValidPrices(day).length < 2) {
            return null;
        }

        return this.ratesCommonService.getCardAssessment(percentFromFilterAll, compset);
    }

    myPriceAvg(day: Day) {
        const doc = this.storeState.document as RatesDocumentAllModel;
        const checkinDates = doc && doc.checkinDates && doc.checkinDates[day];
        if (!checkinDates) {
            return null;
        }

        const providers = Object
            .keys(checkinDates)
            .filter(provider => provider !== 'average');

        let numberOfPrices = 0;

        const price = providers.reduce((totalPrice, provider) => {
            const providerPrice = this.getProviderPrice(day, provider);

            if (!providerPrice) {
                return totalPrice;
            }

            numberOfPrices += 1;
            return totalPrice + providerPrice;
        }, 0);

        if (!price) {
            return null;
        }

        return price / numberOfPrices;
    }

    checkinDates(day: Day) {
        const doc = this.storeState.document as RatesDocumentAllModel;
        const checkinDate = doc && doc.checkinDates && doc.checkinDates[day];
        if (!checkinDate) {
            return {};
        }
        return checkinDate;
    }

    getCheckinDatesFromData(day: Day, data: RatesDocumentAllModel) {
        const doc = data as RatesDocumentAllModel;
        const checkinDate = doc && doc.checkinDates && doc.checkinDates[day];
        if (!checkinDate) {
            return null;
        }
        return checkinDate;
    }

    getProviderPrice(day: Day, provider: string) {
        const data = this.checkinDates(day);

        if (!data[provider] || !data[provider].statistics) {
            return null;
        }

        return data[provider].statistics.lowest;
    }

    getHighestPrice(day: Day) {
        const prices = this.getValidPrices(day);
        const max = Math.max(...prices);
        return max === -Infinity ? 0 : max;
    }

    getLowestPrice(day: Day) {
        const prices = this.getValidPrices(day);
        const min = Math.min(...prices);
        return min === Infinity ? 0 : min;
    }

    getValidPrices(day: Day) {
        const providers = Object.keys(this.checkinDates(day)).filter(p => p !== 'average');
        const prices: number[] = [];
        providers.forEach(provider => {
            const price = this.getProviderPrice(day, provider);
            if (price && price > 0) {
                prices.push(price);
            }
        });
        return prices;
    }

    priceDiff(myPrice: number, comparePrice: number): Percent | null {
        return (myPrice - comparePrice) / comparePrice;
    }

    isNoData(day: Day) {
        if (!this.storeState.document || !this.storeState.document.checkinDates) {
            return true;
        }

        const allRooms = this.checkinDates(day);
        const isNoRooms = !Object.keys(allRooms).length;

        if (isNoRooms) {
            return true;
        }

        return !this.storeState.document.checkinDates[day];
    }

    isOutOfRange() {
        return !this.storeState.document;
    }

    isSoldOut(day: Day, provider: string) {
        if (!this.storeState.document || !this.storeState.document.checkinDates) {
            return false;
        }

        const price = this.getProviderPrice(day, provider);

        return !price;
    }

    isNoAverage(day: Day) {
        if (!this.storeState.document || !this.storeState.document.checkinDates) {
            return false;
        }

        const allRooms = this.checkinDates(day);
        return !Object.entries(allRooms).find(([_, value]) => !!value.statistics && !!value.statistics.lowest);
    }
}
