import CompsetMainModel from '@/modules/cluster/models/compset-main.model';
import LoadingModel from '@/modules/common/models/loading.model';
import RatesDocumentAllModel from './rates-document-all.model';
import RatesDocumentModel from './rates-document.model';

interface IRatesAnalysisSettings {
    closeNoDataNotification: boolean,
    comparisonFilter: {
        key: string,
        values: { value: (string | number), name: string }[],
    },
}

export default class RatesAnalysisData {
    loading = new LoadingModel();
    documents: (RatesDocumentModel | null)[] = [];
    settings: IRatesAnalysisSettings = {
        closeNoDataNotification: false,
        comparisonFilter: {
            key: 'diffDays',
            values: [{ value: 1, name: 'Yesterday' }],
        },
    };
}
