import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import NotifyService, { NotifyServiceS } from '@/modules/common/services/notify.service';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import ResponseValidationException from '@/modules/common/modules/exception-handler/exceptions/response-validation.exception';

export const MealTypesApiServiceS = Symbol.for('MealTypesApiServiceS');
@injectable(MealTypesApiServiceS as unknown as string)
export default class MealTypesApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(NotifyServiceS) private notifyService!: NotifyService;
    @Inject(ValidatorServiceS) private validatorService!: ValidatorService;

    async getMealTypes(): Promise<MealTypeModel[] | null> {
        const { data } = await this.apiService.get('/supported/meal-types');

        if (!data) {
            return null;
        }

        const mealTypesDocument = plainToClass(MealTypeModel, <MealTypeModel[]> data, { excludeExtraneousValues: true });

        // const errors = await this.validatorService.validateResponse(mealTypesDocument);
        // if (errors) {
        //     throw new ResponseValidationException(errors);
        // }

        return mealTypesDocument;
    }
}
