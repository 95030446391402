import {
    ValidatorConstraint,
    ValidatorConstraintInterface,
    ValidationArguments,
    validate,
} from 'class-validator';
import { Inject } from 'inversify-props';
import MarketsDocumentItemModel from '@/modules/markets/models/markets-document-item.model';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';

interface ICheckinDates {
    [day: number]: {
        [hotelId: number]: MarketsDocumentItemModel
    }
}

@ValidatorConstraint({ name: 'CheckinDates', async: true })
export default class MarketsCheckinDatesValidator implements ValidatorConstraintInterface {
    @Inject(ValidatorServiceS) private validatorService!: ValidatorService;

    private errorMessage = 'Markets checkinDates not valid';

    private async validateDocumentItem(documentItems: MarketsDocumentItemModel[]) {
        const documentItemErrors = await Promise.all(documentItems.map(item => (
            this.validatorService.validateResponse(item)
        )));

        return Array.from(new Set(documentItemErrors.filter(errorItem => errorItem) as string[])).join('; ');
    }

    async validate(checkinDates: ICheckinDates | null, args: ValidationArguments) {
        let isValid = true;
        let marketsDocumentItems: MarketsDocumentItemModel[] = [];

        if (!checkinDates) {
            return isValid;
        }

        Object.keys(checkinDates).forEach(day => {
            if (Number.isNaN(Number(day))) {
                this.errorMessage += ' day is not a number';
                isValid = false;
            }
            marketsDocumentItems = Object.entries(checkinDates[Number(day)]!).map(([hotelId, documentItem]) => {
                if (Number.isNaN(Number(hotelId))) {
                    this.errorMessage += ' hotelId is not a number';
                    isValid = false;
                }

                return documentItem;
            });
        });

        if (!marketsDocumentItems.length) {
            return isValid;
        }

        const errors = await this.validateDocumentItem(marketsDocumentItems);

        if (!errors) {
            return isValid;
        }

        isValid = false;
        this.errorMessage += `, ${errors}`;

        return isValid;
    }

    defaultMessage(args: ValidationArguments) {
        return this.errorMessage;
    }
}
