import { Inject, injectable } from 'inversify-props';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import { plainToClass } from 'class-transformer';
import UserModel from '@/modules/user/models/user.model';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import ConfigService, { ConfigServiceS } from '../config/config.service';
import CurrencyListModel from './models/currency-list.model';
import UserHotelAlertsModel from './models/user-hotel-alerts.model';

export const UserApiServiceS = Symbol.for('UserApiServiceS');
@injectable(UserApiServiceS as unknown as string)
export default class UserApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(ValidatorServiceS) private validatorService!: ValidatorService;
    @Inject(ConfigServiceS) private configService!: ConfigService;

    async getUser(token: string): Promise<UserModel> {
        const fornovaAppPostfix = this.configService.fornovaApp ? `/${this.configService.fornovaApp}` : '';
        const { data } = await this.apiService.post(
            `users/sso${fornovaAppPostfix}`,
            { token },
            { headers: { 'Content-Type': 'application/json' } },
        );

        const userDocument = plainToClass(UserModel, <UserModel> data, { excludeExtraneousValues: true });
        await new Promise<void>(res => setTimeout(res, 500));
        // const error = await this.validatorService.validateResponse(userDocument);
        // if (error) {
        //     throw new ResponseValidationException(error);
        // }

        return userDocument;
    }

    async updateCurrentHotel(hotelId: number) {
        return this.apiService.put('users/switch', {
            fornova_user_id: String(hotelId),
        });
    }

    async updateMainPos(compsetId: string, newMainPos: string) {
        const body = {
            main_pos: newMainPos,
            compset_ids: [compsetId],
        };

        return this.apiService.put('/compsets/update-main-pos', body);
    }

    updateSubscriptions({ htmlReports, scheduledReports }: UserAPI.Subscriptions) {
        return this.apiService.post('users/settings', {
            settings: {
                subscribedListForMail: {
                    'html-report': htmlReports,
                    'scheduled-excel': scheduledReports,
                },
            },
        });
    }

    updateCompsetSettings(newSettings: Partial<UserAPI.CompsetSettingsBody>) {
        return this.apiService.post('users/compsets-settings', {
            settings: {
                ...newSettings,
            },
        });
    }

    updateSettings(settings: Partial<UserAPI.Settings>) {
        return this.apiService.post('users/settings', { settings });
    }

    updateHotelSettings(settings: UserAPI.IUserHotelAlerts) {
        const fornovaId = Number(Object.keys(settings)[0]);
        const body = {
            fornovaId,
            alertsAndNotifications: {
                ...settings[fornovaId],
            },
        };
        return this.apiService.put('hotels/update-alert-setting', body);
    }

    async getCurrentCompsetSettings(): Promise<UserAPI.CompsetSettingsBody> {
        const res = await this.apiService.get('users/compsets-settings');

        return res.data.settings;
    }

    async updateDisplayCurrency(currency: string | null) {
        const { data } = await this.apiService.post('users/settings', {
            settings: {
                currency,
            },
        });
        return data;
    }

    async updateChartColors(colors: string[]) {
        const { data } = await this.apiService.post('users/settings', {
            settings: {
                colors,
            },
        });
        return data;
    }

    async getAllCurrencies() {
        const { data } = await this.apiService.get('users/currencies');
        return plainToClass(CurrencyListModel, <CurrencyListModel> data, { excludeExtraneousValues: true });
    }

    async getHotelSettings(fornovaId: number) {
        const { data } = await this.apiService.get(`hotels/alerts-and-notifications-settings/${fornovaId}`);
        return plainToClass(UserHotelAlertsModel, <UserHotelAlertsModel> data, { excludeExtraneousValues: true });
    }
}
