import { Expose, plainToClass, Transform } from 'class-transformer';
import ParityBrokerDataModel from './parity-broker-data.model';

export default class ParityDocumentItemModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain._id)
    id!: number;

    @Expose()
    @Transform((_, plain) => plain.creation_timestamp)
    creationTimestamp!: string;

    @Expose()
    @Transform((_, plain) => plain.customer_category_id)
    customerCategoryId!: string;

    @Expose()
    @Transform((_, plain) => plain.customer_id)
    customerId!: string;

    @Expose()
    @Transform((_, plain) => plain.fn_car_category_id)
    fnCarCategoryId!: string;

    @Expose()
    @Transform((_, plain) => plain.fn_car_id)
    fnCarId!: string;

    @Expose()
    @Transform((_, plain) => plain.fn_drop_off_location_code)
    fnDropOffLocationCode!: string;

    @Expose()
    @Transform((_, plain) => plain.fn_pick_up_location_code)
    fnPickUpLocationCode!: string;

    @Expose()
    lor!: number;

    @Expose()
    @Transform((_, plain) => plain.mileage_policy)
    mileage!: string;

    @Expose()
    @Transform((_, plain) => plain.pick_up_date)
    pickUpDate!: string;

    @Expose()
    @Transform((_, plain) => plain.pick_up_time)
    pickUpTime!: string;

    @Expose()
    pos!: string;

    @Expose()
    @Transform((_, plain) => plain.route_id)
    routeId!: number;

    @Expose()
    @Transform((_, plain) => plain.rate_type)
    paymentTerms!: string;

    @Expose()
    transmission!: string;

    @Expose()
    @Transform((_, plain) => ({
        brandData: plainToClass(ParityBrokerDataModel, <ParityBrokerDataModel> plain.latest_bml.brand_data, { excludeExtraneousValues: true }),
        brokerData: plainToClass(ParityBrokerDataModel, <ParityBrokerDataModel> plain.latest_bml.broker_data, { excludeExtraneousValues: true }),
        scanDate: plain.latest_bml.scan_date,
        currency: plain.latest_bml.currency,
        bmlClassification: plain.latest_bml.bml_classification,
        bmlPriceDiffRatio: plain.latest_bml.bml_price_diff_ratio,
        comparasionType: plain.latest_bml.comparison_type,
    }))
    latestBml!: {
        brandData: ParityBrokerDataModel
        brokerData: ParityBrokerDataModel
        scanDate: string
        currency: string
        bmlClassification: string
        bmlPriceDiffRatio: number
        comparisonType: string
    };
}
