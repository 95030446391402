import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import Day from '@/modules/common/types/day.type';

@Module
export default class FleetHistoryStore extends VuexModule {
    loading: LoadingModel = new LoadingModel();

    tableDay: Day | null = null;

    // last chartJs tooltip position, to save it between price history openings
    lastTooltipPos: string | null = null;
}
