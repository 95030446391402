import CarsAlertApiService, { CarsAlertApiServiceS } from '@/modules/cars/car.alert-api.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import CarsAlertConfigurationModel from '@/modules/cars/models/cars-alert-configuration.model';
import { Inject, injectable } from 'inversify-props';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import CarsAlertStore from './store/cars.alert.store';
import SIppCodeAlertConfigurationModel from './models/sippcode-alert-configuration.model';

export const CarsAlertServiceS = Symbol.for('CarsAlertServiceS');
@injectable(CarsAlertServiceS as unknown as string)
export default class CarsAlertService implements Stateable {
    @Inject(UserServiceS) protected userService!: UserService;
    @Inject(CarsAlertApiServiceS) protected carsAlertApiService!: CarsAlertApiService;
    @Inject(StoreFacadeS) protected storeFacade!: StoreFacade;
    @Inject(HelperServiceS) protected helperService!: HelperService;

    readonly storeState: CarsAlertStore = this.storeFacade.getState('CarsAlertStore');

    constructor() {
        this.storeFacade.watch(
            () => this.userService.storeState.user,
            this.storeState.loading.reset.bind(this.storeState.loading),
        );
    }

    resetLoadings() {
        this.storeState.loading.reset();
    }

    async loadData() {
        if (!this.userService.isCarUser) {
            return false;
        }
        const grouped = await this.carsAlertApiService.getAlertsConfiguration();

        this.storeState.document = grouped as CarsAlertConfigurationModel[];

        return true;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));

        return this.storeState.document;
    }

    get loading() {
        return this.storeState.loading;
    }

    async saveSippcodeAlert(configuration: SIppCodeAlertConfigurationModel) {
        return this.carsAlertApiService.saveSippCodeAlertConfiguration(configuration);
    }

    async saveCarCategoryAlertConfiguration(configuration: SIppCodeAlertConfigurationModel) {
        return this.carsAlertApiService.saveCarCategoryAlertConfiguration(configuration);
    }
}
