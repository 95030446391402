import CAR_PRICE_TYPE from '@/modules/cars/constants/car-price-type.constant';
import Percent from '@/modules/common/types/percent.type';

type AssessmentSettings = { [priceType in CAR_PRICE_TYPE]: [Percent, Percent] };

const SETTINGS_LS_KEY = 'assessmentSettings';

/**
 * Caches assessmentSettings in local storage.
 * @param value - settings object which should be cached.
 * @example
 * setCachedAssessmentSettings({
 *   [CAR_PRICE_TYPE.LOWEST]: [0, 0.05],
 *   [CAR_PRICE_TYPE.MEDIAN]: [0.01, 0.05],
 *   [CAR_PRICE_TYPE.HIGHEST]: [-0.05, 0],
 * })
 */
export const setCachedAssessmentSettings = (value: AssessmentSettings): void => {
    localStorage.setItem(SETTINGS_LS_KEY, JSON.stringify(value));
};

/**
 * Get cached in local storage assessmentSettings.
 * @returns cached assessmentSettings object if it's in local storage and correct, else null.
 */
export const getCachedAssessmentSettings = (): AssessmentSettings | null => {
    const rawValue = localStorage.getItem(SETTINGS_LS_KEY) || '';
    try {
        return JSON.parse(rawValue);
    } catch (e) {
        localStorage.removeItem(SETTINGS_LS_KEY);
        return null;
    }
};
