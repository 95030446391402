import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import CarsCategoryModel from '@/modules/cars-category-manager/models/cars-category-model.model';
import CarClassChangesModel from '@/modules/cars-category-manager/models/car-class-change.model';
import NewCategoryModel from '@/modules/cars-category-manager/models/new-category.model';

@Module
export default class CarsCategoryStore extends VuexModule {
    localCarsCategory: CarsCategoryModel | null = null;
    carsCategory: CarsCategoryModel | null = null;
    carClassChanges: CarClassChangesModel[] = [];
    newCategories: NewCategoryModel[] = [];
    dragChoose: string = '';
    loading: LoadingModel = new LoadingModel();
}
