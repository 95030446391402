import { Expose, Transform } from 'class-transformer';

interface ICurrency {
    symbol: string
    name: string
    code: string
}

interface IRawCurrency {
    symbol: string
    name: string
    code: string
}

export default class CurrencyListModel {
    @Expose()
    @Transform((_, plain) => (
        Object.entries(plain as { [currency: string]: IRawCurrency }).reduce((acc: { [currency: string]: ICurrency }, [key, value]) => {
            acc[key] = {
                symbol: value.symbol,
                name: value.name,
                code: value.code,
            };
            return acc;
        }, {})
    ))
    currencies!: { [currency: string]: ICurrency };
}
