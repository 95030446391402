export default function downloadBlobAsFile(filename: string, blob: Blob) {
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('download', filename);
    link.href = url;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
