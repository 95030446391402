import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import HotelModel from '@/modules/hotels/models/hotel.model';
import CompsetsApiService, { CompsetsApiServiceS } from '../compsets/compsets-api.service';

export const HotelsApiServiceS = Symbol.for('HotelsApiServiceS');
@injectable(HotelsApiServiceS as unknown as string)
export default class HotelsApiService {
    @Inject(ApiServiceS)
    private apiService!: ApiService;

    @Inject(CompsetsApiServiceS)
    private compsetsApiService!: CompsetsApiService;

    async getHotels(fornovaId: number): Promise<HotelModel[] | null> {
        const data = await this.compsetsApiService.getCompsetsReq(fornovaId);

        if (!data) {
            return null;
        }

        const hotelsDocument = plainToClass(HotelModel, <HotelModel[]> data.hotels, { excludeExtraneousValues: true });

        return hotelsDocument;
    }

    async getMyHotels(): Promise<HotelModel[] | null> {
        const { data } = await this.apiService.get('users/fornova-ids');

        if (!data) {
            return null;
        }

        return plainToClass(HotelModel, <HotelModel[]> data, { excludeExtraneousValues: true });
    }

    async getHotelsById(hotelIds: number[]): Promise<HotelModel[] | null> {
        const { data } = await this.apiService.post('supported/hotel-names', {
            fornovaIds: hotelIds,
        });

        if (!data) {
            return null;
        }

        return plainToClass(HotelModel, <HotelModel[]> data, { excludeExtraneousValues: true });
    }
}
