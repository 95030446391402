import { Expose, Transform } from 'class-transformer';
import { IsString, IsDate } from 'class-validator';
import ALERT_TYPES from '@/modules/alerts/constants/alert-types.constant';
import snakeToCamel from '@/modules/common/filters/snake-to-camel.filter';

export default class AlertModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain._id)
    @IsString()
    id!: string;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.fornova_id)
    @IsString()
    fornovaId!: number;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.alert_type)
    @IsString()
    alertType!: ALERT_TYPES;

    @Expose()
    @Transform((_, plain) => plain.message)
    @IsString()
    message!: string;

    @Expose()
    @Transform((_, plain) => plain.alert_name)
    @IsString()
    title!: string;

    @Expose()
    @Transform((_, plain) => new Date(plain.date || plain.created_at))
    @IsDate()
    date!: Date;

    @Expose()
    @Transform((_, plain) => {
        const src = plain.ext_data || {};
        const entries = Object
            .entries(src)
            .map(([k, v]) => [snakeToCamel(k), v]);

        return Object.fromEntries(entries);
    })
    extData!: { [k: string]: any };

    @Expose()
    @Transform((_, plain) => !!plain.read)
    @IsDate()
    read!: Boolean;
}
