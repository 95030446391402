import { injectable } from 'inversify-props';
import Day from '@/modules/common/types/day.type';

export interface DemandData {
    day: Day,
    demandsCount: number,
}

export interface MarkerData {
    isMyHotel?: boolean;
    position: { lat: number, lng: number },
    isVisible?: boolean
}

export interface CircleData {
    position: { lat: number, lng: number },
    radius: number
}

export const RatesDemandMapServiceS = Symbol.for('RatesDemandMapServiceS');
@injectable(RatesDemandMapServiceS as unknown as string)
export default class RatesDemandMapService {
    data: DemandData[] = [];

    markers: any[] = [];
    amountMarkersPerDay: number[] = [];

    mediumCircles: any[] = [];
    amountMediumCirclesPerDay: number[] = [];

    lowCircles: any[] = [];
    amountLowCirclesPerDay: number[] = [];

    highCircles: any[] = [];
    amountHighCirclesPerDay: number[] = [];

    constructor() {
        const daysCount = 31;
        for (let i = 0; i < daysCount; i++) {
            const markerPerDay: MarkerData[] = [];
            const mediumCirclesPerDay: CircleData[] = [];
            const lowCirclesPerDay: CircleData[] = [];
            const highCirclesPerDay: CircleData[] = [];

            this.data.push({
                day: i + 1 as Day,
                demandsCount: Math.round(Math.random() * 10),
            });

            this.amountMarkersPerDay.push(Math.round(Math.random() * 5 + 1));
            this.amountMediumCirclesPerDay.push(Math.round(Math.random() * 5 + 1));
            this.amountLowCirclesPerDay.push(Math.round(Math.random() * 5 + 1));
            this.amountHighCirclesPerDay.push(Math.round(Math.random() * 5 + 1));

            for (let j = 0; j < this.amountMarkersPerDay[i]; j++) {
                markerPerDay.push({
                    position: { lat: 10 + Math.random() * 2, lng: 10 + Math.random() * 2 },
                });
            }

            for (let j = 0; j < this.amountMediumCirclesPerDay[i]; j++) {
                mediumCirclesPerDay.push({
                    position: { lat: 10 + Math.random() * 2, lng: 10 + Math.random() * 2 },
                    radius: Math.round(Math.random() * 100000),
                });
            }

            for (let j = 0; j < this.amountLowCirclesPerDay[i]; j++) {
                lowCirclesPerDay.push({
                    position: { lat: 10 + Math.random() * 2, lng: 10 + Math.random() * 2 },
                    radius: Math.round(Math.random() * 100000),
                });
            }

            for (let j = 0; j < this.amountHighCirclesPerDay[i]; j++) {
                highCirclesPerDay.push({
                    position: { lat: 10 + Math.random() * 2, lng: 10 + Math.random() * 2 },
                    radius: Math.round(Math.random() * 100000),
                });
            }

            this.markers.push(markerPerDay);
            this.mediumCircles.push(mediumCirclesPerDay);
            this.lowCircles.push(lowCirclesPerDay);
            this.highCircles.push(highCirclesPerDay);
        }
    }

    get demands(): DemandData[] {
        return this.data;
    }

    getMarkers(day: Day): MarkerData[] {
        return this.markers[day];
    }

    getMediumCircles(day: Day): CircleData[] {
        return this.mediumCircles[day];
    }

    getLowCircles(day: Day): CircleData[] {
        return this.lowCircles[day];
    }

    getHighCircles(day: Day): CircleData[] {
        return this.highCircles[day];
    }
}
