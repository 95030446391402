import { Expose, Transform } from 'class-transformer';
import { IsNumber } from 'class-validator';

export default class BmlDataModel {
    @Expose()
    @Transform((_, plain) => plain.meet || null)
    @IsNumber()
    meet!: number | null;

    @Expose()
    @Transform((_, plain) => plain.lose || null)
    @IsNumber()
    lose!: number | null;

    @Expose()
    @Transform((_, plain) => plain.beat || null)
    @IsNumber()
    beat!: number | null;
}
