enum ASSESSMENT_TYPES {
    GOOD = 1,
    NORMAL,
    FAIR,
    BAD,
    SOLD_OUT,
    NO_DATA,
    NA,
}

export default ASSESSMENT_TYPES;
