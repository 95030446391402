import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '../common/models/loading.model';
import PromotionsDocumentModel from './models/promotions-document.model';
import PromotionHistoryDocumentModel from './models/promotion-history-document.model';
import { COMPARE_KEY, COMPARE_VALUES } from './consts/compare-filter-values';

export enum PromotionGraphType {
    DEALS = 'deal',
    PROGRAMS = 'programs',
}

@Module
export default class PromotionsStore extends VuexModule {
    loading = new LoadingModel();
    data: PromotionsDocumentModel | null = null;
    comparedLoading = new LoadingModel();
    comparedData: PromotionsDocumentModel | null = null;

    provider: string | null = null;
    programView: string = '';
    graphType: PromotionGraphType = PromotionGraphType.PROGRAMS;

    historyDoc: PromotionHistoryDocumentModel | null = null;
    comparisonFilter: {
        key: COMPARE_KEY;
        value: string;
    } = {
        key: COMPARE_KEY.DIFF_DAYS,
        value: String(COMPARE_VALUES[COMPARE_KEY.DIFF_DAYS][0].value),
    };
}
