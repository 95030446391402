/* eslint-disable camelcase */
import { Expose, Transform } from 'class-transformer';
import {
    IsNumber, IsString, IsBoolean, IsInt,
} from 'class-validator';
import DEFAULT_NUMBER_OF_GUESTS from '@/modules/number-of-guests/constants/default-number-of-guests.constant';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';
import PRICE from '@/modules/common/modules/rates/constants/price.enum';

export default class RatesDocumentItemModel {
    @Expose()
    @Transform((_, plain) => {
        if (!plain.price) {
            return null;
        }

        if (plain.price === 0) {
            return PRICE.SOLD_OUT;
        }

        if (typeof plain.price === 'object') {
            const { total_price, net_price } = plain.price;
            const { shown_price, lowest } = plain.price;

            // If no shown_price - no other prices
            if (typeof shown_price !== 'number') {
                return null;
            }

            const getPrice = (value: number) => {
                if (typeof value !== 'number') {
                    return PRICE.NA;
                }

                if (value === 0) {
                    return PRICE.SOLD_OUT;
                }

                return value;
            };

            const totalPrice = getPrice(total_price);
            const netPrice = getPrice(net_price);
            const lowestPrice = getPrice(lowest);

            return {
                totalPrice,
                netPrice,
                shownPrice: shown_price,
                lowestPrice,
            };
        }
        return plain.price;
    })
    @IsNumber()
    price!: {
        totalPrice: number,
        netPrice: number,
        shownPrice: number,
        lowestPrice: number, // NOTE: For all channels
    } | null;

    @Expose()
    @Transform((_, plain) => plain.room_name || '')
    @IsString()
    roomName!: string;

    @Expose()
    @Transform((_, plain) => Boolean(plain.cancellation))
    @IsBoolean()
    cancellation!: boolean;

    @Expose()
    @Transform((_, plain) => Boolean(plain.is_basic))
    @IsBoolean()
    isBasic!: boolean;

    @Expose()
    @Transform((_, plain) => Boolean(plain.multiple_cancellation_policy))
    @IsBoolean()
    multipleCancellation!: boolean;

    /**
     * Appears in cheapest mode. Shows the provider that provides the price of the room.
     */
    @Expose()
    @Transform((_, plain) => plain.provider)
    @IsBoolean()
    provider?: string;

    @Expose()
    @Transform((_, plain) => {
        if (plain.meal_type_id === null || plain.meal_type_id === '') {
            return ANY_MEAL_TYPE.id;
        }
        return plain.meal_type_id;
    })
    @IsNumber()
    mealTypeId!: number;

    @Expose()
    @Transform((_, plain) => plain.occupancy || DEFAULT_NUMBER_OF_GUESTS)
    @IsInt()
    occupancy!: number;

    @Expose()
    intraday?: boolean;

    @Expose()
    @Transform((_, plain) => plain.price_type || null)
    priceType!: string | null;

    // Calculated dynamically
    priceRank?: number;
}
