import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';

const BOOKING_BOOK_DATE_STATISTICS : STATISTIC_TYPE[] = [
    STATISTIC_TYPE.REVENUE,
    STATISTIC_TYPE.ROOM_NIGHTS,
    STATISTIC_TYPE.NET_ADR,
    STATISTIC_TYPE.AVG_LOS,
    STATISTIC_TYPE.AVERAGE_BOOKING_WINDOW,
    STATISTIC_TYPE.CANCELLATION,
    STATISTIC_TYPE.PAGE_VIEWS,
    STATISTIC_TYPE.CONVERSION,
    // STATISTIC_TYPE.PAGE_VIEWS_SHARE,
    STATISTIC_TYPE.INTERNATIONAL_SHARE,
];

export default BOOKING_BOOK_DATE_STATISTICS;
