import { Inject, injectable } from 'inversify-props';
import { get } from 'lodash';
import CarsFilterApiService, { CarsFilterApiServiceS } from '@/modules/cars/cars-filter-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import {
    COUNTRIES_ANY, MILEAGE_ANY, PAYMENT_TERMS_ANY, TRANSMISSION_ANY,
} from '@/modules/cars/constants/car-filter-types.constant';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import ParitySettingsService, { ParitySettingsServiceS } from '@/modules/cars/modules/parity/parity-settings.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import FleetStore from '@/modules/cars/modules/fleet/store/fleet.store';
import { routerData } from '@/router';
import * as _ from 'lodash';
// todo, rename to FleetFiltersService - same as in other pages
export const FleetFilterServiceS = Symbol.for('FleetFilterServiceS');
@injectable(FleetFilterServiceS as unknown as string)
export default class FleetFilterService {
    @Inject(CarsFilterApiServiceS) private carFilterApiService!: CarsFilterApiService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(FleetServiceS) private fleetService!: FleetService;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;

    readonly storeState: FleetStore = this.storeFacade.getState('FleetStore');

    private defaultFilterValues: { [key: string]: any } = {};
    private userFilterStorageKey = 'fleet-density';

    constructor() {
        // todo, is this service can be actually created not in MI app?
        if (!this.userService.isCarUser) {
            return;
        }

        let finishUpdateOfDefaultFilters: any = false;

        this.initFilters()
            .then(() => {
                // Remember original chain defaults on first load.
                this.defaultFilterValues = JSON.parse(JSON.stringify(this.extractUserFilterValues()));

                // Apply user defaults saved in localStorage
                const settings = this.carsSharedService.getUserFilterValues(this.userFilterStorageKey);
                if (settings) {
                    // Setup filters which affect document load
                    Object.assign(this.storeState.settings, {
                        // These filters are stored in defaultFilterValues and can trigger document reload
                        country: settings.country,
                        pickUpCityCode: settings.pickUpCityCode,
                        dataSource: settings.dataSource,
                        pos: settings.pos,
                        lor: settings.lor,
                        competitors: [],
                        carClasses: [],
                        transmission: settings.transmission,
                        mileage: settings.mileage,
                        paymentTerms: settings.paymentTerms,
                    });

                    // Remember filters to update after document load
                    finishUpdateOfDefaultFilters = {
                        competitors: settings.competitors,
                        carClasses: settings.carClasses,
                        transmission: settings.transmission,
                        mileage: settings.mileage,
                        paymentTerms: settings.paymentTerms,
                    };
                }

                // Reset specific filters on pickUpCityCode change
                this.storeFacade.watch(() => this.storeState.settings.pickUpCityCode, async () => {
                    this.refreshTransmissionFilters();
                    this.refreshMileageFilters();
                    this.refreshPaymentTermsFilters();
                });
                this.storeFacade.watch(() => this.storeState.settings.dataSource, async () => {
                    this.initCompetitorFilters();
                });

                this.storeFacade.watch(() => this.storeState.document, async () => {
                    // Document can be NULL if we reset filters.
                    if (this.storeState.document) {
                        this.refreshFilters();

                        // Finish update of stored defaults. Update is executed only one time on first document load.
                        if (finishUpdateOfDefaultFilters) {
                            Object.assign(this.storeState.settings, finishUpdateOfDefaultFilters);
                            finishUpdateOfDefaultFilters = false;
                        }
                    }
                });

                // Mark filters as ready
                this.storeState.filtersReady = true;
            });
    }

    async initFilters() {
        await this.initCountries();
        await this.initPickUpCity();
        await this.initDataSource();
        await this.initLor();
        await this.initPos();
    }

    /**
     * After document load. These filters don't trigger document reload.
     */
    refreshFilters() {
        this.initCompetitorFilters();
        this.initCarClasses();
        this.initTransmissionFilter();
        this.initMileageFilter();
        this.initPaymentTermsFilter();
    }

    get settings() {
        return this.carsSharedService.filters;
    }

    get competitors() {
        return this.storeState.settings.competitors;
    }

    async initPickUpCity() {
        const { locations } = this.settings;

        if (locations && locations.length) {
            const [defaultLocation] = locations;
            this.storeState.settings.pickUpCityCode = defaultLocation.locationId;
        }
    }

    filterPickUpCitiesByCountry(country: string) {
        const {
            locations, countries, routes, availability,
        } = this.carsSharedService.filters;

        if (!locations || !routes || !availability) {
            return [];
        }

        const availableLocations = locations.filter(location => {
            const isAvailableRoute = routes.find(route => route.pickUpLocationId === location.locationId);
            const isAvailableGroup = availability.find(group => group.id === location.locationId);

            return isAvailableGroup && isAvailableRoute;
        });

        if (!country || country === COUNTRIES_ANY || !countries) {
            return availableLocations;
        }

        const selectedCountryCodes = countries[country];

        return availableLocations.filter(location => selectedCountryCodes.find(code => code === location.locationId));
    }

    async initDataSource() {
        const { dataSources } = this.settings;

        if (dataSources && dataSources.length) {
            const sources = dataSources.filter(item => item !== 'Brand');
            const [defaultDataSource] = sources;
            this.storeState.settings.dataSource = defaultDataSource;
            this.storeState.settings.dataSources = sources;
        }
    }

    get availableDataSources() {
        const { pickUpCityCode } = this.storeState.settings;
        const { availability } = this.carsSharedService.filters;

        if (!pickUpCityCode || !availability) {
            return null;
        }

        const currentLocation = availability.find(location => location.id === pickUpCityCode);

        if (!currentLocation) {
            return [];
        }

        return Object.keys(currentLocation.path);
    }

    async initLor() {
        const { lor } = this.settings;

        if (lor && lor.length) {
            const [defaultLor] = lor;
            this.storeState.settings.lor = defaultLor;
        }
    }

    get availableLors() {
        const { pickUpCityCode, pos } = this.storeState.settings;
        const { availability } = this.carsSharedService.filters;
        const { dataSource } = this.storeState.settings;

        if (!availability || !dataSource || !pos) {
            return [];
        }

        const currentLocation = availability.find(location => location.id === pickUpCityCode);

        if (!currentLocation) {
            return [];
        }

        const availableLoks: Record<string | number, boolean> = get(currentLocation?.path, [dataSource, pos], {});

        return Object.keys(availableLoks).map(lok => {
            if (availableLoks[lok]) {
                return lok;
            }

            return null;
        }).filter(lok => lok) as string[];
    }

    get availableCalendarLors() {
        const { pickUpCityCode, pos, dataSources } = this.storeState.settings;
        const { availability } = this.carsSharedService.filters;

        if (!availability || !dataSources || !pos) {
            return [];
        }

        const currentLocation = availability.find(location => location.id === pickUpCityCode);

        if (!currentLocation) {
            return [];
        }

        const lors: string[] = [];
        dataSources.forEach(dataSource => {
            const availableLocationLors: Record<string | number, boolean> = get(currentLocation?.path, [dataSource, pos], {});
            const availableLors: string[] = Object.keys(availableLocationLors).filter((lor: string) => availableLocationLors[lor]);
            lors.push(...availableLors);
        });
        return [...new Set(lors)];
    }

    async initPos() {
        const { pos } = this.settings;
        if (this.storeState.settings.pos) {
            return;
        }

        if (pos && pos.length) {
            const [defaultPos] = pos;
            this.storeState.settings.pos = defaultPos;
        }
    }

    get countriesFilter(): string[] {
        const { countries } = this.carsSharedService.filters;

        if (!countries) {
            return [COUNTRIES_ANY];
        }

        const countryNames = new Set([COUNTRIES_ANY].concat(Object.keys(countries)));

        return Array.from(countryNames);
    }

    async initCountries() {
        this.storeState.settings.country = COUNTRIES_ANY;
    }

    get competitorsFilter(): string[] {
        const { currentCompany } = this.userService;

        const filter = new Set();
        const { document } = this.storeState;

        if (!document || currentCompany === null) {
            return Array.from(filter) as string[];
        }

        const { providers } = document;

        if (!providers) {
            return Array.from(filter) as string[];
        }

        Object.entries(providers).forEach(([, pickup]) => {
            const { pickupDates } = pickup;
            Object.entries(pickupDates).forEach(([, day]) => {
                if (!day) {
                    return;
                }

                Object.keys(day).forEach(companyName => {
                    filter.add(companyName);
                });
            });
        });
        const competitorsFilter = Array.from(filter) as string[];
        const allowFilter = this.allowedVendorsPerCountryAndDataSource;
        return allowFilter?.length ? allowFilter.filter(item => item !== currentCompany) : competitorsFilter.filter(item => item !== currentCompany);
    }

    get allowedVendorsPerCountryAndDataSource() {
        const { pickUpCityCode } = this.carsService.storeState.settings;
        if (!pickUpCityCode) {
            return [];
        }
        const dataSource = this.availableDataSources;
        if (!dataSource) {
            return [];
        }
        const sources = dataSource.filter(item => item !== 'Brand');
        const country = this.getCountryByPickUpLocationId(pickUpCityCode);
        const routeName = routerData.router.currentRoute.name;
        if (dataSource && country && this.allowedVendorsPerCountry[country]) {
            if (routeName === 'fleet-table' && this.fleetService.storeState.settings.dataSource) {
                return this.allowedVendorsPerCountry[country][this.fleetService.storeState.settings.dataSource];
            }
            if (this.storeState.document?.providers) {
                const providers = Object.keys(this.storeState.document?.providers);
                let allowedCompetitors: string[] = [];
                providers.forEach(source => {
                    allowedCompetitors = _.uniq([...this.allowedVendorsPerCountry[country][source] || [], ...allowedCompetitors]);
                });
                return allowedCompetitors;
            }
        }
        return [];
    }

    get allowedVendorsPerCountry() {
        const { allowedVendorsPerCountry } = this.carsSharedService.filters;
        return allowedVendorsPerCountry;
    }

    getCountryByPickUpLocationId(locationId: string) {
        const { countries } = this.carsSharedService.filters;
        if (!countries) {
            return null;
        }
        return Object.keys(countries).filter(country => countries[country].filter(location => location === locationId).length)[0];
    }

    initCompetitorFilters() {
        this.storeState.settings.competitors = this.competitorsFilter.length ? this.competitorsFilter : null;
    }

    get carClassFilter(): string[] {
        const filter = new Set();
        const { currentCompany } = this.userService;
        const { document } = this.storeState;

        if (!document || !currentCompany === null) {
            return Array.from(filter) as string[];
        }

        const { providers } = document;

        if (!providers) {
            return Array.from(filter) as string[];
        }
        Object.entries(providers).forEach(([, pickup]) => {
            const { pickupDates } = pickup;
            if (pickupDates) {
                Object.entries(pickupDates).forEach(([, day]) => {
                    Object.entries(day).forEach(([, company]) => {
                        Object.keys(company).forEach(carClass => {
                            filter.add(carClass);
                        });
                    });
                });
            }
        });

        return Array.from(filter) as string[];
    }

    initCarClasses() {
        this.storeState.settings.carClasses = this.carClassFilter.filter(carClass => carClass !== 'Commercial');
    }

    getLocationCodeByName(name: string) {
        const { locations } = this.carsSharedService.filters;

        if (!locations || !locations.length) {
            return null;
        }

        const defaultLocation = locations.find(loc => loc.locationName === name);
        return defaultLocation ? defaultLocation.locationId : null;
    }

    get addPaymentTerms() {
        const { fleetDocument } = this.fleetService;
        if (!fleetDocument) {
            return [];
        }
        const { providers } = fleetDocument;
        if (!providers) {
            return [];
        }

        const paymentTerms = new Set([PAYMENT_TERMS_ANY]);
        Object.entries(providers).forEach(([, pickup]) => {
            const { pickupDates } = pickup;
            if (pickupDates) {
                Object.values(pickupDates).forEach(day => {
                    Object.values(day).forEach(company => {
                        Object.values(company).forEach(carClass => {
                            Object.values(carClass).forEach(transmisson => {
                                Object.values(transmisson).forEach(miliage => {
                                    Object.keys(miliage).forEach(paymentKey => {
                                        paymentTerms.add(paymentKey);
                                    });
                                });
                            });
                        });
                    });
                });
            }
        });
        return (Array.from(paymentTerms) as string[]).map(item => ({
            value: item,
            disabled: false,
        }));
    }

    initPaymentTermsFilter() {
        const currentValue = this.storeState.settings.paymentTerms;

        if (!this.addPaymentTerms.find(option => option.value === currentValue && !option.disabled)) {
            const defaultOption = this.addPaymentTerms.find(option => !option.disabled);
            this.storeState.settings.paymentTerms = defaultOption ? defaultOption.value : PAYMENT_TERMS_ANY;
        }
    }

    get transmissionFilter() {
        const { fleetDocument } = this.fleetService;
        if (!fleetDocument) {
            return [];
        }
        const { providers } = fleetDocument;
        if (!providers) {
            return [];
        }

        const transmissions = new Set([TRANSMISSION_ANY]);
        Object.entries(providers).forEach(([, pickup]) => {
            const { pickupDates } = pickup;
            if (pickupDates) {
                Object.entries(pickupDates).forEach(([, day]) => {
                    Object.entries(day).forEach(([, company]) => {
                        Object.entries(company).forEach(([, carClass]) => {
                            Object.entries(carClass).forEach(([transmissionKey]) => {
                                transmissions.add(transmissionKey);
                            });
                        });
                    });
                });
            }
        });
        return (Array.from(transmissions) as string[]).map(item => ({
            value: item,
            disabled: false,
        }));
    }

    initTransmissionFilter() {
        const currentValue = this.storeState.settings.transmission;

        if (!this.transmissionFilter.find(option => option.value === currentValue && !option.disabled)) {
            const defaultOption = this.transmissionFilter.find(option => !option.disabled);
            this.storeState.settings.transmission = defaultOption ? defaultOption.value : TRANSMISSION_ANY;
        }
    }

    get mileageFilter() {
        const { fleetDocument } = this.fleetService;
        if (!fleetDocument) {
            return [];
        }
        const { providers } = fleetDocument;
        if (!providers) {
            return [];
        }

        const mileages = new Set([MILEAGE_ANY]);
        Object.entries(providers).forEach(([, pickup]) => {
            const { pickupDates } = pickup;
            if (pickupDates) {
                Object.values(pickupDates).forEach(day => {
                    Object.values(day).forEach(company => {
                        Object.values(company).forEach(carClass => {
                            Object.values(carClass).forEach(transmisson => {
                                Object.keys(transmisson).forEach(miliageKey => {
                                    mileages.add(miliageKey);
                                });
                            });
                        });
                    });
                });
            }
        });
        return (Array.from(mileages) as string[]).map(item => ({
            value: item,
            disabled: false,
        }));
    }

    initMileageFilter() {
        const currentValue = this.storeState.settings.mileage;

        if (!this.mileageFilter.find(option => option.value === currentValue && !option.disabled)) {
            const defaultOption = this.mileageFilter.find(option => !option.disabled);
            this.storeState.settings.mileage = defaultOption ? defaultOption.value : MILEAGE_ANY;
        }
    }

    refreshTransmissionFilters() {
        const defaultOption = this.transmissionFilter.find(option => !option.disabled);
        if (defaultOption) {
            this.storeState.settings.transmission = defaultOption!.value;
        }
    }

    refreshMileageFilters() {
        const defaultOption = this.mileageFilter.find(option => !option.disabled);
        if (defaultOption) {
            this.storeState.settings.mileage = defaultOption!.value;
        }
    }

    refreshPaymentTermsFilters() {
        const defaultOption = this.addPaymentTerms.find(option => !option.disabled);
        if (defaultOption) {
            this.storeState.settings.paymentTerms = defaultOption!.value;
        }
    }

    saveLor(value: number) {
        this.storeState.settings.lor = value;
        this.carsService.storeState.settings.lor = value;
    }

    saveCarClasses(value: string[]) {
        this.carsService.storeState.settings.carClasses = value;
        this.storeState.settings.carClasses = value;
        this.paritySettingsService.carClasses = value;
    }

    saveMileage(value: string) {
        this.storeState.settings.mileage = value;
        this.carsService.storeState.settings.mileage = value;
        this.initMileageFilter();
    }

    savePaymentTerms(value: string) {
        this.storeState.settings.paymentTerms = value;
        this.carsService.storeState.settings.paymentTerms = value;
        this.initPaymentTermsFilter();
    }

    saveTransmission(value: string) {
        this.storeState.settings.transmission = value;
        this.carsService.storeState.settings.transmission = value;
        this.initTransmissionFilter();
    }

    saveCompetitors(value: string[]) {
        this.carsService.storeState.settings.competitors = value;
        this.storeState.settings.competitors = value;
    }

    saveUserFilterValues(key: string = '_') {
        this.carsSharedService.saveUserFilterValues(this.userFilterStorageKey, this.extractUserFilterValues(), key);
    }

    clearUserFilterValues(key: string = '_') {
        this.carsSharedService.saveUserFilterValues(this.userFilterStorageKey, false, key);
        this.carsSharedService.clearUnselectedCompetitorsStore();

        if (!this.defaultFilterValues) {
            document.location.reload();
        }

        // Prevent document load.
        this.storeState.filtersReady = false;

        // Empty document to not update filters from current document.
        this.storeState.document = null;

        const settings = this.defaultFilterValues;

        Object.assign(this.storeState.settings, {
            country: settings.country,
            pickUpCityCode: settings.pickUpCityCode,
            dataSource: settings.dataSource,
            pos: settings.pos,
            lor: settings.lor,
            competitors: this.storeState.settings.competitors,
            carClasses: [],
            transmission: null,
            mileage: null,
            paymentTerms: null,
        });

        // Trigger refresh because document can not be loaded if triggering filters were not changed.
        this.storeState.settings.carClassesPreserve = false;
        this.refreshFilters();

        // Mark filters as ready and trigger data load
        this.storeState.filtersReady = true;
    }

    extractUserFilterValues() {
        const { settings } = this.storeState;
        return {
            country: settings.country,
            pickUpCityCode: settings.pickUpCityCode,
            dataSource: settings.dataSource,
            pos: settings.pos,
            lor: settings.lor,
            competitors: settings.competitors,
            carClasses: settings.carClasses,
            transmission: settings.transmission,
            mileage: settings.mileage,
            paymentTerms: settings.paymentTerms,
        };
    }
}
