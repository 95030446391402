import { Inject, injectable } from 'inversify-props';
import moment from 'moment';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import RatesStore from '@/modules/rates/store/rates.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import NON_REQUESTABLE_FIELDS from '@/modules/rates/constants/non-request-fields.constant';
import Day from '@/modules/common/types/day.type';
import Percent from '@/modules/common/types/percent.type';
import Price from '@/modules/common/types/price.type';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesApiService, { RatesApiServiceS } from '@/modules/rates/rates-api.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import downloadBlobAsFile from '@/modules/common/filters/download-file';
import PRICE from '../common/modules/rates/constants/price.enum';
import HotelRooms from '../common/interfaces/hotelRooms.interface';
import RoomTypesService, { RoomTypesServiceS } from '../room-types/room-types.service';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import RatesFiltersService, { RatesFiltersServiceS } from './rates-filters.service';
import ANY_MEAL_TYPE from '../meal-types/constants/any-meal-type.constant';
import ANY_ROOM_TYPE from '../room-types/constants/any-room-type.constant';
import ExcelQueryParams from './dto/excel-query-params.dto';

export const RatesAnalysisServiceS = Symbol.for('RatesAnalysisServiceS');
@injectable(RatesAnalysisServiceS as unknown as string)
export default class RatesAnalysisService implements Stateable {
    @Inject(RatesApiServiceS) private ratesApiService!: RatesApiService;
    @Inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @Inject(RatesServiceS) private ratesService!: RatesService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @Inject(UserSettingsS) private userSettingsService!: UserSettingsService;
    @Inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;

    readonly storeState: RatesStore = this.storeFacade.getState('RatesStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.storeState.analysis.settings.comparisonFilter.key,
            this.storeState.analysis.settings.comparisonFilter.values,
            this.ratesService.isLoading,
        ], () => {
            this.storeState.analysis.loading.reset();
        });
    }

    get document() {
        const { comparisonValues } = this.ratesAnalysisFiltersService;

        if (comparisonValues.length) {
            return this.getDocumentByComparisonValue(comparisonValues[0].value);
        }
        return null;
    }

    get settings() {
        const { settings: mainSettings } = this.ratesService;
        const { comparisonKey: key } = this.ratesAnalysisFiltersService;
        const { value } = this.ratesAnalysisFiltersService.comparisonValues[0];

        if (key === 'diffDays') {
            return mainSettings;
        }

        return {
            ...mainSettings,
            [key]: value,
        };
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.analysis.loading, this.loadData.bind(this));
        return this.storeState.analysis.documents;
    }

    get isLoading() {
        return this.storeState.analysis.loading.isLoading();
    }

    set closeNoData(value: boolean) {
        this.storeState.analysis.settings.closeNoDataNotification = value;
    }

    get closeNoData() {
        return this.storeState.analysis.settings.closeNoDataNotification;
    }

    get currency() {
        return this.ratesCommonService.currency(this.data[0]);
    }

    async loadData(): Promise<boolean> {
        const { settings: documentSettings } = this.documentFiltersService.storeState;
        const { settings: ratesSettings } = this.storeState;

        if (this.ratesService.isLoading) {
            return false;
        }

        if (
            documentSettings.compsetId === null
            || documentSettings.los === null
            || documentSettings.pos === null
            || ratesSettings.provider === null
        ) {
            return false;
        }

        this.storeState.analysis.documents = [];
        const { comparisonFilter } = this.storeState.analysis.settings;

        const unitedSettings = {
            ...ratesSettings,
            ...documentSettings,
        };

        const ratesDocuments = await Promise.all(
            comparisonFilter.values.map(async item => this.ratesApiService
                .getRatesAnalysisDocument(
                    unitedSettings,
                    comparisonFilter.key,
                    item.value,
                    this.userSettingsService.displayCurrency || this.ratesService.currency,
                )),
        );

        if (ratesDocuments) {
            this.storeState.analysis.documents = ratesDocuments as RatesDocumentModel[];
        }

        return true;
    }

    getDocumentByComparisonValue(value: string | number) {
        if (NON_REQUESTABLE_FIELDS.includes(this.ratesAnalysisFiltersService.comparisonKey)) {
            return this.ratesService.data as RatesDocumentModel;
        }

        const index = this.ratesAnalysisFiltersService.comparisonValues.findIndex(x => x.value === value);
        if (index === -1) {
            return null;
        }
        return this.data && this.data[index] ? this.data[index] : null;
    }

    getDocumentByComparisonName(value: string) {
        if (NON_REQUESTABLE_FIELDS.includes(this.ratesAnalysisFiltersService.comparisonKey)) {
            return this.ratesService.data;
        }

        const index = this.ratesAnalysisFiltersService.comparisonValues.findIndex(x => x.name.toLocaleLowerCase() === value.toLocaleLowerCase());
        if (index === -1) {
            return null;
        }
        return this.data && this.data[index] ? this.data[index] : null;
    }

    getSettingsByComparisonValue(value: string | number) {
        if (this.ratesAnalysisFiltersService.comparisonValues.length) {
            return {
                ...this.ratesService.settings,
                [this.ratesAnalysisFiltersService.comparisonKey]: value,
            };
        }
        return this.ratesService.settings;
    }

    getSettingsByComparisonName(value: string) {
        const data = this.ratesAnalysisFiltersService.comparisonValues.find(x => x.name.toLocaleLowerCase() === value.toLocaleLowerCase());
        if (this.ratesAnalysisFiltersService.comparisonValues.length && data) {
            return {
                ...this.ratesService.settings,
                [this.ratesAnalysisFiltersService.comparisonKey]: data.value,
            };
        }
        return this.ratesService.settings;
    }

    getCardAssessment(day: Day) : ASSESSMENTS_TYPES | null {
        if (!this.compsetsService.currentCompset || !this.settings) {
            return null;
        }
        const percent = this.competitionPercent(day);

        if (!percent) return null;

        return this.ratesCommonService
            .getCardAssessment(percent, this.compsetsService.currentCompset);
    }

    /**
     * Returns price for Hotel Room by its `hotelId` (default: current user hotel)
     */
    getPrice(day: Day, hotelId?: number | string) {
        const hid = hotelId || this.userService.currentHotelId;
        if (!hid || !this.settings) return null;

        const [doc] = this.data as [RatesDocumentModel];
        const { priceShown } = this.settings;

        return this.ratesCommonService
            .getPrice(day, hid, doc, priceShown);
    }

    getCompsetPrice(day: Day) {
        const compset = this.compsetsService.currentCompset;
        if (!compset) return null;

        const competitorRooms = this.getCompetitorsRooms(day);
        if (!competitorRooms) return null;

        const { priceShown } = this.settings || this.ratesService.settings;

        return this.ratesCommonService.getCompsetPrice(competitorRooms, compset.type, priceShown);
    }

    getAllRooms(day: Day): HotelRooms {
        const [doc] = this.data as [RatesDocumentModel];

        return this.ratesCommonService.getAllRooms(day, doc);
    }

    getCompetitorsRooms(day: Day) {
        const allRooms = this.getAllRooms(day);
        const hotelId = this.userService.currentHotelId;

        const { competitors } = this.storeState.settings;

        if (!hotelId || !competitors) return {};

        const entries = competitors
            .map(hid => [hid, allRooms[hid]]);

        return Object.fromEntries(entries) as HotelRooms;
    }

    getCompsetDifference(day: Day) {
        const currentCompsetPrice = this.ratesService.getCompsetPrice(day);
        const analysisCompsetPrice = this.getCompsetPrice(day);

        if (!currentCompsetPrice || !analysisCompsetPrice) return 0;

        return (currentCompsetPrice - analysisCompsetPrice) / (analysisCompsetPrice || 1);
    }

    getRoomDifference(day: Day, hotelId: number | string, raw: boolean = false) {
        const currentRoom = this.ratesService.getPrice(day, hotelId);
        const analysisRoom = this.getPrice(day, hotelId);

        if (!currentRoom || !analysisRoom) return 0;

        const difference = currentRoom - analysisRoom;
        const divider = raw
            ? 1
            : analysisRoom || 1;

        return difference / divider;
    }

    getRoom(day: Day, hotelId: number) {
        const [doc] = this.data as [RatesDocumentModel];

        return this.ratesCommonService
            .getRoom(day, hotelId, doc);
    }

    getTableAssessment(price: number, day: Day): ASSESSMENTS_TYPES | null {
        const { currentHotelId: mainHotelId } = this.userService;
        const { currentCompset: compset } = this.compsetsService;
        const [doc] = this.data;
        const { settings } = this;

        if (!settings) return null;

        return this.ratesCommonService
            .getTableAssessment(price, day, mainHotelId!, compset, doc, settings);
    }

    getHotelLosRestriction(day: Day, hotelId?: number) {
        const hid = hotelId || this.userService.currentHotelId;

        return this.ratesCommonService.getHotelLosRestriction(day, hid!, this.data[0]);
    }

    competitionPercent(day: Day): Percent | null {
        const { exchangeRate } = (this.document || {}) as RatesDocumentModel;
        const { pos: globalPos } = this.documentFiltersService.storeState.settings;

        const isDifferentPos = globalPos !== this.ratesAnalysisFiltersService.comparisonValues[0].value;
        const isPosComparison = this.ratesAnalysisFiltersService.comparisonKey === 'pos';

        const hid = this.userService.currentHotelId!;
        const [doc] = this.data;
        const { priceShown } = this.settings!;

        const mainMyPrice = this.ratesService
            .getPrice(day);

        let comparedMyPrice: Price | null = this.ratesCommonService
            .getPrice(day, hid, doc, priceShown);

        if (!comparedMyPrice || !mainMyPrice) { return null; }

        if (isPosComparison && isDifferentPos) {
            comparedMyPrice *= exchangeRate;
        }

        return (mainMyPrice - comparedMyPrice) / (comparedMyPrice || 1);
    }

    isAllNoData(day: Day) {
        return this.isNoData(day);
    }

    isNoData(day: Day) {
        return this.ratesCommonService
            .isNoData(day, this.data[0]);
    }

    isNA(day: Day, hotelId: number) {
        if (this.isNoData(day)) return false;

        const price = this.getPrice(day, hotelId);

        return false
            || price === PRICE.NA
            || price === null;
    }

    isSoldOut(day: Day, hotelId: number) {
        if (this.isNA(day, hotelId)) return false;
        if (this.isNoData(day)) return false;

        const price = this.getPrice(day, hotelId);

        return price === PRICE.SOLD_OUT;
    }

    isOutOfRange() {
        return this.ratesCommonService
            .isOutOfRange(this.data[0]);
    }

    resetLoading() {
        this.storeState.analysis.loading.reset();
    }

    // Direct download
    async getAnlaysisExcel() {
        const { year, month } = this.documentFiltersService;
        const {
            mealTypeId, roomTypeId, priceType, priceShown,
        } = this.ratesFiltersService.settings;
        const { currentCompset } = this.compsetsService;
        const { displayCurrency } = this.userSettingsService;

        let mealTypes;
        if (mealTypeId !== ANY_MEAL_TYPE.id) {
            const mealType = this.mealTypesService.getMealType(mealTypeId);
            mealTypes = mealType ? [mealType.name] : [];
        } else {
            mealTypes = this.mealTypesService.mealTypes.filter(type => type.id !== ANY_MEAL_TYPE.id).map(type => type.name);
        }

        let roomTypes;
        if (roomTypeId !== ANY_ROOM_TYPE.id) {
            const roomType = this.roomTypesService.getRoomType(roomTypeId);
            roomTypes = roomType ? [roomType.name] : [];
        } else {
            roomTypes = this.roomTypesService.rooms.filter(type => type.id !== ANY_ROOM_TYPE.id).map(room => room.name);
        }

        const startDate = moment(new Date(year, month, 1)).format('YYYY-MM-DD');
        const endDate = moment(new Date(year, month + 1, 0)).format('YYYY-MM-DD');

        const settings = {
            providers: [this.ratesFiltersService.currentProvider],
            los: [this.documentFiltersService.settings.los],
            pos: [this.documentFiltersService.settings.pos],
            mealTypeId: mealTypes || [],
            roomTypeId: roomTypes || [],
            priceType: [priceType],
            priceShown,
            compsetId: currentCompset ? currentCompset.id : null,
            monthrange: [startDate, endDate],
            numberOfGuests: this.ratesFiltersService.currentNumberOfGuests,
            displayCurrency,
        } as ExcelQueryParams;

        const parceCompareToKey = (key: string): string => {
            switch (key) {
                case 'diffDays': return 'past period';
                case 'provider': return 'source';
                case 'roomTypeId': return 'roomType';
                case 'mealTypeId': return 'mealType';
                case 'numberOfGuests': return 'number of guest';
                case 'priceType': return 'price';
                default: return key;
            }
        };

        const compareTo = {
            key: parceCompareToKey(this.ratesAnalysisFiltersService.comparisonKey),
            value: this.ratesAnalysisFiltersService.comparisonValues[0].value,
        };

        if (compareTo.key === 'past period') {
            compareTo.value = Number(`-${compareTo.value}`);
        }

        if (compareTo.key === 'roomType') {
            const roomType = this.roomTypesService.getRoomType(compareTo.value as number);
            compareTo.value = roomType ? roomType.name : '';
        }

        if (compareTo.key === 'mealType') {
            const mealType = this.mealTypesService.getMealType(compareTo.value as number);
            compareTo.value = mealType ? mealType.name : '';
        }

        const excelData = await this.ratesApiService.getAnalysisExcelDocument(settings, compareTo);

        if (excelData) {
            downloadBlobAsFile('rates-comparison.xlsx', excelData);
        }
    }
}
