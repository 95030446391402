import { Inject, injectable } from 'inversify-props';
import _ from 'lodash';
import moment from 'moment';

import Day from '@/modules/common/types/day.type';
import Stateable from '@/modules/common/interfaces/stateable.interface';

import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import ICheckinDates from '@/modules/rates/interfaces/checkin-dates.interface';

import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import RatesPriceHistoryStore from '@/modules/common/modules/rates-price-history/store/rates-price-history.store';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesPriceHistoryAllService, { RatesPriceHistoryAllServiceS } from './rates-price-history-all.service';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS } from './rates-price-history-common.service';

type DocumentDictionary = { [hotelId: number]: RatesDocumentItemModel };

export const RatesPriceHistoryServiceS = Symbol.for('RatesPriceHistoryServiceS');
@injectable(RatesPriceHistoryServiceS as unknown as string)
export default class RatesPriceHistoryService implements Stateable {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ClusterCompsetsServiceS) private clusterCompsetsService!: ClusterCompsetsService;
    @Inject(RatesAnalysisServiceS) ratesAnalysisService!: RatesAnalysisService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(RatesAnalysisFiltersServiceS) ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @Inject(RatesServiceS) ratesService!: RatesService;
    @Inject(RatesPriceHistoryAllServiceS) ratesPriceHistoryAll!: RatesPriceHistoryAllService;
    @Inject(RatesPriceHistoryCommonServiceS) private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;
    @Inject(UserSettingsS) private userSettingsService!: UserSettingsService;

    readonly storeState: RatesPriceHistoryStore = this.storeFacade.getState('RatesPriceHistoryStore');

    currentDataKey: string = 'main';

    set documents(value) {
        this.storeState.documents = value;
    }

    get documents() {
        return this.storeState.documents;
    }

    get documentId() {
        return this.storeState.documentId;
    }

    set documentId(value: number | null) {
        this.storeState.documentId = value;
    }

    set ratesSettings(value: RatesSettingsModel| null) {
        this.storeState.ratesSettings = value;
    }

    get ratesSettings() {
        return this.storeState.ratesSettings;
    }

    get comparisonValues() {
        return this.ratesAnalysisFiltersService.comparisonValues;
    }

    get currency() {
        return this.ratesPriceHistoryCommonService.currency;
    }

    set currency(value: string | null) {
        this.ratesPriceHistoryCommonService.currency = value;
    }

    get lastScanDate() {
        return this.storeState.lastScanDate;
    }

    get hotels() {
        let hotelIds: number[] = [];
        const { competitors, hotelId } = this.ratesPriceHistoryCommonService;

        if (competitors && hotelId) {
            const competitorsFilters = this.ratesFiltersService.competitors;

            const filteredCompetitors = competitorsFilters !== null
                ? competitors.filter((value: number) => competitorsFilters.includes(value))
                : competitors;

            hotelIds = [...filteredCompetitors, hotelId];
        }

        return hotelIds.length ? hotelIds : null;
    }

    get dayIndex() {
        return this.storeState.dayIndex;
    }

    get currentScanDate() {
        return this.getDateByHistoryDay(this.dayIndex);
    }

    get settingsByKey() {
        return this.currentDataKey === 'main'
            ? this.ratesSettings
            : this.ratesAnalysisService.settings;
    }

    getDateByHistoryDay(day: number) {
        if (!this.lastScanDate) return null;

        const dateInstance = new Date(this.lastScanDate);
        dateInstance.setDate(dateInstance.getDate() - day + 1);

        return dateInstance
            .toISOString()
            .split('T')
            .shift()!
            .split('-')
            .reverse()
            .join('-');
    }

    setDataKey(value: string) {
        this.currentDataKey = value;
        return this;
    }

    priceHistory(
        actualDay: Day | null,
        hotelId: number | null,
        compsetId: string | null = null,
        documentFilters: DocumentFiltersModel | null = null, // TODO: Вопрос по фильтрам
    ): DocumentDictionary[] | null {
        const EMPTY_DICTIONARY = {} as DocumentDictionary;
        const { sortedDaysList } = this.ratesPriceHistoryCommonService;
        const toSuitableRoom = (__: any, index: number) => this.getSuitableRoomByDay(index) || EMPTY_DICTIONARY;

        this.ratesPriceHistoryCommonService
            .setData(actualDay, hotelId, compsetId, documentFilters);

        return sortedDaysList.map(toSuitableRoom);
    }

    getSuitableRoomByDay(dayParam: Day | number, diffDays = 0): DocumentDictionary {
        const EMPTY_DICTIONARY = {} as DocumentDictionary;

        const isParametersNotValid = false
            || !this.hotels
            || !this.documents
            || !this.documents[this.currentDataKey]
            || !this.lastScanDate;

        if (isParametersNotValid) {
            return EMPTY_DICTIONARY;
        }

        const { documents } = this;
        const { trendData } = documents[this.currentDataKey]!;
        const date = this.getDateByHistoryDay(dayParam + diffDays)!;
        const data = trendData[date];

        if (!data) {
            if (diffDays && diffDays < 14) {
                return this.getSuitableRoomByDay(dayParam, diffDays + 1);
            }

            return EMPTY_DICTIONARY;
        }

        let hotels = {};

        if (this.ratesService.isAllChannelsMode) {
            hotels = data;
        } else {
            ({ hotels } = data);
        }

        const allHotels = this.ratesCommonService.getAllRoomsByHotels(hotels);
        const byValidRooms = ([__, room]: any) => !!room;

        const filteredHotelsEntries = Object
            .entries(allHotels)
            .map(([hotelId, room]) => {
                const hid = +hotelId || hotelId;
                const isHotelExists = true
                    && this.hotels
                    && this.hotels.includes((hid) as number);

                if (isHotelExists) {
                    return [hid, room];
                }

                return [-1, null];
            })
            .filter(byValidRooms);

        const filteredHotels = Object.fromEntries(filteredHotelsEntries);
        return filteredHotels as DocumentDictionary;
    }

    getHotelByDay(dayParam: Day | number) {
        const filteredHotels = {} as {[hotelId: number]: RatesDocumentItemModel};

        if (!this.hotels) {
            return filteredHotels;
        }

        if (!this.documents || !this.documents[this.currentDataKey] || !this.lastScanDate) {
            return filteredHotels;
        }

        const dateInstance = new Date(
            this.lastScanDate.getFullYear(),
            this.lastScanDate.getMonth(),
            this.lastScanDate.getDate() - dayParam,
        );

        const day = moment(dateInstance).format('DD');
        const month = moment(dateInstance).format('MM');
        const year = moment(dateInstance).format('YYYY');
        // @ts-ignore
        return this.documents[this.currentDataKey].trendData[`${day}-${month}-${year}`];
    }

    getLosRestriction(dayParam: Day | number, hotelId: number) {
        const data: any = this.getHotelByDay(dayParam);
        if (!data || !data.hotels || !data.hotels[hotelId]) {
            return false;
        }
        return data.hotels[hotelId].losRestriction;
    }

    getHotelColor(hotelId: number, compsetId?: string | null) {
        return this.hotelsService.getHotelsGraphColor(compsetId)[String(hotelId)] || '#000000';
    }

    getProviderGraphColor(index: number) {
        const { chartColors } = this.userSettingsService;
        if (chartColors === null) {
            return '#000000';
        }
        return chartColors[index];
    }

    getPriceHistoryHotelData(hotelIdParam: number) {
        const {
            docDay,
            hotelId,
            compsetId,
            documentFilters,
        } = this.ratesPriceHistoryCommonService;

        const priceHistory = docDay
            ? this.priceHistory(
                docDay as Day,
                hotelId,
                compsetId,
                documentFilters,
            ) as {[hotelIdParam: number]: RatesDocumentItemModel}[]
            : null;

        return this.getPriceHistoryMap(priceHistory, hotelIdParam);
    }

    getPriceHistoryMap(priceHistory: {[p: number]: RatesDocumentItemModel}[] | null, hotelId: number) {
        return priceHistory
            ? priceHistory.map((day: {[hotelId: number]: RatesDocumentItemModel} | null) => {
                if (!day || !Object.keys(day).length) {
                    return null;
                }

                const hotelRoom = day[Number(hotelId)];

                if (!hotelRoom) {
                    return null;
                }

                return day[Number(hotelId)];
            })
            : [];
    }

    getPriceHistoryHotelPrices(hotelId: number) {
        const settings = this.settingsByKey;
        return this.getPriceHistoryHotelData(hotelId)
            .map(room => (room && settings ? (this.ratesCommonService.switchPrice(settings, room) || null) : null))
            .reverse();
    }

    getCompsetPrice(
        day: Day,
        compsetId: string | null,
        settings: RatesSettingsModel,
        hotelId: number | null,
    ) {
        if (hotelId) {
            this.ratesPriceHistoryCommonService.hotelId = hotelId;
        }

        const document = this.documents[this.currentDataKey];

        if (!this.ratesPriceHistoryCommonService.hotelId || !settings || !document) {
            return null;
        }
        if (!this.lastScanDate) {
            return null;
        }

        const currentCompset = compsetId
            ? this.clusterCompsetsService.getCompsetById(compsetId)
            : this.compsetsService.currentCompset;

        if (!currentCompset) {
            return null;
        }

        const data = this.getDateByHistoryDay(day);

        if (!data) {
            return null;
        }

        const doc = new RatesDocumentModel();
        doc.checkinDates = {
            [day]: data as unknown as ICheckinDates[0],
        };

        const price = this.ratesPriceHistoryCommonService
            .getCompsetPriceHistory(day, doc, settings, currentCompset.id);

        return { type: currentCompset.type, price };
    }

    get isNetTotalAvailable() {
        const { compsetId, documentFilters } = this.ratesPriceHistoryCommonService;
        const { docDay, hotelId } = this.ratesPriceHistoryCommonService;

        this.setDataKey('main');

        const priceHistory = docDay
            ? this.priceHistory(docDay as Day, hotelId, compsetId, documentFilters)
            : null;

        let isAvailable = true;

        if (priceHistory) {
            const dates = Object.keys(priceHistory).map(day => +day);

            isAvailable = !!dates.find(day => !_.isEmpty(priceHistory[day]));
        } else {
            isAvailable = false;
        }

        return isAvailable;
    }

    getDemand() {
        if (!this.currentScanDate || !this.documents.main) {
            return null;
        }

        const data = this.documents.main.trendData[this.currentScanDate];

        if (!data || !data.demand) {
            return null;
        }

        return +data.demand * 100;
    }

    getOccupancy() {
        if (!this.currentScanDate || !this.documents.main) {
            return null;
        }

        const data = this.documents.main.trendData[this.currentScanDate];

        if (!data || !data.occupancy) {
            return null;
        }

        return +data.occupancy / 100;
    }
}
