import { Inject, injectable } from 'inversify-props';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import CarsFiltersStore from '../../store/cars-filters.store';
import { COUNTRIES_ANY } from '../../constants/car-filter-types.constant';
import ParityStore from './store/parity.store';
import BmlFilterType from './types/bml-filter.type';

export const ParitySettingsServiceS = Symbol.for('ParitySettingsServiceS');
@injectable(ParitySettingsServiceS as unknown as string)
export default class ParitySettingsService {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    readonly storeState: ParityStore = this.storeFacade.getState('ParityStore');
    readonly carFiltersStoreState: CarsFiltersStore = this.storeFacade.getState('CarsFiltersStore');

    get pickUpCities() {
        return this.storeState.settings.pickUpCityCodes;
    }

    set pickUpCities(cities: string[] | null) {
        this.storeState.settings.pickUpCityCodes = cities;
    }

    get lor() {
        return this.storeState.settings.lor;
    }

    set lor(lor: number[] | null) {
        this.storeState.settings.lor = lor;
    }

    get pos() {
        return this.storeState.settings.pos;
    }

    set pos(pos: string[] | null) {
        this.storeState.settings.pos = pos;
    }

    get dataSources() {
        return this.storeState.settings.dataSources;
    }

    set dataSources(dataSources: string[] | null) {
        this.storeState.settings.dataSources = dataSources;
    }

    get carClasses() {
        return this.storeState.settings.carClasses;
    }

    set carClasses(carClasses: string[] | null) {
        this.storeState.settings.carClasses = carClasses;
    }

    get doors() {
        return this.storeState.settings.doors;
    }

    set doors(doors: string[] | null) {
        this.storeState.settings.doors = doors;
    }

    get mileage() {
        return this.storeState.settings.mileage;
    }

    set mileage(mileage: string[] | null) {
        this.storeState.settings.mileage = mileage;
    }

    get transmission() {
        return this.storeState.settings.transmission;
    }

    set transmission(transmission: string[] | null) {
        this.storeState.settings.transmission = transmission;
    }

    get paymentTerms() {
        return this.storeState.settings.paymentTerm;
    }

    set paymentTerms(paymentTerms: string[] | null) {
        this.storeState.settings.paymentTerm = paymentTerms;
    }

    get country() {
        return this.storeState.settings.country;
    }

    set country(country: string | null) {
        this.storeState.settings.country = country;
    }

    get bml() {
        return this.storeState.settings.bml;
    }

    set bml(bml: BmlFilterType[] | null) {
        this.storeState.settings.bml = bml;
    }

    get filteredLocations() {
        const { countries, locations } = this.carFiltersStoreState.settings;
        const { country } = this.storeState.settings;

        if (!locations) {
            return [];
        }

        if (!country || country === COUNTRIES_ANY || !countries) {
            return locations;
        }

        const selectedCountryLocationCodes = countries[country];
        return locations.filter(location => (selectedCountryLocationCodes.find(code => code === location.locationId)));
    }

    get provider() {
        return this.storeState.settings.provider;
    }

    set provider(provider: string | null) {
        this.storeState.settings.provider = provider;
    }

    get pickUpDate() {
        return this.storeState.settings.pickUpDate;
    }

    set pickUpDate(pickUpDate: Date | null) {
        this.storeState.settings.pickUpDate = pickUpDate;
    }

    get priceDif() {
        return this.storeState.settings.priceDif;
    }

    set priceDif(priceDif: [number | null, number | null][] | null) {
        this.storeState.settings.priceDif = priceDif;
    }

    get isAllFiltersSetUp() {
        return this.pickUpCities
            && this.lor
            && this.pos
            && this.carClasses
            && this.mileage
            && this.transmission
            && this.paymentTerms
            && this.bml
            && this.provider
            && this.dataSources;
    }

    updateLocations() {
        // Select all locations of current country by default.
        this.pickUpCities = this.filteredLocations.map(loc => loc.locationId);
    }
}
