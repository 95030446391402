/**
 * The properties that not trigger rates request by changing
 */
const NON_REQUESTABLE_FIELDS = [
    'mealTypeId',
    'roomTypeId',
    'priceType',
];

export default NON_REQUESTABLE_FIELDS;
