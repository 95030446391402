import { Inject, injectable } from 'inversify-props';
import { Route } from 'vue-router';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import { IMiddleware } from '@/router/interfaces/middleware.interface';
import StorageService, { StorageServiceS } from '@/modules/common/services/storage.service';

export const AuthMiddlewareS = Symbol.for('AuthMiddlewareS');
@injectable(AuthMiddlewareS as unknown as string)
export default class AuthMiddleware implements IMiddleware {
    @Inject(AuthServiceS) private authService!: AuthService;
    @Inject(StorageServiceS) private storageService!: StorageService;

    async canActivate(to: Route) {
        if (!await this.authService.isLogin()) {
            if (to.fullPath !== '/forbidden') {
                this.storageService.redirrectUrl = to.fullPath;
            }

            window.location.href = this.authService.loginUrl;
            return true;
        }

        if (this.storageService.redirrectUrl) {
            const { redirrectUrl } = this.storageService;
            this.storageService.clearRedirrectUrl();
            window.location.href = redirrectUrl;
            return true;
        }

        return true;
    }
}
