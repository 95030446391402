import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import use from '@/router/use';
import { container } from 'inversify-props';
import DeepAnalysisClusterLevelRoutes from '../cluster/deep-analysis.routes';

export default function DeepAnalysisChainLevelRoutes() {
    return {
        path: 'compset-benchmark',
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS)]),
        children: [
            // TODO: Create compset-benchmark.routes
            // {
            //     name: 'chain.deep-analysis',
            //     path: '',
            //     component: () => import('@/modules/chain/pages/deep-analysis.page.vue'),
            //     children: [
            //         ...CommonMenuRoutes('chain.markets'),
            //         // NOTE: Place cluster level routes here
            //     ],
            // },

            // NOTE: Forward to cluster level routes
            ...DeepAnalysisClusterLevelRoutes('', 'chain.compset-benchmark.cluster').children!,
        ],
    };
}
