import { Expose, Transform } from 'class-transformer';

export default class CarsAlertConfigurationModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => String(plain._id))
    id!: string;

    @Expose()
    @Transform((_, plain) => String(plain.chain_id))
    chainId!: string;

    @Expose()
    @Transform((_, plain) => String(plain.user_id))
    userId!: string;

    @Expose()
    @Transform((_, plain) => plain.country_codes)
    countryCodes!: string[];

    @Expose()
    @Transform((_, plain) => String(plain.alert_type))
    alertType!: string;

    @Expose()
    @Transform((_, plain) => plain.ext_data)
    extData!: any;

    @Expose()
    status?: string;
}
