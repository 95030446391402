export default function OnBoardingRoutes() {
    return {
        path: '/onboarding',
        component: () => import('@/modules/common/pages/transition.page.vue'),

        children: [
            {
                name: 'onboarding',
                path: '',
                component: () => import('@/modules/onboarding/pages/index.page.vue'),
            },
        ],
    };
}
