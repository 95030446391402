export default function DMSRoutes() {
    return {
        path: '/dms',
        component: () => import('@/modules/common/pages/transition.page.vue'),

        children: [
            {
                name: 'dms',
                path: '',
                component: () => import('@/modules/dms/pages/index.page.vue'),
            },
        ],
    };
}
