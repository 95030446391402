import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import RankingDocumentModel from '@/modules/ranking/models/ranking-document.model';
import RankingDocumentItemModel from '@/modules/ranking/models/ranking-document-item.model';
import Day from '@/modules/common/types/day.type';
import RankingHistoryModel from '../models/ranking-history.model';
import IHistoryItem from '../interfaces/history-item.interface';

@Module
export default class RankingHistoryStore extends VuexModule {
    document: RankingHistoryModel | null = null;
    loading: LoadingModel = new LoadingModel();

    tableDay: Day | null = null;

    // last chartJs tooltip position, to save it between price history openings
    lastTooltipPos: string | null = null;
    trendDates: IHistoryItem[][] | null = null;

    updateDate: string | null = null;
}
