import Percent from '@/modules/common/types/percent.type';

const THRESHOLD = {
    MIN_RANGE: -0.3 as Percent,
    MAX_RANGE: 0.3 as Percent,
    STEP: 0.05 as Percent,
    DEFAULT_MIN: 0.05 as Percent,
    DEFAULT_MAX: 0.1 as Percent,
};

export default THRESHOLD;
