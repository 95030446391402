import { Inject, injectable } from 'inversify-props';
import moment from 'moment';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import RatesApiService, { RatesApiServiceS, RatesRequestMode } from '@/modules/rates/rates-api.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import DiLiteStore from '@/modules/di-lite/store/di-lite.store';
import { ProviderData } from '@/modules/di-lite/interfaces/provider-data.interface';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import Price from '@/modules/common/types/price.type';
import Day from '@/modules/common/types/day.type';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import DocumentFiltersService, { DocumentFiltersServiceS } from '../document-filters/document-filters.service';
import ANY_MEAL_TYPE from '../meal-types/constants/any-meal-type.constant';
import ANY_ROOM_TYPE from '../room-types/constants/any-room-type.constant';
import RatesDocumentItemModel from '../rates/models/rates-document-item.model';
import DiLiteMarketService, { DiLiteMarketServiceS } from './di-lite-market.service';
import PRICE_TYPE from '../document-filters/constants/price-type.constant';
import UserSettings from '../user/store/user-settings.store';
import PRICE from '../common/modules/rates/constants/price.enum';
import RatesService, { RatesServiceS } from '../rates/rates.service';
import { DILiteFilterDevice } from './models/all-channels-settings.model';

export const DiLiteAllChannelsServiceS = Symbol.for('DiLiteAllChannelsServiceS');
@injectable(DiLiteAllChannelsServiceS as unknown as string)
export default class DiLiteAllChannelsService implements Stateable {
    @Inject(DiLiteMarketServiceS)
    private diLiteMarketService!: DiLiteMarketService;

    @Inject(RatesApiServiceS)
    private ratesApiService!: RatesApiService;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @Inject(HelperServiceS)
    private helperService!: HelperService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(RatesServiceS)
    private ratesService!: RatesService;

    readonly storeState: DiLiteStore = this.storeFacade.getState('DiLiteStore');
    readonly settingsState: UserSettings = this.storeFacade.getState('UserSettings');
    readonly brand: string = 'bcom';

    private currentDocument!: RatesDocumentAllModel;

    constructor() {
        this.storeFacade.watch(() => [
            this.allProviders,
        ], ((newValue, oldValue) => {
            const [newCompset] = newValue;
            const [currentCompset] = oldValue;

            if (newCompset && JSON.stringify(currentCompset) !== JSON.stringify(newCompset)) {
                this.provider = null;
                this.diLiteMarketService.resetLoading();
                return this.resetLoading.call(this);
            }
            return {};
        }));

        this.storeFacade.watch(() => [
            this.settingsState.currencies.displayCurrency,
            this.storeState.settings.mealTypeId,
            this.storeState.settings.roomTypeId,
            this.storeState.settings.priceType,
            this.storeState.settings.device,
            this.storeState.docSettings.los,
            this.storeState.docSettings.pos,
            this.storeState.settings.numberOfGuests,
            this.documentFiltersService.storeState.settings.month,
            this.documentFiltersService.storeState.settings.year,
        ], this.resetLoading.bind(this));

        this.storeFacade.watch(() => [
            this.storeState.settings.mealTypeId,
            this.storeState.settings.roomTypeId,
            this.storeState.settings.priceType,
            this.storeState.settings.device,
            this.ratesService.storeState.settings.priceShown,
        ], () => {
            this.mapData(this.currentDocument);
        });
    }

    resetLoading() {
        this.storeState.pricesData = null;
        this.storeState.loading.reset();
    }

    async loadData(): Promise<boolean> {
        const documentSettings = this.storeState.docSettings;
        documentSettings.month = this.documentFiltersService.month;
        documentSettings.year = this.documentFiltersService.year;

        if (!this.compset || this.userService.viewAs !== 'hotel') {
            return false;
        }

        documentSettings.compsetId = this.compset.id;
        documentSettings.los = documentSettings.los || this.compset.los[0];
        documentSettings.pos = documentSettings.pos || this.compset.mainPos;

        this.currentDocument = await this.loadDocument(documentSettings) as RatesDocumentAllModel;
        this.provider = this.mainProvider;
        this.mapData(this.currentDocument);

        return true;
    }

    private loadDocument(documentSettings: DocumentFiltersModel | null) {
        if (!documentSettings) {
            return null;
        }
        const { displayCurrency } = this.settingsState.currencies;
        const unitedSettings = {
            ...documentSettings,
            ...this.settings,
        };

        return this.ratesApiService
            .getRatesDocument(unitedSettings, displayCurrency, {}, RatesRequestMode.DILITE);
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.pricesData;
    }

    get isBrandAvailable() {
        return this.allProviders.includes(this.brand);
    }

    get compset() {
        const { compsets } = this.compsetsService;
        if (compsets && compsets.length) {
            return compsets[0];
        }
        return null;
    }

    get providers() {
        if (!this.allProviders.length) {
            return [];
        }

        const { device } = this.settings;

        const withoutCommonProviders = (provider: string) => !['all', 'cheapest'].includes(provider);
        const withoutBrand = (provider: string) => provider !== this.brand;
        const byDevice = (provider: string) => {
            switch (device) {
                case DILiteFilterDevice.MOBILE:
                    return provider.includes('mobile');

                case DILiteFilterDevice.DESKTOP:
                    return !provider.includes('mobile');

                default:
                    return true;
            }
        };

        let providers = this.allProviders
            .filter(withoutCommonProviders)
            .filter(withoutBrand);

        if (this.allProviders.includes('booking')) {
            providers = [...providers.filter((provider: string) => provider !== 'booking')];
            providers.unshift('booking');
        }

        return providers
            .filter(byDevice);
    }

    get allProviders() {
        if (!this.compset) {
            return [];
        }

        return this.compset.rateProviders;
    }

    get mainProvider() {
        if (this.isBrandAvailable) {
            return this.brand;
        }
        if (this.providers.includes('booking')) {
            return 'booking';
        }
        return this.providers[0];
    }

    get provider() {
        return this.storeState.provider;
    }

    set provider(value: string | null) {
        this.storeState.provider = value;
    }

    get days() {
        return this.documentFiltersService.days;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get demandData() {
        return this.storeState.demandData;
    }

    get currency() {
        return this.storeState.currency;
    }

    get filters() {
        return this.storeState.docSettings;
    }

    get settings() {
        return this.storeState.settings;
    }

    getDateByDay(day: Day) {
        return moment(new Date(this.documentFiltersService.year, this.documentFiltersService.month)).add(day - 1, 'day')
            .format('DD-MM-YYYY');
    }

    isBasic(provider: string): boolean {
        return !!provider && provider.includes('basic');
    }

    getPriceData(date: string, provider: string) {
        return (
            this.data
            && this.data[date]
            && this.data[date][provider]
        ) || null;
    }

    getPrice(date: string, provider: string): number | null {
        const priceData = this.getPriceData(date, provider);
        return priceData ? priceData.price : null;
    }

    getReferencePrice(day: string) {
        if (this.isBrandAvailable) {
            return this.getPrice(day, this.brand);
        }

        const { provider } = this.storeState;
        if (!provider) return null;

        return this.getPrice(day, provider);
    }

    getMaxDiff(day: string): { diff: number; diffRatio: number; } | null {
        const maxDiff : { diff: number; diffRatio: number; } | null = null;

        const providersPrices = this.providers
            .map((provider: string) => {
                const providerData = this.getPriceData(day, provider);
                return providerData ? providerData.price : null;
            })
            .filter(price => !!price);

        const mainPrice = this.storeState.provider
            ? this.getPrice(day, this.storeState.provider)
            : null;

        if (!mainPrice) {
            return maxDiff;
        }

        if (mainPrice && !providersPrices.length) {
            return {
                diff: 0,
                diffRatio: 0,
            };
        }

        const diffs = providersPrices
            .map(price => {
                let diff = 0;
                let diffRatio = 0;
                if (mainPrice && price) {
                    const averagePrice = (price + mainPrice) / 2;
                    diff = price - mainPrice;
                    diffRatio = (diff / averagePrice) * 100;
                }
                return {
                    diff: Math.abs(diff),
                    diffRatio: Math.round(diffRatio),
                };
            });
        const sortedDiffs = diffs
            .sort((a, b) => Math.abs(b.diff) - Math.abs(a.diff));

        return sortedDiffs[0];
    }

    private getDemandFromDocument(day: number, document: RatesDocumentAllModel) {
        if (document && document.demand && document.demand[day]) {
            return Number(document.demand[day]) * 100;
        }
        return null;
    }

    private getTableAssessment(price: Price | null, day: Day, document: RatesDocumentAllModel): ASSESSMENTS_TYPES.GOOD| ASSESSMENTS_TYPES.BAD | null {
        if (!this.compsetsService.currentCompset || !price || !this.provider) {
            return null;
        }

        const { type } = this.compsetsService.currentCompset;
        const mainPrice = this.getProviderPriceFromDocument(day, this.provider, document);

        if (mainPrice === null) {
            return null;
        }

        if (type === COMPSET_TYPE.HIGH || type === COMPSET_TYPE.MEDIAN) {
            return price > mainPrice ? ASSESSMENTS_TYPES.GOOD : ASSESSMENTS_TYPES.BAD;
        }

        return price <= mainPrice ? ASSESSMENTS_TYPES.GOOD : ASSESSMENTS_TYPES.BAD;
    }

    private mapData(document: RatesDocumentAllModel) {
        const providersData: {
            [date: string]: {
                [provider: string]: ProviderData
            }
        } = {};
        const demandData: (number | null)[] = [];

        this.storeState.currency = document && (document.currency || null);

        this.documentFiltersService.days.forEach(day => {
            const date = moment(day, 'DD-MM-YYYY');

            const demand = this.getDemandFromDocument(date.date(), document);
            demandData.push(demand);

            providersData[day] = {};

            const providers = this.isBrandAvailable ? [...this.providers, 'bcom'] : this.providers;

            providers.forEach(provider => {
                providersData[day][provider] = this.calculatePriceData(date.date() as Day, provider, document);
            });
        });

        this.storeState.pricesData = providersData;
        this.storeState.demandData = demandData;
    }

    private calculatePriceData(day: Day, provider: string, document: RatesDocumentAllModel) {
        const data: ProviderData = {
            price: null,
            isOutOfRange: false,
            isNoData: false,
            isNA: false,
            isSoldOut: false,
            isBasic: false,
            assessmentType: null,
            currency: null,
            losRestriction: false,
        };

        if (this.isOutOfRange(document)) {
            data.isOutOfRange = true;
        } else if (this.isNoData(day, document)) {
            data.isNoData = true;
        } else if (this.isNa(day, provider, document)) {
            data.isNA = true;
        } else if (this.isSoldOut(day, provider, document)) {
            data.isSoldOut = true;
        } else {
            data.price = this.getProviderPriceFromDocument(day, provider, document);
            data.assessmentType = this.getTableAssessment(data.price, day, document);
        }

        data.isBasic = this.isBasic(provider);
        data.currency = this.currency;

        if (document && document.checkinDates![day]) {
            const providerData = document.checkinDates![day]![provider];
            if (providerData) {
                data.losRestriction = providerData.losResctricted;
            }
        }

        return data;
    }

    private getProviderPriceFromDocument(day: Day, provider: string, document: RatesDocumentAllModel) {
        const data = this.checkinDates(day, document);
        const {
            mealTypeId, roomTypeId,
            priceType, numberOfGuests,
        } = this.storeState.settings;

        const isBestFlex = priceType === PRICE_TYPE.BEST_FLEX;
        const isMealTypeSpecified = mealTypeId !== ANY_MEAL_TYPE.id;
        const isRoomTypeSpecified = roomTypeId !== ANY_ROOM_TYPE.id;

        const byNumberOfGuests = (room: RatesDocumentItemModel) => room.occupancy >= numberOfGuests;
        const byMealType = (room: RatesDocumentItemModel) => room.mealTypeId === mealTypeId;

        const byBestFlexPrice = (room: RatesDocumentItemModel) => {
            const price = this.ratesService.switchPrice(room);

            return (!room.cancellation && price === PRICE.SOLD_OUT)
                || (room.cancellation && !!price && price !== 0);
        };

        if (!data[provider]) {
            return PRICE.NA;
        }

        let rooms = [] as RatesDocumentItemModel[];
        const providerData = data[provider];

        if (providerData) {
            rooms = isRoomTypeSpecified
                ? providerData.rooms[roomTypeId] || []
                : Object.values(providerData.rooms || []).flat();
        }

        if (isMealTypeSpecified) {
            rooms = rooms.filter(byMealType);
        }

        if (isBestFlex) {
            rooms = rooms.filter(byBestFlexPrice);
        }

        rooms = rooms.filter(byNumberOfGuests);

        const room = rooms.reduce((prevRoom: RatesDocumentItemModel | null, currRoom) => {
            if (!prevRoom) return currRoom;

            const price = this.ratesService.switchPrice(currRoom);
            const prevPrice = this.ratesService.switchPrice(prevRoom);

            if (!price) {
                return prevRoom;
            }

            if (!prevPrice) {
                return currRoom;
            }

            return price < prevPrice ? currRoom : prevRoom;
        }, null);

        if (!room) return PRICE.NA;

        const price = this.ratesService.switchPrice(room);

        return price;
    }

    private checkinDates(day: Day, document: RatesDocumentAllModel | null) {
        const checkinDate = document && document.checkinDates && document.checkinDates[day];
        if (!checkinDate) {
            return {};
        }
        return checkinDate;
    }

    private isNoData(day: Day, document: RatesDocumentAllModel | null) {
        if (!document || !document.checkinDates) {
            return true;
        }

        const allRooms = this.checkinDates(day, document);
        const isNoRooms = !Object.keys(allRooms).length;

        if (isNoRooms) {
            return true;
        }

        return !document.checkinDates[day];
    }

    private isOutOfRange(document: RatesDocumentAllModel | null) {
        return !document;
    }

    private isSoldOut(day: Day, provider: string, document: RatesDocumentAllModel | null) {
        if (!document || !document.checkinDates) {
            return false;
        }

        const price = this.getProviderPriceFromDocument(day, provider, document);

        return !price;
    }

    private isNa(day: Day, provider: string, document: RatesDocumentAllModel | null) {
        if (!document) {
            return false;
        }

        return this.getProviderPriceFromDocument(day, provider, document) === PRICE.NA;
    }
}
