import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import KpisMapDataModel from '@/modules/cars/modules/parity/models/kpis-map-data.model';
import ParitySettingsModel from '../models/parity-settings.model';
import ParityDocumentItemModel from '../models/parity-document-item.model';
import BrokerDataModel from '../models/broker-data.model';
import { ISort } from '../interfaces/grid.interface';

export interface ITrend {
    [date: string]: {
        B?: number;
        M?: number;
        L?: number;
        A?: number;
    }
}

export interface ISpread {
    [key: string]: number
}

export enum BmlOptions {
    B = 'B',
    M = 'M',
    L = 'L',
    A = 'A',
}

@Module
export default class ParityStore extends VuexModule {
    filtersReady: boolean = false;
    settings: ParitySettingsModel = new ParitySettingsModel();
    loading: LoadingModel = new LoadingModel();

    brokers: {
        items: BrokerDataModel[] | null;
        itemsCount: number | null
        loading: LoadingModel
        pageLimit: number;
    } = {
        items: null,
        itemsCount: null,
        loading: new LoadingModel(),
        pageLimit: 50,
    };

    kpis: {
        data: object | null,
        loading: LoadingModel,
    } = {
        data: null,
        loading: new LoadingModel(),
    };
    map: {
        data: KpisMapDataModel[] | null,
        loading: LoadingModel,
    } = {
        data: null,
        loading: new LoadingModel(),
    };

    table: {
        documents: ParityDocumentItemModel[] | null;
        documentsCount: number | null;
        currentDocumentId: number | null;
        pageLimit: number;
        loading: LoadingModel;
        sort: ISort;
    } = {
        documents: null,
        documentsCount: null,
        currentDocumentId: null,
        pageLimit: 50,
        loading: new LoadingModel(),
        sort: {
            sortBy: null,
            sortOrder: null,
        },
    };

    brokersPerformance: {
        data: { [index:string] : { [index:string] : number } } | null;
        loading: LoadingModel;
    } = {
        data: null,
        loading: new LoadingModel(),
    };

    trend: {
        data: ITrend | null;
        disabled: BmlOptions[];
        loading: LoadingModel;
    } = {
        data: null,
        disabled: [],
        loading: new LoadingModel(),
    };

    spread: {
        data: ISpread | null;
        loading: LoadingModel;
    } = {
        data: null,
        loading: new LoadingModel(),
    };
}
