enum METRICS_SCORE {
    MONTH = 1,
    MONTH3,
    MONTH6,
    YTD,
    YEAR,
    MAX,
}

export default METRICS_SCORE;
