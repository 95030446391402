import LoadingModel from '@/modules/common/models/loading.model';
import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import SHOW_BY from '@/modules/deep-analysis/constants/show-by-filter.constant';
import DeepAnalysisItemModel from '@/modules/deep-analysis/models/deep-analysis-item.model';
import { Module, VuexModule } from 'vuex-module-decorators';

@Module
export default class DeepAnalysisStore extends VuexModule {
    items: DeepAnalysisItemModel[] = [];
    loading: LoadingModel = new LoadingModel();
    showBy: SHOW_BY = 'stay.7';
    provider : string = 'all';
    compareTo : COMPARE_TO = COMPARE_TO.COMPSET_AVG;
}
