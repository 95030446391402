import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import FleetAvailabilityTrendsDocumentModel from '@/modules/cars/models/fleet-availability-trends-document.model';
import CarRatesPriceHistoryModel from '@/modules/cars/models/car-rates-price-history.model';
import Day from '@/modules/common/types/day.type';
import CarsSettingsModel from './models/cars-settings.model';
import DocumentFiltersModel from '../document-filters/models/document-filters.model';
import BranchesModel from './models/branches.model';

export const CarsPriceHistoryApiS = Symbol.for('CarsPriceHistoryApiS');
@injectable(CarsPriceHistoryApiS as unknown as string)
export default class CarsPriceHistoryApi {
    @Inject(ApiServiceS) private apiService!: ApiService;

    async getCarRatesPriceHistory(
        settings: DocumentFiltersModel,
        carSettings: CarsSettingsModel,
        chain: BranchesModel | null | undefined,
        day: Day,
    ): Promise<CarRatesPriceHistoryModel | null> {
        const { month, year } = settings;
        const {
            dataSource,
            lor,
            pos,
            currentDataSourceMode,
            pickUpCityCode,
            isVans,
        } = carSettings;

        const body: Record<string, any> = {
            comparison_type: currentDataSourceMode,
            day,
        };
        const chainId = chain ? chain.chainId : '';
        if (chain) {
            body.child_chain_id = chainId;
        }

        let encodedPOS = pos;
        if (pos) {
            encodedPOS = encodeURIComponent(pos);
        }

        if (isVans) {
            body.is_vans = isVans;
        }

        const { data } = await this.apiService.post(
            `/car/single-date-trend/${pickUpCityCode}/${pickUpCityCode}/${month + 1}/${year}/${dataSource}/${lor}/${encodedPOS}`,
            body,
        );

        if (!data) {
            return null;
        }

        return plainToClass(CarRatesPriceHistoryModel, <CarRatesPriceHistoryModel> data, { excludeExtraneousValues: true });
    }

    async getFleetAvailabilityTrendsDocument(day: number, fleetOccupancyId: number, pos: string) {
        const { data } = await this.apiService.get(`car/get-fleet-occupancy-trends-doc/${day}/${fleetOccupancyId}/${pos}`);

        if (!data) {
            return null;
        }

        return plainToClass(FleetAvailabilityTrendsDocumentModel, <unknown> data, { excludeExtraneousValues: true });
    }
}
