import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import VIEW_TYPE from '@/modules/deep-analysis/constants/view-type-filter.constant';

const CLUSTER_SORTING_VALUES = {
    [VIEW_TYPE.ABSOLUTE]: {
        [COMPARE_TO.COMPSET_AVG]: 'absolute_comp_set',
        [COMPARE_TO.LAST_YEAR]: 'absolute_last_year',
    },
    [VIEW_TYPE.PERFORMANCE]: {
        [COMPARE_TO.COMPSET_AVG]: 'performance_calculated_comp_set',
        [COMPARE_TO.LAST_YEAR]: 'performance_calculated_last_year',
    },
};

export default CLUSTER_SORTING_VALUES;
