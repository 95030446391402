import { Inject, injectable } from 'inversify-props';
import PROVIDERS_REGEXP from '@/modules/alerts/constants/providers-regexp.const';
import AlertsApiService, { AlertsApiServiceS } from '@/modules/alerts/alerts-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import AlertsStore from '@/modules/alerts/store/alerts-store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import AlertModel from '@/modules/alerts/models/alert.model';
import HelperService, { HelperServiceS } from '../common/services/helper.service';

export const AlertsServiceS = Symbol.for('AlertsServiceS');
@injectable(AlertsServiceS as unknown as string)
export default class AlertsService implements Stateable {
    @Inject(AlertsApiServiceS) private alertsApiService!: AlertsApiService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;

    readonly storeState: AlertsStore = this.storeFacade.getState('AlertsStore');

    constructor() {
        this.storeFacade.watch(
            () => this.userService.storeState.user,
            this.storeState.loading.reset.bind(this.storeState.loading),
        );
    }

    get alerts() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.alerts.concat().sort((a, b) => b.date.getTime() - a.date.getTime());
    }

    async loadData() {
        const { isViewAsChain, isViewAsCluster } = this.userService;
        const { isCarUser } = this.userService;

        let alerts: AlertModel[] | null;
        if (isCarUser) {
            alerts = await this.alertsApiService.getCarAlerts();
        } else if (isViewAsChain || isViewAsCluster) {
            alerts = await this.alertsApiService.getClusterAlerts();
        } else {
            alerts = await this.alertsApiService.getCiAlerts();
        }

        this.storeState.alerts = alerts || [];
        return true;
    }

    async markAlertAsRead(id: string) {
        this.alerts
            .find(a => a.id === id)!
            .read = true;

        await this.alertsApiService.markAlertAsRead(id);
    }

    async markAlertAsUnread(id: string) {
        const alertData = this.alerts.find(a => a.id === id);

        if (alertData) {
            alertData.read = false;
        }

        await this.alertsApiService.markAlertAsUnread(id);
    }

    async markAllAlertsAsRead() {
        const alertIds = this.alerts.map(a => a.id);
        await this.alertsApiService.markAlertsAsRead(alertIds);

        this.storeState.loading.reset();
    }

    async deleteAlert(id: string) {
        this.storeState.alerts = this.storeState.alerts.filter(a => a.id !== id);
        await this.alertsApiService.deleteAlert(id);
    }

    getProviderName(message: string) {
        const result = message.match(PROVIDERS_REGEXP);
        return result ? result[0] : null;
    }

    isLoading() {
        return this.storeState.loading.isLoading();
    }
}
