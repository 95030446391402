import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import use from '@/router/use';
import { container } from 'inversify-props';
import { Route, RouteConfig } from 'vue-router';
import { CommonMenuRoutes } from '../common.routes';
import MarketsHotelLevelRoutes, { MarketsHistory } from '../hotel/markets.routes';

export function ScheduledReports(prefix: string) {
    return {
        name: `${prefix}.scheduled-reports`,
        path: 'scheduled-reports',
        component: () => import('@/modules/cluster/pages/markets-cluster-reports.modal.page.vue'),
        meta: { modal: true },
    };
}

function DayMarketsCluster(prefix: string) {
    return [
        {
            name: `${prefix}.day-markets-source`,
            path: 'day-markets/:day/:hotelId/:compsetId/:source',
            component: () => import('@/modules/cluster/pages/day-market.modal.page.vue'),
            props: (route: Route) => ({
                hotelId: Number(route.params.hotelId),
                userType: route.params.userType,
                compsetId: route.params.compsetId,
                day: Number(route.params.day),
            }),
            children: [
                MarketsHistory(`${prefix}.day-markets-source`),
            ],
            meta: {
                modal: true,
            },
        },
        {
            name: `${prefix}.day-markets`,
            path: 'day-markets/:day/:hotelId/:compsetId',
            component: () => import('@/modules/cluster/pages/day-market.modal.page.vue'),
            props: (route: Route) => ({
                hotelId: Number(route.params.hotelId),
                userType: route.params.userType,
                compsetId: route.params.compsetId,
                day: Number(route.params.day),
            }),
            children: [
                MarketsHistory(`${prefix}.day-markets`),
            ],
            meta: {
                modal: true,
            },
        },
    ];
}

export default function MarketsClusterLevelRoutes(basePath: string, prefix: string): RouteConfig {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS)]),
        children: [
            {
                path: '',
                name: prefix,
                component: () => import('@/modules/cluster/pages/markets-cluster-list.page.vue'),
                children: [
                    ScheduledReports(prefix),
                    ...CommonMenuRoutes(prefix),
                    ...DayMarketsCluster(prefix),
                ],
            },
            MarketsHotelLevelRoutes(':hotelId', `${prefix}.hotel`),
        ],
    };
}
