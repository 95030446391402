import { Inject, injectable } from 'inversify-props';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import CLUSTER_SORTING_VALUES from '@/modules/deep-analysis/constants/cluster-sorting-values.constant';
import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import SHOW_BY from '@/modules/deep-analysis/constants/show-by-filter.constant';
import STATISTIC_NAMES from '@/modules/deep-analysis/constants/statistic-names.constant';
import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';
import TREND_TYPE from '@/modules/deep-analysis/constants/trend-type.constant';
import VALUE_TYPE from '@/modules/deep-analysis/constants/value-type.constant';
import VIEW_TYPE from '@/modules/deep-analysis/constants/view-type-filter.constant';
import DeepCompsetAnalysisApiService, { DeepCompsetAnalysisApiServiceS } from '@/modules/deep-analysis/deep-analysis-api.service';
import DeepCompsetAnalysisCommonService, { DeepCompsetAnalysisCommonServiceS } from '@/modules/deep-analysis/deep-analysis-common.service';
import HotelData from '@/modules/deep-analysis/interfaces/hotel-data';
import HotelStatisticItem from '@/modules/deep-analysis/interfaces/hotel-statistic-item';
import TotalIndicatorData from '@/modules/deep-analysis/interfaces/total-indicator.interface';
import DeepAnalysisClusterStore from '@/modules/deep-analysis/store/deep-analysis-cluster.store';

export const DeepCompsetAnalysisClusterServiceS = Symbol.for('DeepCompsetAnalysisClusterServiceS');
@injectable(DeepCompsetAnalysisClusterServiceS as unknown as string)
export default class DeepCompsetAnalysisClusterService {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(DeepCompsetAnalysisApiServiceS) private deepCompsetAnalysisApiService!: DeepCompsetAnalysisApiService;
    @Inject(DeepCompsetAnalysisCommonServiceS) private deepCompsetAnalysisCommonService!: DeepCompsetAnalysisCommonService;

    readonly storeState: DeepAnalysisClusterStore = this.storeFacade.getState('DeepAnalysisClusterStore');
    private isLoadingMoreData: boolean = false;

    async loadData() {
        const data = await this.deepCompsetAnalysisApiService
            .getDeepAnalisysCluster(this.params);

        if (data) {
            this.storeState.items = [...this.storeState.items, ...data.data];
            this.storeState.totalCount = data.total;
        }
        return true;
    }

    async loadMoreData() {
        if (!this.storeState.items || this.isLoadingMoreData) return;
        if (this.storeState.skip >= this.storeState.totalCount!) return;
        this.storeState.skip = ++this.storeState.skip;
        this.isLoadingMoreData = true;
        const data = await this.deepCompsetAnalysisApiService.getDeepAnalisysCluster(this.params);
        this.isLoadingMoreData = false;
        if (data) {
            this.storeState.items = [...this.storeState.items, ...data.data];
        }
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.items;
    }

    get sortingKey() {
        return this.storeState.sortingKey;
    }

    set sortingKey(value : STATISTIC_TYPE | null) {
        this.storeState.sortingKey = value;
    }

    get sortingType() {
        return this.storeState.sortingType;
    }

    set sortingType(value : -1 | 1) {
        this.storeState.sortingType = value;
    }

    get tableData(): HotelData[] {
        if (!this.data || !this.data.length) {
            return [];
        }

        const hotelsData = this.data.map(item => {
            const hotelStatistics = {} as {
                [key: string]: HotelStatisticItem
            };

            this.statistics.forEach((statistic: STATISTIC_TYPE) => {
                const providerData = item.providers[this.provider];
                if (providerData) {
                    const [type, value] = this.showBy.split('.');
                    const statistics = providerData.showBy[type]![value];

                    if (statistics && statistics[statistic]) {
                        hotelStatistics[statistic] = {} as HotelStatisticItem;
                        const hotelStat = hotelStatistics[statistic];

                        let diffProp: number | null = null;
                        let comparedValueProp: number | null = null;

                        hotelStat.valueType = this.deepCompsetAnalysisCommonService.getValueType(statistic);
                        hotelStat.currency = item.providers[this.provider].currency;
                        hotelStat.brandTrend = this.getTrend(statistic, statistics[statistic].performanceMyHotel);

                        if (this.viewType === VIEW_TYPE.ABSOLUTE) {
                            const {
                                myHotel,
                                absoluteCompSet,
                                absoluteLastYear,
                                compSet,
                                lastYear,
                            } = statistics[statistic];

                            hotelStat.brand = this.getFormattedValue(myHotel, statistic);

                            switch (this.compareTo) {
                                case COMPARE_TO.COMPSET_AVG:
                                    diffProp = absoluteCompSet;
                                    comparedValueProp = compSet;
                                    break;

                                case COMPARE_TO.LAST_YEAR:
                                    diffProp = absoluteLastYear;
                                    comparedValueProp = lastYear;
                                    break;

                                default: break;
                            }

                            hotelStat.diff = this.getAbsoluteValue(diffProp);
                            hotelStat.diffTrend = this.getTrend(statistic, diffProp);
                            hotelStat.comparedValue = this.getFormattedValue(comparedValueProp, statistic);
                        } else {
                            const {
                                performanceMyHotel,
                                performanceCalculatedCompSet,
                                performanceCompSet,
                                performanceCalculatedLastYear,
                                performanceLastYear,
                            } = statistics[statistic];

                            hotelStat.brand = this.getAbsoluteValue(performanceMyHotel);
                            hotelStat.valueType = VALUE_TYPE.PERCENT;

                            switch (this.compareTo) {
                                case COMPARE_TO.COMPSET_AVG:
                                    diffProp = performanceCalculatedCompSet;
                                    comparedValueProp = performanceCompSet;
                                    break;

                                case COMPARE_TO.LAST_YEAR:
                                    diffProp = performanceCalculatedLastYear;
                                    comparedValueProp = performanceLastYear;
                                    break;

                                default: break;
                            }

                            hotelStat.diff = this.getAbsoluteValue(diffProp);
                            hotelStat.diffTrend = this.getTrend(statistic, diffProp);
                            hotelStat.comparedValue = this.getAbsoluteValue(comparedValueProp);
                        }
                    }
                }
            });
            return {
                id: item.fornovaId,
                name: item.hotelName,
                statistics: hotelStatistics,
            };
        });
        return hotelsData;
    }

    get totalData(): TotalIndicatorData[] {
        return this.statistics.map(statistic => {
            const noChange = this.getNoChangeCount(statistic);
            const decrease = this.getDecreaseCount(statistic);
            const increase = this.getIncreasedCount(statistic);
            const total = noChange + decrease + increase;
            return {
                total,
                noChange,
                decrease,
                increase,
                name: STATISTIC_NAMES[statistic],
            };
        });
    }

    setStatisticFilter(statistic: STATISTIC_TYPE | null, trend: TREND_TYPE | null) {
        this.storeState.filterByStatistic = [statistic, trend];
    }

    getIncreasedCount(statistic: STATISTIC_TYPE) {
        return this.tableData.filter(item => item.statistics[statistic]
            && item.statistics[statistic].diffTrend === TREND_TYPE.HIGH).length;
    }

    getDecreaseCount(statistic: STATISTIC_TYPE) {
        return this.tableData.filter(item => item.statistics[statistic]
            && item.statistics[statistic].diffTrend === TREND_TYPE.lOW).length;
    }

    getNoChangeCount(statistic: STATISTIC_TYPE) {
        return this.tableData.filter(item => item.statistics[statistic]
            && item.statistics[statistic].diffTrend === TREND_TYPE.NO_CHANGE).length;
    }

    getFormattedValue(val: number | string | null, statistic: STATISTIC_TYPE) {
        return this.deepCompsetAnalysisCommonService
            .getFormattedValue(val, statistic, this.isPerformance);
    }

    getAbsoluteValue(val: number | string | null) {
        return this.deepCompsetAnalysisCommonService
            .getAbsoluteValue(val);
    }

    sort(sortingType: -1 | 1, sortingKey: STATISTIC_TYPE) {
        if (this.sortingKey === sortingKey && this.sortingType === sortingType) {
            this.sortingKey = null;
            this.sortingType = 1;
        } else {
            this.sortingKey = sortingKey;
            this.sortingType = sortingType;
        }
        this.storeState.skip = 0;
        this.storeState.items = [];
        this.storeState.loading.reset();
    }

    getTrend(statistic: STATISTIC_TYPE, value: number | null) {
        const exceptions = [STATISTIC_TYPE.CANCELLATION, STATISTIC_TYPE.AVERAGE_BOOKING_WINDOW];
        if (value === null) {
            return null;
        } if (value > 0) {
            return !exceptions.includes(statistic)
                ? TREND_TYPE.HIGH
                : TREND_TYPE.lOW;
        }
        if (value < 0) {
            return !exceptions.includes(statistic)
                ? TREND_TYPE.lOW
                : TREND_TYPE.HIGH;
        }
        return TREND_TYPE.NO_CHANGE;
    }

    async downloadExcel() {
        // TODO add notifications
        const excelData = await this.deepCompsetAnalysisApiService
            .getClusterExcel(this.storeState);
        const url = window.URL.createObjectURL(excelData);

        const name = 'compset-benchmark-hotel.xlsx';
        const link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('download', name);
        link.href = url;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    get calculatedFields() {
        return this.deepCompsetAnalysisCommonService
            .getCalculatedFields(this.provider, this.showBy, this.compareTo);
    }

    get statistics() {
        return this.deepCompsetAnalysisCommonService
            .getsStatistics(this.provider, this.showBy);
    }

    get isPerformance() {
        return this.viewType === VIEW_TYPE.PERFORMANCE;
    }

    get viewType() {
        return this.storeState.view;
    }

    set viewType(value: VIEW_TYPE) {
        this.storeState.view = value;
    }

    get provider() {
        return this.storeState.provider;
    }

    set provider(value: string) {
        this.storeState.provider = value;
    }

    get showBy() {
        return this.storeState.showBy;
    }

    set showBy(value: SHOW_BY) {
        this.storeState.showBy = value;
    }

    get compareTo() {
        return this.storeState.compareTo;
    }

    set compareTo(value: COMPARE_TO) {
        this.storeState.compareTo = value;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get updatedAt() {
        return this.storeState.items.length ? this.storeState.items[0].updateDate : null;
    }

    private get params() {
        const showBy = this.showBy
            .replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
            .replace('stay', 'last').replace('book', 'next'); // TODO: make show by like snake_case

        const valueType = CLUSTER_SORTING_VALUES[this.viewType][this.compareTo];
        const defaultSortingKey = 'hotel_name';
        const key = this.sortingKey
            ? `details.${this.provider}.${showBy}.${this.sortingKey}.${valueType}`
            : defaultSortingKey;

        return {
            skip: this.storeState.skip,
            limit: this.storeState.limit,
            sort: { [key]: this.sortingType },
        };
    }
}
