import { Inject, injectable } from 'inversify-props';
import { Route } from 'vue-router';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { IGuard } from '@/router/interfaces/guard.interface';

export const ClusterOrChainGuardS = Symbol.for('ClusterOrChainGuardS');
@injectable(ClusterOrChainGuardS as unknown as string)
export default class ClusterOrChainGuard implements IGuard {
    @Inject(UserServiceS) private userService!: UserService;

    async canActivate(to: Route, from: Route) {
        return this.userService.isChainUser || this.userService.isClusterUser || false;
    }
}
