import { Expose, plainToClass, Transform } from 'class-transformer';
import {
    IsNotEmpty, IsOptional, Length, IsPositive,
} from 'class-validator';
import EventsLocationModel from '@/modules/events/models/events-location.model';

export default class EventsModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain._id && String(plain._id))
    id: string | null = null;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.chain_id)
    chainId: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.marketId)
    marketId: number | null = null;

    @Expose()
    @Transform((_, plain) => plain.name || plain.event_name)
    @Length(2, 20)
    @IsNotEmpty({ message: 'Name should not be empty' })
    name: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.type && plain.type.toLowerCase())
    @IsNotEmpty({ message: 'Event type should not be empty' })
    type: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.starts || plain.start_date)
    @IsNotEmpty({ message: 'Start Date should not be empty' })
    startDate: Date | null = null;

    @Expose()
    @Transform((_, plain) => plain.ends || plain.end_date)
    @IsNotEmpty({ message: 'End Date should not be empty' })
    endDate: Date | null = null;

    @Expose()
    @Transform((_, plain) => plain.country)
    country: string | null = null;

    @Expose()
    @Transform((_, plain) => (plain.country_code && plain.country_code.toLowerCase()) || (plain.country && plain.country.toLowerCase()))
    countryCode: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.description || plain.event_description)
    @Length(2, 100)
    @IsNotEmpty()
    description: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.no_of_guests || plain.expected_nog)
    @IsPositive({ message: 'Attendees Should Be A Number More Than 0' })
    @IsNotEmpty({ message: 'Attendees is required and should be a number' })
    numberOfGuests: number | null = null;

    @Expose()
    @Transform((_, plain) => plain.owner_type)
    ownerType: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.owner_id)
    ownerId: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.fornovaId)
    fornovaId: number | null = null;

    @Expose()
    @Transform((_, plain) => plainToClass(EventsLocationModel, <EventsLocationModel>plain.location, { excludeExtraneousValues: true }))
    location: EventsLocationModel | null = null;

    @Expose()
    @IsOptional()
    @Transform((_, plain) => plain.privacy)
    privacy?: string;

    isHoliday?: boolean;
    isMy?: boolean;
    isSuggested?: boolean;
    isChain?: boolean;
}
