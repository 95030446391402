import { Inject, injectable } from 'inversify-props';
import { TrendData } from '@/modules/deep-analysis/interfaces/deep-analisys-chart-data.interface';
import { DeepCompsetAnalysisItem } from '@/modules/deep-analysis/interfaces/deep-analisys-item.interface';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import SHOW_BY from '@/modules/deep-analysis/constants/show-by-filter.constant';
import STATISTIC_NAMES from '@/modules/deep-analysis/constants/statistic-names.constant';
import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';
import TREND_TYPE from '@/modules/deep-analysis/constants/trend-type.constant';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import DeepAnalysisStore from '@/modules/deep-analysis/store/deep-analysis.store';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import DeepCompsetAnalysisApiService, { DeepCompsetAnalysisApiServiceS } from '@/modules/deep-analysis/deep-analysis-api.service';
import DeepCompsetAnalysisCommonService, { DeepCompsetAnalysisCommonServiceS } from '@/modules/deep-analysis/deep-analysis-common.service';

export const DeepCompsetAnalysisServiceS = Symbol.for('DeepCompsetAnalysisServiceS');
@injectable(DeepCompsetAnalysisServiceS as unknown as string)
export default class DeepCompsetAnalysisService {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(DeepCompsetAnalysisApiServiceS) private deepCompsetAnalysisApiService!: DeepCompsetAnalysisApiService;
    @Inject(DeepCompsetAnalysisCommonServiceS) private deepCompsetAnalysisCommonService!: DeepCompsetAnalysisCommonService;

    readonly storeState: DeepAnalysisStore = this.storeFacade.getState('DeepAnalysisStore');

    async loadData() {
        this.storeState.items = await this.deepCompsetAnalysisApiService.getDeepAnalisys() || [];
        return true;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));

        const list = [] as DeepCompsetAnalysisItem[];
        const { items } = this.storeState;

        let lastUpdatedItemIndex = null;
        let prevUpdatedItemIndex = null;

        for (let i = 0; i < items.length; i++) {
            if (items[i].updateDate && items[i].providers[this.provider]) {
                if (lastUpdatedItemIndex == null) {
                    lastUpdatedItemIndex = i;
                } else if (prevUpdatedItemIndex == null) {
                    prevUpdatedItemIndex = i;
                } else if (lastUpdatedItemIndex && prevUpdatedItemIndex) {
                    break;
                }
            }
        }

        if (lastUpdatedItemIndex === null) {
            return list;
        }

        const [showType, showDays] = this.showBy.split('.');
        const lastUpdatedProviderData = this.storeState.items[lastUpdatedItemIndex].providers[this.provider];

        if (!lastUpdatedProviderData) {
            return list;
        }

        const lastUpdatedItemShow = lastUpdatedProviderData.showBy[showType];

        if (!lastUpdatedItemShow) {
            return list;
        }

        const lastUpdatedItem = lastUpdatedItemShow[showDays];

        if (!lastUpdatedItem) {
            return list;
        }

        const myHotelData = lastUpdatedItem.myHotel;
        const comparedData = lastUpdatedItem[this.compareTo];
        const updatedDate = lastUpdatedItem.updateDate;

        let myHotelDataPrev: {
            [name: string]: number;
        }| null = null;

        let comparedDataPrev: {
            [name: string]: number;
        }| null = null;

        if (prevUpdatedItemIndex) {
            const previousUpdatedItemShow = this.storeState
                .items[prevUpdatedItemIndex]
                .providers[this.provider]!
                .showBy[showType];

            if (previousUpdatedItemShow && previousUpdatedItemShow[showDays]) {
                myHotelDataPrev = previousUpdatedItemShow[showDays]!.myHotel;
                comparedDataPrev = previousUpdatedItemShow[showDays]![this.compareTo];
            }
        }

        this.statistics.forEach((value, index) => {
            const data = [] as TrendData[];
            const dates = [] as Date[];

            this.storeState.items.forEach((item, i) => {
                let brand = null;
                let compsetAvg = null;
                if (item.providers) {
                    const provider = item.providers[this.provider];

                    if (provider) {
                        const showBy = provider.showBy[showType];

                        if (showBy && showBy[showDays]) {
                            brand = showBy[showDays]!.myHotel[value];
                            compsetAvg = showBy[showDays]![this.compareTo][value];
                        }
                    }
                }
                data.push(<TrendData>{
                    brand: this.deepCompsetAnalysisCommonService.getFormattedValue(brand, value),
                    compsetAvg: this.deepCompsetAnalysisCommonService.getFormattedValue(compsetAvg, value),
                });
                dates.push(item.updateDate);
            });
            const brandValue = myHotelData[value];
            const comparedValue = comparedData[value];

            const myHotelTrend = this.getTrend(myHotelData, myHotelDataPrev, value);
            const comparedTrend = this.getTrend(comparedData, comparedDataPrev, value);
            const updateDateField = updatedDate ? updatedDate[value] : null;

            list.push({
                chartData: {
                    data,
                    dates: this.fillEmptyDates(dates),
                },
                compsetAverage: {
                    note: this.calculatedFields.includes(value) ? 'This is an estimated value' : '',
                    subtitle: this.ifFairShare(value) ? 'Fair Share' : '',
                    trend: comparedTrend ? comparedTrend.trend : null,
                    assessment: comparedTrend ? comparedTrend.assessment : null,
                    value: this.deepCompsetAnalysisCommonService.getFormattedValue(comparedValue, value),
                    hasStar: this.calculatedFields.includes(value),
                },
                hasModal: false,
                id: index,
                myHotel: {
                    note: '',
                    subtitle: '',
                    trend: myHotelTrend ? myHotelTrend.trend : null,
                    assessment: myHotelTrend ? myHotelTrend.assessment : null,
                    value: this.deepCompsetAnalysisCommonService.getFormattedValue(brandValue, value),
                    hasStar: false,
                },
                updatedDateField: updateDateField,
                title: STATISTIC_NAMES[value],
                tooltip: STATISTIC_NAMES[value],
                updatedAt: lastUpdatedProviderData.updateDate,
                valueType: this.deepCompsetAnalysisCommonService.getValueType(value),
                currency: lastUpdatedProviderData.currency,
            });
        });
        return list;
    }

    fillEmptyDates(dates: (Date|null)[]) {
        const { items } = this.storeState;
        const index = items.findIndex(item => item.updateDate !== null);
        const { updateDate } = items[index];
        const startDay = new Date(updateDate);

        startDay.setDate(startDay.getDate() + index + 1);

        return dates.map((date, i) => {
            const newDate = new Date(startDay);
            newDate.setDate(newDate.getDate() - i - 1);
            return newDate;
        }).reverse();
    }

    ifFairShare(statisticType: STATISTIC_TYPE) {
        return statisticType === STATISTIC_TYPE.REVENUE_SHARE && this.provider === 'expedia';
    }

    getTrend(current: any, prev: any, statistic: STATISTIC_TYPE): {
        trend: TREND_TYPE | null,
        assessment: ASSESSMENT_TYPES | null
    } | null {
        if (!current || !prev) {
            return null;
        }
        const currentValue = current[statistic];
        const prevValue = prev[statistic];
        if (typeof currentValue !== 'number' || typeof prevValue !== 'number') {
            return null;
        }
        let trend = null;
        let assessment = null;
        const exceptions = [STATISTIC_TYPE.CANCELLATION, STATISTIC_TYPE.AVERAGE_BOOKING_WINDOW];

        if (currentValue > prevValue) {
            trend = TREND_TYPE.HIGH;
            assessment = !exceptions.includes(statistic) ? ASSESSMENT_TYPES.GOOD : ASSESSMENT_TYPES.BAD;
        }
        if (currentValue < prevValue) {
            trend = TREND_TYPE.lOW;
            assessment = !exceptions.includes(statistic) ? ASSESSMENT_TYPES.BAD : ASSESSMENT_TYPES.GOOD;
        }
        return {
            trend,
            assessment,
        };
    }

    async downloadExcel() {
        // TODO add notifications
        const excelData = await this.deepCompsetAnalysisApiService.getHotelExcel(this.storeState);
        const url = window.URL.createObjectURL(excelData);

        const name = 'compset-benchmark-hotel.xlsx';
        const link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('download', name);
        link.href = url;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    get calculatedFields() {
        return this.deepCompsetAnalysisCommonService.getCalculatedFields(this.provider, this.showBy, this.compareTo);
    }

    get statistics() {
        return this.deepCompsetAnalysisCommonService.getsStatistics(this.provider, this.showBy);
    }

    get updatedAt() {
        const availableItems = this.storeState.items.filter(item => item.updateDate !== null);
        return availableItems.length ? availableItems[0].updateDate : null;
    }

    get provider() {
        return this.storeState.provider;
    }

    set provider(value: string) {
        this.storeState.provider = value;
    }

    get showBy() {
        return this.storeState.showBy;
    }

    set showBy(value: SHOW_BY) {
        this.storeState.showBy = value;
    }

    get compareTo() {
        return this.storeState.compareTo;
    }

    set compareTo(value: COMPARE_TO) {
        this.storeState.compareTo = value;
    }

    get loading() {
        return this.storeState.loading;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }
}
