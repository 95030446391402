import { Inject, injectable } from 'inversify-props';
import _ from 'lodash';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import Day from '../common/types/day.type';
import Price from '../common/types/price.type';
import CarsService, { CarsServiceS } from './cars.service';
import CALENDAR_DATA_SOURCE from './constants/calendar-data-source.constant';
import { BROKER } from './constants/data-source-mode.constant';
import { comparisonDays } from './models/cars-analysis.model';
import CarsDocumentItemModel from './models/cars-document-item.model';
import CarsDocumentModel from './models/cars-document.model';
import CarsPriceHistoryDocumentItemModel from './models/cars-price-history-document-item.model';
import CAR_PRICE_TYPE from './constants/car-price-type.constant';
import { BRAND_AS_BROKER_ANY } from './constants/car-filter-types.constant';

export const CarsAnalysisServiceS = Symbol.for('CarsAnalysisServiceS');
@injectable(CarsAnalysisServiceS as unknown as string)
export default class CarsAnalysisService extends CarsService {
    @Inject(CarsServiceS) private carsService!: CarsService;

    constructor() {
        // Don't create duplicate watcher in parent constructor
        super(false);

        if (this.storeState.filtersReady && this.storeState.analysis.filtersReady) {
            this.initWatcher();
        } else {
            this.storeFacade.watch(() => [
                this.storeState.filtersReady,
                this.storeState.analysis.filtersReady,
            ], this.initWatcher.bind(this));
        }
    }

    initWatcher() {
        if (this.storeState.filtersReady && this.storeState.analysis.filtersReady && !this.storeState.analysis.filtersWatched) {
            this.storeState.analysis.filtersWatched = true;
            this.storeFacade.watch(() => [
                this.documentFiltersService.storeState.settings.year,
                this.documentFiltersService.storeState.settings.month,
                this.storeState.settings.pickUpCityCode,
                this.storeState.settings.dataSource,
                this.storeState.settings.lor,
                this.storeState.settings.pos,
                this.storeState.settings.isAvgPrice,
                this.storeState.filtersReady,
                // Analysis-specific filters
                this.storeState.analysis.settings.comparisonFilter.key,
                this.storeState.analysis.settings.comparisonFilter.values,
                this.storeState.analysis.filtersReady,
            ], this.resetLoadings.bind(this));
        }
    }

    resetLoadings() {
        if (this.storeState.filtersReady && this.storeState.analysis.filtersReady && !this.storeState.analysis.loading.isLoading()) {
            this.storeState.analysis.loading.reset();
        }
    }

    getCarDifference(day: Day, competitor: string, raw: boolean = false) {
        let currentCar;
        let analysisCar;
        if (this.shouldComparePhases) {
            analysisCar = this.getPriceFromPhases(day, competitor, true);
            currentCar = this.getPriceFromPhases(day, competitor, false);
        } else {
            currentCar = this.carsService.getPrice(day, competitor);
            analysisCar = this.getPrice(day, competitor);
        }

        if (!currentCar || !analysisCar) return 'NA';

        const dividerBy = (currentCar + analysisCar) / 2;

        const difference = currentCar - analysisCar;
        const divider = raw
            ? 1
            : dividerBy || 1;

        return difference / divider;
    }

    get data() {
        if (this.storeState.filtersReady && this.storeState.analysis.filtersReady) {
            this.helperService.dynamicLoading(this.storeState.analysis.loading, this.loadData.bind(this));
        }
        return this.storeState.analysis.documents;
    }

    async loadData() {
        if (!this.userService.isCarUser) {
            return false;
        }

        const { settings } = this.documentFiltersService.storeState;
        const carSettings = this.storeState.settings;

        if (
            settings.month === null
            || settings.year === null
            || carSettings.pickUpCityCode === null
            || carSettings.dataSource === null
            || carSettings.lor === null
            || carSettings.pos === null
        ) {
            return false;
        }
        this.storeState.analysis.documents = null;

        const chain = this.currentChain;
        const { clusterMode } = this.carFiltersStoreState.settings.features;

        const pickUpCityCodes = (carSettings.isAvgPrice && clusterMode) ? this.currentClusterLocationIds.join(',') : carSettings.pickUpCityCode;
        const categories = [...(carSettings.carClasses || [])];
        const { comparisonFilter } = this.storeState.analysis.settings;
        const carsDocument = await this.carApiService.getCarsAnalysisDocument(
            settings,
            carSettings,
            chain,
            pickUpCityCodes,
            categories,
            comparisonFilter.key,
            comparisonFilter.values.value,
        );

        if (carsDocument) {
            // Update documentVendors
            this.documentFiltersService.settings.documentVendors = this.getDocumentVendors(carsDocument);

            this.storeState.analysis.documents = carsDocument as CarsDocumentModel;
        }

        return true;
    }

    allCars(
        day: Day,
        isNeedFilter = true,
        broker?: string,
        calendarDataSource?: CALENDAR_DATA_SOURCE,
    ): { [company: string]: CarsDocumentItemModel | CarsPriceHistoryDocumentItemModel | null } | null | false {
        const dataFrom = calendarDataSource || CALENDAR_DATA_SOURCE.GROUPED;
        const currentDay = (dataFrom === CALENDAR_DATA_SOURCE.GROUPED)
            ? day
            : this.getPreviousDateByDay(day);
        const companyCars = this.filterCarClasses(currentDay, dataFrom);
        return this.getAllCarsFilters(companyCars, isNeedFilter, broker);
    }

    allCarsFromPhases(
        day: Day,
        isNeedFilter = true,
        phase: boolean,
    ): { [company: string]: CarsDocumentItemModel | CarsPriceHistoryDocumentItemModel | null } | null | false {
        const currentDay = day;
        const companyCars = this.filterCarClassesFromPhases(currentDay, phase);
        return this.getAllCarsFilters(companyCars, isNeedFilter);
    }

    getAllCarsFilters(
        companyCars: any,
        isNeedFilter: boolean,
        broker?: string,
    ): { [company: string]: CarsDocumentItemModel | CarsPriceHistoryDocumentItemModel | null } | null | false {
        const { isAvgPrice } = this.storeState.settings;
        if (!companyCars) {
            return null;
        }
        const currentCompany = this.currentCompanyCars(companyCars);
        let selectedCompetitors = this.resolveCompetitorsSelected(companyCars);

        if (currentCompany) {
            selectedCompetitors.push(currentCompany);
        }

        if (broker) {
            selectedCompetitors = this.getAllBrandsByBroker(companyCars, broker);
        }

        let filteredCars: { [company: string]: CarsDocumentItemModel[] } | null;

        if (!isAvgPrice) {
            filteredCars = this.filterCompetitors(companyCars, selectedCompetitors);
            filteredCars = this.filterTransmission(filteredCars);
        } else {
            filteredCars = this.filterCompetitors(companyCars, selectedCompetitors);
        }

        if (!filteredCars) {
            return null;
        }

        if (isNeedFilter || isAvgPrice) {
            Object.keys(filteredCars).forEach(company => {
                if (filteredCars && filteredCars[company]) {
                    filteredCars[company] = filteredCars[company].filter(car => car.priceNet || car.priceTotal || car.priceShown);
                }
            });
        }
        const allCars = (isNeedFilter) ? this.getAllCars(filteredCars, currentCompany) : this.getAllCarsPopup(filteredCars, currentCompany);
        return Object.keys(allCars).length ? allCars : false;
    }

    getAllCarsPopup(filteredCars: any, currentCompany: string | null) {
        return Object.keys(filteredCars).reduce((acc: { [company: string]: CarsDocumentItemModel | null }, company) => {
            if (filteredCars[company] && filteredCars[company].length) {
                acc[company] = filteredCars[company].reduce((
                    cheapestCar: CarsDocumentItemModel,
                    car: CarsDocumentItemModel,
                ) => {
                    if (!cheapestCar.priceNet && !cheapestCar.priceTotal && !cheapestCar.priceShown) {
                        return car;
                    }
                    if (!car.priceNet && !car.priceTotal && !car.priceShown) {
                        return cheapestCar;
                    }
                    const price = this.getCarPrice(car, company);
                    const cheapestPrice = this.getCarPrice(cheapestCar, company);
                    const selectedCar = price <= cheapestPrice ? car : cheapestCar;
                    return selectedCar;
                });
                if (acc[company]) {
                    acc[company]!.isMainCar = (company === currentCompany);
                }
            } else {
                delete acc[company];
            }
            return acc;
        }, {});
    }

    filterCarClasses(day: Day | string, calendarDataSource?: CALENDAR_DATA_SOURCE) {
        let checkinDate: any = {};

        const { data } = this;
        if (!data) {
            return null;
        }
        const { carClasses } = this.storeState.settings;
        const { currentDocumentCarClasses } = this.storeState.settings;
        if (carClasses === null || currentDocumentCarClasses === null) {
            return null;
        }
        const selectedCarClasses = currentDocumentCarClasses.filter(item => carClasses.includes(item));

        if (typeof day === 'number') {
            checkinDate = data.checkinDates[day];
        }

        if (checkinDate === null) {
            return null;
        }

        if (!checkinDate) {
            return null;
        }

        const companyCars: { [company: string]: CarsDocumentItemModel[] } = this.getCompanyCarsFromTrends(checkinDate, selectedCarClasses);

        return companyCars;
    }

    filterCarClassesFromPhases(day: Day | string, phase: boolean) {
        let checkinDate: any = {};

        const { data } = this;
        if (!data) {
            return null;
        }
        const { carClasses } = this.storeState.settings;
        const { currentDocumentCarClasses } = this.storeState.settings;
        if (carClasses === null || currentDocumentCarClasses === null) {
            return null;
        }
        const selectedCarClasses = currentDocumentCarClasses.filter(item => carClasses.includes(item));

        if (typeof day === 'number') {
            checkinDate = data.checkinDates[day];
        }

        if (checkinDate === null) {
            return null;
        }

        if (!checkinDate) {
            return null;
        }

        const companyCars: { [company: string]: CarsDocumentItemModel[] } = this.getCompanyCarsFromGroupDocumentsFromPhases(checkinDate, selectedCarClasses, phase);

        return companyCars;
    }

    getCompanyCarsFromGroupDocumentsFromPhases(checkinDate: Record<string, any>, selectedCarClasses: any, phase: boolean) {
        if (!Object.keys(checkinDate).length || !checkinDate.phase) {
            return {};
        }
        const phases = Object.keys(checkinDate.phase).slice(-2);
        if (phase && phases.length <= 1) {
            return {};
        }

        const scanPhase = phase || phases.length === 1 ? phases[0] : phases[1];

        const checkinDateLastPhase = checkinDate.phase[scanPhase];
        const companyCars: { [company: string]: CarsDocumentItemModel[] } = Object.keys(checkinDateLastPhase).reduce((acc: { [company: string]: CarsDocumentItemModel[] }, company) => {
            acc[company] = Object.keys(checkinDateLastPhase[company]).reduce((carsDocumentItems: CarsDocumentItemModel[], companyCarClass) => {
                if (selectedCarClasses.find((carClass: string) => carClass === companyCarClass)) {
                    return carsDocumentItems.concat(checkinDateLastPhase[company][companyCarClass]);
                }
                return carsDocumentItems;
            }, []);

            return acc;
        }, {});
        return companyCars;
    }

    getCarDataTableFromPhases(day: Day, nameOfCompany: string, phase: boolean) {
        const { chainMode } = this.carFiltersStoreState.settings;
        const allCars = this.allCarsFromPhases(day, false, phase);
        let company = nameOfCompany;

        const defaultValue: { price?: number, isAvailable?: boolean } = {
            price: undefined,
            isAvailable: undefined,
        };

        if (chainMode === BROKER) {
            company = _.keys(allCars).find(brand => brand.split(',')[0] === nameOfCompany) || '';
        }

        if (!allCars || !allCars[company]) {
            defaultValue.isAvailable = true;
            return defaultValue;
        }

        return allCars[company];
    }

    get shouldComparePhases() {
        const { comparisonFilter } = this.storeState.analysis.settings;
        return comparisonFilter.values.name === comparisonDays[0].name;
    }

    get isLoading() {
        return this.storeState.analysis.loading.isLoading();
    }

    getPriceFromPhases(day: Day, competitor: string, phase: boolean) {
        const car = this.getCarDataTableFromPhases(day, competitor, phase) as CarsDocumentItemModel;
        return this.getCarPrice(car, competitor);
    }

    isNoData(day: Day, phase?: boolean) {
        if (!this.storeState.analysis.documents) {
            return true;
        }

        if (!this.storeState.analysis.documents.checkinDates[day]) {
            return true;
        }

        if (this.shouldComparePhases) {
            const checkinDate = this.storeState.analysis.documents.checkinDates[day] || {};
            if (checkinDate.phase) {
                const phases = Object.keys(checkinDate.phase).slice(-2);
                if (phase && phases.length <= 1) {
                    return true;
                }
                const scanPhase = phase || phases.length === 1 ? phases[0] : phases[1];
                return !!checkinDate[scanPhase];
            }
        }
        return false;
    }

    get currentCompany() {
        const { isBrokerToBrand, currentBrandAsBroker } = this.carsService;
        if (isBrokerToBrand) {
            return (currentBrandAsBroker === BRAND_AS_BROKER_ANY ? this.userService.currentCompany : currentBrandAsBroker) || '';
        }
        return this.userService.currentCompany || '';
    }

    getTableAssessmentFromPhases(price: Price, day: Day, phase: boolean): ASSESSMENTS_TYPES | null {
        const { priceType } = this.storeState.settings;
        const currentCar = this.getCarDataTableFromPhases(day, this.currentCompany, phase) as CarsDocumentItemModel;
        const { currentCompany } = this.userService;

        if (currentCar === null || currentCompany === null) {
            return null;
        }

        if (priceType === CAR_PRICE_TYPE.HIGHEST || priceType === CAR_PRICE_TYPE.MEDIAN) {
            return price > this.getCarPrice(currentCar, currentCompany) ? ASSESSMENTS_TYPES.GOOD : ASSESSMENTS_TYPES.BAD;
        }

        return price <= this.getCarPrice(currentCar, currentCompany) ? ASSESSMENTS_TYPES.GOOD : ASSESSMENTS_TYPES.BAD;
    }
}
